<template>
  <v-container pa-0 fluid>
    <v-row no-gutters>
      <v-col cols="12" class="gradient-icons">
        <v-row justify="center" align="center" no-gutters>
          <div class="text-center py-10">
            <v-icon size="100" color="accent">{{ titleIcon }}</v-icon>
            <div class="headline font-weight-bold mt-5 mb-5">{{ title }}</div>
            <div class="subtitle-1">{{ description }}</div>
            <v-btn v-if="reloadButton" @click="$reloadApp" class="mt-5" color="primary"
              ><v-icon :class="ButtonIconColor && 'no-gradient'" :color="ButtonIconColor" left>mdi-refresh</v-icon>
              {{ $t('reload') }}</v-btn
            >
            <v-btn v-if="functionButton" @click="propFunction" class="mt-5" color="primary"
              ><v-icon :class="ButtonIconColor && 'no-gradient'" :color="ButtonIconColor" left>{{ functionButtonIcon }}</v-icon>
              {{ functionButtonText }}</v-btn
            >
          </div>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'NoContentView',
  props: {
    titleIcon: String,
    title: String,
    description: String,
    reloadButton: {
      type: Boolean,
      default: true,
    },
    functionButton: {
      type: Boolean,
      default: false,
    },
    propFunction: Function,
    functionButtonIcon: String,
    ButtonIconColor: String,
    functionButtonText: String,
  },
};
</script>

<style scoped></style>
