<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-dialog
    v-model="dialog"
    persistent
    :fullscreen="$vuetify.breakpoint.xsOnly"
    :max-width="$vuetify.breakpoint.xsOnly ? '' : '600'"
    :hide-overlay="$vuetify.breakpoint.xsOnly"
    :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : ''"
  >
    <v-form style="height: 100%" ref="form">
      <v-card style="height: 100%">
        <v-toolbar dark color="primary">
          <v-toolbar-title>{{ workTask && workTask.id ? $t('work_tasks.edit') : $t('work_tasks.new') }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="gradient-icons">
          <v-text-field
            tabindex="1"
            :disabled="saving"
            outlined
            dense
            hide-details
            validate-on-blur
            type="text"
            class="mb-4"
            :label="$t('work_tasks.subject')"
            light
            autocomplete="off"
            clearable
            v-model="workTask.title"
          >
            <template v-slot:prepend>
              <v-icon>mdi mdi-text-short</v-icon>
            </template>
          </v-text-field>
          <v-textarea
            tabindex="2"
            :disabled="saving"
            :rules="[$rules.required]"
            outlined
            dense
            hide-details
            type="text"
            class="mb-4 mt-4"
            :label="$t('work_tasks.message')"
            light
            autocomplete="off"
            auto-grow
            clearable
            v-model="workTask.message"
          >
            <template v-slot:prepend>
              <v-icon>mdi mdi-message-text</v-icon>
            </template>
          </v-textarea>

          <v-autocomplete
            tabindex="3"
            :disabled="!($isTenantAdmin || $isAdmin || workTask.assignerUserId === $userInfo.id)"
            v-model="workTask.assignedUserId"
            validate-on-blur
            :items="richerUsers"
            :item-text="
              item => {
                return `${item.lastName} ${item.firstName}`;
              }
            "
            item-value="id"
            :label="$t('work_tasks.assigned_user')"
            outlined
            dense
            class="mt-4"
          >
            <template v-slot:prepend>
              <v-icon>mdi mdi-account</v-icon>
            </template>
          </v-autocomplete>
          <v-autocomplete
            tabindex="4"
            :disabled="!!workTask.id && !!workTask._object"
            v-model="workTask.object"
            validate-on-blur
            :items="objects"
            item-text="label"
            item-value="value"
            class="mt-n3"
            :label="$t('work_tasks.object')"
            outlined
            dense
          >
            <template v-slot:prepend>
              <v-icon>mdi mdi-package</v-icon>
            </template>
          </v-autocomplete>

          <v-autocomplete
            tabindex="5"
            :disabled="saving"
            v-model="workTask.status"
            :rules="[$rules.required]"
            validate-on-blur
            :items="statuses"
            item-text="desc"
            item-value="value"
            class="mb-4 mt-n2"
            :label="$t('work_tasks.status.subtitle')"
            outlined
            dense
          >
            <template v-slot:prepend>
              <v-icon>mdi mdi-list-status</v-icon>
            </template>
          </v-autocomplete>

          <v-alert :value="showAlert" outlined class="mt-4" type="error">{{ $t('validation.check_all_required_fields') }}.</v-alert>
        </v-card-text>

        <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>

        <v-card-actions>
          <div
            v-if="workTask.id && ($isTenantAdmin || $isAdmin || workTask.assignedUserId === $userInfo.id)"
            style="width: 100%"
            class="px-2"
          >
            <v-btn
              v-if="!$isOnlyTenantBasic"
              text
              :block="$vuetify.breakpoint.xsOnly"
              color="error"
              tabindex="5"
              @click="deleteWorkTask"
              :disabled="saving"
            >
              <span>{{ $t('work_tasks.delete') }}</span>
            </v-btn>
          </div>
          <v-spacer></v-spacer>
          <v-btn
            tabindex="6"
            @click="dialog = false"
            :disabled="saving"
            :fab="$vuetify.breakpoint.xsOnly"
            :fixed="$vuetify.breakpoint.xsOnly"
            :bottom="$vuetify.breakpoint.xsOnly"
            :left="$vuetify.breakpoint.xsOnly"
          >
            <v-icon v-if="$vuetify.breakpoint.xsOnly">close</v-icon>
            <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('cancel') }}</span>
          </v-btn>
          <v-btn
            tabindex="7"
            color="primary"
            @click="save"
            :loading="saving"
            :fab="$vuetify.breakpoint.xsOnly"
            :fixed="$vuetify.breakpoint.xsOnly"
            :bottom="$vuetify.breakpoint.xsOnly"
            :right="$vuetify.breakpoint.xsOnly"
          >
            <v-icon v-if="$vuetify.breakpoint.xsOnly">check</v-icon>
            <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('save') }}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import workTasksApi from '@/api/workTask';

export default {
  name: 'WorkTaskDialog',
  data() {
    return {
      dialog: false,
      saving: false,
      showAlert: false,
      users: [],
      objects: [
        {
          label: this.$t('work_tasks.objects.time_entry'),
          value: 'timeEntry',
          disabled: true,
        },
        {
          label: this.$t('work_tasks.objects.service'),
          value: 'service',
        },
      ],

      statuses: [
        { desc: this.$t('work_tasks.status.RECEIVED'), value: 'RECEIVED' },
        { desc: this.$t('work_tasks.status.WAITING'), value: 'WAITING' },
        { desc: this.$t('work_tasks.status.INPROGRESS'), value: 'INPROGRESS' },
        { desc: this.$t('work_tasks.status.DONE'), value: 'DONE' },
      ],
      workTask: {
        title: '',
        message: '',
        status: 'RECEIVED',
        assignedUserId: null,
        assignerUserId: null,
        object: null,
        object_id: null,
      },
      workTaskTemplate: {
        title: '',
        message: '',
        status: 'RECEIVED',
        assignedUserId: null,
        assignerUserId: null,
        object: null,
        object_id: null,
      },
    };
  },
  mounted() {},
  computed: {
    richerUsers() {
      return this.$richerUsers(this.users);
    },
  },
  methods: {
    async deleteWorkTask() {
      const confirm = await this.$confirmDelete(this.$t('work_tasks.delete_dialog.title'));
      if (confirm === true) {
        this.loading = true;
        workTasksApi.deleteWorkTask(this.workTask.id);
        this.loading = false;
        this.dialog = false;
        this.$showSuccessNotification(this.$t('work_tasks.deleted'));
      }
    },
    async save() {
      if (!this.$refs.form.validate()) {
        this.showAlert = true;
        return;
      }
      if (!this.workTask.assignerUserId) {
        this.workTask.assignerUserId = this.$userInfo.id;
      }

      this.saving = true;
      if (this.workTask.id) {
        this.workTask = await workTasksApi.updateWorkTask(this.workTask.id, this.workTask);
      } else {
        this.workTask = await workTasksApi.createWorkTask(this.workTask);
      }
      this.$store.dispatch('reloadWorkTasks');
      this.$emit('save', this.workTask);

      this.saving = false;
      this.dialog = false;
    },
    async open(workTask) {
      this.dialog = true;
      this.workTask = { ...workTask, _object: workTask.object };
      this.users = await this.$loadUsers();
    },
    async openNew() {
      this.dialog = true;
      this.workTask = Object.assign({}, this.workTaskTemplate);
      this.users = await this.$loadUsers();
    },
  },
  watch: {
    dialog(value) {
      if (!value) {
        this.$refs.form?.reset();
        this.showAlert = false;

        this.$emit('on-dismiss');
      }
    },
  },
};
</script>

<style scoped></style>
