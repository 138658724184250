<template>
  <v-card-text v-if="daybook.daybookItems && Array.isArray(daybook.daybookItems)">
    <v-icon>{{ icon }}</v-icon>
    <v-btn
      @click="
        daybook.daybookItems.push({
          ...daybookItemTemplate,
          type: type,
          extra: { ...daybookItemTemplate.extra, _internalKey: (Math.random() + 1).toString(36) },
        })
      "
      class="ml-2"
    >
      <v-icon>mdi mdi-plus</v-icon> {{ $t('add') }} {{ label }}
    </v-btn>
    <v-row v-for="(item, index) in items" :key="item.id || `idx_${index}`" no-gutters>
      <div v-if="item.type === type">
        <v-col>
          <v-textarea
            :disabled="saving"
            outlined
            dense
            rows="2"
            hide-details
            class="mb-2 mt-4"
            :label="label"
            light
            item-value=""
            autocomplete="off"
            clearable
            auto-grow
            v-model="daybook.daybookItems[index].value"
          >
            <template v-slot:prepend>
              <v-icon>{{ `mdi mdi-numeric-${getNumericPresentation(index)}` }}</v-icon>
            </template>
            <template v-slot:append-outer>
              <v-btn
                icon
                @click="
                  $confirmDelete(deleteMessage).then(confirm => {
                    if (confirm) daybook.daybookItems.splice(index, 1);
                  })
                "
              >
                <v-icon class="no-gradient" color="error">mdi mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-textarea>
          <v-row no-gutters>
            <v-col offset="1">
              <file-upload
                v-if="enableFiles"
                :disabled="saving"
                :object="`daybookItem`"
                :object-id="item.id"
                :item="item"
                :ref="`images_${index}`"
              ></file-upload>
            </v-col>
          </v-row>

          <v-divider v-if="enableFiles" />
        </v-col>
      </div>
    </v-row>
  </v-card-text>
</template>

<script>
import FileUpload from '@/components/FileUpload';
export default {
  name: 'DaybookItem',
  props: {
    icon: String,
    label: String,
    daybook: Object,
    daybookItemTemplate: Object,
    type: String,
    deleteMessage: String,
    saving: Boolean,
    enableFiles: Boolean,
  },
  components: {
    FileUpload,
  },
  computed: {
    items() {
      return this.daybook?.daybookItems.map(item => {
        item._internalKey = (Math.random() + 1).toString(36).substring(7);
        return item;
      });
    },
  },
  methods: {
    getNumericPresentation(index) {
      const item = this.items[index];
      const itemsOfTypes = this.items.filter(i => i.type === this.type);
      return itemsOfTypes.findIndex(i => i._internalKey === item._internalKey) + 1;
    },
  },
};
</script>

<style scoped></style>
