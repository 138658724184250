import axios from 'axios';

export default {
  async getEquipmentCheck(id) {
    return await axios({
      url: '/api/tenant/equipmentcheck/' + id,
    }).then(r => r.data);
  },
  async getEquipmentChecksBySite(siteId) {
    return await axios({
      method: 'post',
      url: '/api/tenant/equipmentcheck/' + siteId,
    }).then(r => r.data);
  },
  async getEquipmentChecksByEquipment(equipmentId) {
    return await axios({
      method: 'post',
      url: '/api/tenant/equipmentcheck/byequipment/' + equipmentId,
    }).then(r => r.data);
  },
  async createEquipmentCheck(data) {
    return await axios({
      url: '/api/tenant/equipmentcheck',
      method: 'post',
      data,
    }).then(r => r.data);
  },
  async updateEquipmentCheck(id, /* EquipmentCheck */ data) {
    return await axios({
      url: '/api/tenant/equipmentcheck/' + id,
      method: 'put',
      data,
    }).then(r => r.data);
  },
  async markEquipmentCheckChecked(id ) {
    return await axios({
      url: '/api/tenant/equipmentcheck/' + id + '/check',
      method: 'post',
    }).then(r => r.data);
  },
  async markEquipmentCheckInspected(id ) {
    return await axios({
      url: '/api/tenant/equipmentcheck/' + id + '/inspected',
      method: 'post',
    }).then(r => r.data);
  },
};
