<template>
  <v-menu v-model="modal" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :disabled="$isOnlyTenantBasic"
        v-model="dateFormatted"
        :label="label"
        persistent-hint
        readonly
        v-bind="attrs"
        v-on="on"
        outlined
        dense
        hide-details
        class="mb-5"
        :clearable="$isTenantAdmin"
      >
        <template v-slot:prepend>
          <v-icon>mdi mdi-calendar</v-icon>
        </template>
      </v-text-field>
    </template>
    <v-date-picker v-model="date" no-title :first-day-of-week="1" locale="fi" @input="dateChanged" show-week></v-date-picker>
  </v-menu>
</template>

<script>
import dayjs from 'dayjs';

export default {
  props: ['format', 'value', 'label'],
  name: 'SiteViewDatePicker',
  data() {
    return {
      date: null,
      dateDayJs: null,
      modal: false,
    };
  },
  watch: {
    value(newValue) {
      if (newValue == null) {
        this.dateDayJs = null;
        this.date = null;
      } else {
        this.dateDayJs = dayjs(newValue);
        this.date = this.dateDayJs.format('YYYY-MM-DD');
      }
    },
  },
  methods: {
    dateChanged() {
      this.modal = false;

      this.dateDayJs = this.date != null && this.date.length > 3 ? dayjs(this.date) : null;
      this.$emit('input', this.dateDayJs);
    },
  },
  computed: {
    dateFormatted() {
      return this.dateDayJs ? this.dateDayJs.format(this.format) : '';
    },
  },
  mounted() {
    if (this.value == null) {
      this.dateDayJs = null;
      this.date = null;
    } else {
      this.dateDayJs = dayjs(this.value);
      this.date = this.dateDayJs.format('YYYY-MM-DD');
    }
  },
};
</script>

<style scoped>
i {
  background: radial-gradient(circle at 30% 107%, #01a7c2 50%, #007090 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
