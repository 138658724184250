import axios from 'axios';

export default {
  async createWorker(data) {
    return await axios({
      url: '/api/tenant/worker',
      method: 'post',
      data,
    }).then(r => r.data);
  },
  async updateWorker(id, /* Worker */ data) {
    return await axios({
      url: '/api/tenant/worker/' + id,
      method: 'put',
      data,
    }).then(r => r.data);
  },
  async getWorker(id) {
    return await axios({
      url: '/api/tenant/worker/byuserid/' + id,
    }).then(r => r.data);
  },
  async getWorkers() {
    return await axios({
      url: '/api/tenant/worker',
    }).then(r => r.data);
  },
};
