<template>
  <v-menu ref='menu' v-model='menu' :close-on-content-click='false' min-width='auto' offset-y
          transition='scale-transition'>
    <template v-slot:activator='{ on, attrs }'>
      <div v-if='dense'
           class='body-2'
           style='cursor: pointer;'
           v-on='on'
      >
        <v-icon>mdi-calendar</v-icon>
        {{ dateFormatted }}
      </div>
      <v-text-field
        v-else
        v-model='dateFormatted'
        :append-icon='appendIcon'
        :clearable='clearable'
        :disabled='saving'
        :label='label'
        :prepend-inner-icon="prependInnerIcon && 'mdi-calendar'"
        hide-details
        readonly
        v-bind='{ ...attrs, ...inputStyles }'
        @blur='/*parseDate(dateFormatted)*/'
        @change='onChange'
        v-on='on'
      >
        <template v-if='!prependInnerIcon' slot='prepend'>
          <v-icon>mdi-calendar</v-icon>
        </template>
        <template v-if='prependInnerIcon' slot='prepend-inner-icon'>
          <v-icon class='mr-2'>{{ prependInnerIcon }}</v-icon>
        </template>
      </v-text-field>
    </template>
    <v-date-picker
      :active-picker.sync='activePicker'
      :max='(birthDate && maxDate) || null'
      :min="(birthDate && '1950-01-01') || null"
      :show-week='true'
      :type='type'
      :value='isoFormattedDate()'
      no-title
      @change='onChange'
      @input='menu = false'
    ></v-date-picker>
  </v-menu>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'DatePicker',
  props: {
    birthDate: Boolean,
    date: [Object, String],
    label: {
      type: String,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'date',
    },
    inputStyles: Object,
    saving: Boolean,
    prependInnerIcon: String,
    appendIcon: String,
    clearable: Boolean,
  },
  data() {
    return {
      activePicker: null,
      dateFormatted: '',
      menu: false,
    };
  },
  computed: {
    maxDate() {
      return new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10);
    },
  },
  methods: {
    onChange(e) {
      this.$emit('change', e);
    },
    parseDate(d) {
      if (d === null) {
        return '';
      }
      if (this.type === 'month') {
        this.$emit('change', dayjs(d, ['MM/YYYY', 'MMYYYY', 'MMYY']).format('YYYY-MM-DD'));
      } else {
        this.$emit('change', dayjs(d, ['DD.MM.YYYY', 'DDMMYYYY', 'DDMMYY']).format('YYYY-MM-DD'));
      }
    },
    formatDate() {
      const date = dayjs(this.date);
      if (!this.date || !date.isValid()) {
        this.dateFormatted = '';
        return;
      }

      if (this.type === 'month') {
        this.dateFormatted = date.format('MM/YYYY');
      } else {
        this.dateFormatted = date.format('DD.MM.YYYY');
      }
    },
    isoFormattedDate() {
      const date = dayjs(this.date);
      if (!date.isValid()) return;
      if (this.type === 'month') {
        return date?.format('YYYY-MM') || null;
      }
      return date?.format('YYYY-MM-DD') || null;
    },
  },
  mounted() {
    this.formatDate();
  },
  watch: {
    date() {
      this.formatDate();
    },
    menu(val) {
      val && setTimeout(() => (this.birthDate ? (this.activePicker = 'YEAR') : (this.activePicker = null)));
    },
  },
};
</script>

<style scoped></style>
