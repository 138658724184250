import axios from 'axios';

export default {
  async getItems() {
    return await axios({
      url: '/api/tenant/item/',
    }).then(r => r.data);
  },
  async updateItem(id, /* Item */ data) {
    return await axios({
      url: '/api/tenant/item/' + id,
      method: 'put',
      data,
    }).then(r => r.data);
  },
  async removeItem(id, /* Item */ data) {
    return await axios({
      url: '/api/tenant/item/' + id,
      method: 'put',
      data,
    }).then(r => r.data);
  },
  async createItem(data) {
    return await axios({
      url: '/api/tenant/item/',
      method: 'post',
      data,
    }).then(r => r.data);
  },
  async getItem(id) {
    return await axios({
      url: '/api/tenant/item/' + id,
    }).then(r => r.data);
  },
  async getItemsByTypes(/*Array<String>*/ types) {
    if (typeof types === 'string') {
      types = [types];
    }
    return await axios({
      method: 'post',
      url: '/api/tenant/item/types',
      data: { types: types },
    }).then(r => r.data);
  },
};
