<template>
  <v-timeline-item
    v-if="(!showOnlyFilledWeeks || (showOnlyFilledWeeks && sites.length > 0)) && withoutWeeks === false"
    :color="isWeekThisWeek ? 'success' : 'primary'"
    :class="[thisWeekAndSmall && 'gradient-success white--text'].filter(Boolean)"
    fill-dot
  >
    <template v-slot:icon>
      <div
        class="d-flex justify-center align-center gradient-info elevation-3"
        :class="[isWeekThisWeek && 'gradient-success'].filter(Boolean)"
      ></div>
      <span class="white--text">{{ week.week }}</span>
    </template>
    <v-list-item light>
      <v-list-item-content>
        <v-list-item-subtitle :class="[thisWeekAndSmall && 'white--text']"
          ><span v-if="$vuetify.breakpoint.smAndDown"
            >{{ $t('sites.week') }} <span class="font-weight-bold">{{ week.week }}</span></span
          >
          {{ week.weekStartDate }} - {{ week.weekEndDate }}
        </v-list-item-subtitle>
        <v-list v-if="sites.length > 0" class="mt-5 pa-2" color="transparent">
          <template v-for="(site, index) in sites">
            <v-divider v-if="index !== 0" :key="`${site.id}_divider`" />
            <work-sites-view-list-item :week="week" :site="site" :key="site.id" />
          </template>
        </v-list>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
  </v-timeline-item>
</template>

<script>
import WorkSitesViewListItem from '@/components/Sites/WorkSitesViewListItem';
import dayjs from 'dayjs';

export default {
  name: 'WeekListItemForAllSites',
  components: { WorkSitesViewListItem },
  props: {
    filteredSites: {
      type: Array,
    },
    week: {
      type: undefined,
      default: null,
    },
    searchPhrase: {
      type: String,
      default: '',
    },
    showOnlyFilledWeeks: {
      type: Boolean,
      default: false,
    },
    withoutWeeks: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    thisWeekAndSmall() {
      return this.$vuetify.breakpoint.smAndDown && this.isWeekThisWeek;
    },
    isWeekThisWeek() {
      return this.week.id === dayjs().format('W/YYYY');
    },
    sites() {
      if (this.withoutWeeks) return this.filteredSites;

      return this.filteredSites.filter(site => {
        //const startWeek = site.plannedStartDate?.format('W/YYYY');

        const duration = parseInt(site.plannedDuration) || 1;

        let endDate = site.plannedStartDate.clone().add(duration, 'days');
        if ([0, 6].includes(endDate.day())) {
          // day js iso weekday palauttaa numeron
          endDate = endDate.add(2, 'days');
        }
        //const endWeek = endDate.format('W/YYYY');

        const weekDiffBetweenStartAndEnd = dayjs(endDate).diff(dayjs(site.plannedStartDate), 'days');

        let weeksOfWorkSite = new Set();
        for (let i = 0; i < weekDiffBetweenStartAndEnd; i++) {
          const startWeek = dayjs(site.plannedStartDate).add(i, 'day').format('W/YYYY');
          weeksOfWorkSite.add(startWeek);
        }
        const weeksArray = Array.from(weeksOfWorkSite);
        //return this.week.id === endWeek || this.week.id === startWeek;
        return weeksArray.includes(this.week.id);
      });
    },
  },
};
</script>

<style scoped lang="scss">
.current-week {
  background-color: var(--v-success-lighten4);
}
</style>
