import Vue from 'vue';
import UserService from '../service/user.service';
import TenantService from '../service/tenant.service';

const RolesPlugin = {
  install(/* vue */) {
    Vue.mixin({
      computed: {
        $isLoggedIn() {
          return UserService.loggedIn;
        },
        $userInfo() {
          return UserService.getUserInfo();
        },
        $isAdmin() {
          return this.$hasMatchingRoles(['admin']);
        },
        $isTenantAdmin() {
          return this.$hasMatchingTenantRoles(['tenant_admin']);
        },
        $isTenantBasic() {
          return this.$hasMatchingTenantRoles(['tenant_basic']);
        },
        $isTenantManager() {
          return this.$hasMatchingTenantRoles(['tenant_manager']);
        },
        $isOnlyTenantBasic() {
          return !this.$hasMatchingTenantRoles(['tenant_manager', 'tenant_admin']) && this.$hasMatchingTenantRoles(['tenant_basic']);
        },
        $isOnlyTenantAdmin() {
          return !this.$hasMatchingTenantRoles(['tenant_manager', 'tenant_basic']) && this.$hasMatchingTenantRoles(['tenant_admin']);
        },
        $isBasicUser() {
          return this.$hasMatchingRoles(['basic']);
        },
        $isAgentUser() {
          return this.$hasMatchingRoles(['agent']);
        },
      },
      methods: {
        $clearUserInfo() {
          UserService.clearUserInfo();
        },
        $hasMatchingRoles(roles) {
          const userInfo = UserService.getUserInfo();
          if (userInfo == null) {
            return false;
          }
          const userRoles = userInfo.roles;
          const matchingRoles = roles.findIndex(role => {
            if (userRoles.length > 0) {
              return userRoles.findIndex(r => r.toLowerCase() === role.toLowerCase()) > -1;
            }
          });
          return matchingRoles > -1;
        },
        $hasMatchingTenantRoles(roles) {
          const userInfo = UserService.getUserInfo();
          if (userInfo == null) {
            return false;
          }

          const savedTenant = TenantService.tenant;

          const tenantIndex = userInfo.tenantRoles.findIndex(tenant => tenant.tenant === savedTenant);
          if (tenantIndex > -1) {
            const userRoles = userInfo.tenantRoles[tenantIndex].roleSet;
            const matchingRoles = roles.findIndex(role => {
              if (userRoles.length > 0) {
                return userRoles.findIndex(r => r.toLowerCase() === role.toLowerCase()) > -1;
              }
            });
            return matchingRoles > -1;
          }
          return false;
        },
      },
    });
  },
};

export default RolesPlugin;
