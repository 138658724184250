<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-dialog
    v-model="dialog"
    persistent
    :fullscreen="$vuetify.breakpoint.xsOnly"
    :max-width="$vuetify.breakpoint.xsOnly ? '' : '600'"
    :hide-overlay="$vuetify.breakpoint.xsOnly"
    :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : ''"
  >
    <v-form style="height: 100%" ref="form">
      <v-card style="height: 100%" :elevation="$vuetify.breakpoint.xsOnly ? 0 : 2">
        <v-toolbar dark color="primary">
          <v-toolbar-title>{{
            workGroup && workGroup.id ? $t('work_groups.edit_work_group') : $t('work_groups.create_new_work_group')
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="close">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="gradient-icons">
          <v-text-field
            :disabled="saving"
            outlined
            dense
            :rules="[$rules.required]"
            validate-on-blur
            type="text"
            class="mt-4"
            :label="$t('work_groups.name')"
            light
            item-value=""
            autocomplete="off"
            clearable
            v-model="workGroup.name"
          >
            <template v-slot:prepend>
              <v-icon>mdi mdi-star-circle-outline</v-icon>
            </template>
          </v-text-field>
          <v-autocomplete
            :disabled="saving"
            outlined
            dense
            hide-details
            class="mb-5"
            :label="$tc('sites.workers.add_workers', 1)"
            light
            item-value="id"
            :item-text="item => `${item.person.lastname} ${item.person.firstname}`"
            :items="filteredWorkers"
            autocomplete="off"
            v-model="selectedWorker"
            @change="onSelectWorker"
            return-object
            clearable
          >
            <template v-slot:prepend>
              <v-icon>mdi mdi-account</v-icon>
            </template>
          </v-autocomplete>

          <small v-if="!workGroup.workers || (workGroup.workers && workGroup.workers.length === 0)">{{
            $t('work_groups.no_attached_workers')
          }}</small>
          <v-col cols="12" v-else-if="workGroup.workers.length > 0">
            <v-row>
              <v-col cols="12" sm="12" md="4" class="ml-4">{{ $t('sites.workers.name') }}</v-col>
            </v-row>
            <v-list
              style="overflow-y: auto; overflow-x: hidden"
              v-if="dialog"
              :style="{ maxHeight: $vuetify.breakpoint.xsOnly ? 'calc(100vh - 330px)' : 'calc(100vh - 460px)' }"
            >
              <v-list-item style="height: 50px" v-for="worker in workGroup.workers" :key="worker.id" no-gutters>
                <v-list-item-title>
                  <span class="black--text lighten-1"> {{ workerFirstName(worker) }} {{ workerLastName(worker) }} </span>
                </v-list-item-title>
                <v-list-item-action>
                  <v-btn small fab text @click="deleteWorker(worker)">
                    <v-icon class="no-gradient" color="red">mdi-delete</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-col>
        </v-card-text>

        <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>

        <v-card-text>
          <equipment-combobox
            :saving="saving"
            :equipments="filteredEquipments"
            :value="workGroup.equipments"
            :label="$t('timer.equipment')"
            :multiple="false"
            :hide-details="true"
            @change="onSelectEquipment"
          />

          <small v-if="!workGroup.equipments || (workGroup.equipments && workGroup.equipments.length === 0)">{{
            $t('work_groups.no_attached_equipments')
          }}</small>
          <v-col cols="12" v-else-if="workGroup.equipments.length > 0">
            <v-row>
              <v-col cols="12" sm="12" md="4" class="ml-4">{{ $tc('equipments.title', 1) }}</v-col>
            </v-row>
            <v-list
              style="overflow-y: auto; overflow-x: hidden"
              v-if="dialog"
              :style="{ maxHeight: $vuetify.breakpoint.xsOnly ? 'calc(100vh - 330px)' : 'calc(100vh - 460px)' }"
            >
              <v-list-item style="height: 50px" v-for="equipment in workGroup.equipments" :key="equipment.id" no-gutters>
                <v-list-item-title>
                  <span class="black--text lighten-1"> {{ equipment.licenseNumber }} {{ equipment.make }}</span>
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ equipment.description }}
                </v-list-item-subtitle>
                <v-list-item-action>
                  <v-btn small fab text @click="deleteEquipment(equipment)">
                    <v-icon class="no-gradient" color="red">mdi-delete</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-col>
        </v-card-text>

        <div class="d-flex justify-center">
          <v-btn
            class="mb-15"
            v-if="$vuetify.breakpoint.xsOnly && workGroup.id"
            @click="ConfirmWorkGroupSoftDelete"
            text
            color="error"
            :disabled="saving"
          >
            <span>{{ $t('work_groups.delete_work_group') }}</span>
          </v-btn>
        </div>
        <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>

        <v-card-actions>
          <v-btn
            v-if="$vuetify.breakpoint.smAndUp && workGroup.id"
            @click="ConfirmWorkGroupSoftDelete"
            text
            color="error"
            :disabled="saving"
          >
            <span>{{ $t('delete') }}</span>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            @click="close"
            :disabled="saving"
            :fab="$vuetify.breakpoint.xsOnly"
            :fixed="$vuetify.breakpoint.xsOnly"
            :bottom="$vuetify.breakpoint.xsOnly"
            :left="$vuetify.breakpoint.xsOnly"
          >
            <v-icon v-if="$vuetify.breakpoint.xsOnly">close</v-icon>
            <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('cancel') }}</span>
          </v-btn>
          <v-btn
            color="primary"
            @click="saveWorkGroup"
            :loading="saving"
            :fab="$vuetify.breakpoint.xsOnly"
            :fixed="$vuetify.breakpoint.xsOnly"
            :bottom="$vuetify.breakpoint.xsOnly"
            :right="$vuetify.breakpoint.xsOnly"
          >
            <v-icon v-if="$vuetify.breakpoint.xsOnly">check</v-icon>
            <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('save') }}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import workGroupApi from '@/api/workGroup';
import workerApi from '@/api/worker';
import equipmentApi from '@/api/equipment';
import uniqBy from 'lodash/uniqBy';
import EquipmentCombobox from '@/components/EquipmentCombobox';

export default {
  name: 'WorkGroupDialog',
  data() {
    return {
      dialog: false,
      saving: false,
      currentWorkGroup: {},
      workGroup: {
        name: '',
        workers: [],
        equipments: [],
      },
      workGroupTemplate: {
        name: '',
        workers: [],
        equipments: [],
      },
      workers: [],
      equipments: [],
      selectedWorker: null,
      selectedEquipment: null,
    };
  },
  components: { EquipmentCombobox },
  mounted() {
    this.getWorkers();
    this.getEquipments();
  },
  computed: {
    filteredEquipments() {
      const ids = {};
      this.workGroup.equipments?.forEach(w => (ids[w.id] = true));
      return this.equipments.filter(w => !ids[w.id]);
    },
    filteredWorkers() {
      const ids = {};
      this.workGroup.workers?.forEach(w => (ids[w.id] = true));
      return this.allWorkers.filter(w => !ids[w.id]);
    },
    allWorkers() {
      return this.$richerUsers(this.workers);
    },
  },
  methods: {
    async ConfirmWorkGroupSoftDelete() {
      const confirm = await this.$confirmDelete(this.$t('work_groups.confirm_dialog.title'));
      if (confirm) {
        await this.softDeleteWorkGroup();
      }
    },
    async softDeleteWorkGroup() {
      this.saving = true;
      try {
        this.workGroup = await workGroupApi.deleteWorkGroup(this.workGroup.id);
        this.saving = false;
        this.$emit('work-group-removed');
        this.$showSuccessNotification(this.$t('work_groups.work_group_deleted'), false);
      } catch (error) {
        console.log(error);
        this.$showErrorNotification(this.$t('work_groups.errors.error_deleting_work_group'), false);
        this.saving = false;
      }
      this.close();
    },
    workerFirstName(worker) {
      if (worker.person) {
        return `${worker.person?.firstname}`;
      } else if (worker && !worker?.person) {
        return `${worker.firstname}`;
      }
      return this.$t('worker_view.first_name_is_not_defined');
    },
    workerLastName(worker) {
      if (worker.person) {
        return `${worker.person?.lastname}`;
      } else if (worker && !worker?.person) {
        return `${worker.lastname}`;
      }
      return this.$t('worker_view.last_name_is_not_defined');
    },
    async saveWorkGroup() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.saving = true;
      if (this.workGroup.id) {
        this.workGroup = await workGroupApi.updateWorkGroup(this.workGroup.id, this.workGroup);
      } else {
        this.workGroup = await workGroupApi.createWorkGroup(this.workGroup);
      }
      this.$emit('save', this.workGroup);

      this.saving = false;
      this.close();
    },
    openWorkGroup(workGroup) {
      this.dialog = true;
      this.workGroup = Object.assign({}, workGroup);
    },
    close() {
      this.dialog = false;
    },
    openNew() {
      this.dialog = true;
      this.workGroup = Object.assign({}, this.workGroupTemplate);
    },
    async getWorkers() {
      const workers = await workerApi.getWorkers();
      workers.forEach(worker => {
        worker.email = worker.person?.email;
      });
      this.workers = workers;
    },
    async getEquipments() {
      const equipments = await equipmentApi.getAllEquipments();
      this.equipments = equipments;
    },
    async onSelectWorker(worker) {
      if (worker == null) return false;

      const _worker = { ...worker };

      const workers = uniqBy([...this.workGroup.workers, _worker], 'id');
      this.workGroup.workers = workers;
      this.$nextTick(() => {
        this.selectedWorker = null;
      });
    },
    async onSelectEquipment(equipment) {
      if (equipment == null) return false;

      const _equipment = { ...equipment };

      const equipments = uniqBy([...this.workGroup.equipments, _equipment], 'id');
      this.workGroup.equipments = equipments;
      this.$nextTick(() => {
        this.selectedEquipment = null;
      });
    },
    async deleteWorker(worker) {
      const wIndex = this.workGroup.workers.findIndex(w => w.id === worker.id);
      if (wIndex > -1) this.workGroup.workers.splice(wIndex, 1);
    },
    async deleteEquipment(equipment) {
      const wIndex = this.workGroup.equipments.findIndex(w => w.id === equipment.id);
      if (wIndex > -1) this.workGroup.equipments.splice(wIndex, 1);
    },
  },
  watch: {
    dialog(value) {
      if (!value) {
        this.$refs.form?.reset();

        this.$emit('on-dismiss');
      }
    },
  },
};
</script>

<style scoped></style>
