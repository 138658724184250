<template>
  <hb-basic-page
    :search-title="`${$t('user_management.search')}...`"
    :subtle-loading="subtleLoading"
    :loading="loading"
    :extension-height="60"
    name="tenant-users-list"
    @handle-search="handleSearch"
    :noContainerPadding="$vuetify.breakpoint.xsOnly"
    no-gutters
  >
    <template v-if="$vuetify.breakpoint.smAndUp" v-slot:app-bar-extension>
      <v-btn
        color="primary"
        dark
        class="mb-3 ml-n1"
        :style="$store.state.bottomNavigation ? $bottomNavigationStyle : ''"
        :class="{ 'ma-0': $vuetify.breakpoint.smAndUp }"
        @click="addUser"
      >
        <v-icon class="no-gradient" :left="$vuetify.breakpoint.smAndUp">mdi-account-plus</v-icon>
        <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('tenants.add_new_worker') }}</span>
      </v-btn>
    </template>

    <v-btn v-if="$vuetify.breakpoint.xsOnly && !loading" color="primary" dark fab fixed bottom right @click="addUser">
      <v-icon class="no-gradient">mdi-account-plus</v-icon>
    </v-btn>

    <v-col cols="12" v-if="filteredUsers.length > 0" :class="$vuetify.breakpoint.xsOnly && 'mb-7'">
      <v-list two-line color="transparent" class="pa-0 pb-15">
        <v-list-item class="users-header" v-if="$vuetify.breakpoint.smAndUp">
          <v-list-item-avatar></v-list-item-avatar>
          <v-list-item-content class="grey--text">
            <v-container fluid ma-0 pa-0>
              <v-row align="center" justify="center" class="fill-height" ma-0>
                <v-col cols="12" sm="6" lg="5" xl="3">
                  <span class="caption">{{ $t('user_management.user') }}</span>
                </v-col>
                <v-col cols="12" sm="6" lg="7" xl="9">
                  <span class="caption">{{ $t('user_management.roles') }}</span>
                </v-col>
              </v-row>
            </v-container>
          </v-list-item-content>
          <v-list-item-action style="width: 36px"></v-list-item-action>
        </v-list-item>
        <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>
        <users-list :users="filteredUsers" :allRoles="roles" type="user" @reload-users="getAllUsers" />
      </v-list>
    </v-col>
  </hb-basic-page>
</template>

<script>
import UsersList from '../../components/Tenant/TenantUserManagement/UsersList.vue';
import tenantApi from '../../api/tenant';

export default {
  name: 'WorkerManagementView',
  components: {
    UsersList: UsersList,
  },
  data() {
    return {
      loading: true,
      subtleLoading: false,
      searchPhrase: '',
      users: [],
      roles: [],
    };
  },
  methods: {
    handleSearch(searchPhrase) {
      this.searchPhrase = searchPhrase;
    },
    addUser() {
      this.$router.push({ name: 'tenant_new_worker' });
    },
    async getAllUsers() {
      if (this.users.length > 0) {
        this.subtleLoading = true;
      } else {
        this.loading = true;
      }
      this.users = await tenantApi.loadTenantUsers();
      this.loading = false;
      this.subtleLoading = false;
    },
    filterUsers(users) {
      const filteredUsers = users.sort((a, b) => {
        return a.email < b.email ? -1 : b.email < a.email ? 1 : 0;
      });

      if (this.searchPhrase == null || this.searchPhrase.length === 0) {
        return filteredUsers;
      }

      const searchPhraseLower = this.searchPhrase.toLowerCase();
      const searchPhraseArray = searchPhraseLower.split(' ');
      return filteredUsers.filter(user => {
        let found = [];
        const lowerSearchedString =
          (user.email ? user.email.toLowerCase() : '') +
          (user.subject ? user.subject.toLowerCase() : '') +
          (user.firstName ? user.firstName.toLowerCase() : '') +
          (user.lastName ? user.lastName.toLowerCase() : '');
        searchPhraseArray.forEach(phrase => {
          if (lowerSearchedString.indexOf(phrase) !== -1) {
            found.push(true);
          } else {
            found.push(false);
          }
        });
        return found.indexOf(false) === -1;
      });
    },
    async getRoles() {
      try {
        this.roles = await tenantApi.getRoles();
      } catch (error) {
        this.$handleApiError(error);
      }
    },
  },
  computed: {
    filteredUsers() {
      return this.filterUsers(this.users);
    },
  },
  mounted() {
    this.getAllUsers();
    this.getRoles();
  },
};
</script>

<style lang="scss">
.users-header {
  .v-list__tile {
    height: 30px;
  }
}
</style>
