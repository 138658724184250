<template>
  <div>
    <div v-if="durationData && dayDuration && filteredEntries.length > 0">
      <v-tooltip v-model="show" bottom>
        <template v-slot:activator="{ on }">
          <span v-on="on" @click="show = !show">{{ duration }}</span>
        </template>
        <div>
          <span v-if="durationData.timeEntryMinutes > 0"
            >{{ $t('reports.duration_tooltip.work_time') }}: {{ $formatNumber1Dec(durationData.timeEntryMinutes / 60) }} h<br
          /></span>

          <span v-if="durationData.m1Minutes > 0"
            >M1 {{ $t('reports.duration_tooltip.travel_time') }}: {{ $formatNumber1Dec(durationData.m1Minutes / 60) }} h<br
          /></span>

          <span v-if="durationData.m2Minutes > 0"
            >M2 {{ $t('reports.duration_tooltip.travel_time') }}: {{ $formatNumber1Dec(durationData.m2Minutes / 60) }} h<br /><br
          /></span>
          <span v-if="durationData.overTimeElement > 0"
            >{{ $t('reports.duration_tooltip.over_time_element') }}: {{ $formatNumber1Dec(durationData.overTimeElement / 60) }} h<br
          /></span>
          <span v-if="durationData.overTime50 > 0"
            >{{ $t('reports.duration_tooltip.over_time') }} 50%: {{ $formatNumber1Dec(durationData.overTime50 / 60) }} h<br
          /></span>

          <span v-if="durationData.overTime100 > 0"
            >{{ $t('reports.duration_tooltip.over_time') }} 100%: {{ $formatNumber1Dec(durationData.overTime100 / 60) }} h<br
          /></span>

          <span v-if="durationData.overnightStayInCarPay > 0"
            >{{ $t('timer.overnight_stay_in_car') }}: {{ durationData.overnightStayInCarPay }} {{ $t('timer.kpl') }}<br
          /></span>
          <span v-if="durationData.lunchPay > 0">{{ $t('timer.lunch_pay') }}: {{ durationData.lunchPay }} {{ $t('timer.kpl') }}<br /></span>
          <span v-if="durationData.partDayPay > 0"
            >{{ $t('timer.part_day_pay') }}: {{ durationData.partDayPay }} {{ $t('timer.kpl') }}<br
          /></span>
          <span v-if="durationData.wholeDayPay > 0"
            >{{ $t('timer.home_country_day_pay') }}: {{ durationData.wholeDayPay }} {{ $t('timer.kpl') }}<br
          /></span>
          <span v-if="durationData.foreignCountryDayPay > 0"
            >{{ $t('timer.foreign_country_day_pay') }}: {{ durationData.foreignCountryDayPay }} {{ $t('timer.kpl') }}<br
          /></span>
        </div>
      </v-tooltip>
    </div>
    <div v-else>
      <span>{{ duration }}</span>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import dayjs from 'dayjs';

export default {
  name: 'DurationDisplay',
  props: {
    entries: {
      type: Array,
      default: () => [],
    },
    freeTimes: {
      type: Array,
    },
    dayDuration: {
      type: Boolean,
      default: false,
    },
    salaryData: {
      type: Object,
    },
    day: {
      type: Object,
    },
  },
  data() {
    return {
      show: false,
      currentTime: moment().toISOString(),
    };
  },
  methods: {
    updateCurrentTime() {
      this.currentTime = moment().toISOString();
    },
    getEntryDuration(entry) {
      if (this.freeTimes.includes(entry.workType?.name)) {
        const startDay = dayjs(entry.entryStart);
        const endDay = dayjs(entry.entryEnd);
        return endDay.diff(startDay, 'days') + 'pv';
      }
      if (!entry.entryStart) return this.$humanizeDuration(moment.duration(0), false);

      let diff;
      let returningDiff;
      const start = entry.entryStart;
      const isActiveEntry = !entry.entryEnd;
      const returnStart = entry.entryStartReturn;
      const returnEnd = entry.entryEndReturn;

      let end = isActiveEntry ? moment(this.currentTime) : entry.entryEnd;

      if (end.isBefore(start)) {
        diff = start.diff(end);
        return '-' + this.$humanizeDuration(moment.duration(diff), isActiveEntry);
      } else {
        if (entry.entryStartReturn && entry.entryEndReturn) {
          diff = end.diff(start);
          returningDiff = returnEnd.diff(returnStart);
          const totalTime = diff + returningDiff;
          return this.$humanizeDuration(moment.duration(totalTime), isActiveEntry);
        } else {
          diff = end.diff(start);
          if (entry.containsLunch) {
            diff = end.diff(start);
            return this.$humanizeDuration(moment.duration(diff).add(-30, 'minutes'), isActiveEntry);
          } else {
            return this.$humanizeDuration(moment.duration(diff), isActiveEntry);
          }
        }
      }
    },
    getCombinedDurations(entries) {
      const end = moment(this.currentTime);
      const start = moment(this.currentTime);
      let hasActiveEntry = false;
      entries.forEach(entry => {
        let diff;
        const entryStart = entry.entryStart;
        const isActiveEntry = !entry.entryEnd;
        const entryEnd = isActiveEntry ? moment(this.currentTime) : entry.entryEnd;

        if (isActiveEntry) hasActiveEntry = true;

        if (entryEnd.isBefore(entryStart)) {
          diff = start.diff(entryEnd);
          start.add(diff, 'milliseconds');
        } else {
          diff = entryEnd.diff(entryStart);
          start.subtract(diff, 'milliseconds');
        }
      });

      let combinedDiff;
      if (end.isBefore(start)) {
        combinedDiff = start.diff(end);
        return '-' + this.$humanizeDuration(moment.duration(combinedDiff), hasActiveEntry);
      } else {
        combinedDiff = end.diff(start);
        return this.$humanizeDuration(moment.duration(combinedDiff), hasActiveEntry);
      }
    },
  },
  computed: {
    filteredEntries() {
      return this.entries?.filter(e => e.timeEntryType !== 'AWAYENTRY');
    },
    durationData() {
      if (this.dayDuration) {
        const date = dayjs(this.day?.date).format('YYYY-MM-DD');
        return this.salaryData?.salaryData?.find(d => d.date === date);
      }
      return {};
    },
    duration() {
      if (
        this.filteredEntries.length === 0 ||
        this.filteredEntries.findIndex(entry => this.freeTimes.indexOf(entry.workType?.name) > -1) > -1
      )
        return this.$humanizeDuration(moment.duration(0));

      if (this.filteredEntries.length === 1) return this.getEntryDuration(this.filteredEntries[0]);

      return this.getCombinedDurations(this.filteredEntries);
    },
  },
  mounted() {
    this.currentTime = moment().toISOString();
    if (this.filteredEntries.findIndex(entry => !entry.entryEnd) > -1) {
      setInterval(this.updateCurrentTime, 1000);
    }
  },
};
</script>

<style scoped></style>
