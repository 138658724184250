import axios from 'axios';
import Helper from '@/helper';

export default {
  async getProjects() {
    return await axios({
      url: '/api/tenant/project',
    }).then(r => r.data);
  },
  async getProjectsPaged(/*filter*/ filter = {}, /* v-data-table.options */ options = {}) {
    return await axios({
      url: `/api/tenant/project/paged`,
      method: 'post',
      params: { ...Helper.tableOptionsToRestPageParams(options) },
      data: filter,
    }).then(r => r.data);
  },
  async getProject(id) {
    return await axios({
      url: '/api/tenant/project/' + id,
    }).then(r => r.data);
  },
  async updateProject(id, /* Project */ data) {
    return await axios({
      url: '/api/tenant/project/' + id,
      method: 'put',
      data,
    }).then(r => r.data);
  },
  async createProject(/* Project */ data) {
    return await axios({
      url: '/api/tenant/project/',
      method: 'post',
      data,
    }).then(r => r.data);
  },
  async addSiteToProject(siteId, projectId) {
    return await axios({
      url: '/api/tenant/site/' + siteId + '/addproject/' + projectId,
      method: 'put',
    }).then(r => r.data);
  },
};
