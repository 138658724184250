<template>
  <v-dialog v-model="dialog" max-width="600" @keydown.esc="cancel()" persistent>
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title class="white--text">{{ $t('party.new_party') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="cancel">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-5 gradient-icons">
        <v-text-field
          tabindex="1"
          :disabled="saving"
          outlined
          dense
          class="mb-5"
          hide-details
          :label="$t('party.company_name_long')"
          light
          item-value=""
          autocomplete="off"
          clearable
          v-model="party.companyName"
        >
          <template v-slot:prepend>
            <v-icon>mdi mdi-account</v-icon>
          </template>
        </v-text-field>
        <v-text-field
          tabindex="1"
          :disabled="saving"
          outlined
          dense
          class="mb-5"
          hide-details
          :label="$t('party.business_code')"
          light
          item-value=""
          autocomplete="off"
          clearable
          v-model="party.businessCode"
        >
          <template v-slot:prepend>
            <v-icon>mdi mdi-identifier</v-icon>
          </template>
        </v-text-field>

        <v-text-field
          tabindex="2"
          :disabled="saving"
          outlined
          dense
          hide-details
          class="mb-5"
          :label="$t('party.phone')"
          light
          item-value=""
          autocomplete="off"
          clearable
          v-model="party.phone"
        >
          <template v-slot:prepend>
            <v-icon>mdi mdi-phone</v-icon>
          </template>
        </v-text-field>

        <v-text-field
          tabindex="3"
          :disabled="saving"
          outlined
          dense
          hide-details
          class="mb-5"
          :label="$t('party.email')"
          light
          item-value=""
          autocomplete="off"
          v-model="party.email"
          clearable
        >
          <template v-slot:prepend>
            <v-icon>mdi mdi-email</v-icon>
          </template>
        </v-text-field>
        <v-autocomplete
          :disabled="saving"
          outlined
          dense
          hide-details
          class="mb-5"
          :label="$t('party.type')"
          light
          item-value="value"
          item-text="label"
          :items="types"
          autocomplete="off"
          v-model="party.type"
          clearable
        >
          <template v-slot:prepend>
            <v-icon>mdi mdi-shape-outline</v-icon>
          </template>
        </v-autocomplete>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions :class="$vuetify.breakpoint.xsOnly ? 'flex-column-reverse' : ''">
        <v-spacer></v-spacer>
        <v-btn
          :class="$vuetify.breakpoint.xsOnly ? 'mx-0' : ''"
          :block="$vuetify.breakpoint.xsOnly"
          color="default"
          @click.native="cancel()"
          >{{ $t('cancel') }}</v-btn
        >
        <v-btn
          :block="$vuetify.breakpoint.xsOnly"
          :class="$vuetify.breakpoint.xsOnly ? 'mb-2 mx-0' : ''"
          color="primary"
          @click="createParty"
          :loading="saving"
          >{{ $t('save') }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import partyApi from '@/api/party';
export default {
  name: 'AddPartyDialog',
  props: {
    manager: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      saving: false,
      party: {
        companyName: '',
        contactFirstname: '',
        contactLastname: '',
        phone: '',
        email: '',
        code: '',
        type: 'customer',
        businessCode: '',
      },
      type: null,
      types: partyApi.getPartyTypes(),
    };
  },
  methods: {
    open(party = {}, type = null) {
      this.dialog = true;
      this.type = type;
      this.party = {
        ...{
          companyName: '',
          contactFirstname: '',
          contactLastname: '',
          phone: '',
          email: '',
          code: '',
          type: 'customer',
          businessCode: '',
        },
        ...party,
      };
    },
    async createParty() {
      this.saving = true;
      const party = await partyApi.createParty(this.party);
      this.saving = false;
      this.$emit('created', party, this.type);
      this.dialog = false;
    },
    cancel() {
      this.dialog = false;
    },
  },
};
</script>
