import axios from 'axios';
import dayjs from 'dayjs';

export default {
  async getSites() {
    return await axios({
      url: '/api/tenant/site',
    })
      .then(r => r.data)
      .then(data => {
        return data.map(site => {
          site.plannedStartDate = site.plannedStartDate ? dayjs(site.plannedStartDate) : null;
          return site;
        });
      });
  },
  async getSitesWithFilters(filter) {
    return await axios({
      method: 'post',
      url: '/api/tenant/site/list',
      data: filter,
    })
      .then(r => r.data)
      .then(data => {
        return data.map(site => {
          site.plannedStartDate = site.plannedStartDate ? dayjs(site.plannedStartDate) : null;
          return site;
        });
      });
  },
  async getSite(id) {
    return await axios({
      url: '/api/tenant/site/' + id,
    }).then(r => r.data);
  },
  async updateSite(id, data) {
    return await axios({
      url: '/api/tenant/site/' + id,
      method: 'put',
      data,
    }).then(r => r.data);
  },
  async createSite(data) {
    return await axios({
      url: '/api/tenant/site/',
      method: 'post',
      data,
    }).then(r => r.data);
  },
};
