import axios from 'axios';
import dayjs from 'dayjs';
import orderBy from 'lodash/orderBy';
function convertDates(daybook) {
  daybook.daybookDate = daybook.daybookDate ? dayjs(daybook.daybookDate) : null;

  daybook.entryStart = daybook.entryStart ? dayjs(daybook.entryStart) : null;
  daybook.entryEnd = daybook.entryEnd ? dayjs(daybook.entryEnd) : null;
  daybook.entryStartWait = daybook.entryStartWait ? dayjs(daybook.entryStartWait) : null;
  daybook.entryEndWait = daybook.entryEndWait ? dayjs(daybook.entryEndWait) : null;

  daybook.entryStartWait2 = daybook.entryStartWait2 ? dayjs(daybook.entryStartWait2) : null;
  daybook.entryEndWait2 = daybook.entryEndWait2 ? dayjs(daybook.entryEndWait2) : null;

  daybook.entryStartWait3 = daybook.entryStartWait3 ? dayjs(daybook.entryStartWait3) : null;
  daybook.entryEndWait3 = daybook.entryEndWait3 ? dayjs(daybook.entryEndWait3) : null;
  return daybook;
}
export default {
  async getDaybooksBySiteId(siteId) {
    return await axios({
      url: '/api/tenant/daybook/bysite/' + siteId,
    })
      .then(r => r.data)
      .then(data => {
        data = data.map(convertDates);

        return orderBy(data, ['daybookDate'], ['desc']);
      });
  },
  async getDaybook(id) {
    return await axios({
      url: '/api/tenant/daybook/' + id,
    })
      .then(r => r.data)
      .then(convertDates);
  },
  async updateDaybook(id, data) {
    return await axios({
      url: '/api/tenant/daybook/' + id,
      method: 'put',
      data,
    })
      .then(r => r.data)
      .then(convertDates);
  },
  async createDaybook(data) {
    return await axios({
      url: '/api/tenant/daybook',
      method: 'post',
      data,
    })
      .then(r => r.data)
      .then(convertDates);
  },
};
