<template>
  <v-container fluid fill-height class="no-tenants">
    <v-row no-gutters>
      <v-col cols="12">
        <v-row justify="center" align="center" no-gutters>
          <div class="text-center pl-5 pr-5">
            <v-icon size="100" color="secondary lighten-2">domain_disabled</v-icon>
            <div class="headline font-weight-bold mt-3 mb-3">{{ $t('tenants.no_tenants') }}</div>
            <div class="subtitle-1 mb-5">{{ $t('tenants.no_tenants_help') }}</div>
            <v-btn @click="$reloadApp" class="mt-5" outlined><v-icon left>refresh</v-icon> {{ $t('try_again') }}</v-btn>
            <br />
            <v-btn v-if="$isLoggedIn" @click="logout" class="mt-5" outlined><v-icon left>logout</v-icon> {{ $t('login.logout') }}</v-btn>
          </div>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: "NoTenantsView",
    methods: {
      logout() {
        // this logout for remote (if not used e.g. local logout used then service has 'rememberme'-functionality)
        this.$Amplify.Auth.signOut().then(() => {
          // this is client side only logout
          this.$clearUserInfo();
          this.$store.commit('setLoadingOverlay', true);
          this.$store.commit('setLoggedIn', false);
          this.$router.push({name: 'login'});
        })
      },
    }
  }
</script>

<style scoped>

</style>
