<template>
  <v-list-item @click="goToSite" class="elevation-2 mb-3" style="background: #fff">
    <v-list-item-content>
      <v-list-item-title class="text-wrap mb-3">{{ title }}</v-list-item-title>

      <v-list-item-subtitle v-if="site.project && site.project.orderer" class="caption"
        >{{ $t('projects.orderer') }}:
        <strong>{{ site.project && site.project.orderer && site.project.orderer.companyName }}</strong></v-list-item-subtitle
      >
      <v-list-item-subtitle v-if="site.project && site.project.primeContractor" class="caption"
        >{{ $t('projects.prime_contractor') }}:
        <strong>{{
          site.project && site.project.primeContractor && site.project.primeContractor.companyName
        }}</strong></v-list-item-subtitle
      >
      <v-list-item-subtitle v-if="site.workGroup && site.workGroup" class="caption"
        >{{ $t('sites.new_site.work_group') }}: <strong>{{ site.workGroup && site.workGroup.name }}</strong>
      </v-list-item-subtitle>
      <v-list-item-subtitle v-if="site.methods && site.methods.length > 0" class="caption"
        >{{ $t('sites.work_type') }}: {{ site.methods && site.methods.map(m => m.name).join(', ') }}
      </v-list-item-subtitle>
      <v-list-item-subtitle v-if="site.plannedStartDate" class="caption"
        >{{ $t('sites.planned_start_time') }}: {{ site.plannedStartDate && site.plannedStartDate.format('dd').capitalize() }}
        {{ $formatDateNoTime(site.plannedStartDate) }}</v-list-item-subtitle
      >
      <v-list-item-subtitle v-if="site.plannedDuration" class="caption"
        >{{ $t('sites.planned_duration') }}: {{ site.plannedDuration }}
        {{ $tc('sites.in_days', site.plannedDuration) }}</v-list-item-subtitle
      >

      <v-list-item-subtitle class="mt-2 caption"
        ><v-chip class="mb-1" small :color="statusColor.color" :text-color="statusColor.textColor">{{
          site.status === null ? $t(`sites.statuses.no_status`) : $t(`sites.statuses.${site.status}`)
        }}</v-chip></v-list-item-subtitle
      >
    </v-list-item-content>
    <!-- RELEASE 1
    <v-list-item-action>
      <v-btn v-if="!site.location" icon @click="addLocation">
        <v-icon color="mdi-dark" class="mdi-36px mdi-dark">mdi-map-marker-plus-outline</v-icon>
      </v-btn>
      <v-btn v-if="site.location" icon @click="showLocation">
        <v-icon class="mdi-36px mdi-dark">mdi-map-marker-outline</v-icon>
      </v-btn>
    </v-list-item-action>
    -->
  </v-list-item>
</template>

<script>
export default {
  name: 'WorkSitesViewListItem',
  props: {
    site: {
      type: Object,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    //openMaarain() {
    //  this.$router.push({ name: 'tyomaarain', params: {id: this.tyomaarain.id.toString()} });
    //},
    showLocation() {
      // TODO - Release 1
    },
    addLocation() {
      // TODO - Release 1
    },
    goToSite() {
      this.$router.push({ name: 'tenant_site_edit_site', params: { id: this.site.id.toString(), tenantId: this.$tenant } });
    },
  },
  computed: {
    title() {
      return [this.site.project?.orderer?.companyName, this.site.name].filter(Boolean).join(', ');
    },
    statusColor() {
      switch (this.site.status) {
        case 'CREATED':
          return { color: 'light-blue', textColor: 'white' };
        case 'SCHEDULED':
          return { color: 'blue', textColor: 'white' };
        case 'INPROGRESS':
          return { color: 'orange', textColor: 'white' };
        case 'DONE':
          return { color: 'green', textColor: 'white' };
        default:
          return { color: 'pink', textColor: 'white' };
      }
    },
  },
};
</script>

<style scoped></style>
