<template>
  <v-card :elevation="$vuetify.breakpoint.xsOnly ? 0 : ''" class='site-equipments mb-5'>
    <v-card-title>
      {{ $t('sites.mvr.past_checks') }}
    </v-card-title>
    <v-list v-if='pastChecks && pastChecks.length'>
      <template v-for='(check, index) in pastChecks'>
        <v-list-item :key='`${index}_${check.id}`'>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t('sites.mvr.checked_at') }}: {{ getCheckDate(check) }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ $t('sites.mvr.mvr_score') }}: {{ getMvrScore(check) }}
            </v-list-item-subtitle>
            <template v-if='check.mittauksensuorittaja'>
              <v-list-item-subtitle>
                {{ $t('sites.mvr.performed_by') }}:
                {{ check.mittauksensuorittaja }}
              </v-list-item-subtitle>
            </template>
            <!--            <template v-if='check.workerUserId'>-->
            <!--              <v-list-item-subtitle>-->
            <!--                {{ $t('sites.mvr.worker_user') }}:-->
            <!--                {{ check.workerUser.firstName }} {{ check.workerUser.lastName }}-->
            <!--              </v-list-item-subtitle>-->
            <!--            </template>-->
            <template v-if='check.inspectorUserId'>
              <v-list-item-subtitle>
                {{ $t('sites.mvr.inspector_user') }}:
                {{ check.inspectorUser.firstName }} {{ check.inspectorUser.lastName }}
              </v-list-item-subtitle>
            </template>
            <v-list-item-subtitle v-if='checkContainsNoticesRequiringAction(check)'>
              <v-chip color='warning' small>
                {{ $tc('sites.mvr.check_contains_notices', checkContainsNoticesRequiringAction(check)) }}
              </v-chip>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn outlined small @click='selectMvrCheck(check)'>
              {{ $t('sites.mvr.view_check') }}
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </template>
    </v-list>
  </v-card>
</template>

<script>
export default {
  name: 'SiteMvrChecksList',
  props: ['site', 'pastChecks', 'steps'],
  data() {
    return {};
  },
  methods: {
    checkContainsNoticesRequiringAction(check) {
      if (check.notices && check.notices.length) {
        return check.notices.filter((notice) => !notice.fixedDate).length;
      }
      return false;
    },
    selectMvrCheck(check) {
      this.$emit('select-mvr-check', check);
    },
    getCheckDate(check) {
      if (check.createdAt) {
        return this.$formatDateNoTime(check.createdAt);
      }
      return '';
    },
    getMvrScore(check) {
      let correct = 0;
      let incorrect = 0;
      this.steps.forEach((step) => {
        if (typeof check[step] === 'string' && check[step].length) {
          const helperArray = check[step].split(';');
          correct += parseInt(helperArray[0]);
          incorrect += parseInt(helperArray[1]);
        }
      });
      if (correct + incorrect) {
        return Math.round((correct / (correct + incorrect)) * 100);
      }
      return 0;
    },
  },
};
</script>

<style scoped>

</style>
