<template>
  <div class="users-list">
    <v-progress-linear
      style="position: fixed; left: 0; right: 0; margin: 0; z-index: 99"
      :style="{ top: $vuetify.breakpoint.smAndDown ? '54px' : '62px' }"
      color="primary lighten-2"
      height="2"
      v-show="subtleLoading"
      :indeterminate="true"
    ></v-progress-linear>

    <template v-for="user in users">
      <div :key="user.id">
        <v-list-item style="background: none">
          <v-list-item-avatar
            :color="user.enabled ? $randomizeColorFromString(user.email ? user.email : user.subject) : 'grey lighten-2'"
            class="user-avatar"
          >
            <v-img v-if="user.imageKey != null" :src="$getImageUrl(user.imageKey)"></v-img>
            <v-icon v-else color="white" style="margin-top: 8px" size="36">fas fa-user</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-container fluid ma-0 pa-0>
              <v-row align="center" justify="center" class="fill-height" ma-0>
                <v-col cols="12" sm="6" lg="5" xl="3" :class="!user.enabled ? 'grey--text text--lighten-1' : ''">
                  {{ user.email ? user.email : user.subject }}
                  <template v-if="user.firstName && user.lastName">
                    <br />
                    <span class="caption grey--text">{{ user.firstName }} {{ user.lastName }}</span>
                  </template>
                  <template v-if="$vuetify.breakpoint.xsOnly">
                    <div>
                      <span
                        v-for="role in userRoles(user)"
                        :key="role"
                        class="caption"
                        :style="{ color: $randomizeColorFromString(role, 60, 45) }"
                        >{{ $t('user_management.role.' + role) }}
                      </span>
                    </div>
                  </template>
                </v-col>
                <v-col v-if="$vuetify.breakpoint.smAndUp" sm="6" lg="7" xl="9">
                  <template v-for="role in userRoles(user)">
                    <v-chip
                      :key="role"
                      @click:close="removeRoleFromUser(user, role)"
                      class="mr-1"
                      small
                      close
                      outlined
                      :color="$randomizeColorFromString(role, 60, 45)"
                      ><strong>{{ $t('user_management.role.' + role) }}</strong></v-chip
                    >
                  </template>
                  <v-menu bottom right>
                    <template v-slot:activator="{ on }">
                      <v-btn text icon class="ma-0" color="grey lighten-2" v-on="on"><v-icon size="22">add_circle</v-icon></v-btn>
                    </template>
                    <v-list dense class="pt-0 pb-0">
                      <v-subheader>{{ $t('user_management.add_role_to_user') }}:</v-subheader>
                      <v-list-item v-for="role in availableRolesForUser(user)" @click="addRoleToUser(user, role)" :key="role">
                        <v-list-item-content :style="{ color: $randomizeColorFromString(role, 60, 55) }">
                          <v-list-item-title class="font-weight-bold">{{ $t('user_management.role.' + role) }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
          </v-list-item-content>
          <v-list-item-action>
            <v-menu bottom left :close-on-content-click="$vuetify.breakpoint.smAndUp">
              <template v-slot:activator="{ on }">
                <v-btn text icon class="mt-0 mb-0" color="grey"><v-icon size="28" v-on="on">more_vert</v-icon></v-btn>
              </template>
              <v-list dense class="pt-0 pb-0">
                <template v-if="$vuetify.breakpoint.xsOnly">
                  <v-subheader>{{ $t('user_management.user') }}:</v-subheader>
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-icon size="18">fas fa-user</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-bold">{{ user.firstName }} {{ user.lastName }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <v-subheader>{{ $t('user_management.manage_user') }}:</v-subheader>
                <v-list-item @click="editUser(user)">
                  <v-list-item-avatar><v-icon size="30">mdi-account-edit</v-icon></v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold">{{ $t('user_profile.edit_profile') }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="removeUserFromTenant(user)">
                  <v-list-item-avatar><v-icon size="30">mdi-account-minus</v-icon></v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold">{{ $t('tenants.delete_user') }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <template v-if="$vuetify.breakpoint.xsOnly">
                  <v-subheader>{{ $t('user_management.manage_user_roles') }}:</v-subheader>
                  <v-list-item v-for="role in allRoles" @click="toggleUserRole(user, role)" :key="role">
                    <v-list-item-avatar>
                      <v-icon v-if="user.roles.includes(role)" :color="$randomizeColorFromString(role, 60, 45)">check_box</v-icon>
                      <v-icon v-else :color="$randomizeColorFromString(role, 60, 45)">check_box_outline_blank</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content :style="{ color: $randomizeColorFromString(role, 60, 55) }">
                      <v-list-item-title class="font-weight-bold">{{ $t('user_management.role.' + role) }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list>
            </v-menu>
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
      </div>
    </template>
  </div>
</template>

<script>
import tenantApi from '../../../api/tenant';

export default {
  name: 'UsersList',
  props: {
    users: {
      type: Array,
      default: () => [],
    },
    allRoles: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: 'user',
    },
  },
  data() {
    return {
      subtleLoading: false,
    };
  },
  methods: {
    editUser(user) {
      this.$router.push({ name: 'tenant_edit_worker', params: { id: user.id } });
    },
    reloadUsers() {
      this.$emit('reload-users');
    },
    userRoles(user) {
      return this.allRoles.filter(role => user.roles.includes(role));
    },
    availableRolesForUser(user) {
      const availableRoles = this.allRoles.filter(role => role.startsWith('tenant_') && !user.roles.includes(role));
      return availableRoles;
    },
    toggleUserRole(user, role) {
      if (user.roles.findIndex(r => r === role) > -1) {
        this.removeRoleFromUser(user, role);
      } else {
        this.addRoleToUser(user, role);
      }
    },
    async addRoleToUser(user, role) {
      try {
        const response = await tenantApi.addRole(user.subject, role);
        user.roles = response.roles || user.roles;
        this.$showSuccessNotification(this.$t('user_management.user_roles_updated'));
      } catch (err) {
        this.$handleApiError(this.$t('user_management.user_roles_update_failed'));
      }
    },
    async removeRoleFromUser(user, role) {
      try {
        const response = await tenantApi.deleteRole(user.subject, role);
        user.roles = response.roles || user.roles;
        this.$showSuccessNotification(this.$t('user_management.user_roles_updated'));
      } catch (err) {
        this.$handleApiError(this.$t('user_management.user_roles_update_failed'));
      }
    },
    async removeUserFromTenant(user) {
      try {
        await tenantApi.removeUserFromTenant(user.id);
        this.reloadUsers();
        this.$showSuccessNotification(this.$t('tenants.user_delete_success'));
      } catch (error) {
        console.log(error);
        this.$showErrorNotification(this.$t('tenants.user_delete_fail'));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.user-avatar {
  overflow: hidden;
}
</style>
