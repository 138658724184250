import { render, staticRenderFns } from "./TenantSelect.vue?vue&type=template&id=8c2f52ee&scoped=true&"
import script from "./TenantSelect.vue?vue&type=script&lang=js&"
export * from "./TenantSelect.vue?vue&type=script&lang=js&"
import style0 from "./TenantSelect.vue?vue&type=style&index=0&id=8c2f52ee&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8c2f52ee",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VAvatar,VList,VListItem,VListItemAction,VListItemContent,VListItemTitle,VMenu,VSelect,VSubheader})
