<template>
  <v-autocomplete
    v-bind="$attrs"
    :clearable="!$attrs.readonly"
    @change="v => this.$emit('change', v)"
    outlined
    dense
    :hide-details="hideDetails"
    return-object
    validate-on-blur
    :value="value"
    item-value="id"
    :item-text="
      item =>
        [item.person ? item.person.firstname : item.firstname, item.person ? item.person.lastname : item.lastname].filter(Boolean).join(' ')
    "
    :label="label"
    :items="persons"
    :disabled="saving || disabled"
    :loading="loading"
  >
    <template v-slot:selection="{ item }">
      {{ item.person ? item.person.firstname : item.firstname }} {{ item.person ? item.person.lastname : item.lastname }}
      <span v-if="item.phone" class="ml-2 grey--text"
        ><small>{{ item.phone }}</small
        >&nbsp;</span
      >
      <span v-if="item.employer"
        >&nbsp;<small>{{ item.employer.companyName }}</small
        >&nbsp;</span
      >
    </template>
    <template v-slot:item="data">
      <v-list-item v-bind="data.attrs" v-on="data.on">
        <v-list-item-content>
          <v-list-item-title>
            {{ data.item.person ? data.item.person.firstname : data.item.firstname }}
            {{ data.item.person ? data.item.person.lastname : data.item.lastname }}
          </v-list-item-title>
          <v-list-item-subtitle>
            <span v-if="data.item.phone"
              ><small>{{ data.item.phone }}</small
              >&nbsp;</span
            >
            <span v-if="data.item.employer"
              ><small> {{ data.item.employer.companyName }}</small
              >&nbsp;</span
            >
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template v-slot:prepend>
      <v-icon>{{ icon }}</v-icon>
    </template>
    <template v-slot:append-outer v-if="value && value.phone">
      <v-btn :style="{ marginTop: $vuetify.breakpoint.xsOnly ? '-7px' : '-5.5px' }" fab text small
        ><a style="text-decoration: none" :href="`tel:${value.phone}`" @click.stop>
          <v-icon>mdi mdi-phone</v-icon>
        </a></v-btn
      >
    </template>
    <template v-slot:append-item>
      <v-divider></v-divider>
      <v-list-item @click="addPerson">
        <v-list-item-title class="accent--text"> {{ addNewText }} </v-list-item-title>
        <v-list-item-action>
          <v-icon color="accent">add</v-icon>
        </v-list-item-action>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: 'PersonCombobox',
  props: {
    label: {
      required: true,
      type: String,
    },
    saving: Boolean,
    persons: Array,
    addNewText: String,
    value: Object,
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: Boolean,
    icon: {
      type: String,
      default: 'mdi mdi-account-tie',
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
  },
  date() {
    return {};
  },
  methods: {
    addPerson() {
      this.$emit('add-person');
    },
  },
};
</script>

<style scoped></style>
