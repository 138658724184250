<template>
  <v-btn icon @click="toggleMainMenu">
    <v-icon>mdi mdi-menu</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'MainMenuButton',
  methods: {
    toggleMainMenu() {
      this.$store.commit('toggleMainMenuState');
    },
  },
};
</script>

<style scoped></style>
