import axios from 'axios';
import Helper from '@/helper';

export default {
  async getManagers() {
    return await axios({
      url: '/api/tenant/person/managers',
    }).then(r => r.data);
  },
  async getPersonsPaged(/*filter*/ filter = {}, /* v-data-table.options */ options = {}) {
    return await axios({
      url: `/api/tenant/person/paged`,
      method: 'post',
      params: { ...Helper.tableOptionsToRestPageParams(options) },
      data: filter,
    }).then(r => r.data);
  },
  async createPerson(data) {
    return await axios({
      url: '/api/tenant/person',
      method: 'post',
      data,
    }).then(r => r.data);
  },
  async updatePerson(id, data) {
    return await axios({
      url: '/api/tenant/person/' + id,
      method: 'put',
      data,
    }).then(r => r.data);
  },
  async getPersons() {
    return await axios({
      url: '/api/tenant/person',
    }).then(r => r.data);
  },
  async getPerson(id) {
    return await axios({
      url: '/api/tenant/person/' + id,
    }).then(r => r.data);
  },
  async getPersonsFull() {
    return await axios({
      url: '/api/tenant/person/full',
    }).then(r => r.data);
  },
  async getPersonsByEmployer(/*number*/ employerId) {
    return await axios({
      url: '/api/tenant/person/byemployer/' + employerId,
    }).then(r => r.data);
  },
  async getPersonsByEmployerNull() {
    return await axios({
      url: '/api/tenant/person/byemployernull/',
    }).then(r => r.data);
  },
};
