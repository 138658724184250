import { render, staticRenderFns } from "./WorkSitesViewListItem.vue?vue&type=template&id=bf980448&scoped=true&"
import script from "./WorkSitesViewListItem.vue?vue&type=script&lang=js&"
export * from "./WorkSitesViewListItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bf980448",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VChip,VListItem,VListItemContent,VListItemSubtitle,VListItemTitle})
