import moment from 'moment';

function isAdmin(user) {
  const userRoles = user.roles;
  return userRoles.length > 0 ? userRoles.findIndex(role => role.toLowerCase() === 'admin') > -1 : false;
}

function canModifyEntry(entry, user) {
  if (isAdmin(user)) {
    return true;
  }

  const entryStart = entry.entryStart;
  const startOfDay = moment().startOf('day');

  const isDayBeforeYesterday = entryStart.isBefore(moment(startOfDay).subtract(1, 'day'));
  if (isDayBeforeYesterday) {
    return false;
  }

  const isYesterday = entryStart.isBefore(startOfDay);
  if (isYesterday && moment().hours() < 9) {
    return true;
  }

  if (!isYesterday) {
    return true;
  }

  return false;
}

function isEntryValid(entry, user) {
  return canModifyEntry(entry, user);
}

export default {
  canModifyEntry, isEntryValid
}
