var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.timeEntry.id)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredLogs,"loading":_vm.loadingLogs,"disable-pagination":"","hide-default-footer":"","show-expand":""},scopedSlots:_vm._u([{key:"item.user",fn:function(ref){
var item = ref.item;
return [(item.user)?_c('span',[_vm._v(" "+_vm._s(item.user.lastName)+" "+_vm._s(item.user.firstName)+" ")]):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(item.action)?_c('span',[_vm._v(" "+_vm._s(_vm.$t(("timeentrylogs.actions." + (item.action))))+" ")]):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"py-3",attrs:{"colspan":headers.length}},[_c('div',[_c('v-row',[_c('v-col',{staticClass:"font-weight-bold",attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(_vm.$t('timeentrylogs.key'))+" ")]),_c('v-col',{staticClass:"font-weight-bold",attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(_vm.$t('timeentrylogs.from'))+" ")]),_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{staticClass:"font-weight-bold",attrs:{"cols":"3"}},[_vm._v(" "+_vm._s(_vm.$t('timeentrylogs.to'))+" ")])],1)],1),_vm._l((item.diff),function(value,key){return _c('div',{key:key},[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(_vm.$t('timer.' + _vm.getSnakeCase(key)))+" ")]),_c('v-col',{attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(value.old)+" ")]),_c('v-col',{attrs:{"cols":"1"}},[_c('v-icon',[_vm._v("mdi mdi-arrow-right")])],1),_c('v-col',{attrs:{"cols":"3"}},[_vm._v(" "+_vm._s(value.new)+" ")])],1)],1)})],2)]}}],null,false,3576045173)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }