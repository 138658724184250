<template>
  <v-card :elevation="$vuetify.breakpoint.xsOnly ? 0 : ''" v-if="site">
    <v-card-title>
      {{ $t('sites.introduction.licences') }}
    </v-card-title>
    <v-card-text class="mb-4">
      <v-row no-gutters>
        <template v-for="kortti in Object.keys(introductionLicences)">
          <v-checkbox class="mr-3" dense hide-details disabled :label="$t(`sites.introduction.licence.${kortti}`)" :key="kortti" :value="introductionLicences[kortti]"></v-checkbox>
        </template>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-title>
      {{ $t('sites.introduction.steps') }}
    </v-card-title>
    <v-card-text>
      {{ $t('sites.introduction.steps_help') }}
    </v-card-text>
    <v-list>
      <template v-for="step in introductionSteps">
        <v-divider class="mx-4" :key="`${step}_divider`"></v-divider>
        <v-list-item :key="step">
          <v-list-item-content>
            <v-list-item-title class="text-wrap body-1" :class="validationErrors.findIndex(ve => ve === step) > -1 ? 'error--text font-weight-bold' : ''">
              {{ $t(`sites.introduction.step.${step}`) }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action class="py-1">
            <v-btn :disabled="!!introduction.accepted" outlined icon :color="validationErrors.findIndex(ve => ve === step) > -1 ? 'error' : 'primary'" @click="toggleStepApprove(step)">
              <v-fab-transition hide-on-leave>
                <v-icon size="32" v-if="introduction[step]">mdi mdi-check-bold</v-icon>
                <v-icon size="16" v-if="validationErrors.findIndex(ve => ve === step) > -1">mdi mdi-exclamation</v-icon>
              </v-fab-transition>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </template>
    </v-list>
    <v-divider></v-divider>
    <v-card-text v-if="!introduction.accepted">
      <v-alert v-if="validationErrors.length > 0" type="error" text>
        {{ $t('sites.introduction.validation_error_message') }}
      </v-alert>
      <div class="text-center my-5">
        {{ $t('sites.introduction.what_accepting_means') }}
      </div>
      <v-btn
        :disabled='saving'
        :loading="accepting"
        block
        :small="$vuetify.breakpoint.xsOnly"
        color='primary'
        @click='acceptIntroduction'
      >
        {{ $t('sites.introduction.accept_and_save') }}
      </v-btn>
    </v-card-text>
    <v-card-text v-else-if="introduction.accepted && !introduction.checked">
      <v-alert type="info" text class="mb-0">
        {{ $t('sites.introduction.introduction_accepted') }}
      </v-alert>
    </v-card-text>
    <v-card-text v-else-if="introduction.accepted && introduction.checked">
      <v-alert type="success" text class="mb-0">
        {{ $t('sites.introduction.introduction_accepted_and_checked') }}
      </v-alert>
    </v-card-text>
  </v-card>
</template>

<script>
import introductionApi from "@/api/introduction";
import workerLicenceApi from "@/api/workerLicence";
import dayjs from "dayjs";

export default {
  name: "SiteIntroduction",
  props: ['site'],
  data() {
    return {
      saving: false,
      accepting: false,
      loading: true,
      introduction: {
        ea1: false,
        tyoturvallisuuskortti: false,
        tulityokortti: false,
        tieturva1: false,
        tieturva2: false,
        rataturva: false,
        yleistiedot: null,
        toteutusorganisaatio: null,
        tyomaatilat: null,
        tyosuojeluorganisaatio: null,
        paloturvallisuus: "",
        tyoterveyshuoltojaensiapu: null,
        liikenneturvallisuus: null,
        tyomaanjarjestysjasiisteys: null,
        tilaajanturvallisuusaineisto: null,
        tyomaankunnossapitotarkastukset: null,
        tyomaanvaaratekijat: null,
        erityisetvaaranpaikat: null,
        telineet: null,
        koneet: null,
        tyomaakierros: null,
      },
      introductionSteps: [
        'yleistiedot', 'toteutusorganisaatio', 'tyomaatilat', 'tyosuojeluorganisaatio', 'paloturvallisuus',
        'tyoterveyshuoltojaensiapu', 'liikenneturvallisuus', 'tyomaanjarjestysjasiisteys', 'tilaajanturvallisuusaineisto',
        'tyomaankunnossapitotarkastukset', 'tyomaanvaaratekijat', 'erityisetvaaranpaikat', 'telineet', 'koneet', 'tyomaakierros'
      ],
      validationErrors: [],
      workerLicences: [],
    }
  },
  methods: {
    toggleStepApprove(step) {
      if (this.introduction[step]) {
        this.introduction[step] = null;
      } else {
        this.introduction[step] = dayjs().toISOString();
        const validationErrorIndex = this.validationErrors.findIndex(ve => ve === step);
        if (validationErrorIndex > -1) {
          this.validationErrors.splice(validationErrorIndex, 1)
        }
      }
    },
    async getIntroduction() {
      try {
        const introduction = await introductionApi.getIntroductionByUserAndSite(this.site?.id);
        if (introduction) {
          this.introduction = introduction;
        }
      } catch (e) {
        this.$handleApiError(e);
      }
    },
    validateIntroduction() {
      const invalidSteps = this.introductionSteps.filter(step => !this.introduction[step]);
      if (invalidSteps.length > 0) {
        this.validationErrors = invalidSteps;
        return false;
      }
      return true;
    },
    async acceptIntroduction() {
      if (!this.validateIntroduction()) return;
      this.accepting = true;
      await this.saveIntroduction();
      this.accepting = false;
    },
    async saveIntroduction() {
      if (!this.accepting) this.saving = true;
      try {
        this.introduction.site = this.site;
        const introduction = {...structuredClone(this.introduction)};
        if (!introduction.site) introduction.site = this.site;
        if (this.accepting) introduction.accepted = dayjs().toISOString();

        // paska tapa, mut jonin vika
        introduction.ea1 = this.workerLicences.findIndex(licence => licence.card?.name === 'Ensiapu') > -1;
        introduction.tyoturvallisuuskortti = this.workerLicences.findIndex(licence => licence.card?.name === 'Työturva') > -1;
        introduction.tulityokortti = this.workerLicences.findIndex(licence => licence.card?.name === 'Tulityö') > -1;
        introduction.tieturva1 = this.workerLicences.findIndex(licence => licence.card?.name === 'Tieturva 1') > -1;
        introduction.tieturva2 = this.workerLicences.findIndex(licence => licence.card?.name === 'Tieturva 2') > -1;
        introduction.rataturva = this.workerLicences.findIndex(licence => licence.card?.name === 'Rataturva') > -1;

        if (introduction.id) {
          this.introduction = await introductionApi.updateIntroduction(introduction.id, introduction);
        } else {
          this.introduction = await introductionApi.createIntroduction(introduction);
        }
      } catch (e) {
        this.$handleApiError(e);
      }
      this.saving = false;
    },
    async getWorkerLicences() {
      try {
        const worker = await this.$userApi.getMeWorker(this.$userInfo.id);
        if (worker && worker.id) {
          this.workerLicences = await workerLicenceApi.getWorkerLicencesByWorker(worker.id);
        }
      } catch (e) {
        this.$handleApiError(e);
      }
    }
  },
  computed: {
    introductionLicences() {
      return {
        ea1: this.workerLicences.findIndex(licence => licence.card?.name === 'Ensiapu') > -1,
        tyoturvallisuuskortti: this.workerLicences.findIndex(licence => licence.card?.name === 'Työturva') > -1,
        tulityokortti: this.workerLicences.findIndex(licence => licence.card?.name === 'Tulityö') > -1,
        tieturva1: this.workerLicences.findIndex(licence => licence.card?.name === 'Tieturva 1') > -1,
        tieturva2: this.workerLicences.findIndex(licence => licence.card?.name === 'Tieturva 2') > -1,
        rataturva: this.workerLicences.findIndex(licence => licence.card?.name === 'Rataturva') > -1,
      }
    }
  },
  mounted() {
    if (this.site && this.site.id) {
      this.getIntroduction();
      this.getWorkerLicences();
    }
  }
}
</script>

<style scoped>

</style>
