import axios from 'axios';

export default {
  async getWorkGroups() {
    return await axios({
      url: '/api/tenant/workgroup',
    }).then(r => r.data);
  },
  async getWorkGroup(id) {
    return axios({
      method: 'get',
      url: '/api/tenant/workgroup/' + id,
    }).then(r => r.data);
  },
  async createWorkGroup(/*WorkGroup*/ workGroup) {
    return axios({
      method: 'post',
      url: '/api/tenant/workgroup',
      data: workGroup,
    }).then(r => r.data);
  },
  async updateWorkGroup(id, /*WorkGroup*/ workGroup) {
    return axios({
      method: 'put',
      url: '/api/tenant/workgroup/' + id,
      data: workGroup,
    }).then(r => r.data);
  },
  async deleteWorkGroup(id) {
    return axios({
      method: 'delete',
      url: '/api/tenant/workgroup/' + id,
    });
  },
  async getOwnWorkGroupUsers() {
    return axios({
      method: 'get',
      url: '/api/tenant/workgroup/own-users',
    }).then(r => r.data);
  },
};
