import Vue from 'vue';

export default new Vue({
  data() {
    return {
      innerTenant: localStorage.getItem("tenant" ),
    }
  },
  watch: {
    innerTenant(to) {
      if (to == null) {
        localStorage.removeItem("tenant");
      } else {
        localStorage.setItem("tenant", to);
      }
    },
  },
  computed: {
    tenant() {
      return this.innerTenant;
    },
  },
  created() {
  },
  methods: {
    setTenant(tenant) {
      this.innerTenant = tenant;
    },
    clear() {
      this.innerTenant = null;
    }
  },
})
