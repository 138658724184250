<template>
  <v-dialog ref="durationDialog" v-model="dialog" persistent width="450px" :disabled="disabled">
    <template v-slot:activator="{ on }">
      <v-text-field
        :disabled="disabled"
        :label="$t('timer.duration')"
        :value="humanizedCurrentDuration"
        readonly
        :hint="durationHint"
        persistent-hint
        class="title"
        :class="defaultWorkHours && 'mb-4'"
        v-on="on"
      >
        <template slot="prepend">
          <div style="width: 25px; padding-top: 4px; text-align: center"></div>
        </template>
      </v-text-field>
    </template>

    <v-card light>
      <v-card-title class="primary">
        <v-row>
          <v-col cols="12" class="display-1 font-weight-medium text-right white--text">
            {{ humanizedCurrentDuration }}
          </v-col>
        </v-row>
      </v-card-title>
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <label class="caption ml-1">{{ $t('timer.add_time') }}</label
            ><br />
            <v-row justify="space-between">
              <v-col cols="6">
                <v-btn color="success darken-3" block outlined @click="addMinutes(30)">+30min</v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn color="success darken-3" block outlined @click="addMinutes(60)">+1h</v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="mb-3">
            <label class="caption ml-1">{{ $t('timer.subtract_time') }}</label
            ><br />
            <v-row justify="space-between">
              <v-col cols="6">
                <v-btn color="warning darken-3" block outlined @click="subtractMinutes(30)">-30min</v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn color="warning darken-3" block outlined @click="subtractMinutes(60)">-1h</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="default" @click="handleCancel">{{ $t('cancel') }}</v-btn>
        <v-btn elevation="0" color="primary" @click="handleSave">{{ $t('save') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'DurationDialog',
  props: {
    defaultWorkHours: Number,
    startTime: {
      type: [Boolean, String],
      default: false,
    },
    startDate: {
      type: [Boolean, String],
      default: false,
    },
    endTime: {
      type: [Boolean, String],
      default: false,
    },
    endDate: {
      type: [Boolean, String],
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    containsLunch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      start: false,
      end: false,
    };
  },
  methods: {
    handleSave() {
      this.dialog = false;
      this.$emit('on-save', this.end);
    },
    handleCancel() {
      this.dialog = false;
      this.updateTimes();
    },
    addMinutes(minutes) {
      this.end = dayjs(this.end).add(minutes, 'minutes').toISOString();
    },
    subtractMinutes(minutes) {
      this.end = dayjs(this.end).subtract(minutes, 'minutes').toISOString();
    },
    getClosestHalfHourdayjs() {
      const start = dayjs();
      let remainder = 30 - ((start.minute() + start.second() / 60) % 30);
      remainder = remainder > 30 / 2 ? (remainder = -30 + remainder) : remainder;
      return dayjs(start).add(remainder, 'minutes').second(0);
    },
    updateTimes() {
      if (!this.startDate || !this.startTime || this.startDate === true || this.startTime === true) {
        this.start = null;
        this.end = null;
        return;
      }

      this.start = dayjs(`${this.startDate} ${this.startTime}`).toISOString();
      if (this.endDate && this.endTime) {
        this.end = dayjs(`${this.endDate} ${this.endTime}`).toISOString();
      } else {
        this.end = null;
      }
    },
  },
  computed: {
    durationHint() {
      if (this.defaultWorkHours > 0) {
        return `${this.$t('timer.default_work_hours_1')} ${this.defaultWorkHours} ${this.$t('timer.default_work_hours_2')}`;
      }
      return '';
    },
    humanizedCurrentDuration() {
      return (this.isNegativeDuration ? '-' : '') + this.$humanizeDurationWithoutNegative(this.duration.duration, false);
    },
    duration() {
      if (!this.start || !this.end) return { duration: dayjs.duration(0), diff: 0 };

      let diff;
      let start = dayjs(this.start);
      if (this.containsLunch) {
        start = start.clone().add(30, 'minutes');
      }

      let end = dayjs(this.end);

      if (end.isBefore(start)) {
        diff = start.diff(end);
      } else {
        diff = end.diff(start);
      }

      return { duration: dayjs.duration(diff), diff: diff };
    },
    isNegativeDuration() {
      let start = dayjs(this.start).clone();
      if (this.end) {
        let result = dayjs(this.end).isBefore(start);
        if (this.containsLunch) {
          start = start.add(30, 'minutes');
          result = dayjs(this.end).isBefore(start);
        }
        return result;
      }
      return false;
    },
  },
  watch: {
    duration() {
      this.$emit('duration', this.duration);
    },
    startTime() {
      this.updateTimes();
    },
    startDate() {
      this.updateTimes();
    },
    endTime() {
      this.updateTimes();
    },
    endDate() {
      this.updateTimes();
    },
  },
  mounted() {
    this.updateTimes();
  },
};
</script>

<style scoped></style>
