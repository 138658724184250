<template>
  <tr :key='entry.date' :class='[timeClashBorder, timeGapBorder]' class='grey lighten-4'>
    <td
      v-if='entry._firstEntry/*index === 0 && entryIndex === 0*/'
      :class="dayIndex % 2 === 0 ? 'primary lighten-4' : 'accent lighten-4'"
      :rowspan='dateCellRowSpan'
      class='primary--text custom-date-cell'
    >
      <v-row class='flex-column justify-space-around' no-gutters>
        <v-col align-self='stretch' class='flex'>
          <div>
            <span class='text-h5 font-weight-medium'>{{ $formatWeekDay(day.date) }}</span>
            <br />
            <span>{{ $formatDateNoTimeNoYear(day.date) }}</span>
          </div>
        </v-col>
        <v-col align-self='stretch' class='flex'>
          <v-btn v-if='showAddEntryButton' icon small @click.stop='newEntryInDialog'>
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </td>
    <td :title='getItemCheckColumnTitle(entry)'>
      <v-icon v-if='entry.checked && !entry.approved' class='no-gradient' color='success'>mdi mdi-check</v-icon>
      <v-icon v-if='entry.approved' class='no-gradient' color='success'>mdi mdi-check-all</v-icon>
      <v-checkbox
        v-if='showSelectionCheckbox'
        v-model='selected'
        class='mt-0 mb-1'
        hide-details
        @click.stop
      >
      </v-checkbox>
      <loading-indicator
        v-if='!(!loadingApprove || (loadingApprove && parentSelectedItems.findIndex(i => i.id === entry.id) === -1))'
        :size='24'
      />
    </td>
    <td class='caption font-weight-medium'>{{ entry.user }}</td>
    <td v-if="entry.timeEntryType !== 'AWAYENTRY'" class='caption'>
      <template v-if='timeGapBorder && timeGapBorder.length'>
        <v-tooltip top>
          <template v-slot:activator='{ on }'>
            <span style='color: darkorange' v-on='on'>
              {{ $formatTimeNoDate(entry.entryStart) }}-{{ $formatTimeNoDate(entry.entryEnd) }}
            </span>
          </template>
          <span>{{ $t('reports.gap_in_entries') }}</span>
        </v-tooltip>
      </template>
      <template v-else-if='timeClashBorder'>
        <v-tooltip top>
          <template v-slot:activator='{ on }'>
            <span style='color: red' v-on='on'>
              {{ $formatTimeNoDate(entry.entryStart) }}-{{ $formatTimeNoDate(entry.entryEnd) }}
            </span>
          </template>
          <span>{{ $t('reports.clash_in_entries') }}</span>
        </v-tooltip>
      </template>
      <template v-else>
        {{ $formatTimeNoDate(entry.entryStart) }}-{{ $formatTimeNoDate(entry.entryEnd) }}
      </template>

    </td>
    <td v-else class='caption'>{{ $t('reports.whole_day') }}</td>
    <td class='caption text-right'>
      {{ duration(entry.duration) }}
    </td>
    <td class='caption'>{{
        entry.site ? entry.site.name + (entry.site.project ? ', ' +
          entry.site.project.orderer.companyName : '') : $tc('reports.no_site', 1)
      }}
    </td>
    <td :class="[entry.timeEntryType === 'AWAYENTRY' && 'error--text']" class='caption'>
      {{
        entry.timeEntryType === 'AWAYENTRY'
          ? $t('reports.awayentry')
          : entry.timeEntryType !== 'TIMEENTRY'
            ? entry.timeEntryType
            : '-'
      }}
    </td>
    <td class='caption'>
      <template v-if='entry.equipments && entry.equipments.length > 0'>
        <div v-for='(equipment, index) in entry.equipments' :key='equipment.id'>
          <v-chip :class="[index === entry.equipments.length - 1 ? 'mb-2 mt-2' : 'mt-2']" x-small>
            {{ equipment.make }} {{ equipment.licenseNumber }}&nbsp;{{ equipment.type && equipment.type.name }}
          </v-chip>
        </div>
      </template>
      <template v-else>-</template>
    </td>
    <td class='caption'>
      {{ entry.workType ? entry.workType.name : '-' }}<br>
      <span v-if='entry.info'>{{ entry.info }}</span>
    </td>
    <td class='caption'>{{ entry.diameter ? entry.diameter.name : '-' }}</td>
    <td class='caption text-right'>
      {{ $formatNumber(entry.length, '0.00') }}
    </td>
    <td :style="{ borderRight: dayIndex % 2 === 0 ? '4px solid #8ddbff' : '4px solid #cbf4ff' }">
      <v-btn icon small @click='openEntryInDialog(entry)'>
        <v-icon>mdi mdi-tab</v-icon>
      </v-btn>
    </td>
  </tr>
</template>
<script>
import LoadingIndicator from '@/components/LoadingIndicator';
import numeral from 'numeral';

export default {
  name: 'CombinedSalaryDataTableRow',
  components: { LoadingIndicator },
  props: {
    entry: { type: Object },
    entryIndex: { type: Number },
    dateCellRowSpan: { type: Number },
    day: {},
    dayIndex: { type: Number },
    index: {},
    loadingApprove: { type: Boolean },
    parentSelectedItems: { type: Array },
    showAddEntryButton: { type: Boolean, default: false },
    userSalaryDataForDay: {},
  },
  data() {
    return {
      editMode: false,
      overnightStayInCar: null,
      dayPay: null,
      selected: false,
    };
  },
  computed: {
    showSelectionCheckbox() {
      if (!this.entry) return false;
      const managerSelectable = (!this.entry.checked && this.$isTenantManager && !this.$isTenantAdmin && !this.entry.approved && !this.loadingApprove);
      const adminSelectable = (!this.entry.approved && this.$isTenantAdmin && !this.loadingApprove);
      const notSelectedAsManagerWhileLoading = (this.$isTenantManager && (!this.entry.checked && !this.entry.approved && this.loadingApprove && this.parentSelectedItems.findIndex(i => i.id === this.entry.id) === -1));
      const notSelectedAsAdminWhileLoading = (this.$isTenantAdmin && (!this.entry.approved && this.loadingApprove && this.parentSelectedItems.findIndex(i => i.id === this.entry.id) === -1));
      return (managerSelectable || adminSelectable || notSelectedAsManagerWhileLoading || notSelectedAsAdminWhileLoading);
    },
    timeClashBorder() {
      let ret = '';
      const clashObject = this.userSalaryDataForDay.clashes[this.entry.id];
      if (clashObject === undefined) return;
      if (clashObject.start) {
        ret = 'clash-before';
      }
      if (clashObject.end) {
        ret = ret + ' clash-after';
      }
      return ret;
    },
    timeGapBorder() {
      if (this.userSalaryDataForDay.ranges.length <= 1) return;
      let ret = [];
      const thisClash = this.userSalaryDataForDay.clashes[this.entry.id];

      if (!(thisClash && thisClash?.start) /* ei ole jo merkattu päällekkäistä */
        && this.userSalaryDataForDay.ranges.some(r => r.id !== this.entry.id && r.end < this.entry.entryStart.format())
        && (this.userSalaryDataForDay.ranges.some(r => r.id !== this.entry.id && r.end === this.entry.entryStart.format()) === false)) {
        ret.push('gap-before');
      }
      if (!(thisClash && thisClash?.end) /* ei ole jo merkattu päällekkäistä */
        && this.userSalaryDataForDay.ranges.some(r => r.id !== this.entry.id && r.start > this.entry.entryEnd.format())
        && (this.userSalaryDataForDay.ranges.some(r => r.id !== this.entry.id && r.start === this.entry.entryEnd.format()) === false)) {
        ret.push('gap-after');
      }
      return ret;
    },
  },

  watch: {
    selected(val) {
      this.$emit('handle-selected-items', this.entry, val);
    },
    parentSelectedItems(val) {
      if (val.find((e) => e.id === this.entry.id)) {
        this.selected = true;
      } else {
        this.selected = false;
      }
    },
  },
  methods: {
    duration(duration) {
      return duration ? numeral(Number(duration)).format('0.0') : numeral(Number(0)).format('0.0');
    },
    newEntryInDialog() {
      const userId = this.entry.userId;
      const date = this.entry.entryEnd;
      this.$emit('new-entry-for-user-and-day', userId, date);
    },
    openEntryInDialog(entry) {
      this.$emit('open-entry', entry);
    },
    getItemCheckColumnTitle(item) {
      let title = '';
      if (item.approved) {
        const approvedDateAndUser = [
          item.approvedUser && [item.approvedUser.lastName, item.approvedUser.firstName].join(' '),
          this.$formatDateTime(item.approved),
        ]
          .filter(Boolean)
          .join(', ');
        title = `${this.$t('reports.approved')}: ` + approvedDateAndUser;
      }
      if (item.checked) {
        if (item.approved) title = title + ' - ';
        const checkedDateAndUser = [
          item.checkedUser && [item.checkedUser.lastName, item.checkedUser.firstName].join(' '),
          this.$formatDateTime(item.checked),
        ]
          .filter(Boolean)
          .join(', ');
        title = title + `${this.$t('reports.checked')}: ` + checkedDateAndUser;
      }
      return title;
    },
  },
};
</script>

<style lang='scss'>
#entries-table {
  .clash-before td {
    border-top-color: red !important;
    border-top-width: 1px !important;
    border-top-style: solid;
  }

  .clash-after td {
    border-bottom-color: red !important;
    border-bottom-width: 1px !important;
    border-bottom-style: solid;
  }

  .gap-before td {
    border-top-color: darkorange !important;
    border-top-width: 1px !important;
    border-top-style: dashed;
  }

  .gap-after td {
    border-bottom-color: darkorange !important;
    border-bottom-width: 1px !important;
    border-bottom-style: dashed;
  }

  .custom-date-cell {
    border: none !important;
  }
}

::v-deep .small-radio i {
  font-size: 14px !important;
}

::v-deep .small-radio label {
  font-size: 14px !important;
  padding-left: 0px !important;
  margin-left: -4px !important;
  margin-right: 2px !important;
}

::v-deep .small-radio [class*="__ripple"] {
  left: 0 !important;
}
</style>
