<template>
  <hb-basic-page :title="$t('user_profile.title')" :loading="loading" :subtle-loading="subtleLoading" name="user-profile">
    <v-col
      md="8"
      offset-md="2"
      lg="8"
      offset-lg="2"
      :class="$vuetify.breakpoint.xsOnly ? 'gradient-icons pa-0 ma-0' : ' gradient-icons mt-3'"
    >
      <v-card
        v-if="user"
        :color="$vuetify.breakpoint.xsOnly ? 'primary darken-1' : 'white'"
        :class="$vuetify.breakpoint.xsOnly ? 'white--text' : ''"
      >
        <v-card-title primary-title :class="$vuetify.breakpoint.xsOnly ? 'column justify-center' : ''">
          <div>
            <v-avatar
              :size="$vuetify.breakpoint.xsOnly ? '68' : '48'"
              :color="user.enabled ? $randomizeColorFromString(user.email ? user.email : user.subject) : 'grey lighten-2'"
              class="user-avatar"
              :class="$vuetify.breakpoint.xsOnly ? 'mb-2' : 'mr-3'"
            >
              <!--<img :src="$getGravatarUrl(user.email)" alt="Avatar" />-->
              <v-img v-if="user.imageKey != null" :src="profileImageUrl"></v-img>
              <v-icon
                v-else-if="!user.agentUser"
                :color="$vuetify.breakpoint.xsOnly ? 'primary darken-1' : 'white'"
                style="margin-top: 8px"
                :size="$vuetify.breakpoint.xsOnly ? '60' : '42'"
                >fas fa-user</v-icon
              >
              <v-icon
                v-else
                :color="$vuetify.breakpoint.xsOnly ? 'primary darken-1' : 'white'"
                style="margin-top: 8px"
                :size="$vuetify.breakpoint.xsOnly ? '68' : '46'"
                >fas fa-users-cog</v-icon
              >
            </v-avatar>
          </div>
          <div :style="$vuetify.breakpoint.xsOnly ? 'width: 100%' : ''">
            <h3 :class="$vuetify.breakpoint.xsOnly ? 'title' : 'headline'" class="text-center text-sm-left">
              {{ user.email ? user.email : user.sub }}
            </h3>
            <div class="body-1 text-center text-sm-left">
              <span v-for="role in tenantRoles" :key="role" :style="{ color: $randomizeColorFromString(role, 60, 45) }">
                {{ getRoleText(role) }}
              </span>
            </div>
            <div class="body-1 text-center text-sm-left">
              <span v-for="role in systemRoles" :key="role" :style="{ color: $randomizeColorFromString(role, 60, 45) }">
                {{ getRoleText(role) }}
              </span>
            </div>
          </div>
        </v-card-title>

        <v-card-actions :class="$vuetify.breakpoint.xsOnly ? 'justify-center pt-0' : ''">
          <!--<v-btn text :color="$vuetify.breakpoint.xsOnly ? 'primary lighten-3' : 'primary'" @click="editUserProfile">{{
            $t('user_profile.edit_profile')
          }}</v-btn>-->
          <v-btn
            class="ma-1"
            text
            :color="$vuetify.breakpoint.xsOnly ? 'primary lighten-3' : 'primary'"
            @click="openChangePasswordDialog"
            >{{ $t('user_profile.change_password') }}</v-btn
          >
        </v-card-actions>
      </v-card>

      <!--<v-card class="mt-5">
        <v-card-title primary-title>
          <div>
            <h3 :class="$vuetify.breakpoint.xsOnly ? 'title' : 'headline'" class="mb-0">{{ $t('user_profile.push_notifications') }}</h3>
            <div class="body-1" style="word-break: break-all">{{ $t('user_profile.push_notifications_explained') }}</div>
          </div>
        </v-card-title>
        <v-card-actions>
          <v-switch
            v-model="allowPushNotifications"
            color="primary"
            class="mt-0 mb-2 ml-2"
            hide-details
            :label="$t('user_profile.allow_notifications')"
          ></v-switch>
        </v-card-actions>
      </v-card>-->
    </v-col>
    <edit-user-profile-dialog @user-profile-updated="handleUserProfileUpdated" ref="editUserProfileDialog"></edit-user-profile-dialog>
    <change-password-dialog :user="user" ref="changePasswordDialog" />
  </hb-basic-page>
</template>

<script>
import ChangePasswordDialog from '../components/UserProfile/ChangePasswordDialog.vue';
import EditUserProfileDialog from '@/components/UserProfile/EditUserProfileDialog.vue';
import UserService from '../service/user.service';

export default {
  name: 'UserProfile',
  components: {
    EditUserProfileDialog,
    ChangePasswordDialog: ChangePasswordDialog,
  },
  data() {
    return {
      loading: true,
      subtleLoading: false,
      savingImage: false,
      allowPushNotifications: false,
    };
  },
  methods: {
    handleUserProfileUpdated(user) {
      UserService.setUserInfo(user);
    },
    editUserProfile() {
      this.$refs.editUserProfileDialog.openUser(this.user);
    },
    getRoleText(role) {
      return this.$t('user_management.role.' + role);
    },
    openChangePasswordDialog() {
      this.$refs.changePasswordDialog.open();
    },
  },
  computed: {
    profileImageUrl() {
      return this.$getImageUrl(this.user.imageKey);
    },
    user() {
      if (this.$isLoggedIn) return this.$userInfo;

      return {};
    },
    tenantRoles() {
      return this.user.roles.filter(r => r.startsWith('tenant_'));
    },
    systemRoles() {
      return this.user.roles.filter(r => !r.startsWith('tenant_'));
    },
  },
  mounted() {
    this.loading = false;
  },
};
</script>

<style lang="scss">
.user-avatar {
  overflow: hidden;
}

.users-header {
  .v-list__tile {
    height: 30px;
  }
}
</style>
