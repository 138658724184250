<template>
  <v-col cols="12">
    <v-card elevation="0">
      <v-card-title dense class="subtitle-1 text-uppercase font-weight-medium flex justify-space-between v-card-title-gradient">
        <div>{{ $tc('items.' + itemType, 2) }}</div>
        <div>
          <v-btn
            @click="$emit('open-new', itemType)"
            :fab="$vuetify.breakpoint.xsOnly"
            :color="$vuetify.breakpoint.xsOnly ? 'white' : 'primary'"
            class=""
            small
          >
            <v-icon class="no-gradient" :left="$vuetify.breakpoint.smAndUp">mdi mdi-plus</v-icon>
            <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('items.create_new') }} {{ $tc('items.' + itemType, 0) }}</span>
          </v-btn>
        </div>
      </v-card-title>
      <v-card-text v-if="items.length > 0">
        <v-list dense>
          <template v-for="item in items">
            <v-list-item style="height: 50px" :key="item.id + '_listitem'" @click="openItem(item)">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn @click.stop="openItemRemoveDialog(item)" text fab v-if="isEditable">
                  <v-icon class="no-gradient" style="color: red !important">mdi-delete</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-divider :key="item.id + '_divider'"></v-divider>
          </template>
        </v-list>
      </v-card-text>

      <v-card-text v-else>
        <v-list dense>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ noContent }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
export default {
  name: 'ItemsViewItemType',
  props: {
    noContent: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: null,
    },
    itemType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dialog: false,
      dialogItem: null,
    };
  },
  computed: {
    isEditable(){
      const uneditableTypes = ['työtehtävä','poissaolo']
      return !uneditableTypes.includes(this.itemType)
    }
  },
  methods: {
    openItem(item) {
      this.$emit('open-item', item.id);
    },
    openItemRemoveDialog(item) {
      this.$emit('open-item-remove-dialog', item);
    },
  },
};
</script>

<style scoped></style>
