<template>
  <hb-basic-page
    :title="$tc('party.title', 2)"
    fluid
    :loading="false"
    :fill-height="true"
    :extension-height="60"
    :search-title="`${$t('party.search')}...`"
    @handle-search="debouncedSearch"
    class="parties-view"
    :no-gutters="true"
    v-if="$isTenantAdmin"
  >
    <template v-if="$vuetify.breakpoint.smAndUp" v-slot:app-bar-extension>
      <v-btn class="mb-3 ml-n1" @click="createNewParty" color="primary">
        <v-icon class="no-gradient" left>mdi-plus</v-icon>
        <span>{{ $t('party.buttons.new_party') }}</span>
      </v-btn>
    </template>
    <v-btn v-if="$vuetify.breakpoint.xsOnly" @click="createNewParty" color="primary" fixed bottom right fab>
      <v-icon class="no-gradient">mdi-plus</v-icon>
    </v-btn>

    <v-col cols="12" class="gradient-icons">
      <v-fade-transition>
        <v-row no-gutters>
          <v-col :style="filterStyle" cols="12">
            <checkbox-filter :filter="filter" :filter-options="filterOptions" @change="handleFilterChange" @blur="this.reloadParties" />
          </v-col>
          <v-col cols="12" class="mt-15">
            <v-data-table
              :headers="headers"
              :items="pagedResult.content"
              class="h-100 gradient-info__table"
              :options.sync="options"
              :server-items-length="pagedResult.totalElements"
              :loading="loading"
              :footer-props="{ itemsPerPageOptions: [50, 100, 500, -1] }"
              :items-per-page="50"
              @click:row="editParty"
              style="cursor: pointer"
            >
              <template v-slot:item.type="{ item }"> {{ $t(`party.types.${item.type}`) }} </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-fade-transition>
    </v-col>
  </hb-basic-page>
</template>

<script>
import partyApi from '@/api/party';
import debounce from 'lodash.debounce';
import CheckboxFilter from '@/components/CheckboxFilter';
export default {
  name: 'PartiesView',
  components: { CheckboxFilter },
  data() {
    return {
      loading: false,
      options: {},
      pagedResult: {},
      searchString: '',
      headers: [
        {
          text: this.$t('party.company_name'),
          value: 'companyName',
        },
        {
          text: this.$t('party.business_code'),
          value: 'businessCode',
        },
        {
          text: this.$t('party.type'),
          value: 'type',
        },
        {
          text: '',
          value: 'actions',
          align: 'end',
          sortable: false,
        },
      ],
      filter: ['customer', 'supplier', 'employer', 'contractor'],
      filterOptions: [
        { desc: this.$t('party.types.customer'), value: 'customer' },
        { desc: this.$t('party.types.supplier'), value: 'supplier' },
        { desc: this.$t('party.types.employer'), value: 'employer' },
        { desc: this.$t('party.types.contractor'), value: 'contractor' },
      ],
      parties: [],
    };
  },
  created() {
    this.debouncedSearch = debounce(this.handleSearch, 450, { maxWait: 5000 });
  },
  mounted() {
    //this.reloadParties();
  },
  computed: {
    filterStyle() {
      if (this.$vuetify.breakpoint.smAndUp) {
        return 'position: fixed; z-index: 99; max-width: 300px';
      }
      return 'position: fixed; z-index: 99; max-width: calc(100vw - 24px)';
    },
  },
  methods: {
    async reloadParties() {
      this.loading = true;
      this.pagedResult = await partyApi.getPartiesPaged({ search: this.searchString, types: this.filter }, this.options);
      this.loading = false;
    },
    createNewParty() {
      this.$router.push({ name: 'tenant_new_party' });
    },
    handleSearch(e) {
      this.searchString = e;
    },
    handleFilterChange(filters) {
      this.filter = filters;
    },
    editParty(party) {
      this.$router.push({ name: 'tenant_party', params: { id: party.id, tenantId: this.$tenant } });
    },
  },
  watch: {
    options: {
      handler() {
        this.reloadParties();
      },
      deep: true,
    },
    searchString() {
      this.reloadParties();
    },
  },
};
</script>

<style scoped lang="scss"></style>

<style lang="scss"></style>
