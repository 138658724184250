<template>
  <v-row row justify="center" :align="alignMiddle ? 'center' : 'start'" class="py-10">
    <v-progress-circular :size="size" :width="3" color="primary" indeterminate></v-progress-circular>
  </v-row>
</template>

<script>
export default {
  name: 'LoadingIndicator',
  props: {
    alignMiddle: {
      type: Boolean,
      default: true,
    },
    size: {
      type: Number,
      default: 70,
    },
  },
};
</script>

<style scoped></style>
