import sanitizeFilename from 'sanitize-filename';
import parseFilepath from 'parse-filepath';

let isIE = false;
if (typeof window !== 'undefined') {
  let ua = window.navigator.userAgent;
  let old_ie = ua.indexOf('MSIE ');
  let new_ie = ua.indexOf('Trident/');

  if (old_ie > -1 || new_ie > -1) {
    isIE = true;
  }
}

const FileNameValidator = function FileNameValidator(filename) {
  const parsedPath = parseFilepath(filename);

  let name = parsedPath['name'];
  if (!isIE) name = name.normalize();
  const ext = parsedPath['ext'];
  const dir = parsedPath['dirname']; //.replace(/\//gi, dirSeparator)
  name = sanitizeFilename(name, { replacement: '_' }).replace(/[^a-zA-ZäöÄÖåÅ0-9_\-()]/gi, '_');

  // DO not use sanitize on dir
  //dir = sanitize(dir, {replacement: '_'}).replace(dRegEx, '/')

  if (dir.length > 0) return dir + '/' + name + ext;
  else return name + ext;
};

export default FileNameValidator;
