<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-dialog
    v-model="dialog"
    :max-width="$vuetify.breakpoint.xsOnly ? '' : '600'"
    :hide-overlay="$vuetify.breakpoint.xsOnly"
    :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : ''"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>{{ $t('party.buttons.new_person') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text v-if="persons" class="gradient-icons">
        <person-combobox
          class="mt-4"
          :saving="saving"
          :value="selectedPerson"
          :persons="persons"
          :add-new-text="$t('party.buttons.new_person')"
          :label="$tc('party.person')"
          @add-person="addPerson"
          @change="v => (this.selectedPerson = v)"
          icon="mdi mdi-account-tie"
        >
        </person-combobox>
      </v-card-text>
      <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="loading"
          :loading="loading"
          class="mb-1"
          color="primary"
          @click="SetEmployerToPerson"
          :block="$vuetify.breakpoint.xsOnly"
        >
          <span>{{ $t('add') }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
    <add-person-dialog ref="AddPersonDialog" @created="getPersons()" :selectEmployer="false" />
  </v-dialog>
</template>

<script>
import personApi from '@/api/person';
import 'dayjs';
import PersonCombobox from '@/components/PersonCombobox';
import AddPersonDialog from '@/components/AddPersonDialog';

export default {
  name: 'selectPersonDialog',
  components: { AddPersonDialog, PersonCombobox },

  data() {
    return {
      party: null,
      showAlert: false,
      dialog: false,
      saving: false,
      loading: false,
      selectedPerson: null,
      persons: [],
    };
  },
  methods: {
    async SetEmployerToPerson() {
      try {
        this.loading = true;
        this.selectedPerson.employer = this.party;
        await personApi.updatePerson(this.selectedPerson.id, this.selectedPerson);
        this.loading = false;
        this.$emit('created');
        this.dialog = false;
      } catch {
        this.$showErrorNotification(this.$t('party.errors.person_cannot_be_added'));
      }
    },
    handleSiteChange(value) {
      this.selectedSite = value;
    },
    addPerson() {
      this.$refs.AddPersonDialog.open();
    },
    async getPersons() {
      this.persons = await personApi.getPersonsByEmployerNull();
      //this.persons = persons.filter(p => p.employer.id !== this.partyId);
    },
    cancel() {
      this.loading = false;
      this.settingLocation = false;
      this.searchPhrase = '';
      this.dialog = false;
    },
    async open(party) {
      this.party = party;
      this.dialog = true;
      this.loading = true;
      this.getPersons();
      this.loading = false;
    },
  },
};
</script>

<style lang="scss"></style>
