import axios from 'axios';

export default {
  async deleteFile(fileKey) {
    await axios.delete(`/api/tenant/file/${fileKey}`);
  },
  async getFiles(objectId, object, field = null) {
    let url = `/api/tenant/file/${objectId}/${object}/${field}`;
    if (!field) {
      url = `/api/tenant/file/${objectId}/${object}`;
    }
    const response = await axios.get(url);
    return response.data;
  },
  async getFilesMultipleObjectIds(objectIds /*Number[]*/, object) {
    if (!object || !objectIds || objectIds.length === 0) return [];
    const response = await axios.get(`/api/tenant/file/multiple/${objectIds.join(',')}/${object}`);
    return response.data;
  },
  async uploadFile(id, object, file, filename, info = {}) {
    const formData = new FormData();
    formData.append('file', file, filename);
    formData.append('info', JSON.stringify(info));
    const response = await axios.post(`/api/tenant/file/${id}/${object}`, formData /*, config*/);
    return response.data;
  },
  async getFileUrl(that, file) {
    const data = await this.getFile(that, file);
    return URL.createObjectURL(new Blob([data], { type: 'image/jpg' }));
  },
  async getFileUrlLazy(that, file) {
    const url = `${that.$baseUrl}/api/tenant/file/${file.key}?thumbnail=true`;
    const img = await axios.get(url, { responseType: 'blob' });
    return URL.createObjectURL(new Blob([img.data], { type: 'image/jpg' }));
  },
  async getFile(that, file) {
    const url = `${that.$baseUrl}/api/tenant/file/${file.key}`;
    const data = await axios.get(url, { responseType: 'blob' }).then(r => r.data);
    return data;
  },
};
