<template>
  <hb-basic-page
    :back-button='backButton'
    :extension-height='$vuetify.breakpoint.smAndUp && ($isTenantAdmin || $isTenantManager) ? 107 : 47'
    :fill-height='loading'
    :loading='loading'
    :title="`${id ? site.name : $t('sites.new_site.new_site')}`"
    no-container-padding
    style='overflow: hidden'
  >
    <template v-slot:app-bar-extension>
      <v-col class='px-0'>
        <v-row>
          <div v-if='$vuetify.breakpoint.smAndUp' :class="$isTenantAdmin || $isTenantManager ? 'mb-3' : ''"
               class='ml-2'>
            <v-btn v-if='id && ($isTenantAdmin || $isTenantManager)' :disabled='isSiteDataSame' class='primary mr-3'
                   @click='saveSite'>
              <v-icon class='mr-2' left>mdi-content-save</v-icon>
              {{ $t('save') }}
            </v-btn>
            <v-btn v-if='!id && ($isTenantAdmin || $isTenantManager)' :disabled='isSiteDataSame' class='primary'
                   @click='createSite'>
              <v-icon class='mr-2' left>mdi mdi-content-save</v-icon>
              {{ $t('save') }}
            </v-btn>
          </div>
          <template>
            <v-tabs v-if='id && tabsWithRoute && site' background-color='grey darken-3'>
              <v-tab v-for='item in tabsWithRoute' :key='item.name' :to='item.route' replace>
                {{ item.name }}
              </v-tab>
            </v-tabs>
            <v-tabs v-if='!id && tabsWithoutRoute && site' v-model='selectedTab' background-color='grey darken-3'>
              <v-tab v-for='item in tabsWithoutRoute' :key='item.name'>
                {{ item.name }}
              </v-tab>
            </v-tabs>
          </template>
        </v-row>
      </v-col>
    </template>

    <v-col cols='12' style='margin-bottom: 72px'>
      <v-tabs-items v-model='selectedTab'>
        <!-- PERUSTIEDOT -->
        <v-tab-item :value="id && 'basic_info'" style='height: 100%'>
          <v-container fluid>
            <v-row>
              <v-col
                v-if="tab === 'basic_info'"
                :class="$vuetify.breakpoint.xsOnly ? 'gradient-icons pa-0 ma-0' : ' gradient-icons mt-3'"
                lg='8'
                md='8'
                offset-lg='2'
                offset-md='2'
              >
                <v-card :elevation="$vuetify.breakpoint.xsOnly ? 0 : ''">
                  <v-card-text>
                    <v-text-field
                      v-model='site.name'
                      :clearable='!$isOnlyTenantBasic'
                      :disabled='saving'
                      :label="$t('sites.new_site.work_site_name')"
                      :readonly='$isOnlyTenantBasic'
                      :rules='[$rules.required]'
                      autocomplete='off'
                      class='mt-4'
                      dense
                      item-value=''
                      light
                      outlined
                      tabindex='1'
                    >
                      <template v-slot:prepend>
                        <v-icon>mdi mdi-office-building</v-icon>
                      </template>
                    </v-text-field>

                    <v-text-field
                      v-model='site.address'
                      :clearable='!$isOnlyTenantBasic'
                      :disabled='saving'
                      :label="$t('sites.new_site.address')"
                      :readonly='$isOnlyTenantBasic'
                      autocomplete='off'
                      class='mb-5'
                      dense
                      hide-details
                      item-value=''
                      light
                      outlined
                      style='margin-top: -4px'
                      tabindex='2'
                    >
                      <template v-slot:prepend>
                        <v-icon>mdi mdi-map-marker</v-icon>
                      </template>
                      <template v-if='site.address !== null && site.address.length > 0' v-slot:append-outer>
                        <v-btn
                          v-if='!$isOnlyTenantBasic'
                          :fab='$vuetify.breakpoint.xsOnly'
                          :style="{ marginTop: $vuetify.breakpoint.xsOnly ? '-7px' : '-5.5px' }"
                          small
                          text
                          @click='openMapDialog'
                        >
                          <v-icon :left='$vuetify.breakpoint.smAndUp'>mdi mdi-crosshairs-gps
                          </v-icon
                          >
                          <span v-if='$vuetify.breakpoint.smAndUp && !$isOnlyTenantBasic'>{{
                              site.geocoding.address ? $t('sites.show_location') : $t('sites.set_location')
                            }}</span>
                        </v-btn>

                        <v-btn
                          v-if='$isOnlyTenantBasic'
                          :fab='$vuetify.breakpoint.xsOnly'
                          :style="{ marginTop: $vuetify.breakpoint.xsOnly ? '-7px' : '-5.5px' }"
                          small
                          text
                          @click='openMapDialog'
                        >
                          <v-icon :left='$vuetify.breakpoint.smAndUp'>mdi mdi-crosshairs-gps
                          </v-icon
                          >
                          <span v-if='$vuetify.breakpoint.smAndUp && $isOnlyTenantBasic'>{{ $t('sites.show_location')
                            }}</span>
                        </v-btn>
                      </template>
                    </v-text-field>

                    <v-text-field
                      v-model='site.geocoding.address'
                      :label="$t('sites.address_by_coordinates')"
                      autocomplete='off'
                      class='mb-5'
                      dense
                      disabled
                      hide-details
                      item-value=''
                      light
                      outlined
                      tabindex='2'
                    >
                      <template v-slot:prepend>
                        <v-icon>mdi mdi-map-marker</v-icon>
                      </template>
                    </v-text-field>

                    <v-autocomplete
                      v-model='site.project'
                      :clearable='!$isOnlyTenantBasic'
                      :disabled='saving'
                      :item-text="item => [item.name, item.orderer && item.orderer.companyName].filter(Boolean).join(' ')"
                      :items='projects'
                      :label="$t('sites.new_site.project')"
                      :readonly='$isOnlyTenantBasic'
                      autocomplete='off'
                      class='mb-5'
                      dense
                      hide-details
                      item-value='id'
                      light
                      outlined
                      return-object
                      tabindex='3'
                    >
                      <template v-slot:selection='data'>
                        <v-list-item class='ml-n4' style='max-width: 100%'>
                          <v-list-item-content>
                            <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                            <v-list-item-subtitle v-if='data.item.orderer'>{{ data.item.orderer.companyName }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-slot:item='data'>
                        <v-list-item v-bind='data.attr' v-on='data.on'>
                          <v-list-item-content>
                            <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                            <v-list-item-subtitle v-if='data.item.orderer'>{{ data.item.orderer.companyName }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-slot:prepend>
                        <v-icon>mdi mdi-clipboard-account-outline</v-icon>
                      </template>
                    </v-autocomplete>

                    <!-- TYÖMAAMESTARI -->
                    <person-combobox
                      :add-new-text="`${$t('new')} ${$t('sites.new_site.work_site_master')}`"
                      :clearable='!$isOnlyTenantBasic'
                      :label="$t('sites.new_site.work_site_master')"
                      :persons='persons'
                      :readonly='$isOnlyTenantBasic'
                      :saving='saving'
                      :value='site.siteMaster'
                      icon='mdi mdi-account-tie-hat'
                      tabindex='4'
                      @change='v => (site.siteMaster = v)'
                      @add-person="addPerson('siteMaster')"
                    />

                    <!-- TYÖMAAOPAS -->
                    <person-combobox
                      :add-new-text="`${$t('new')} ${$t('sites.new_site.work_site_guide')}`"
                      :clearable='!$isOnlyTenantBasic'
                      :label="$t('sites.new_site.work_site_guide')"
                      :persons='persons'
                      :readonly='$isOnlyTenantBasic'
                      :saving='saving'
                      :value='site.siteGuide'
                      class='mt-n1'
                      icon='mdi mdi-account-tie'
                      tabindex='5'
                      @change='v => (site.siteGuide = v)'
                      @add-person="addPerson('siteGuide')"
                    />

                    <div :class="$vuetify.breakpoint.xsOnly ? 'flex-column-reverse' : 'd-flex flex-row'"
                         style='margin-top: -6px'>
                      <v-col class='pa-0 ma-0' md='8'>
                        <site-view-date-picker
                          v-model='site.plannedStartDate'
                          :label="$t('sites.new_site.planned_start_date')"
                          format='D.M.YYYY'
                          tabindex='5'
                        />
                      </v-col>
                      <v-col :class="$vuetify.breakpoint.smAndUp ? 'pl-4' : ''" class='pa-0 ma-0' md='4'>
                        <v-text-field
                          v-model='site.plannedDuration'
                          :clearable='!$isOnlyTenantBasic'
                          :disabled='saving'
                          :label="$t('sites.new_site.planned_duration_in_days')"
                          :readonly='$isOnlyTenantBasic'
                          autocomplete='off'
                          class='mb-5'
                          dense
                          hide-details
                          inputmode='numeric'
                          item-value=''
                          light
                          outlined
                          tabindex='7'
                          type='number'
                        >
                          <template v-if='$vuetify.breakpoint.xsOnly' v-slot:prepend>
                            <v-icon>mdi mdi-timetable</v-icon>
                          </template>
                        </v-text-field>
                      </v-col>
                    </div>

                    <v-autocomplete
                      v-model='site.workGroup'
                      :disabled='saving || true'
                      :items='workGroups'
                      :label="$t('sites.new_site.no_workgroup_placeholder')"
                      autocomplete='off'
                      class='mb-5'
                      clearable
                      dense
                      hide-details
                      item-text='name'
                      item-value='id'
                      light
                      outlined
                      return-object
                      tabindex='8'
                      @change='onWorkGroupChange'
                    >
                      <template v-slot:prepend>
                        <v-icon>mdi mdi-account-group</v-icon>
                      </template>
                    </v-autocomplete>

                    <v-autocomplete
                      v-model='site.status'
                      :clearable='!$isOnlyTenantBasic'
                      :disabled='saving'
                      :item-text='item => item.desc'
                      :items='statuses'
                      :label="$t('sites.status')"
                      :readonly='$isOnlyTenantBasic'
                      autocomplete='off'
                      class='mb-5'
                      dense
                      hide-details
                      item-value='value'
                      light
                      outlined
                      tabindex='9'
                    >
                      <template v-slot:prepend>
                        <v-icon>mdi mdi-information-outline</v-icon>
                      </template>
                    </v-autocomplete>

                    <equipment-combobox
                      :equipments='equipments'
                      :label="$t('sites.new_site.equipment')"
                      :multiple='true'
                      :readonly='$isOnlyTenantBasic'
                      :saving='saving'
                      :tabindex='9'
                      :value='site.equipments'
                      @change='v => (site.equipments = v)'
                    />

                    <v-text-field
                      v-model='site.billingReference'
                      :clearable='!$isOnlyTenantBasic'
                      :disabled='saving'
                      :label="$t('sites.new_site.reference')"
                      :readonly='$isOnlyTenantBasic'
                      autocomplete='off'
                      class='mb-5'
                      dense
                      hide-details
                      item-value=''
                      light
                      outlined
                      tabindex='11'
                    >
                      <template v-slot:prepend>
                        <v-icon>mdi mdi-numeric</v-icon>
                      </template>
                    </v-text-field>

                    <v-textarea
                      v-model='site.additionalWork'
                      :clearable='!$isOnlyTenantBasic'
                      :disabled='saving'
                      :label="$t('sites.new_site.additional_jobs')"
                      :readonly='$isOnlyTenantBasic'
                      auto-grow
                      autocomplete='off'
                      class='mb-5'
                      dense
                      hide-details
                      item-value=''
                      light
                      outlined
                      tabindex='11'
                    >
                      <template v-slot:prepend>
                        <v-icon>mdi mdi-clipboard-plus-outline</v-icon>
                      </template>
                    </v-textarea>

                    <v-btn
                      v-if='id && !$isOnlyTenantBasic'
                      :block='$vuetify.breakpoint.xsOnly'
                      :loading='loadingSiteDaybookListReport'
                      color='default'
                      @click='siteDaybookListReport'
                    >
                      <v-icon left>mdi-file-document-outline</v-icon>
                      {{ $t('sites.site_daybook_list_report') }}
                    </v-btn>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>

        <!-- PORAUSTIEDOT -->
        <v-tab-item :value="id && 'drilling_info'" style='height: 100%'>
          <v-container fluid>
            <v-row>
              <v-col
                :class="$vuetify.breakpoint.xsOnly ? 'gradient-icons pa-0 ma-0' : ' gradient-icons mt-3'"
                lg='8'
                md='8'
                offset-lg='2'
                offset-md='2'
              >
                <template v-for='(drilling, key) in siteDrillings'>
                  <v-card
                    :key='drilling.method && drilling.method.id'
                    :class="$vuetify.breakpoint.xsOnly ? 'mb-5 mx-4' : 'mb-4'"
                    :elevation="$vuetify.breakpoint.xsOnly ? 5 : ''"
                  >
                    <v-card-title
                      :class="
                        $vuetify.breakpoint.xsOnly ? 'd-flex justify-space-between pb-0 mb-0' : 'd-flex justify-space-between'
                      "
                      dense
                    >
                      <span>{{ $t('sites.new_site.drilling') }} {{ key + 1 }}</span>
                      <v-btn v-if='!$isOnlyTenantBasic' icon @click='confirmDrillingDelete(drilling)'
                      >
                        <v-icon class='no-gradient' color='error'>delete</v-icon>
                      </v-btn
                      >
                    </v-card-title>
                    <v-card-text>
                      <!-- Site items -->
                      <v-row class='mt-5'>
                        <v-col cols='12'>
                          <item-combobox
                            :items='methods'
                            :label="$t('sites.new_site.method')"
                            :readonly='$isOnlyTenantBasic'
                            :saving='saving'
                            :value='drilling.method && drilling.method.item'
                            hide-details
                            @change="v => changeDrillingProperty(drilling, drilling.method && drilling.method._idx, 'item', 'menetelmä', v)"
                          >
                            <template v-slot:prepend>
                              <v-icon>mdi mdi-shovel</v-icon>
                            </template>
                          </item-combobox>
                        </v-col>
                      </v-row>
                      <v-row class='mb-1'>
                        <v-col cols='12' md='7'>
                          <item-combobox
                            :items='pipes'
                            :label="$t('sites.new_site.pipe_diameter')"
                            :readonly='$isOnlyTenantBasic'
                            :saving='saving'
                            :value='drilling.pipe && drilling.pipe.item'
                            hide-details
                            @change="v => changeDrillingProperty(drilling, drilling.pipe && drilling.pipe._idx, 'item', 'putki', v)"
                          >
                            <template v-slot:prepend>
                              <v-icon>mdi mdi-diameter-outline</v-icon>
                            </template>
                          </item-combobox>
                        </v-col>
                        <v-col class='' cols='12' md='5'>
                          <v-text-field
                            :clearable='!$isOnlyTenantBasic'
                            :disabled='saving'
                            :label="$t('sites.new_site.pipe_length')"
                            :readonly='$isOnlyTenantBasic'
                            :value='drilling.pipe && drilling.pipe.amount'
                            autocomplete='off'
                            dense
                            hide-details
                            item-value=''
                            light
                            outlined
                            suffix='m'
                            type='number'
                            @change="v => changeDrillingProperty(drilling, drilling.pipe && drilling.pipe._idx, 'amount', 'putki', v)"
                          >
                            <template v-slot:prepend>
                              <v-icon>mdi mdi-arrow-expand-horizontal</v-icon>
                            </template>
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <!-- Site items end -->
                    </v-card-text>
                  </v-card>
                </template>
                <v-row class='mb-2'>
                  <v-col class='text-left'>
                    <div
                      :class="$vuetify.breakpoint.xsOnly ? 'py-2 px-4 pt-5' : 'mt-0'"
                      :style="{
                        width: $vuetify.breakpoint.xsOnly ? '100%' : 'auto',
                      }"
                    >
                      <v-btn
                        v-if='!$isOnlyTenantBasic'
                        :style="{ width: $vuetify.breakpoint.xsOnly ? '100%' : 'auto' }"
                        color='primary'
                        @click='addNewDrilling'
                      >
                        <v-icon class='no-gradient' left>mdi mdi-plus</v-icon>
                        {{ $t('sites.new_site.add_drilling') }}
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>

                <template v-if='siteAdditionalThings.length > 0'>
                  <v-card
                    v-for='juttu in siteAdditionalThings'
                    :key='`${juttu.id}_${juttu._idx}`'
                    :class="$vuetify.breakpoint.xsOnly ? 'ma-4' : ''"
                    :elevation="$vuetify.breakpoint.xsOnly ? 5 : ''"
                    class='pa-4 mb-4'
                  >
                    <template v-if="juttu.attribute === 'keskitysrengas'">
                      <site-drilling-multiple-item-select
                        :amount='site.items.find(i => i._idx === juttu._idx).amount'
                        :items='centeringRings'
                        :itemsite='site.items.find(i => i._idx === juttu._idx).item'
                        :label="$t('sites.new_site.centering_rings')"
                        :saving='saving'
                        icon='mdi mdi-format-horizontal-align-center'
                        type='keskitysrengas'
                        @change='changeDrillingPropertyOnSite($event, juttu._idx)'
                        @delete='deleteDrillingProperty(juttu._idx)'
                      />
                    </template>

                    <template v-if="juttu.attribute === 'ylivuotokaivo'">
                      <site-drilling-multiple-item-select
                        :amount='site.items.find(i => i._idx === juttu._idx).amount'
                        :items='flowingWells'
                        :itemsite='site.items.find(i => i._idx === juttu._idx).item'
                        :label="$t('sites.new_site.flowing_wells')"
                        :saving='saving'
                        icon='mdi mdi-water-well-outline'
                        type='ylivuotokaivo'
                        @change='changeDrillingPropertyOnSite($event, juttu._idx)'
                        @delete='deleteDrillingProperty(juttu._idx)'
                      />
                    </template>

                    <template v-if="juttu.attribute === 'päätytulppa'">
                      <site-drilling-multiple-item-select
                        :amount='site.items.find(i => i._idx === juttu._idx).amount'
                        :items='endPlugs'
                        :itemsite='site.items.find(i => i._idx === juttu._idx).item'
                        :label="$t('sites.new_site.end_plugs')"
                        :saving='saving'
                        icon='mdi mdi-stop-circle-outline'
                        type='päätytulppa'
                        @change='changeDrillingPropertyOnSite($event, juttu._idx)'
                        @delete='deleteDrillingProperty(juttu._idx)'
                      />
                    </template>
                  </v-card>
                </template>

                <v-row class='mb-2'>
                  <v-col class='text-left'>
                    <div
                      :class="$vuetify.breakpoint.xsOnly && 'px-4 pt-2'"
                      :style="{ width: $vuetify.breakpoint.xsOnly ? '100%' : 'auto' }"
                    >
                      <v-menu v-if='!$isOnlyTenantBasic' max-width='200' offset-y top>
                        <template v-slot:activator='{ on, attrs }'>
                          <v-row>
                            <v-col>
                              <v-btn
                                v-if='!$isOnlyTenantBasic'
                                :style="{ width: $vuetify.breakpoint.xsOnly ? '100%' : 'auto' }"
                                color='primary'
                                v-bind='attrs'
                                v-on='on'
                              >
                                <v-icon class='no-gradient' left>mdi mdi-plus</v-icon>
                                {{ $t('sites.add_feature') }}
                              </v-btn>
                            </v-col>
                          </v-row>
                        </template>
                        <v-list>
                          <v-list-item link @click="addNewDrillingOption('keskitysrengas')"
                          >
                            <v-list-item-title>{{ $tc('items.keskitysrengas', 0) }}</v-list-item-title>
                          </v-list-item
                          >
                          <v-list-item link @click="addNewDrillingOption('ylivuotokaivo')"
                          >
                            <v-list-item-title>{{ $tc('items.ylivuotokaivo', 0) }}</v-list-item-title>
                          </v-list-item
                          >
                          <v-list-item link @click="addNewDrillingOption('päätytulppa')"
                          >
                            <v-list-item-title>{{ $tc('items.päätytulppa', 0) }}</v-list-item-title>
                          </v-list-item
                          >
                        </v-list>
                      </v-menu>
                    </div>
                  </v-col>
                </v-row>

                <v-card :elevation="$vuetify.breakpoint.xsOnly ? 0 : ''">
                  <v-card-text>
                    <numeric-input
                      v-model='site.minDrillingLength'
                      :clearable='!$isOnlyTenantBasic'
                      :disabled='saving'
                      :label="$t('sites.new_site.pipe_minimum_invoicing_length')"
                      :readonly='$isOnlyTenantBasic'
                      autocomplete='off'
                      class='mb-5'
                      dense
                      hide-details
                      item-value=''
                      light
                      outlined
                      suffix='m'
                    >
                      <template v-slot:prepend>
                        <v-icon>mdi mdi-arrow-expand-horizontal</v-icon>
                      </template>
                    </numeric-input>

                    <v-textarea
                      v-model='site.additionalInfo'
                      :clearable='!$isOnlyTenantBasic'
                      :disabled='saving'
                      :label="$t('sites.new_site.additional_info')"
                      :readonly='$isOnlyTenantBasic'
                      auto-grow
                      autocomplete='off'
                      class='mb-5'
                      dense
                      hide-details
                      item-text='name'
                      item-value=''
                      light
                      outlined
                    >
                      <template v-slot:prepend>
                        <v-icon>mdi mdi-clipboard-plus-outline</v-icon>
                      </template>
                    </v-textarea>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>

        <!-- TYÖNTEKIJÄT -->
        <v-tab-item :value="id && 'workers'" style='height: 100%'>
          <v-container fluid>
            <v-row>
              <v-col
                :class="$vuetify.breakpoint.xsOnly ? 'gradient-icons pa-0 ma-0 mt-4' : ' gradient-icons mt-3'"
                cols='12'
                lg='8'
                md='8'
                offset-lg='2'
                offset-md='2'
              >
                <v-card :elevation="$vuetify.breakpoint.xsOnly ? 0 : ''">
                  <v-card-text>
                    <v-row>
                      <v-col>
                        <v-autocomplete
                          v-model='site.workGroup'
                          :clearable='!$isOnlyTenantBasic'
                          :disabled='saving'
                          :items='workGroups'
                          :label="$t('sites.new_site.work_group')"
                          :readonly='$isOnlyTenantBasic'
                          autocomplete='off'
                          class='mb-5'
                          dense
                          hide-details
                          item-text='name'
                          item-value='id'
                          light
                          outlined
                          return-object
                          @change='onWorkGroupChange'
                        >
                          <template v-slot:prepend>
                            <v-icon>mdi mdi-account-group</v-icon>
                          </template>
                        </v-autocomplete>
                        <v-autocomplete
                          v-model='selectedWorker'
                          :clearable='!$isOnlyTenantBasic'
                          :disabled='saving'
                          :item-text='item => `${item.person.lastname} ${item.person.firstname}`'
                          :items='filteredWorkers'
                          :label="$tc('sites.workers.add_workers', 1)"
                          :readonly='$isOnlyTenantBasic'
                          autocomplete='off'
                          class='mb-5 mt-4'
                          dense
                          hide-details
                          item-value='id'
                          light
                          outlined
                          return-object
                          @change='onSelectWorker'
                        >
                          <template v-slot:prepend>
                            <v-icon>mdi mdi-account</v-icon>
                          </template>
                        </v-autocomplete>

                        <small v-if='!siteWorkers || (siteWorkers && siteWorkers.length === 0)'>Ei liitettyjä
                          työntekijöitä</small>

                        <v-data-table v-else
                                      :headers='computedHeaders'
                                      :items='siteWorkers'
                                      :items-per-page='20'
                                      class='elevation-0'
                                      hide-default-footer
                                      mobile-breakpoint='800'
                        >
                          <template v-slot:item.firstname='{ item }'>
                            {{ item.firstname }} {{ item.lastname }}
                          </template>
                          <template v-slot:item.workStart='{ item }'>
                            <template v-if='$isOnlyTenantBasic'>
                              {{ $formatDateNoTime(item.workStart) }}
                            </template>
                            <date-picker v-else :date='item.workStart' dense
                                         @change='value => handleWorkerWorkStartChange(value, item)' />
                          </template>
                          <template v-slot:item.workEnd='{ item }'>
                            <template v-if='$isOnlyTenantBasic'>
                              {{ $formatDateNoTime(item.workEnd) }}
                            </template>
                            <date-picker v-else :date='item.workEnd' dense
                                         @change='value => handleWorkerWorkEndChange(value, item)' />
                          </template>
                          <template v-slot:item.intro='{item}'>
                            <template v-if='isWorkerIntroChecked(item.userId)'>
                              <!-- ruma duplikaatio jotta saadaan elementit eri järjestykseen mobiilinäykmässä -->
                              <template v-if='!$vuetify.breakpoint.mobile'>
                                <v-chip color='success' small>{{ $t('sites.intro_checked')
                                  }}
                                </v-chip>
                                <span class='ml-2'>{{ isWorkerIntroChecked(item.userId) }}</span>
                              </template>
                              <template v-else>
                                <span class='mr-2'>{{ isWorkerIntroChecked(item.userId) }}</span>
                                <v-chip color='success' small>{{ $t('sites.intro_checked')
                                  }}
                                </v-chip>
                              </template>
                            </template>
                            <template v-else-if='isWorkerIntroAccepted(item.userId)'>
                              <v-chip color='warning' small>{{ $t('sites.intro_accepted')
                                }}
                              </v-chip>
                              <span class='ml-2'>{{ isWorkerIntroAccepted(item.userId) }}</span>
                            </template>
                            <template v-else>
                              <v-chip color='red' dark small>{{ $t('sites.no_intro') }}</v-chip>
                            </template>
                          </template>
                          <template v-slot:item.checker='{item}'>
                            <span v-if='isWorkerIntroChecked(item.userId)'>{{ getWorkerIntroChecker(item.userId)
                              }}</span>
                            <v-btn v-else-if='isWorkerIntroAccepted(item.userId)'
                                   outlined
                                   small
                                   @click='setWorkerIntroChecked(item.worker)'
                            >Hyväksy
                            </v-btn>
                          </template>
                          <template v-slot:item.actions='{item}'>
                            <v-btn fab small text @click='deleteWorker(item.workerId)'>
                              <v-icon class='no-gradient' color='red'>mdi mdi-delete</v-icon>
                            </v-btn>
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-btn
                          v-if='!$isOnlyTenantBasic'
                          :block='$vuetify.breakpoint.xsOnly'
                          :loading='loadingSiteWorkerListReport'
                          class='default mr-3'
                          @click='siteWorkerListReport'
                        >
                          <v-icon class='mr-2'>mdi-file-document-outline</v-icon>
                          {{ $t('sites.site_worker_list_report') }}
                        </v-btn>
                        <v-btn
                          v-if='!$isOnlyTenantBasic'
                          :block='$vuetify.breakpoint.xsOnly'
                          :loading='loadingSiteWorkerIntroPDF'
                          class='default mr-3'
                          @click='siteWorkerIntroPDF'
                        >
                          <v-icon class='mr-2'>mdi-file-pdf-box</v-icon>
                          {{ $t('sites.site_introductions_report') }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>

        <!-- LIITETIEDOSTOT -->
        <v-tab-item :value="id && 'attachments'" style='height: 100%'>
          <v-container fluid>
            <v-row>
              <v-col
                :class="$vuetify.breakpoint.xsOnly ? 'gradient-icons pa-0 ma-0 mt-4' : ' gradient-icons mt-3'"
                cols='12'
                lg='8'
                md='8'
                offset-lg='2'
                offset-md='2'
              >
                <v-card :elevation="$vuetify.breakpoint.xsOnly ? 0 : ''">
                  <v-card-text>
                    <file-upload :compress='false' :objectId='site.id' :use-description='true' object='site' />
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>

        <!-- TYÖMAAPÄIVÄKIRJA -->
        <v-tab-item :value="id && 'daybooks'" class='daybook-list' style='height: 100%'>
          <v-container fluid>
            <v-row>
              <v-col
                v-if='id'
                :class="$vuetify.breakpoint.xsOnly ? 'pa-0 ma-0' : 'mt-3'"
                class='mt-5'
                cols='12'
                lg='10'
                md='10'
                offset-lg='1'
                offset-md='1'
              >
                <!--<v-card elevation="5">
                  <v-card-text>
                    <v-row
                      ><v-col>-->
                <v-timeline class='pa-4' dense>
                  <v-timeline-item v-if='!$isOnlyTenantBasic' :class="$vuetify.breakpoint.smAndUp && 'ml-n4'"
                                   color='primary'>
                    <template v-slot:icon>
                      <v-icon color='white' style='font-size: 1em'>mdi mdi-plus</v-icon>
                    </template>
                    <v-btn :block='$vuetify.breakpoint.xsOnly' class='primary mr-3' @click='siteDaybook'>
                      <v-icon class='mr-2' left>mdi-clipboard-outline</v-icon>
                      {{ $vuetify.breakpoint.smAndDown ? $t('daybook.new_short') : $t('daybook.new') }}
                    </v-btn>
                  </v-timeline-item>

                  <v-timeline-item
                    v-if='$isOnlyTenantBasic && daybooks.length === 0'
                    :class="$vuetify.breakpoint.smAndUp && 'ml-n4'"
                    color='primary'
                  >
                    <template v-slot:icon>
                      <v-icon color='white' style='font-size: 1em'>mdi mdi-information-outline</v-icon>
                    </template>
                    <v-btn :block='$vuetify.breakpoint.xsOnly' class='primary mr-3' disabled>
                      {{ $vuetify.breakpoint.smAndDown ? $t('daybook.no_daybooks_short') : $t('daybook.no_daybooks') }}
                    </v-btn>
                  </v-timeline-item>

                  <v-timeline-item v-for='daybook in daybooks' :key='daybook.id'
                                   :class="$vuetify.breakpoint.smAndUp && 'ml-n4'">
                    <template v-slot:icon>
                      <v-icon color='white' style='font-size: 1em'>mdi mdi-clipboard-edit-outline</v-icon>
                    </template>
                    <v-card elevation='5'>
                      <v-card-title>
                        {{ formatDate(daybook.daybookDate) }}
                        <v-spacer></v-spacer>
                        <v-btn
                          v-if='$userInfo.id === daybook.userId || $isTenantAdmin'
                          class='text-right gradient-icons'
                          dense
                          fab
                          icon
                          small
                          @click='goToDaybook(daybook.id)'
                        >
                          <v-icon>mdi mdi-clipboard-edit-outline</v-icon>
                        </v-btn>
                      </v-card-title>
                      <v-card-text class='gradient-icons pt-2'>
                        <v-row>
                          <v-col>
                            <daybook-entry
                              v-if='daybook.method'
                              :label="$t('daybook.method')"
                              :value='daybook.method.name'
                              icon='mdi mdi-map-legend'
                            />
                            <v-divider
                              v-if='daybook.entryStart || daybook.entryEnd || daybook.entryStartWait || daybook.entryEndWait'
                            ></v-divider>
                            <daybook-entry
                              v-if='daybook.entryStart || daybook.entryEnd'
                              :label="$t('daybook.entry_times')"
                              :value="
                                [
                                  [$formatTimeNoDate(daybook.entryStart), $formatTimeNoDate(daybook.entryEnd)].join(' - '),
                                  daybook.containsLunch ? $t('daybook.contains_lunch_long') : '',
                                  `(${$formatNumber(daybook.entryDuration, '0,0.0')} h)`,
                                ]
                                  .filter(Boolean)
                                  .join(' ')
                              "
                              icon='fas fa-clock'
                            />
                            <daybook-entry
                              v-if='daybook.entryStartWait || daybook.entryEndWait'
                              :label="$t('daybook.entry_wait_times')"
                              :value="
                                [
                                  [$formatTimeNoDate(daybook.entryStartWait), $formatTimeNoDate(daybook.entryEndWait)].join(' - '),
                                  `(${$formatNumber(daybook.entryWaitDuration, '0,0.0')} h)`,
                                ]
                                  .filter(Boolean)
                                  .join(' ')
                              "
                              :value2='daybook.entryWaitDescription'
                              icon='mdi mdi-timer-sand'
                            />
                            <daybook-entry
                              v-if='daybook.entryStartWait2 !== null || daybook.entryEndWait2 !== null'
                              :label="`${$t('daybook.entry_wait_times')} 2`"
                              :value="
                                [
                                  [$formatTimeNoDate(daybook.entryStartWait2), $formatTimeNoDate(daybook.entryEndWait2)].join(' - '),
                                  `(${$formatNumber(daybook.entryWaitDuration2, '0,0.0')} h)`,
                                ]
                                  .filter(Boolean)
                                  .join(' ')
                              "
                              :value2='daybook.entryWait2Description'
                              icon='mdi mdi-timer-sand'
                            />
                            <daybook-entry
                              v-if='daybook.entryStartWait3 !== null || daybook.entryEndWait3 !== null'
                              :label="`${$t('daybook.entry_wait_times')} 3`"
                              :value="
                                [
                                  [$formatTimeNoDate(daybook.entryStartWait3), $formatTimeNoDate(daybook.entryEndWait3)].join(' - '),
                                  `(${$formatNumber(daybook.entryWaitDuration3, '0,0.0')} h)`,
                                ]
                                  .filter(Boolean)
                                  .join(' ')
                              "
                              :value2='daybook.entryWait3Description'
                              icon='mdi mdi-timer-sand'
                            />
                            <v-divider></v-divider>

                            <daybook-entry
                              v-if="daybook.daybookItems.filter(i => i.type === 'additionalWork').length > 0"
                              :label="$tc('daybook.additional_work', 0)"
                              :value="daybook.daybookItems.filter(i => i.type === 'additionalWork')"
                              daybookItem
                              icon='mdi mdi-page-next'
                            />
                            <daybook-entry
                              v-if='daybook.siteSituation'
                              :label="$t('daybook.site_situation')"
                              :value='daybook.siteSituation'
                              icon='mdi mdi-progress-question'
                            />
                            <daybook-entry
                              v-if="daybook.daybookItems.filter(i => i.type === 'deviation').length > 0"
                              :label="$tc('daybook.deviation', 2)"
                              :value="daybook.daybookItems.filter(i => i.type === 'deviation')"
                              daybookItem
                              icon='mdi mdi-image-broken-variant'
                            />
                            <daybook-entry
                              v-if='daybook.deviationReportMade'
                              :label="$t('daybook.deviation_report_made')"
                              :value="daybook.deviationReportMade ? $t('yes') : ''"
                              icon='mdi mdi-file-delimited-outline'
                            />
                            <daybook-entry
                              v-if='daybook.ordererResponsibilityItems'
                              :label="$t('daybook.orderer_responsibility_items')"
                              :value='daybook.ordererResponsibilityItems'
                              icon='mdi mdi-folder-star-multiple-outline'
                            />
                            <daybook-entry
                              v-if='daybook.reviewsHeld'
                              :label="$t('daybook.reviews_held')"
                              :value='daybook.reviewsHeld'
                              icon='mdi mdi-message-draw'
                            />
                            <v-divider
                              v-if='
                                daybook.diameter ||
                                daybook.drillingLength ||
                                daybook.drillingLengthAtDay ||
                                daybook.ofWhichRock ||
                                daybook.elevationTopEnd ||
                                daybook.elevationLowerEnd
                              '
                            >
                            </v-divider>

                            <template v-for='(drilling, key) in daybook.daybookDrillings'>
                              <div
                                v-if='
                                  drilling.drillingLengthAtDay !== null ||
                                  drilling.ofWhichRock !== null ||
                                  drilling.elevationTopEnd !== null ||
                                  drilling.elevationLowerEnd !== null
                                '
                                :key='drilling.id'
                              >
                                <v-card-title>Poraus {{ key + 1 }}</v-card-title>
                                <daybook-entry
                                  v-if='drilling.method'
                                  :label="$t('sites.new_site.method')"
                                  :value='drilling.method.name'
                                  icon='mdi mdi-shovel'
                                />
                                <daybook-entry
                                  v-if='drilling.diameter'
                                  :label="$t('sites.new_site.pipe_diameter')"
                                  :value='drilling.diameter.name'
                                  icon='mdi mdi-diameter-outline'
                                />
                                <!--<daybook-entry
                                  v-if="daybook.diameter"
                                  icon="mdi mdi-diameter-outline"
                                  :label="$t('daybook.diameter')"
                                  :value="daybook.diameter.name"
                                />-->
                                <daybook-entry
                                  v-if='drilling.drillingLength'
                                  :label="$t('daybook.drilling_length')"
                                  :value="$formatNumber2DecAppendix(drilling.drillingLength, 'm')"
                                  icon='mdi mdi-arrow-expand-horizontal'
                                />
                                <daybook-entry
                                  v-if='drilling.drillingLengthAtDay'
                                  :label="$t('daybook.drilling_length_at_day')"
                                  :value="$formatNumber2DecAppendix(drilling.drillingLengthAtDay, 'm')"
                                  icon='mdi mdi-arrow-expand-horizontal'
                                />
                                <daybook-entry
                                  v-if='drilling.ofWhichRock'
                                  :label="$t('daybook.of_which_rock')"
                                  :value="$formatNumber2DecAppendix(drilling.ofWhichRock, 'm')"
                                  icon='mdi mdi-image-filter-hdr'
                                />
                                <daybook-entry
                                  v-if='drilling.elevationTopEnd'
                                  :label="$t('daybook.elevation_top_end')"
                                  :value="$formatNumber2DecAppendix(drilling.elevationTopEnd, 'mm')"
                                  icon='mdi mdi-elevation-rise'
                                />
                                <daybook-entry
                                  v-if='drilling.elevationLowerEnd'
                                  :label="$t('daybook.elevation_lower_end')"
                                  :value="$formatNumber2DecAppendix(drilling.elevationLowerEnd, 'mm')"
                                  icon='mdi mdi-elevation-decline'
                                />
                                <v-divider></v-divider>
                              </div>
                            </template>

                            <daybook-entry
                              v-if="daybook.daybookItems.filter(i => i.type === 'bought').length > 0"
                              :label="$tc('daybook.bought', 2)"
                              :value="daybook.daybookItems.filter(i => i.type === 'bought')"
                              daybookItem
                              icon='mdi mdi-cart-arrow-down'
                            />
                            <daybook-entry
                              v-if="daybook.daybookItems.filter(i => i.type === 'ordered').length > 0"
                              :label="$tc('daybook.ordered', 2)"
                              :value="daybook.daybookItems.filter(i => i.type === 'ordered')"
                              daybookItem
                              icon='mdi mdi-shopping-outline'
                            />
                            <daybook-entry
                              v-if='daybook.ordererNotices'
                              :label="$t('daybook.orderer_notices')"
                              :value='daybook.ordererNotices'
                              icon='mdi mdi-message-alert'
                            />
                            <daybook-entry
                              v-if='daybook.other'
                              :label="$t('daybook.other')"
                              :value='daybook.other'
                              icon='mdi mdi-frequently-asked-questions'
                            />
                            <daybook-entry
                              v-if='daybook.user'
                              :label="$t('daybook.user')"
                              :value="[daybook.user.lastName, daybook.user.firstName].join(' ')"
                              icon='mdi mdi-account'
                            />
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-timeline-item>
                </v-timeline>
              </v-col>
              <v-col v-else>
                <no-content-view
                  :description="$t('sites.not_possible_to_add_daybook_description')"
                  :reloadButton='false'
                  :title="$t('sites.not_possible_to_add_daybook_title')"
                  title-icon='mdi mdi-card-bulleted-off'
                />
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>

        <!-- PEREHDYTYKSET -->
        <v-tab-item :value="id && 'introduction'" style='height: 100%'>
          <v-container fluid>
            <v-row>
              <v-col
                :class="$vuetify.breakpoint.xsOnly ? 'pa-0 ma-0 mt-4' : 'mt-3'"
                cols='12'
                lg='8'
                md='8'
                offset-lg='2'
                offset-md='2'
              >
                <site-introduction :site='site' />
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>

        <!-- TARKASTUKSET -->
        <v-tab-item :value="id && 'equipmentchecks'" style='height: 100%'>
          <v-container fluid>
            <v-row>
              <v-col
                :class="$vuetify.breakpoint.xsOnly ? 'pa-0 ma-0 mt-4' : 'mt-3'"
                cols='12'
                lg='8'
                md='8'
                offset-lg='2'
                offset-md='2'
              >
                <site-equipment-checks :site='site' />
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>

        <!-- MVR-MITTARIT -->
        <v-tab-item :value="id && 'mvr'" style='height: 100%'>
          <v-container fluid>
            <v-row>
              <v-col
                :class="$vuetify.breakpoint.xsOnly ? 'pa-0 ma-0 mt-4' : 'mt-3'"
                cols='12'
                lg='8'
                md='8'
                offset-lg='2'
                offset-md='2'
              >
                <site-mvr-checks :site='site' />
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
      </v-tabs-items>

      <div v-if='$vuetify.breakpoint.xsOnly'>
        <v-btn
          v-if='id && ($isTenantAdmin || $isTenantManager)'
          :disabled='isSiteDataSame'
          bottom
          color='primary'
          fab
          fixed
          right
          @click='saveSite'
        >
          <v-icon>mdi mdi-content-save</v-icon>
        </v-btn>

        <v-btn
          v-if='!id && ($isTenantAdmin || $isTenantManager)'
          :disabled='isSiteDataSame'
          bottom
          class='primary'
          fab
          fixed
          right
          @click='createSite'
        >
          <v-icon>mdi mdi-content-save</v-icon>
        </v-btn>
      </div>
      <add-person-dialog ref='AddPersonDialog' @master-created='siteMasterCreated' @guide-created='siteGuideCreated' />
      <map-dialog ref='MapDialog' :geocoding='this.site.geocoding' @change='setLocation' />
    </v-col>
  </hb-basic-page>
</template>

<script>
import siteApi from '@/api/site';
import geocodingApi from '@/api/geocoding';
import itemApi from '@/api/item';
import fileApi from '@/api/file';
import personApi from '@/api/person';
import workGroupApi from '@/api/workGroup';
import equipmentApi from '@/api/equipment';
import projectApi from '@/api/project';
import workerApi from '@/api/worker';
import reportApi from '@/api/report';
import introductionApi from '@/api/introduction';
import downloadjs from 'downloadjs';
import SiteViewDatePicker from '@/components/Sites/SiteViewDatePicker';
import ItemCombobox from '@/components/ItemCombobox';
import AddPersonDialog from '@/components/AddPersonDialog';
import PersonCombobox from '@/components/PersonCombobox';
import uniqBy from 'lodash/uniqBy';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import EquipmentCombobox from '@/components/EquipmentCombobox';
import dayjs from 'dayjs';
import DatePicker from '@/components/DatePicker';
import daybookApi from '@/api/daybook';
import DaybookEntry from '@/components/Sites/DaybookEntry';
import MapDialog from '@/components/MapDialog';
import NoContentView from '@/components/NoContentView';
import NumericInput from '@/components/NumericInput';
import SiteDrillingMultipleItemSelect from '@/components/Sites/SiteDrillingMultipleItemSelect';
import FileUpload from '@/components/FileUpload';
import orderBy from 'lodash.orderby';
import SiteIntroduction from '@/components/Sites/SiteIntroduction';
import axios from 'axios';
import SiteEquipmentChecks from '@/components/Sites/SiteEquipmentChecks';
import SiteMvrChecks from '@/components/Sites/SiteMvrChecks.vue';

export default {
  name: 'SiteView',
  components: {
    SiteEquipmentChecks,
    SiteIntroduction,
    FileUpload,
    SiteDrillingMultipleItemSelect,
    NumericInput,
    NoContentView,
    MapDialog,
    DaybookEntry,
    DatePicker,
    EquipmentCombobox,
    PersonCombobox,
    AddPersonDialog,
    ItemCombobox,
    SiteViewDatePicker,
    SiteMvrChecks,
  },
  props: {
    tab: {
      type: String,
      default: 'basic_info',
    },
    id: {
      type: [Number, String],
    },
  },
  data() {
    return {
      tabsWithRoute: [
        {
          name: this.$t('sites.new_site.work_site_basic_info'),
          route: {
            name: 'tenant_site_edit_site',
            params: { id: this.id, tab: 'basic_info' },
          },
        },
        {
          name: this.$t('sites.new_site.work_site_drilling_info'),
          route: {
            name: 'tenant_site_edit_site',
            params: { id: this.id, tab: 'drilling_info' },
          },
        },
        {
          name: this.$tc('sites.workers.title', 2),
          route: {
            name: 'tenant_site_edit_site',
            params: { id: this.id, tab: 'workers' },
          },
        },
        {
          name: this.$tc('sites.attachment', 2),
          route: {
            name: 'tenant_site_edit_site',
            params: { id: this.id, tab: 'attachments' },
          },
        },
        {
          name: this.$tc('daybook.title', 2),
          route: {
            name: 'tenant_site_edit_site',
            params: { id: this.id, tab: 'daybooks' },
          },
        },
        {
          name: this.$t('sites.introduction.title'),
          route: {
            name: 'tenant_site_edit_site',
            params: { id: this.id, tab: 'introduction' },
          },
        },
        {
          name: this.$t('sites.equipment_checks.title'),
          route: {
            name: 'tenant_site_edit_site',
            params: { id: this.id, tab: 'equipmentchecks' },
          },
        },
        {
          name: this.$t('sites.mvr.title'),
          route: {
            name: 'tenant_site_edit_site',
            params: { id: this.id, tab: 'mvr' },
          },
        },
      ],
      tabsWithoutRoute: [
        {
          name: this.$t('sites.new_site.work_site_basic_info'),
        },
        {
          name: this.$t('sites.new_site.work_site_drilling_info'),
        },
        {
          name: this.$tc('sites.workers.title', 2),
        },
        {
          name: this.$tc('sites.attachment', 2),
        },
        {
          name: this.$tc('daybook.title', 2),
        },
      ],
      statuses: [
        { desc: this.$t('sites.statuses.CREATED'), value: 'CREATED' },
        { desc: this.$t('sites.statuses.SCHEDULED'), value: 'SCHEDULED' },
        { desc: this.$t('sites.statuses.INPROGRESS'), value: 'INPROGRESS' },
        { desc: this.$t('sites.statuses.DONE'), value: 'DONE' },

        { desc: this.$t('sites.statuses.BILLED'), value: 'BILLED' },
        { desc: this.$t('sites.statuses.DELETED'), value: 'DELETED' },
      ],
      files: [],
      loading: true,
      searchPhrase: '',
      loadingSiteWorkerListReport: false,
      backButton: {
        show: true,
        fallback: 'tenant_worksites',
      },
      selectedTab: 'basic_info',
      saving: false,
      items: [],
      persons: [],
      workGroups: [],
      equipments: [],
      projects: [],
      workers: [],
      daybooks: [],
      introductions: [],
      loadingSiteWorkerIntroPDF: false,
      site: {
        name: '',
        address: '',
        project: null,
        siteMaster: null,
        siteGuide: null,
        plannedStartDate: null,
        plannedDuration: '',
        workGroup: null,
        equipments: [],
        additionalWork: '',
        billingReference: '',
        diameters: [],
        drillingLength: '',
        minDrillingLength: '',
        centeringRings: [],
        methods: [],
        additionalInfo: '',
        hidden: false,
        status: 'CREATED',
        workers: [],
        items: [],
        geocoding: {
          result: null,
          lat: null,
          lng: null,
          id: null,
          address: '',
        },
      },
      selectedWorker: null,
      loadingSiteDaybookListReport: false,
      siteWhenMounted: {},
      headers: [
        {
          text: `${this.$t('sites.workers.name')}`,
          align: 'start',
          filterable: true,
          value: 'firstname',
        },
        { text: `${this.$t('sites.working_starts')}`, sortable: false, value: 'workStart' },
        { text: `${this.$t('sites.working_ends')}`, sortable: false, value: 'workEnd' },
        { text: `${this.$t('sites.introduction_completed')}`, sortable: false, class: 'admin-only', value: 'intro' },
        {
          text: `${this.$t('sites.intro_checker')}`,
          sortable: false,
          class: 'admin-only',
          value: 'checker',
        },
        { text: ``, align: 'end', sortable: false, class: 'admin-only', value: 'actions' },
      ],
    };
  },
  async mounted() {
    this.loading = true;

    if (this.id) {
      this.site.id = parseInt(this.id);
      await this.getSite();
    } else {
      if (!this.site.items || this.site.items.length === 0) {
        await this.addNewDrilling();
      }
    }
    await Promise.all([
      (async () => {
        await this.getItems();
      })(),
      (async () => {
        await this.getPersons();
      })(),
      (async () => {
        await this.getWorkGroups();
      })(),
      (async () => {
        await this.getEquipments();
      })(),
      (async () => {
        await this.getProjects();
      })(),
      (async () => {
        await this.getWorkers();
      })(),
      (async () => {
        await this.getDaybooks();
      })(),
      (async () => {
        await this.getIntroductionsBySite();
      })(),
    ]);

    //if (this.id) {
    this.selectedTab = this.tab;
    //}
    this.loading = false;
  },
  computed: {
    computedHeaders() {
      if (this.$isOnlyTenantBasic) {
        return this.headers.filter(h => h.class !== 'admin-only');
      }
      return this.headers;
    },
    methods() {
      return this.items.filter(item => item.type === 'menetelmä');
    },
    pipes() {
      return this.items.filter(item => item.type === 'putki');
    },
    centeringRings() {
      return this.items.filter(item => item.type === 'keskitysrengas');
    },
    flowingWells() {
      return this.items.filter(item => item.type === 'ylivuotokaivo');
    },
    endPlugs() {
      return this.items.filter(item => item.type === 'päätytulppa');
    },
    siteAdditionalThings() {
      return this.site?.items?.filter(i => i.task === 'additional_item') || [];
    },
    siteDrillings() {
      const d = this.site?.items?.filter(i => i.task === 'drilling') || [];

      const tasks = Array.from(new Set(d.map(i => i.taskOrder)));
      const res = {};
      tasks.forEach(t => {
        res[t] = d.filter(r => r.taskOrder === t);
      });
      const keys = Object.keys(res);
      keys.sort();
      const res1 = [];
      keys.forEach(k => {
        res1.push({
          pipe: res[k].find(r => r.attribute === 'putki'),
          centeringRings: res[k].filter(r => r.attribute === 'keskitysrengas'),
          method: res[k].find(r => r.attribute === 'menetelmä'),
          flowingWells: res[k].filter(r => r.attribute === 'ylivuotokaivo'),
          endPlugs: res[k].filter(r => r.attribute === 'päätytulppa'),
          task: 'drilling',
          taskOrder: parseInt(k),
        });
      });

      return res1;
    },
    filteredWorkers() {
      const ids = {};
      this.siteWorkers?.forEach(w => (ids[w.workerId] = true));
      const workers = this.workers.filter(w => !ids[w.workerId] && w.person?.email?.indexOf('hubble.fi') === -1);
      workers.sort((a, b) => `${a.person.lastname} ${a.person.firstname}`.localeCompare(`${b.person.lastname} ${b.person.firstname}`));
      return workers;
    },
    siteWorkers() {
      if (!this.site.workers) return [];
      const workers = uniqBy([...this.site.workers], 'workerId');
      workers.sort((a, b) => `${a.lastname} ${a.firstname}`.localeCompare(`${b.lastname} ${b.firstname}`));
      return workers;
    },
    isSiteDataSame() {
      return isEqual(this.siteWhenMounted, this.site);
    },
  },
  watch: {
    selectedTab(val) {
      if (this.id && val !== this.tab) {
        this.$router.replace({ name: 'tenant_site_edit_site', params: { id: this.site.id, tab: val } });
      }
    },
    tab(val) {
      if (this.id && val !== this.selectedTab) {
        this.selectedTab = val;
      }
    },
  },
  methods: {
    async siteWorkerIntroPDF() {
      if (!this.site || !this.site.id) return false;
      this.loadingSiteWorkerIntroPDF = true;
      let data;
      try {
        data = await axios({
          url: 'api/tenant/report/introduction/' + this.id + '/data',
          method: 'GET',
        }).then(r => r.data);
      } catch (e) {
        console.warn('Työmaan tietojen haku epäonnistui', e.Message);
      }
      if (!data) return;
      try {
        const filename = this.$fileNameSanitizer(`${this.site.name}_${dayjs().format('YYYY-MM-DD_HH-mm-ss')}_perehdytykset.pdf`);
        downloadjs(await reportApi.getSiteWorkerIntroReport(this.site.id), filename, 'application/pdf');
      } catch (e) {
        console.log('PDF:n haku epäonnistui', e);
      }
      this.loadingSiteWorkerIntroPDF = false;
    },
    getWorkerIntroduction(workerId) {
      const introductionData = this.introductions.find((intro) => intro.userId === workerId);
      return introductionData;
    },
    isWorkerIntroAccepted(workerId) {
      const intro = this.getWorkerIntroduction(workerId);
      if (typeof intro === 'undefined') return false;
      return this.$formatDateNoTime(dayjs(intro.accepted));
    },
    isWorkerIntroChecked(workerId) {
      const intro = this.getWorkerIntroduction(workerId);
      if (typeof intro === 'undefined' || intro && intro.checked === null) return false;
      return `${this.$formatDateNoTime(dayjs(intro.checked))}`;
    },
    getWorkerIntroChecker(workerId) {
      const intro = this.getWorkerIntroduction(workerId);
      if (typeof intro === 'undefined' || intro && intro.checked === null) return '';
      return `${intro.checkedUser?.firstName} ${intro.checkedUser?.lastName}`;
    },
    async setWorkerIntroChecked(worker) {
      const intro = this.getWorkerIntroduction(worker.userId);
      if (typeof intro === 'undefined') return;
      try {
        await introductionApi.markIntroductionChecked(intro.id);
        await this.getIntroductionsBySite();
      } catch (e) {
        console.log(e.Message);
      }
    },
    changeDrillingPropertyOnSite(event, _idx) {
      let items = [...this.site.items];
      items.forEach(i => {
        if (i._idx === _idx) {
          if (typeof event === 'object') {
            i.item = event;
          } else if (parseInt(event)) {
            i.amount = event;
          }
        }
      });
      this.site.items = items;
    },
    changeDrillingProperty(drilling, _idx, field, attribute, newValue) {
      const iIdx = this.site?.items.findIndex(i => i.task === 'drilling' && i._idx === _idx);
      if (iIdx > -1) {
        this.$set(this.site.items[iIdx], field, newValue);
      } else {
        let items = [...this.site.items];
        items.push({
          [field]: newValue,
          taskOrder: drilling.taskOrder,
          task: drilling.task,
          _idx: this.$randomString(),
          attribute: attribute,
        });
        this.site.items = items;
      }
    },
    addNewDrilling() {
      const orders = (this.site.items || []).map(i => i.taskOrder);
      const maxOrder = Math.max(...orders, 0);

      if (this.site) {
        let items = [...this.site.items];
        items.push({
          item: null,
          taskOrder: (maxOrder || 0) + 10,
          task: 'drilling',
          attribute: 'putki',
          _idx: this.$randomString(),
          amount: null,
        });
        this.site.items = items;
      }
    },
    addNewDrillingOption(attribute) {
      if (this.site) {
        let items = [...this.site.items];
        items.push({
          item:
            attribute === 'keskitysrengas'
              ? this.centeringRings[0]
              : attribute === 'ylivuotokaivo'
                ? this.flowingWells[0]
                : attribute === 'päätytulppa' && this.endPlugs[0],
          taskOrder: 0,
          task: 'additional_item',
          attribute,
          _idx: this.$randomString(),
          amount: 1,
        });
        this.site.items = items;
      }
    },
    deleteDrillingProperty(_idx) {
      const iIdx = this.site?.items.findIndex(i => i.task === 'additional_item' && i._idx === _idx);
      if (iIdx > -1) {
        const items = [...this.site.items];
        items.splice(iIdx, 1);
        this.site.items = items;
      }
    },
    confirmDrillingDelete(drilling) {
      this.$confirmDelete('Poista poraus').then(confirm => {
        if (confirm) {
          const toRemove = this.site.items
            .filter(si => si.taskOrder === drilling.taskOrder && si.task === drilling.task)
            .map(si => si._idx);
          this.site.items = this.site.items.filter(si => !toRemove.includes(si._idx));
        }
      });
    },
    async setLocation(geocoding) {
      // Set latitude & longitude

      this.site.geocoding = Object.assign({}, geocoding);
      // Get address by coordinates
      const addressObject = await geocodingApi.getByLatLng(this.site.geocoding.lat, this.site.geocoding.lng);
      this.site.geocoding.address = addressObject?.[0].address;
      this.site.geocoding.result = addressObject?.[0].result;
      //this.$showSuccessNotification('Sijainti tallennettu!');
    },
    openMapDialog() {
      this.$refs.MapDialog.open(this.site);
    },
    log(...args) {
      console.log(args);
    },
    async getDaybooks() {
      if (this.siteId) {
        const daybooks = await daybookApi.getDaybooksBySiteId(this.siteId);
        this.daybooks = daybooks.map(daybook => {
          daybook.entryDuration = this.getEntryDuration(daybook);
          daybook.entryWaitDuration = this.getEntryDuration(daybook, 'Wait');
          daybook.entryWaitDuration2 = this.getEntryDuration(daybook, 'Wait2');
          daybook.entryWaitDuration3 = this.getEntryDuration(daybook, 'Wait3');
          daybook.daybookItems.map(item => (item.files = []));
          return daybook;
        });
        const daybookItemImages = await fileApi.getFilesMultipleObjectIds(
          daybooks.flatMap(daybook => daybook.daybookItems.flatMap(i => i.id)),
          'daybookItem',
        );

        const daybookItemIds = daybookItemImages.map(fileEntity => fileEntity.objectId);
        this.daybooks = daybooks.map(daybook => {
          daybook.daybookItems.map(async item => {
            if (daybookItemIds.includes(item.id)) {
              const files = daybookItemImages.filter(fileEntity => fileEntity.objectId === item.id);

              for (let i = 0; i < files.length; i++) {
                if (files[i]?.contentType?.startsWith('image/')) {
                  try {
                    files[i].thumbnail = await fileApi.getFileUrlLazy(this, files[i]);
                  } catch (e) {
                    console.error(e);
                  }
                }
              }
              item.files = files;
            }
            return item;
          });
          return daybook;
        });

        // Doing this twice to first load all thumbnails and then full source
        this.daybooks = daybooks.map(daybook => {
          daybook.daybookItems.map(async item => {
            if (daybookItemIds.includes(item.id)) {
              const files = daybookItemImages.filter(fileEntity => fileEntity.objectId === item.id);

              for (let i = 0; i < files.length; i++) {
                if (files[i]?.contentType?.startsWith('image/')) {
                  //files[i].thumbnail = await fileApi.getImageUrlLazy(this, files[i]);

                  try {
                    files[i].source = await fileApi.getFileUrl(this, files[i]);
                  } catch (e) {
                    console.error(e);
                  }
                }
              }
              item.files = files;
            }
            return item;
          });
          return daybook;
        });
      }
    },
    formatDate(date) {
      if (date) return dayjs(date).format('DD.MM.YYYY');
      return;
    },
    async siteWorkerListReport() {
      if (!this.site || !this.site.id) return false;
      this.loadingSiteWorkerListReport = true;
      try {
        const filename = this.$fileNameSanitizer(`${this.site.name}_${dayjs().format('YYYY-MM-DD_HH-mm-ss')}.pdf`);
        downloadjs(await reportApi.getSiteWorkerListReport(this.site.id), filename, 'application/pdf');
      } catch (e) {
        console.error(e);
        this.$showErrorNotification(this.$t('generic_error'));
      }
      this.loadingSiteWorkerListReport = false;
    },
    async siteDaybookListReport() {
      if (!this.site || !this.site.id) return false;
      this.loadingSiteDaybookListReport = true;
      try {
        const filename = this.$fileNameSanitizer(`${this.site.name}_${dayjs().format('YYYY-MM-DD_HH-mm-ss')}.pdf`);
        downloadjs(await reportApi.getSiteDaybookReport(this.site.id), filename, 'application/pdf');
      } catch (e) {
        console.error(e);
        this.$showErrorNotification(this.$t('generic_error'));
      }
      this.loadingSiteDaybookListReport = false;
    },
    async siteDaybook() {
      await this.$router.push({ name: 'tenant_site_new_daybook', params: { siteId: this.site.id } });
    },
    async goToDaybook(id) {
      await this.$router.push({ name: 'tenant_site_daybook', params: { siteId: this.site.id, id: id } });
    },
    getEntryDuration(entry, appendix = '') {
      if (!entry[`entryStart${appendix}`]) return 0;
      if (!entry[`entryEnd${appendix}`]) return this.$t('reports.unfinished');

      let diff;
      let start = entry[`entryStart${appendix}`];
      let end = entry[`entryEnd${appendix}`];

      if (appendix === '' && entry.containsLunch) {
        end = dayjs(end).subtract(0.5, 'hours');
      }

      if (end.isBefore(start)) {
        diff = start.diff(end);
        return '-' + dayjs.duration(diff).asHours().toFixed(1);
      } else {
        diff = end.diff(start);
        return dayjs.duration(diff).asHours().toFixed(1);
      }
    },
    handleWorkerWorkStartChange(value, worker) {
      const fIndex = this.site.workers.findIndex(w => w.workerId === worker.workerId);
      if (fIndex > -1) {
        this.site.workers[fIndex].workStart = dayjs(value);
      }
    },
    handleWorkerWorkEndChange(value, worker) {
      console.log('hWWEC called');
      console.log(value);
      console.log(worker);
      const fIndex = this.site.workers.findIndex(w => w.workerId === worker.workerId);
      if (fIndex > -1) {
        this.site.workers[fIndex].workEnd = dayjs(value);
      }
    },
    async createSite() {
      if (this.site.name === null || this.site.name.length === 0) {
        return;
      }
      this.loading = true;
      await siteApi.createSite(this.site);
      this.loading = false;
      this.$showSuccessNotification(this.$t('sites.site_created'));
      await this.$router.push({ name: 'tenant_worksites', params: { siteStatus: 'CREATED' } });
    },
    async saveSite() {
      this.loading = true;
      await siteApi.updateSite(this.id, this.site);
      await this.getSite();
      this.$showSuccessNotification(this.$t('sites.site_saved'));
      this.loading = false;
      //await this.$router.go(-1);
    },
    cancel() {
      this.$router.go(-1);
    },
    async getSite() {
      this.siteId = this.id;
      let site = await siteApi.getSite(this.id);
      if (site.geocoding === null) {
        site.geocoding = {
          result: null,
          lat: null,
          lng: null,
          id: null,
          address: '',
        };
      }
      site.items?.forEach((i, idx) => (i._idx = `${idx}_${this.$randomString()}`));

      this.site = site;
      if (!this.site.items || this.site.items.length === 0) {
        this.addNewDrilling();
      }

      this.siteWhenMounted = cloneDeep(site);
    },
    async getItems() {
      const items = await itemApi.getItemsByTypes(['putki', 'menetelmä', 'keskitysrengas', 'ylivuotokaivo', 'päätytulppa']);
      this.items = items;
    },
    async getPersons() {
      const personList = (await personApi.getPersonsPaged()).content;
      this.persons = this.$richerUsers(personList);
    },
    async siteMasterCreated(person) {
      await this.getPersons();
      this.site.siteMaster = person;
    },
    async siteGuideCreated(person) {
      await this.getPersons();
      this.site.siteGuide = person;
    },
    async addPerson(personRole) {
      await this.$refs.AddPersonDialog.open(null, personRole);
    },
    async getWorkGroups() {
      this.workGroups = await workGroupApi.getWorkGroups();
    },
    async getEquipments() {
      this.equipments = await equipmentApi.getAllEquipments();
    },
    async getProjects() {
      let projects = await projectApi.getProjects();
      projects = orderBy(projects, ['createdAt'], ['desc']);
      this.projects = projects;
    },
    async getWorkers() {
      this.workers = (await workerApi.getWorkers()).map(worker => {
        worker.workerId = worker.id;
        return worker;
      });
    },
    async getIntroductionsBySite() {
      if (this.id) {
        this.introductions = await introductionApi.getIntroductionsBySite(this.id);
      }
    },
    async onWorkGroupChange(workGroup) {
      const added = [];
      if ((!this.site.workers || (Array.isArray(this.site.workers) && this.site.workers.length === 0)) && workGroup && workGroup.workers) {
        let workStart = this.site.plannedStartDate ? dayjs(this.site.plannedStartDate).clone() : null;
        let workEnd =
          this.site.plannedStartDate && this.site.plannedDuration
            ? dayjs(this.site.plannedStartDate).clone().add(this.site.plannedDuration, 'days')
            : null;
        const workers = [...workGroup.workers].map(w => {
          w.worker = { ...w };
          w.workerId = w.id;
          w.id = void 0;
          w.workStart = dayjs(workStart).clone();
          w.workEnd = dayjs(workEnd).clone();

          return w;
        });
        this.site.workers = workers;
        this.$showSuccessNotification(this.$t('sites.messages.workers_added'));
        added.push('workers');
      }
      if (
        (!this.site.equipments || (Array.isArray(this.site.equipments) && this.site.equipments.length === 0)) &&
        workGroup &&
        workGroup.equipments
      ) {
        const equipments = [...workGroup.equipments];
        this.site.equipments = equipments;
        //this.$showSuccessNotification(this.$t('sites.messages.equipments_added'));
        added.push('equipments');
      }
      if (added.length > 0) {
        this.$showSuccessNotification(
          this.$t('sites.messages.added_x', [added.map(a => this.$tc(`sites.messages.part_${a}`, 1)).join(', ')]),
        );
      }
    },
    async deleteWorker(workerId) {
      const wIndex = this.site.workers.findIndex(w => w.workerId === workerId);
      if (wIndex > -1) {
        this.site.workers.splice(wIndex, 1);
      }
    },
    async onSelectWorker(worker) {
      if (worker == null) return false;

      let workStart = this.site.plannedStartDate ? dayjs(this.site.plannedStartDate).clone() : null;
      let workEnd =
        this.site.plannedStartDate && this.site.plannedDuration
          ? dayjs(this.site.plannedStartDate).clone().add(this.site.plannedDuration, 'days')
          : null;

      const _worker = {
        ...worker,
        worker: worker,
        workStart,
        workEnd,
        firstname: worker.person.firstname,
        lastname: worker.person.lastname,
      };

      const workers = uniqBy([...this.site.workers, _worker], 'workerId');
      this.site.workers = workers;
      this.$nextTick(() => {
        this.selectedWorker = null;
      });
    },
  },
};
</script>

<style lang='scss' scoped>
.v-toolbar__extension .button_group {
  position: fixed;
  bottom: 16px;
  right: 16px;
}

.grey--bg {
  background-color: var(--v-default-lighten1);
}

.v-tabs-items {
  background-color: transparent !important;
}
</style>
<style lang='scss'>
@import '~vuetify/src/styles/settings/variables';

@media #{map-get($display-breakpoints,'sm-and-down')} {
  .daybook-list {
    .v-timeline {
      &:before {
        display: none !important;
      }

      &-item__body {
        max-width: 100% !important;

        .v-card {
          &:before,
          &:after {
            top: 0 !important;
            border: 0 !important;
          }
        }
      }

      .v-timeline-item__divider {
        display: none;
      }
    }
  }
}
</style>
