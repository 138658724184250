import axios from 'axios';

export default {
  async addComment(object, id, /* json stringified comment */ data) {
    return await axios({
      url: `/api/tenant/comment/${object}/${id}`,
      method: 'post',
      data,
    }).then(r => r.data);
  },
  async listComments(object, id) {
    if(!object || !id){
      return []
    }
    return await axios({
      url: `/api/tenant/comment/${object}/${id}`,
      method: 'get',
    }).then(r => r.data);
  },
  async deleteComment(id) {
    return await axios({
      url: `/api/tenant/comment/${id}`,
      method: 'delete',
    }).then(r => r.data);
  },
};
