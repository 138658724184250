<template>
  <v-dialog :fullscreen="$vuetify.breakpoint.xsOnly" v-model="dialog" max-width="600" @keydown.esc="cancel()" persistent>
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title class="white--text">{{ $t('sites.add_file') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="cancel">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-1 gradient-icons">
        <v-row class="mb-3 mt-2">
          <v-col>
            <div v-if="isImage" style="width: 100%;" class="d-flex justify-center">
              <v-img :src="file.url" style="border-radius: 10px; height: 300px" aspect-ratio="1" class="primary lighten-2 mb-5"></v-img>
            </div>
            <v-text-field
              tabindex="1"
              :disabled="saving"
              outlined
              dense
              hide-details
              :label="$t('sites.attachment_description')"
              light
              item-value=""
              autocomplete="off"
              clearable
              v-model="file.description"
            >
              <template v-slot:prepend>
                <v-icon>mdi mdi-card-text-outline</v-icon>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>

      <v-card-actions :class="$vuetify.breakpoint.xsOnly ? 'flex-column-reverse' : ''">
        <v-spacer></v-spacer>
        <v-btn
          tabindex="6"
          :fab="$vuetify.breakpoint.xsOnly"
          :bottom="$vuetify.breakpoint.xsOnly"
          :fixed="$vuetify.breakpoint.xsOnly"
          :left="$vuetify.breakpoint.xsOnly"
          color="default"
          @click.native="cancel()"
          ><v-icon v-if="$vuetify.breakpoint.xsOnly">mdi mdi-close</v-icon><span v-if="$vuetify.breakpoint.smAndUp">{{ $t('cancel') }}</span></v-btn
        >
        <v-btn
          tabindex="5"
          :fab="$vuetify.breakpoint.xsOnly"
          :bottom="$vuetify.breakpoint.xsOnly"
          :fixed="$vuetify.breakpoint.xsOnly"
          :right="$vuetify.breakpoint.xsOnly"
          color="primary"
          @click="saveFile"
          :loading="saving"
          ><v-icon v-if="$vuetify.breakpoint.xsOnly">mdi mdi-content-save</v-icon><span v-if="$vuetify.breakpoint.smAndUp">{{ $t('save') }}</span></v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'AddFileDialog',
  components: {},
  props: {},
  data() {
    return {
      dialog: false,
      saving: false,
      file: {
        description: '',
        url: '',
        filename: '',
        contentType: ''
      },
      fileAddedEvent: null,
    };
  },
  computed: {
    isImage() {
      return this.file.filename.indexOf('jpg' || 'png' || 'jpeg' || 'gif') !== -1
    }
  },
  methods: {
    async open(fileAddedEvent) {
      this.fileAddedEvent = fileAddedEvent
      const file = {
        file: fileAddedEvent.target.files[0],
        filename: fileAddedEvent.target.value.split('\\').pop(),
      };
      this.file.description = file.filename
      this.file.filename = file.filename
      const reader = new FileReader();
      const ind = (this.previewIndex += 1);
      reader.onload = e => {
        const ig = {
          data: e.target.result,
          index: ind,
          loading: false,
        };
        this.file.url = ig.data
      };
      reader.readAsDataURL(file.file);
      this.dialog = true;
    },
    saveFile() {
      const returnedTarget = Object.assign(this.fileAddedEvent, this.file);
      this.$emit('description-given', returnedTarget);
      this.file.description = '';
      this.dialog = false;
    },
    cancel() {
      this.file.description = '';
      this.dialog = false;
    },
  },
};
</script>
