<template>
  <v-dialog :fullscreen="$vuetify.breakpoint.xsOnly" v-model="dialog" max-width="600" @keydown.esc="cancel()" persistent>
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title class="white--text">{{ $t('worker_licenses.card') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="cancel">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-5 gradient-icons">
        <v-form ref="form">
          <v-row class="mb-3 mt-2">
            <v-col class="mb-0 pb-0" cols="12" lg="12" xl="12">
              <worker-combobox
                @change="handleWorkerChange"
                :value="workerLicense.worker"
                :label="$t('worker_licenses.worker')"
                :saving="saving"
                :disabled="!!worker"
                :workers="workers"
                :required="true"
              />

              <item-combobox
                @change="handleCardChange"
                :label="$t('worker_licenses.card')"
                :value="workerLicense.card"
                :saving="saving"
                :items="cards"
                :required="true"
              >
                <template v-slot:prepend>
                  <v-icon>mdi mdi-credit-card</v-icon>
                </template>
              </item-combobox>
              <v-text-field
                :disabled="saving"
                outlined
                dense
                :label="$t('worker_licenses.card_number')"
                light
                item-value=""
                autocomplete="off"
                clearable
                v-model="workerLicense.cardNumber"
                :rules="[$rules.required]"
                validate-on-blur
              >
                <template v-slot:prepend>
                  <v-icon>mdi-numeric</v-icon>
                </template>
              </v-text-field>
            </v-col>
            <v-col class="mt-0 pt-0" cols="12" lg="6" xl="6">
              <date-picker
                :input-styles="{ outlined: true }"
                :label="$t('worker_licenses.date_from')"
                :date="workerLicense.dateFrom"
                :saving="saving"
                :clearable="true"
                @change="handleDateFromChange"
              />
            </v-col>
            <v-col class="mt-0 pt-0" cols="12" lg="6" xl="6">
              <date-picker
                :input-styles="{ outlined: true }"
                :label="$t('worker_licenses.date_to')"
                :date="workerLicense.dateTo"
                :saving="saving"
                :clearable="true"
                @change="handleDateToChange"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>

      <v-card-actions>
        <v-btn
          v-if="$vuetify.breakpoint.smAndUp && workerLicense.id > 0"
          color="error"
          :disabled="saving"
          text
          :loading="deleting"
          @click="ConfirmWorkerLicenseDelete"
        >
          <span>{{ $t('delete') }}</span>
        </v-btn>
        <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
        <v-btn
          tabindex="6"
          :fab="$vuetify.breakpoint.xsOnly"
          :bottom="$vuetify.breakpoint.xsOnly"
          :left="$vuetify.breakpoint.xsOnly"
          :fixed="$vuetify.breakpoint.xsOnly"
          color="default"
          @click.native="cancel()"
          ><v-icon v-if="$vuetify.breakpoint.xsOnly">mdi-close</v-icon
          ><span v-if="$vuetify.breakpoint.smAndUp">{{ $t('cancel') }}</span></v-btn
        >
        <v-btn
          tabindex="5"
          :fab="$vuetify.breakpoint.xsOnly"
          :right="$vuetify.breakpoint.xsOnly"
          :fixed="$vuetify.breakpoint.xsOnly"
          :bottom="$vuetify.breakpoint.xsOnly"
          color="primary"
          @click="save"
          :loading="saving"
          ><v-icon v-if="$vuetify.breakpoint.xsOnly">mdi-plus</v-icon
          ><span v-if="$vuetify.breakpoint.smAndUp">{{ $t('save') }}</span></v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import workerLicencesApi from '@/api/workerLicence';
import workersApi from '@/api/worker';
import itemsApi from '@/api/item';
import WorkerCombobox from '@/components/WorkerCombobox';
import ItemCombobox from '@/components/ItemCombobox';
import DatePicker from '@/components/DatePicker';
import dayjs from 'dayjs';
export default {
  name: 'WorkerLicenseDialog',
  components: { DatePicker, ItemCombobox, WorkerCombobox },
  props: {
    worker: {
      type: Object,
    },
  },
  data() {
    return {
      dialog: false,
      saving: false,
      deleting: false,
      type: null,
      workers: [],
      cards: [],
      workerLicense: {
        card: null,
        cardNumber: '',
        worker: null,
        dateFrom: null,
        dateTo: null,
      },
    };
  },
  methods: {
    async open(workerLicense = {}, type = null) {
      this.showError = false;
      this.dialog = true;
      this.type = type;

      if (this.worker && workerLicense.card) {
        this.workerLicense = workerLicense;
      } else if (this.worker) {
        this.workerLicense = {
          card: null,
          cardNumber: '',
          worker: this.worker,
          dateFrom: null,
          dateTo: null,
        };
      } else {
        this.workerLicense = {
          ...{
            card: null,
            cardNumber: '',
            worker: null,
            dateFrom: null,
            dateTo: null,
          },
          ...workerLicense,
        };
      }
      this.workers = await workersApi.getWorkers();
      this.cards = await itemsApi.getItemsByTypes(['lisenssi']);
    },

    async ConfirmWorkerLicenseDelete() {
      const confirm = await this.$confirmDelete(this.$t('worker_licenses.confirm_dialog.title'));
      if (confirm) {
        await this.deleteWorkerLicense();
      }
    },
    async deleteWorkerLicense() {
      this.deleting = true;
      await workerLicencesApi.deleteWorkerLicense(this.workerLicense.id);
      this.deleting = false;
      this.$showSuccessNotification(this.$t('worker_licenses.deleted'));
      this.$emit('saved', null);
      this.dialog = false;
    },
    async save() {
      this.$refs.form.validate();
      if (this.workerLicense.worker && this.workerLicense.card && this.workerLicense.cardNumber.length > 0) {
        this.saving = true;
        this.dialog = true;
        let workerLicense;
        if (this.workerLicense && this.workerLicense.id > 0) {
          workerLicense = await workerLicencesApi.updateWorkerLicense(this.workerLicense.id, this.workerLicense);
        } else if (this.workerLicense) {
          workerLicense = await workerLicencesApi.createWorkerLicense(this.workerLicense);
        }
        this.saving = false;
        this.$showSuccessNotification(this.$t('worker_licenses.saved'));
        this.$emit('saved', workerLicense);
        this.cancel();
      }
    },
    cancel() {
      this.workerLicense = {
        card: null,
        cardNumber: '',
        worker: null,
        dateFrom: null,
        dateTo: null,
      };
      this.dialog = false;
    },
    handleCardChange(card) {
      this.workerLicense.card = card;
    },
    handleWorkerChange(worker) {
      this.workerLicense.worker = worker;
    },

    handleDateFromChange(date) {
      if (date === null) {
        this.workerLicense.dateFrom = null;
        return;
      }
      this.workerLicense.dateFrom = dayjs(date).toISOString();
    },
    handleDateToChange(date) {
      if (date === null) {
        this.workerLicense.dateTo = null;
        return;
      }
      this.workerLicense.dateTo = dayjs(date).toISOString();
    },
  },
};
</script>
