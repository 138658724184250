<template>
  <hb-basic-page
    :title="$t('work_groups.title')"
    fluid
    :loading="loading"
    :fill-height="loading"
    :extension-height="60"
    :search-title="`${$t('work_groups.search')}...`"
    @handle-search="debouncedSearch"
    class="work-groups-view"
    :pa-0="$vuetify.breakpoint.xsOnly"
    v-if="$isTenantAdmin"
  >
    <template v-if="$vuetify.breakpoint.smAndUp" v-slot:app-bar-extension>
      <v-btn class="mb-3 ml-n1" @click="openNewWorkGroupInDialog" color="primary">
        <v-icon class="no-gradient" :left="$vuetify.breakpoint.smAndUp">mdi-plus</v-icon>
        <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('work_groups.create_new_work_group') }}</span>
      </v-btn>
    </template>

    <v-col cols="12" class="gradient-icons">
      <v-fade-transition>
        <v-row no-gutters>
          <v-btn v-if="$vuetify.breakpoint.xsOnly" @click="openNewWorkGroupInDialog" color="primary" fab fixed bottom right>
            <v-icon class="no-gradient">mdi-plus</v-icon>
          </v-btn>
          <v-col cols="12">
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="filteredWorkGroups"
                  class="h-100 gradient-info__table"
                  :loading="loading"
                  :footer-props="{ itemsPerPageOptions: [50, 100, 500, -1] }"
                  :items-per-page="50"
                  @click:row="openWorkGroupInDialog"
                  style="cursor: pointer"
                >
                  <template v-slot:item.workers="{ item }">
                    <span v-for="(worker, index) in item.workers" :key="index"
                      >{{ worker.lastname }} {{ worker.firstname }}<span v-if="index !== item.workers.length - 1">,&nbsp;</span></span
                    >
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-fade-transition>
    </v-col>
    <workGroup-dialog ref="workGroupDialog" @save="reloadWorkGroups" @work-group-removed="reloadWorkGroups" />
  </hb-basic-page>
</template>

<script>
import WorkGroupDialog from '../../components/WorkGroups/WorkGroupDialog.vue';
import workGroupApi from '@/api/workGroup';
import debounce from 'lodash.debounce';
export default {
  name: 'WorkGroupsView',
  components: {
    WorkGroupDialog: WorkGroupDialog,
  },
  data() {
    return {
      loading: true,
      searchPhrase: '',
      offsetTop: 0,
      headers: [
        {
          text: this.$tc('work_groups.name', 1),
          value: 'name',
          sortable: true,
        },
        {
          text: this.$tc('work_groups.workers', 1),
          value: 'workers',
        },
        {
          text: '',
          value: 'actions',
        },
      ],
      workGroups: [],
    };
  },
  methods: {
    handleSearch(searchPhrase) {
      this.searchPhrase = searchPhrase;
    },
    openNewWorkGroupInDialog() {
      this.$refs.workGroupDialog.openNew();
    },
    openWorkGroupInDialog(workGroup) {
      this.$refs.workGroupDialog.openWorkGroup(workGroup);
    },
    async reloadWorkGroups() {
      if (this.workGroups.length === 0) {
        this.loading = true;
      }
      this.workGroups = await workGroupApi.getWorkGroups();
      this.loading = false;
    },
  },
  computed: {
    filteredWorkGroups() {
      if (!this.searchPhrase) return this.workGroups;
      return this.workGroups.filter(wg => {
        const combinedString = `${wg.name} ${wg.workers.flatMap(w => [w.lastname, w.firstname]).join(' ')}`.toLowerCase();
        console.log(this.searchPhrase.split(' '));
        return this.searchPhrase
          .toLowerCase()
          .split(' ')
          .filter(Boolean)
          .some(s => combinedString.indexOf(s) > -1);
      });
    },
  },
  created() {
    this.debouncedSearch = debounce(this.handleSearch, 450, { maxWait: 5000 });
  },
  mounted() {
    this.$vuetify.goTo(0, {
      duration: 0,
      offset: 0,
    });
    this.reloadWorkGroups();
  },
};
</script>

<style scoped></style>
