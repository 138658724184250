import axios from 'axios';

/*
 * Admin api calls.
 * For example create tenant, add user to tenant, disable/enable user ...
 *
 * ROLE_ADMIN required for all calls
 */
const adminApi = {
  //
  // TenantAdmin
  //
  async getTenants() {
    const response = await axios.get('/api/admin/tenant');
    return response.data;
  },
  async addTenant(name) {
    const data = {
      name,
    }
    const response = await axios.post('/api/admin/tenant', data);
    return response.data;
  },
  /**
   *
   * @param {!string} tenantId
   * @param {!Object} data
   * @param {!string} data.name
   * @param {!string} data.status
   * @returns {Promise<any>}
   */
  async updateTenant(tenantId, data) {
    const requestData = {
      name: data.name,
      status: data.status,
    }
    const response = await axios.post(`/api/admin/tenant/${tenantId}`, requestData);
    return response.data;
  },

  async deleteTenant(tenantId) {
    const response = await axios.delete(`/api/admin/tenant/${tenantId}`);
    return response.data;
  },
  async getTenantUsers(tenantId) {
    const response = await axios.get(`/api/admin/tenant/${tenantId}/user`);
    return response.data;
  },
  async addUserToTenant(userId, tenantId) {
    const request = {
      userId: userId,
    };

    const response = await axios.post(`/api/admin/tenant/${tenantId}/user`, request);
    return response.data;
  },

  async removeUserFromTenant(userId, tenantId) {
    const response = await axios.delete(`/api/admin/tenant/${tenantId}/user/${userId}`);
    return response.data;
  },

  //
  // UserAdmin
  async enableUser(username) {
    const response = await axios.post(`/api/admin/user/${username}/enable`);
    return response.data;
  },

  async disableUser(username) {
    const response = await axios.post(`/api/admin/user/${username}/disable`);
    return response.data;
  },

  async updateUser(subject, data) {
    const response = await axios.post(`/api/admin/user/${subject}`, data);
    return response.data;
  },
  async addRole(subject, role) {
    const response = await axios.post(`/api/admin/user/${subject}/role/${role}`);
    return response.data;
  },
  async deleteRole(subject, role) {
    const response = await axios.delete(`/api/admin/user/${subject}/role/${role}`);
    return response.data;
  },
  async createUser(user) {
    const response = await axios.post(`/api/admin/user`, user);
    return response.data;
  },
  async getLocalUsers() {
    const response = await axios.get('/api/admin/user?user_type=local');
    return response.data;
  },
  async getCloudUsers() {
    const response = await axios.get('/api/admin/user?user_type=cloud');
    return response.data;
  },
  async getAllUsers() {
    const response = await axios.get('/api/admin/user');
    return response.data;
  },
};

export default adminApi;
