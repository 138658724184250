import axios from 'axios';

export default {
  async getWorkerLicences() {
    return await axios({
      url: '/api/tenant/workerlicense',
    }).then(r => r.data);
  },
  async createWorkerLicense(workerLicense) {
    return await axios({
      url: '/api/tenant/workerlicense',
      method: 'post',
      data: workerLicense,
    }).then(r => r.data);
  },
  async updateWorkerLicense(id, workerLicense) {
    return await axios({
      url: '/api/tenant/workerlicense/' + id,
      method: 'put',
      data: workerLicense,
    }).then(r => r.data);
  },

  async deleteWorkerLicense(id) {
    return await axios({
      url: '/api/tenant/workerlicense/' + id,
      method: 'delete',
    }).then(r => r.data);
  },
  async getWorkerLicencesByWorker(workerId) {
    return await axios({
      url: '/api/tenant/workerlicense/worker/' + workerId,
      method: 'get',
    }).then(r => r.data);
  }
};
