import axios from 'axios';
import dayjs from 'dayjs';
import Helper from '@/helper';

function convertTimes(entry) {
  if (entry.entryStart) entry.entryStart = dayjs(entry.entryStart);
  if (entry.entryEnd) entry.entryEnd = dayjs(entry.entryEnd);

  if (entry.entryStartReturn) entry.entryStartReturn = dayjs(entry.entryStartReturn);
  if (entry.entryEndReturn) entry.entryEndReturn = dayjs(entry.entryEndReturn);
  return entry;
}
export default {
  async getTimeEntries() {
    return await axios({
      url: '/api/tenant/timeentry',
    })
      .then(r => r.data)
      .then(r => {
        r.forEach(e => {
          convertTimes(e);
        });
        return r;
      });
  },
  async getOwnTimeEntries(/*filter*/ filter, /* v-data-table.options */ options = {}) {
    const _filter = { ...filter };

    if (_filter.timeEntryTypes) {
      const travelInvoiceIndex = _filter.timeEntryTypes.findIndex(type => type === 'TRAVELINVOICE');
      const timeEntryIndex = _filter.timeEntryTypes.findIndex(type => type === 'TIMEENTRY');
      const combinedIndex = _filter.timeEntryTypes.findIndex(type => type === 'COMBINED');
      if (combinedIndex > -1) {
        _filter.timeEntryTypes.splice(travelInvoiceIndex, 1, ...['TIMEENTRY', 'AWAYENTRY', 'M1', 'M2']);
      } else if (travelInvoiceIndex > -1) {
        _filter.timeEntryTypes.splice(travelInvoiceIndex, 1, ...['M1', 'M2', 'M0']);
      } else if (timeEntryIndex > -1) {
        _filter.timeEntryTypes.splice(timeEntryIndex, 1, ...['TIMEENTRY', 'AWAYENTRY']);
      }
    }
    return await axios({
      url: `/api/tenant/timeentry/own-entries`,
      params: {
        ...Helper.tableOptionsToRestPageParams(options),
        timeEntryTypes: _filter.timeEntryTypes?.join(',') || void 0,
        dates: (_filter.dates || []).join(',') || void 0,
        search: filter.search || void 0,
        userIds: filter.userIds?.join(',') || void 0,
      },
    })
      .then(r => r.data)
      .then(r => {
        if (r.content) {
          r.content.forEach(e => {
            convertTimes(e);
          });
        }
        return r;
      });
  },
  async getTimeEntriesPaged(/*filter*/ filter, /* v-data-table.options */ options = {}) {
    const _filter = { ...filter };

    if (_filter.timeEntryTypes) {
      const travelInvoiceIndex = _filter.timeEntryTypes.findIndex(type => type === 'TRAVELINVOICE');
      const timeEntryIndex = _filter.timeEntryTypes.findIndex(type => type === 'TIMEENTRY');
      const combinedIndex = _filter.timeEntryTypes.findIndex(type => type === 'COMBINED');
      if (combinedIndex > -1) {
        _filter.timeEntryTypes.splice(travelInvoiceIndex, 1, ...['TIMEENTRY', 'AWAYENTRY', 'M1', 'M2', 'M0']);
      } else if (travelInvoiceIndex > -1) {
        _filter.timeEntryTypes.splice(travelInvoiceIndex, 1, ...['M1', 'M2', 'M0']);
      } else if (timeEntryIndex > -1) {
        _filter.timeEntryTypes.splice(timeEntryIndex, 1, ...['TIMEENTRY', 'AWAYENTRY']);
      }
    }
    return await axios({
      url: `/api/tenant/timeentry/paged`,
      params: {
        ...Helper.tableOptionsToRestPageParams(options),
        timeEntryTypes: _filter.timeEntryTypes?.join(',') || void 0,
        dates: (_filter.dates || []).join(',') || void 0,
        search: filter.search || void 0,
        userIds: (filter.userIds || []).join(',') || void 0,
      },
    })
      .then(r => r.data)
      .then(r => {
        if (r.content) {
          r.content.forEach(e => {
            convertTimes(e);
          });
        }
        return r;
      });
  },
  async getSalaryData(/*filter*/ filter) {
    const _filter = { ...filter };
    if (_filter.timeEntryTypes) {
      const travelInvoiceIndex = _filter.timeEntryTypes.findIndex(type => type === 'TRAVELINVOICE');
      if (travelInvoiceIndex > -1) {
        _filter.timeEntryTypes.splice(travelInvoiceIndex, 1, ...['M1', 'M2']);
      } else {
        const travelInvoiceIndex1 = _filter.timeEntryTypes.findIndex(type => type === 'TIMEENTRY');
        if (travelInvoiceIndex1 > -1) {
          _filter.timeEntryTypes.splice(travelInvoiceIndex1, 1, ...['TIMEENTRY']);
        }
      }
    }
    return await axios({
      url: `/api/tenant/timeentry/salarydata`,
      params: {
        timeEntryTypes: _filter.timeEntryTypes?.join(',') || void 0,
        dates: (_filter.dates || []).join(',') || void 0,
        search: filter.search || void 0,
        userIds: (filter.userIds || []).join(',') || void 0,
      },
    }).then(r => r.data);
  },
  async getSalaryDataCSV(/*filter*/ filter) {
    const _filter = { ...filter };
    return await axios({
      url: `/api/tenant/timeentry/salarydata/csv`,
      params: {
        dates: (_filter.dates || []).join(',') || void 0,
        search: filter.search || void 0,
        userIds: (filter.userIds || []).join(',') || void 0,
      },
      headers: {
        Accept: 'application/zip',
      },
      responseType: 'blob',
    }).then(r => r.data);
  },
  async getTimeEntry(id) {
    return await axios({
      url: '/api/tenant/timeentry/' + id,
    })
      .then(r => r.data)
      .then(r => {
        convertTimes(r);
        //if (!r.visitingAddress) r.visitingAddress = {};
        //if (!r.billingAddress) r.billingAddress = {};
        return r;
      });
  },
  async getLogs(id) {
    return await axios({
      url: '/api/tenant/timeentry/logs/' + id,
    })
      .then(r => r.data)
      .then(r => {
        //convertTimes(r);
        //if (!r.visitingAddress) r.visitingAddress = {};
        //if (!r.billingAddress) r.billingAddress = {};
        return r;
      });
  },
  async updateTimeEntry(id, /* TimeEntry */ data) {
    return await axios({
      url: '/api/tenant/timeentry/' + id,
      method: 'put',
      data,
    })
      .then(r => r.data)
      .then(convertTimes);
  },
  async addComment(id, /* json stringified comment */ data) {
    await axios({
      url: '/api/tenant/timeentry/' + id + '/comment',
      method: 'put',
      data,
    })
      .then(r => r.data)
      .then(convertTimes);
  },
  async createTimeEntry(data) {
    return await axios({
      url: '/api/tenant/timeentry/',
      method: 'post',
      data,
    }).then(r => r.data);
  },
  async massUpdateApproved(/*{approved boolean, entryIds: number[]}*/ data) {
    return await axios({
      url: '/api/tenant/timeentry/mass-update-approved',
      method: 'post',
      data,
    }).then(r => r.data);
  },
  async massUpdateChecked(/*{approved boolean, entryIds: number[]}*/ data) {
    return await axios({
      url: '/api/tenant/timeentry/mass-update-checked',
      method: 'post',
      data,
    }).then(r => r.data);
  },
  async removeCheck(entryId) {
    return await axios({
      url: '/api/tenant/timeentry/' + entryId + '/removecheck',
      method: 'post',
    }).then(r => r.data);
  },
  async deleteTimeEntry(entryId) {
    return await axios
      .delete('/api/tenant/timeentry/' + entryId)
      .then(r => r.data)
      .then(convertTimes);
  },
  async getEntryImages(entryId) {
    return await axios.get(`/api/tenant/timeentry/${entryId}/images`).then(r => r.data);
  },
  async uploadImage(entryId, file, imageFilename) {
    const formData = new FormData();
    formData.append('file', file, imageFilename);

    // const config =  {
    //   onUploadProgress: progressEvent => {
    //     this.imageUploadProgress = progressEvent.loaded / progressEvent.total * 100;
    //   },
    // };
    return await axios.post(`/api/tenant/image/${entryId}/timeentry`, formData /*, config*/);
  },
};
