<template>
  <div>

    <site-equipment-checks-equipment-list :site="site"
                                          :equipment-checks="equipmentChecksForSite"
                                          :equipment-check-steps="equipmentCheckSteps"
                                          @select-equipment="selectEquipmentForCheck" />

    <v-divider v-if="$vuetify.breakpoint.xsOnly && equipmentCheck"></v-divider>

    <v-card v-if="equipmentCheck" :elevation="$vuetify.breakpoint.xsOnly ? 0 : ''" class="site-equipment-check-form">
      <v-card-title>
        {{ equipmentCheck.id ? $t('sites.equipment_checks.equipment') : $t('sites.equipment_checks.create_check_for_equipment')}}:
        <v-spacer></v-spacer>
        <v-btn icon @click="closeEquipmentCheck"><v-icon>mdi mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text v-if="equipmentCheck.equipment" class="grey lighten-4">
        <v-simple-table class="transparent pt-4">
          <tr>
            <th class="text-left">{{ $t('sites.equipment_checks.make') }}:</th>
            <td class="text-right">{{ equipmentCheck.equipment.make }}</td>
          </tr>
          <tr>
            <th class="text-left">{{ $t('sites.equipment_checks.equipment_license_number') }}:</th>
            <td class="text-right">{{ equipmentCheck.equipment.licenseNumber }}</td>
          </tr>
          <tr>
            <th class="text-left">{{ $t('sites.equipment_checks.equipment_serial_number') }}:</th>
            <td class="text-right">{{ equipmentCheck.equipment.serialNumber }}</td>
          </tr>
          <tr>
            <th class="text-left">{{ $t('sites.equipment_checks.equipment_year') }}:</th>
            <td class="text-right">{{ equipmentCheck.equipment.year }}</td>
          </tr>
        </v-simple-table>
      </v-card-text>
      <v-card-text class="mb-1">
        <v-row no-gutters>
          <v-text-field
            type="number"
            v-model="equipmentCheck.equipmentOperatingHours"
            :disabled='saving || !!equipmentCheck.id'
            :label="$t('sites.equipment_checks.operating_hours')"
            autocomplete='off'
            :suffix="$t('sites.equipment_checks.hours')"
            hide-details
            dense
            outlined />
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-title>
        {{ $t('sites.equipment_checks.steps') }}
      </v-card-title>
      <v-card-text>
        {{ $t('sites.equipment_checks.steps_help') }}
      </v-card-text>
      <v-list class="site-equipment-check-steps">
        <template v-for="step in equipmentCheckSteps">
          <v-list-item :key="step">
            <v-list-item-content>
              <v-list-item-title class="text-wrap body-1" :class="validationErrors.findIndex(ve => ve === step) > -1 ? 'error--text font-weight-bold' : ''">
                {{ $t(`sites.equipment_checks.step.${step}`) }}
              </v-list-item-title>
              <div class="mt-2 body-2">
                <div @click="toggleStepApprove(step, 'true')">
                  <v-text-field outlined
                                readonly
                                :disabled='saving || !!equipmentCheck.id'
                                :class="equipmentCheck[step] === 'true' ? 'selected success lighten-4' : ''"
                                hide-details
                                class="rounded-b-0 body-2 accept-step-input"
                                :placeholder="$t('sites.equipment_checks.ok')">
                    <template v-slot:prepend-inner>
                      <v-btn class="mb-n1 mr-2"
                             @click.stop="toggleStepApprove(step, 'true')"
                             x-small
                             outlined
                             icon
                             :color="validationErrors.findIndex(ve => ve === step) > -1 ? 'error' : 'success'">
                        <v-fab-transition hide-on-leave>
                          <v-icon size="16" v-if="equipmentCheck[step] === 'true'">mdi mdi-check-bold</v-icon>
                          <v-icon size="10" v-if="validationErrors.findIndex(ve => ve === step) > -1">mdi mdi-exclamation</v-icon>
                        </v-fab-transition>
                      </v-btn>
                    </template>
                  </v-text-field>
                </div>
                <div @click="toggleStepApprove(step, 'false')">
                  <v-text-field outlined
                                hide-details
                                :disabled='saving || !!equipmentCheck.id'
                                v-model="equipmentCheck[step + 'Fault']"
                                class="rounded-t-0 body-2 accept-step-input"
                                :class="equipmentCheck[step] === 'false' ? 'selected warning lighten-5' : ''"
                                style="margin-top: -1px;"
                                @blur="validateFault(step)"
                                :placeholder="equipmentCheck[step] === 'false' ? '' : $t('sites.equipment_checks.fault')">
                    <template v-slot:prepend-inner>
                      <v-btn class="mb-n1 mr-2"
                             x-small
                             @click.stop="toggleStepApprove(step, 'false')"
                             outlined
                             icon
                             :color="validationErrors.findIndex(ve => ve === step) > -1 ? 'error' : 'warning'">
                        <v-fab-transition hide-on-leave>
                          <v-icon size="16" v-if="equipmentCheck[step] === 'false'">mdi mdi-close-thick</v-icon>
                          <v-icon size="12" v-if="validationErrors.findIndex(ve => ve === step) > -1">mdi mdi-exclamation</v-icon>
                        </v-fab-transition>
                      </v-btn>
                    </template>
                    <template v-if="equipmentCheck.id" v-slot:append>
                      <v-btn :loading="fixingStep === step"
                             @click="markFixed(step)"
                             x-small
                             v-if="equipmentCheck[step] === 'false' && !equipmentCheck[step + 'Fixed']"
                             class="fix-button">
                        {{ $t('sites.equipment_checks.mark') }}
                        <br v-if="$vuetify.breakpoint.xsOnly" />
                        {{ $t('sites.equipment_checks.as_fixed') }}
                      </v-btn>
                      <v-chip v-if="equipmentCheck[step + 'Fixed']" color="success" small>{{ $t('sites.equipment_checks.fixed') }} {{ $formatDateNoTimeNoYear(equipmentCheck[step + 'Fixed']) }}</v-chip>
                    </template>
                  </v-text-field>
                </div>
              </div>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <v-card-text>
        <v-textarea
          v-model="equipmentCheck.info"
          :disabled='saving || !!equipmentCheck.id'
          :label="$t('sites.equipment_checks.additional_info')"
          autocomplete='off'
          hide-details
          dense
          outlined />
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text v-if="!equipmentCheck.id" class="pb-0">
        <v-alert v-if="validationErrors.length > 0" type="error" text>
          {{ $t('sites.introduction.validation_error_message') }}
        </v-alert>
        <div class="text-center my-5">
          {{ $t('sites.equipment_checks.what_signing_means') }}
        </div>
        <v-btn
          :loading="saving"
          block
          :small="$vuetify.breakpoint.xsOnly"
          color='primary'
          @click='saveEquipmentCheck'
        >
          {{ $t('sites.equipment_checks.save') }}
        </v-btn>
      </v-card-text>
      <v-card-text v-else class="pb-0">
        <v-alert v-if="equipmentCheck.workerUser" type="info" text class="mb-0">
          {{ $t('sites.equipment_checks.user_that_did_the_equipment_check') }}: <strong>{{ equipmentCheck.workerUser.firstName }} {{ equipmentCheck.workerUser.lastName }}</strong>
        </v-alert>
        <v-alert v-if="equipmentCheck.inspectorUser" type="success" text class="mb-0 mt-4">
          {{ $t('sites.equipment_checks.supervisor_user') }}: <strong>{{ equipmentCheck.inspectorUser.firstName }} {{ equipmentCheck.inspectorUser.lastName }}</strong>
        </v-alert>
      </v-card-text>
      <v-card-text>
        <template v-if="equipmentCheck.id && !equipmentCheck.inspectorUserId && ($isTenantAdmin || $isTenantManager)">
          <v-btn
            :loading="saving"
            block
            :small="$vuetify.breakpoint.xsOnly"
            color='primary'
            class="mb-4"
            @click='markInspected'
          >
            {{ $t('sites.equipment_checks.mark_inspected') }}
          </v-btn>
        </template>
        <v-btn
          :disabled="saving"
          block
          depressed
          :small="$vuetify.breakpoint.xsOnly"
          @click='closeEquipmentCheck'
        >
          {{ $t('sites.equipment_checks.close') }}
        </v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import equipmentChecksApi from "@/api/equipmentcheck";
import SiteEquipmentChecksEquipmentList from "@/components/Sites/SiteEquipmentChecksEquipmentList";
import dayjs from "dayjs";

export default {
  name: "SiteEquipmentChecks",
  components: {SiteEquipmentChecksEquipmentList},
  props: ['site'],
  data() {
    return {
      saving: false,
      loading: true,
      selectedEquipment: null,
      equipmentCheck: null,
      equipmentChecksForSite: [],
      equipmentCheckTemplate: {
        equipmentOperatingHours: null,
        contractor: null,
        contactPerson: null,
        phone: null,
        info: null,
        koneenhavaittavuus: null,
        koneenhavaittavuusFault: null,
        koneenhavaittavuusFixed: null,
        valaisimetjasuuntavalaisimet: null,
        valaisimetjasuuntavalaisimetFault: null,
        valaisimetjasuuntavalaisimetFixed: null,
        hydrauliikka: null,
        hydrauliikkaFault: null,
        hydrauliikkaFixed: null,
        akselistotjavaihteistot: null,
        akselistotjavaihteistotFault: null,
        akselistotjavaihteistotFixed: null,
        nostokoukut: null,
        nostokoukutFault: null,
        nostokoukutFixed: null,
        laitekiinnitykset: null,
        laitekiinnityksetFault: null,
        laitekiinnityksetFixed: null,
        ajojahallintalaitteet: null,
        ajojahallintalaitteetFault: null,
        ajojahallintalaitteetFixed: null,
        turvakatkaisijat: null,
        turvakatkaisijatFault: null,
        turvakatkaisijatFixed: null,
        suojauksetjasuojalaitteet: null,
        suojauksetjasuojalaitteetFault: null,
        suojauksetjasuojalaitteetFixed: null,
        alkusammutinensiapulaukku: null,
        alkusammutinensiapulaukkuFault: null,
        alkusammutinensiapulaukkuFixed: null,
        koneenhuoltokayttoturvallisuus: null,
        koneenhuoltokayttoturvallisuusFault: null,
        koneenhuoltokayttoturvallisuusFixed: null,
        koneenmerkinnatjakilvet: null,
        koneenmerkinnatjakilvetFault: null,
        koneenmerkinnatjakilvetFixed: null,
        koneenlisalaitteidenturvallisuus: null,
        koneenlisalaitteidenturvallisuusFault: null,
        koneenlisalaitteidenturvallisuusFixed: null,
        koneenkuljettajaperehdytystyomaanolosuhteisiin: null,
        koneenkuljettajaperehdytystyomaanolosuhteisiinFault: null,
        koneenkuljettajaperehdytystyomaanolosuhteisiinFixed: null,
        tyokonekunnossa: null,
        tyokonekunnossaFault: null,
        tyokonekunnossaFixed: null,
        tyokonevaatimustenmukainen: null,
        tyokonevaatimustenmukainenFault: null,
        tyokonevaatimustenmukainenFixed: null,
      },
      equipmentCheckSteps: [
        'koneenhavaittavuus', 'valaisimetjasuuntavalaisimet', 'hydrauliikka', 'akselistotjavaihteistot', 'nostokoukut',
        'laitekiinnitykset', 'ajojahallintalaitteet', 'turvakatkaisijat', 'suojauksetjasuojalaitteet', 'alkusammutinensiapulaukku',
        'koneenhuoltokayttoturvallisuus', 'koneenmerkinnatjakilvet', 'koneenlisalaitteidenturvallisuus',
        'koneenkuljettajaperehdytystyomaanolosuhteisiin', 'tyokonekunnossa', 'tyokonevaatimustenmukainen'
      ],
      validationErrors: [],
      fixingStep: null,
    }
  },
  methods: {
    validateFault(step) {
      if (this.equipmentCheck[step + 'Fault'] === null || this.equipmentCheck[step + 'Fault'].length === 0) {
        this.equipmentCheck[step] = null;
      }
    },
    selectEquipmentForCheck(equipment) {
      const checkIndex = this.equipmentChecksForSite.findIndex(equipmentCheck => equipmentCheck.equipment.id === equipment.id);
      if (checkIndex > -1) {
        this.equipmentCheck = this.equipmentChecksForSite[checkIndex];
      } else {
        this.equipmentCheck = {...structuredClone(this.equipmentCheckTemplate), site: this.site, equipment: equipment};
      }
    },
    toggleStepApprove(step, value) {
      if (this.equipmentCheck.id) return;
      if (this.equipmentCheck[step] === value) {
        this.equipmentCheck[step] = null;
      } else {
        this.equipmentCheck[step] = value;
        const validationErrorIndex = this.validationErrors.findIndex(ve => ve === step);
        if (validationErrorIndex > -1) {
          this.validationErrors.splice(validationErrorIndex, 1)
        }
      }
    },
    async getEquipmentChecks() {
      try {
        const equipmentChecks = await equipmentChecksApi.getEquipmentChecksBySite(this.site.id);
        if (equipmentChecks) {
          this.equipmentChecksForSite = equipmentChecks;
        }
      } catch (e) {
        this.$handleApiError(e);
      }
    },
    async saveEquipmentCheck() {
      this.saving = true;
      try {
        const equipmentCheck = {...structuredClone(this.equipmentCheck)};
        if (!equipmentCheck.site) equipmentCheck.site = this.site;
        this.equipmentCheckSteps.forEach(step => {
          if (equipmentCheck[step] === 'true') {
            equipmentCheck[step + 'Fault'] = null;
          }
        });
        if (!this.equipmentCheck.id) {
          const response = await equipmentChecksApi.createEquipmentCheck(equipmentCheck);
          if (response.id) {
            this.equipmentCheck = await equipmentChecksApi.markEquipmentCheckChecked(response.id); // merkitään samalla tarkastus suoritetuksi
          }
        } else {
          this.equipmentCheck = await equipmentChecksApi.updateEquipmentCheck(equipmentCheck.id, equipmentCheck);
        }
        this.$showSuccessNotification(this.$t('sites.equipment_checks.equipment_check_saved'));
        this.getEquipmentChecks();
      } catch (e) {
        this.$handleApiError(e);
      }
      this.saving = false;
    },
    async markInspected() {
      this.saving = true;
      try {
        if (this.equipmentCheck.id) {
          this.equipmentCheck = await equipmentChecksApi.markEquipmentCheckInspected(this.equipmentCheck.id);
          this.getEquipmentChecks();
        }
      } catch (e) {
        this.$handleApiError(e);
      }
      this.saving = false;
    },
    async markFixed(step) {
      this.fixingStep = step;
      try {
        const equipmentCheck = {...structuredClone(this.equipmentCheck)};
        equipmentCheck[step + 'Fixed'] = dayjs().toISOString();
        this.equipmentCheck = await equipmentChecksApi.updateEquipmentCheck(equipmentCheck.id, equipmentCheck);
        this.getEquipmentChecks();
      } catch (error) {
        this.$handleApiError(error);
      }
      this.fixingStep = null;
    },
    closeEquipmentCheck() {
      this.selectedEquipment = null;
      this.equipmentCheck = null;
    },
  },
  mounted() {
    this.getEquipmentChecks();
  }
}
</script>

<style lang="scss">
.site-equipment-check-form {
  .selected {
    font-weight: 600;
  }

  .site-equipment-check-steps {
    .theme--light.v-input input {
      &::placeholder {
        color: rgba(0, 0, 0, 0.88);
      }

      &:focus {
        font-weight: 600;
      }

      &:focus::placeholder {
        color: rgba(0, 0, 0, 0.68);
      }
    }
  }

  .v-input--is-disabled input {
    color: rgba(0,0,0,0.68);
  }

  .accept-step-input {

    * {
      cursor: pointer !important;
    }

    &.v-input--is-disabled * {
      cursor: default !important;
    }

    .fix-button, .fix-button * {
      pointer-events: all !important;
      cursor: pointer !important;
      height: 30px;
      padding-top: 2px;
      margin-top: -4px;
    }
  }
}
</style>
