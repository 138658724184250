<template>
  <div>
    <v-data-table
      v-if="timeEntry.id"
      :headers="headers"
      :items="filteredLogs"
      :loading="loadingLogs"
      disable-pagination
      hide-default-footer
      show-expand
    >
      <template v-slot:item.user="{ item }">
        <span v-if="item.user"> {{ item.user.lastName }} {{ item.user.firstName }} </span>
      </template>
      <template v-slot:item.action="{ item }">
        <span v-if="item.action"> {{ $t(`timeentrylogs.actions.${item.action}`) }} </span>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="py-3">
          <div>
            <v-row>
              <v-col cols="4" class="font-weight-bold">
                {{ $t('timeentrylogs.key') }}
              </v-col>
              <v-col cols="4" class="font-weight-bold">
                {{ $t('timeentrylogs.from') }}
              </v-col>
              <v-col cols="1"></v-col>
              <v-col cols="3" class="font-weight-bold">
                {{ $t('timeentrylogs.to') }}
              </v-col>
            </v-row>
          </div>
          <div v-for="(value, key) in item.diff" :key="key">
            <v-row>
              <v-col cols="4">
                {{ $t('timer.' + getSnakeCase(key)) }}
              </v-col>
              <v-col cols="4">
                {{ value.old }}
              </v-col>
              <v-col cols="1"><v-icon>mdi mdi-arrow-right</v-icon></v-col>
              <v-col cols="3">
                {{ value.new }}
              </v-col>
            </v-row>
          </div>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import timeentriesApi from '@/api/timeentries';
import orderBy from 'lodash/orderBy';
import { snakeCase } from 'lodash/string';

export default {
  name: 'TimeEntryLogs',
  props: {
    timeEntry: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      logs: [],
      loadingLogs: false,
      headers: [
        {
          text: this.$t('timeentrylogs.createdAt'),
          align: 'left',
          value: 'createdAt',
        },
        {
          text: this.$t('timeentrylogs.user'),
          align: 'left',
          value: 'user',
        },
        {
          text: this.$t('timeentrylogs.action'),
          align: 'left',
          value: 'action',
        },
      ],
    };
  },
  computed: {
    filteredLogs() {
      return orderBy(
        this.logs.map(log => {
          log.createdAt = this.$formatDateTime(log.createdAt);
          return log;
        }),
        ['createdAt'],
        ['desc']
      );
    },
  },
  methods: {
    getSnakeCase(key) {
      return snakeCase(key);
    },
    async getLogs() {
      if (!this.timeEntry.id) return;
      this.loadingLogs = true;
      const logs = await timeentriesApi.getLogs(this.timeEntry.id);
      logs.forEach(log => {
        log.diff = {};
        Object.keys(log.newTimeEntry).forEach(key => {
          if (
            this.timeEntry.timeEntryType !== 'M0' &&
            [
              'wholeDayPay',
              'partDayPay',
              'homeCountryDayPay',
              'overnightStayInCar',
              'foreignCountryDayPay',
              'invoiced',
              'lunchPay',
            ].includes(key)
          ) {
            return;
          }
          if (key === 'entryStart' || key === 'entryEnd') {
            if (this.$formatUnix(log.newTimeEntry[key]) !== this.$formatUnix(log.oldTimeEntry?.[key])) {
              log.diff[key] = {
                new: this.$formatDateTime(log.newTimeEntry[key]),
                old: this.$formatDateTime(log.oldTimeEntry?.[key]),
              };
            }
            return;
          } else if (typeof log.newTimeEntry[key] === 'boolean' || typeof log.oldTimeEntry?.[key] === 'boolean') {
            if (log.newTimeEntry[key] !== log.oldTimeEntry?.[key]) {
              log.diff[key] = {
                new: this.$t(String(log.newTimeEntry[key])),
                old: this.$t(String(log.oldTimeEntry?.[key] ?? '')),
              };
            }
          } else if (typeof log.newTimeEntry[key] === 'object' || typeof log.oldTimeEntry?.[key] === 'object') {
            if (log.newTimeEntry[key]?.id !== log.oldTimeEntry?.[key]?.id) {
              log.diff[key] = {
                new: log.newTimeEntry[key]?.name,
                old: log.oldTimeEntry?.[key]?.name,
              };
            }
          } else if (log.oldTimeEntry?.[key] !== log.newTimeEntry[key]) {
            log.diff[key] = {
              new: log.newTimeEntry[key],
              old: log.oldTimeEntry?.[key],
            };
          }
        });
      });
      logs.forEach(l => {
        if (l?.diff?.timeEntryType) {
          if (l.diff.timeEntryType.new && l.diff.timeEntryType.new === 'AWAYENTRY') {
            l.diff.timeEntryType.new = this.$t('reports.awayentry');
          } else if (l.diff.timeEntryType.old && l.diff.timeEntryType.old === 'AWAYENTRY') {
            l.diff.timeEntryType.old = this.$t('reports.awayentry');
          }
        }
      });
      this.logs = logs;
      this.loadingLogs = false;
    },
  },
  mounted() {
    this.getLogs();
  },
};
</script>

<style scoped></style>
