import Vue from 'vue';
import Router from 'vue-router';
import LoginView from './views/LoginView.vue';
import RedirectToTenantStartView from '@/views/RedirectToTenantStartView';

// Admin views
import UserManagementView from './views/UserManagementView.vue';
import Tenants from './views/TenantsView.vue';

// User views
import UserProfile from './views/UserProfile.vue';

// Tenant views
import TenantContainer from './views/TenantContainer.vue';
import TenantUserManagementView from './views/tenant/WorkerManagementView.vue';
import WorkerView from '@/views/tenant/WorkerView';
import TenantManagementView from './views/tenant/TenantManagementView.vue';

// App specific views
import SiteView from '@/views/tenant/SiteView';
import SiteDaybookView from '@/views/tenant/SiteDaybookView';
import EquipmentsView from '@/views/tenant/EquipmentsView';
import ReportView from '@/views/tenant/ReportView';
import TimerView from '@/views/tenant/TimerView';
import ItemsView from '@/views/tenant/ItemsView';
import ProjectsView from '@/views/tenant/ProjectsView';
import ProjectView from '@/views/tenant/ProjectView';
import PartiesView from '@/views/tenant/PartiesView';
import PartyView from '@/views/tenant/PartyView';

import PersonsView from '@/views/tenant/PersonsView';
import PersonView from '@/views/tenant/PersonView';

import WorkSitesView from '@/views/tenant/WorkSitesView';
import WorkerLicensesView from '@/views/tenant/WorkerLicensesView';

import WorkGroupsView from '@/views/tenant/WorkGroupsView';
import WorkTasksView from '@/views/tenant/WorkTaskView';
import TimerAdminView from '@/views/tenant/TimerAdminView';

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/login',
      name: 'login',
      component: LoginView,
    },
    {
      path: '/',
      name: 'start',
      component: RedirectToTenantStartView,
    },
    {
      path: '/users',
      name: 'users',
      component: UserManagementView,
    },
    {
      path: '/me',
      name: 'userprofile',
      component: UserProfile,
    },
    {
      path: '/t/:tenantId',
      props: true,
      component: TenantContainer,
      children: [
        {
          path: 'workers',
          name: 'tenant_workers',
          props: true,
          component: TenantUserManagementView,
        },
        {
          path: 'worker/:id',
          name: 'tenant_edit_worker',
          props: true,
          component: WorkerView,
        },
        {
          path: 'worker',
          name: 'tenant_new_worker',
          component: WorkerView,
        },
        {
          path: 'settings',
          name: 'tenant_settings',
          props: true,
          component: TenantManagementView,
        },
        {
          path: 'site/:id/:tab?',
          name: 'tenant_site_edit_site',
          props: true,
          component: SiteView,
        },
        {
          path: 'site',
          name: 'tenant_site_new_site',
          component: SiteView,
        },
        {
          path: 'site/:siteId/daybook',
          name: 'tenant_site_new_daybook',
          component: SiteDaybookView,
        },
        {
          path: 'site/:siteId/daybook/:id',
          name: 'tenant_site_daybook',
          component: SiteDaybookView,
        },
        {
          path: 'projects',
          name: 'tenant_projects',
          props: true,
          component: ProjectsView,
        },
        {
          path: 'workerlicenses',
          name: 'tenant_worker_licenses',
          props: true,
          component: WorkerLicensesView,
        },
        {
          path: 'project/:id',
          name: 'tenant_project_edit_project',
          props: true,
          component: ProjectView,
        },
        {
          path: 'project',
          name: 'tenant_project_new_project',
          component: ProjectView,
        },
        {
          path: 'persons',
          name: 'tenant_persons',
          component: PersonsView,
        },
        {
          path: 'person',
          name: 'tenant_new_person',
          props: true,
          component: PersonView,
        },
        {
          path: 'person/:id',
          name: 'tenant_person',
          props: true,
          component: PersonView,
        },
        {
          path: 'parties',
          name: 'tenant_parties',
          component: PartiesView,
        },
        {
          path: 'party',
          name: 'tenant_new_party',
          props: true,
          component: PartyView,
        },
        {
          path: 'party/:id/:tab?',
          name: 'tenant_party',
          props: true,
          component: PartyView,
        },
        {
          path: 'items',
          name: 'tenant_items',
          component: ItemsView,
        },
        {
          path: 'equipments',
          name: 'tenant_equipments',
          component: EquipmentsView,
        },
        {
          path: 'report',
          name: 'tenant_report',
          component: ReportView,
        },
        {
          path: 'timer/:id?',
          name: 'tenant_timer',
          props: { key: 'timer' },
          component: TimerView,
        },
        {
          path: 'timer_admin/:id?',
          name: 'tenant_timer_admin',
          props: { adminMode: true, key: 'timer_admin' },
          component: TimerAdminView,
        },
        {
          path: 'worksites',
          name: 'tenant_worksites',
          component: WorkSitesView,
        },
        {
          path: 'workgroups',
          name: 'tenant_workgroups',
          component: WorkGroupsView,
        },
        {
          path: 'worktasks',
          name: 'tenant_work_tasks_view',
          component: WorkTasksView,
        },
      ],
    },
    {
      path: '/tenants',
      name: 'tenants',
      component: Tenants,
    },
    {
      path: '*',
      redirect: '/',
    },
  ],
});
