<template>
  <hb-basic-page
    :title="id ? $t('tenants.edit_worker') : $t('tenants.new_worker')"
    :loading="loading"
    :fill-height="loading"
    :back-button="backButton"
    :extension-height="$vuetify.breakpoint.smAndUp && ($isTenantAdmin || $isTenantManager) ? 107 : 47"
    style="background-color: transparent"
  >
    <template v-slot:app-bar-extension>
      <v-col class="px-0">
        <v-row>
          <div v-if="$vuetify.breakpoint.smAndUp" class="ml-2" :class="$isTenantAdmin || $isTenantManager ? 'mb-3' : ''">
            <v-btn v-if="id" :disabled="dataIsNotChanged" @click="saveUser" class="primary mr-3">
              <v-icon left class="mr-2">mdi-content-save</v-icon>
              {{ $t('user_management.user_page.save_edit') }}
            </v-btn>
            <v-btn v-else @click="worker.userId ? saveUser() : createUser()" class="primary">
              <v-icon :left="$vuetify.breakpoint.smAndUp" class="mr-2">mdi-content-save</v-icon>
              {{ $t('save') }}
            </v-btn>
          </div>
          <template>
            <v-tabs v-model="tab" background-color="grey darken-3">
              <v-tab>{{ $t('user_management.user_page.person_details') }}</v-tab>
              <v-tab>{{ $t('user_management.user_page.person_address_details') }}</v-tab>
              <v-tab>{{ $t('user_management.user_page.worker_licence') }}</v-tab>
            </v-tabs>
          </template>
        </v-row>
      </v-col>
    </template>

    <v-col cols="12" class="pa-0" :style="{ marginBottom: $vuetify.breakpoint.xsOnly ? '76px' : '68px' }">
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-col
            md="8"
            offset-md="2"
            lg="8"
            offset-lg="2"
            :class="$vuetify.breakpoint.xsOnly ? 'gradient-icons pa-0 ma-0' : 'mt-5 gradient-icons'"
          >
            <v-card :elevation="$vuetify.breakpoint.xsOnly ? 0 : ''">
              <v-card-text>
                <!--<div style="cursor: pointer" class="mb-5" v-if="worker.person.manager" @click="worker.person.manager = false">
                <v-icon>mdi mdi-checkbox-marked-circle-outline</v-icon>
                <span class="ml-5">{{ $t('user_management.user_page.remove_from_foreman_list') }}</span>
              </div>-->

                <v-text-field
                  tabindex="1"
                  :disabled="saving"
                  outlined
                  dense
                  hide-details
                  class="mb-5 mt-4"
                  :label="$t('user_management.user_page.first_name')"
                  light
                  item-value=""
                  autocomplete="off"
                  clearable
                  v-model="worker.person.firstname"
                >
                  <template v-slot:prepend>
                    <v-icon>mdi mdi-account</v-icon>
                  </template>
                </v-text-field>

                <v-text-field
                  tabindex="2"
                  :disabled="saving"
                  outlined
                  dense
                  hide-details
                  class="mb-5 mt-4"
                  :label="$t('user_management.user_page.last_name')"
                  light
                  item-value=""
                  autocomplete="off"
                  clearable
                  v-model="worker.person.lastname"
                >
                  <template v-slot:prepend>
                    <v-icon>mdi mdi-account</v-icon>
                  </template>
                </v-text-field>
                <v-text-field
                  tabindex="3"
                  :disabled="saving"
                  outlined
                  dense
                  hide-details
                  class="mb-5"
                  :label="$t('user_management.user_page.phone')"
                  light
                  item-value=""
                  autocomplete="off"
                  clearable
                  v-model="worker.person.phone"
                >
                  <template v-slot:prepend>
                    <span>
                      <a style="text-decoration: none" v-if="worker.person.phone" :href="`tel:${worker.person.phone}`"
                        ><v-icon>mdi mdi-phone</v-icon></a
                      >
                      <v-icon v-if="!worker.person || !worker.person.phone">mdi mdi-phone</v-icon>
                    </span>
                  </template>
                </v-text-field>

                <v-text-field
                  tabindex="4"
                  :disabled="saving || !!id"
                  outlined
                  dense
                  :rules="[$rules.required, $rules.email, $rules.spacesNotAllowed]"
                  validate-on-blur
                  :label="$t('user_management.user_page.email')"
                  light
                  item-value=""
                  autocomplete="off"
                  v-model="worker.person.email"
                  clearable
                >
                  <template v-slot:prepend>
                    <v-icon>mdi mdi-email</v-icon>
                  </template>
                </v-text-field>

                <v-alert v-if="showAlert" outlined type="error">{{ $t('user_management.errors.check_email') }}</v-alert>
                <v-text-field
                  tabindex="5"
                  :disabled="saving"
                  outlined
                  dense
                  hide-details
                  class="mb-6 mt-n2"
                  :label="$t('user_management.user_page.procountor_code')"
                  light
                  item-value=""
                  autocomplete="off"
                  clearable
                  v-model="worker.procountorCode"
                >
                  <template v-slot:prepend>
                    <v-icon>mdi mdi-alpha-p</v-icon>
                  </template>
                </v-text-field>
                <v-text-field
                  tabindex="6"
                  :disabled="saving"
                  outlined
                  dense
                  hide-details
                  class="mb-5"
                  style="margin-top: -6px"
                  :label="$t('user_management.user_page.social_security_number')"
                  light
                  item-value=""
                  autocomplete="off"
                  v-model="worker.person.ssn"
                  clearable
                >
                  <template v-slot:prepend>
                    <v-icon>mdi mdi-identifier</v-icon>
                  </template>
                </v-text-field>
                <v-text-field
                  tabindex="7"
                  :disabled="saving"
                  outlined
                  dense
                  hide-details
                  class="mb-5"
                  :label="$t('user_management.user_page.tax_number')"
                  light
                  item-value=""
                  autocomplete="off"
                  clearable
                  v-model="worker.person.taxNumber"
                >
                  <template v-slot:prepend>
                    <v-icon>mdi mdi-numeric</v-icon>
                  </template>
                </v-text-field>

                <v-text-field
                  tabindex="8"
                  class="mb-n1"
                  :rules="[$rules.required]"
                  :disabled="saving"
                  outlined
                  dense
                  type="number"
                  :label="$t('user_management.user_page.hours_per_day')"
                  light
                  item-value=""
                  autocomplete="off"
                  clearable
                  v-model.number="worker.workHours"
                >
                  <template v-slot:prepend>
                    <v-icon>mdi mdi-account-clock</v-icon>
                  </template>
                </v-text-field>

                <date-picker
                  birthDate
                  tabindex="9"
                  :disabled="saving"
                  :label="$t('user_management.user_page.dob')"
                  light
                  autocomplete="off"
                  clearable
                  :input-styles="{ outlined: true, class: 'mb-5', dense: true }"
                  :date="worker.person.dob"
                  @change="handleDobChange"
                >
                  <template v-slot:prepend>
                    <v-icon>mdi mdi-cake</v-icon>
                  </template>
                </date-picker>

                <v-text-field
                  tabindex="10"
                  :disabled="saving"
                  outlined
                  dense
                  hide-details
                  class="mb-5"
                  :label="$t('user_management.user_page.social_security_country')"
                  light
                  item-value=""
                  autocomplete="off"
                  clearable
                  v-model="worker.socialSecurityCountry"
                >
                  <template v-slot:prepend>
                    <v-icon>mdi mdi-map-legend</v-icon>
                  </template>
                </v-text-field>

                <person-combobox
                  :saving="saving"
                  :value="worker.foreman"
                  :persons="managers"
                  :add-new-text="$t('user_management.user_page.new_foreman')"
                  :label="$t('user_management.user_page.foreman')"
                  @add-person="addManager"
                  @change="v => (this.worker.foreman = v)"
                  icon="mdi mdi-account-tie"
                >
                </person-combobox>

                <party-combobox
                  :saving="saving"
                  :parties="parties"
                  :loading="loadingParties"
                  :label="$t('person.employer')"
                  icon="mdi mdi-domain"
                  :value="worker.person.employer"
                  @change="handlePersonEmployerChange"
                />

                <v-autocomplete
                  tabindex="12"
                  :disabled="saving"
                  outlined
                  dense
                  hide-details
                  class="mb-5"
                  :label="$t('user_management.user_page.relation')"
                  light
                  item-value="value"
                  :item-text="item => [item.label].filter(Boolean).join(' ')"
                  :items="relations"
                  autocomplete="off"
                  v-model="worker.relation"
                  clearable
                >
                  <template v-slot:prepend>
                    <v-icon>mdi mdi-account-details-outline</v-icon>
                  </template>
                </v-autocomplete>

                <div style="cursor: pointer" class="mb-5 d-flex" @click="worker.person.manager = !worker.person.manager">
                  <div>
                    <v-icon v-if="worker.person.manager">mdi mdi-checkbox-marked-circle</v-icon>
                    <v-icon v-if="!worker.person.manager">mdi mdi-checkbox-blank-circle-outline</v-icon>
                  </div>

                  <div class="d-inline-block">
                    <span class="ml-2">{{ $t('user_management.user_page.is_manager') }}</span>
                    <div class="ml-2">
                      <small>{{ $t('user_management.user_page.is_manager_hint') }}</small>
                    </div>
                  </div>
                </div>
                <div class="d-flex mb-5" v-if="user && user.id && user.id > 0">
                  <div class="d-inline-block">
                    <v-icon>mdi mdi-account-supervisor-circle</v-icon>
                  </div>
                  <div class="ml-2 d-inline-block">
                    <label class="">{{ $t('user_management.roles') }}</label>
                    <div class="mt-2">
                      <template v-for="role in userRoles(user)">
                        <v-chip
                          :key="role"
                          @click:close="removeRoleFromUser(user, role)"
                          class="mr-1"
                          small
                          close
                          outlined
                          :color="$randomizeColorFromString(role, 60, 45)"
                          ><strong>{{ $t('user_management.role.' + role) }}</strong></v-chip
                        >
                      </template>
                      <v-menu bottom right>
                        <v-list dense class="pt-0 pb-0">
                          <v-subheader>{{ $t('user_management.add_role_to_user') }}:</v-subheader>
                          <v-list-item v-for="role in availableRolesForUser(user)" @click="addRoleToUser(user, role)" :key="role">
                            <v-list-item-content :style="{ color: $randomizeColorFromString(role, 60, 55) }">
                              <v-list-item-title class="font-weight-bold">{{ $t('user_management.role.' + role) }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                        <template v-slot:activator="{ on }">
                          <v-btn text icon class="ma-0" color="grey lighten-2" v-on="on"
                            ><v-icon size="22" class="no-gradient">add_circle</v-icon></v-btn
                          >
                        </template>
                      </v-menu>
                    </div>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-tab-item>
        <v-tab-item>
          <v-col
            md="8"
            offset-md="2"
            lg="8"
            offset-lg="2"
            :class="$vuetify.breakpoint.xsOnly ? 'gradient-icons pa-0 ma-0' : 'mt-5 gradient-icons'"
          >
            <v-card :elevation="$vuetify.breakpoint.xsOnly ? 0 : ''">
              <address-card
                :saving="saving"
                :address="worker.fiAddress"
                :subtitle="$t('user_management.user_page.address_in_fi')"
                @change="handleFiAddressChange"
              />
              <address-card
                :saving="saving"
                :address="worker.person.address"
                :subtitle="$t('user_management.user_page.address_in_other_country')"
                @change="handlePersonAddressChange"
              />
            </v-card>
          </v-col>
        </v-tab-item>
        <!-- Kortit ja luvat -->
        <v-tab-item>
          <v-col
            v-if="id"
            md="8"
            offset-md="2"
            lg="8"
            offset-lg="2"
            :class="$vuetify.breakpoint.xsOnly ? 'gradient-icons pa-0 ma-0' : 'mt-5 gradient-icons'"
          >
            <v-card :elevation="$vuetify.breakpoint.xsOnly ? 0 : ''">
              <template v-if="!loading">
                <v-data-table
                  id="worker-licenses-table"
                  :items="filteredWorkerLicenses"
                  :headers="headers"
                  disable-pagination
                  hide-default-footer
                  locale="fi-FI"
                  :no-data-text="$t('worker_licenses.no_licenses')"
                  :mobile-breakpoint="0"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="descending"
                  class="gradient-info__table"
                  style="cursor: pointer"
                >
                  <template v-slot:body="{ items }">
                    <tbody v-for="item in items" :key="item.id">
                      <tr
                        v-if="items.length > 0"
                        :class="[item._dateError ? `date-${item._dateError}` : ''].filter(Boolean)"
                        @click="openWorkerLicenseInDialog(item)"
                        class="bottom-border"
                      >
                        <td>
                          <span v-if="item.worker">{{ item.card.name }}</span>
                        </td>
                        <td>{{ item.cardNumber }}</td>
                        <td>{{ $formatDateNoTime(item.dateFrom) }}</td>
                        <td :class="[item._dateError ? `date-${item._dateError}` : ''].filter(Boolean)">
                          <span>{{ item._dateTo !== FOR_NOW_VALUE ? $formatDateNoTime(item.dateTo) : $t('worker_licenses.for_now') }}</span>
                          <v-icon small v-if="item._dateError === 'warning'" color="warning" class="no-gradient ml-4"
                            >mdi mdi-exclamation</v-icon
                          >
                          <v-icon small v-if="item._dateError === 'error'" color="error" class="no-gradient ml-4">mdi mdi-alert</v-icon>
                        </td>
                      </tr>
                      <tr v-else>
                        <td colspan="6" class="text-center grey--text">
                          <span>{{ $t('worker_licenses.no_licenses') }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
              </template>
            </v-card>
            <v-btn color="primary" class="mt-4" @click="createNewWorkerLicense(false)"
              ><v-icon class="no-gradient">mdi-plus</v-icon>{{ $t('tenants.add_new_worker_license') }}</v-btn
            >
          </v-col>
          <v-col v-else>
            <no-content-view
              :reloadButton="false"
              title-icon="mdi mdi-card-bulleted-off"
              :title="$t('worker_licenses.not_possible_to_add_licenses_title')"
              :description="$t('worker_licenses.not_possible_to_add_licenses_description')"
            />
          </v-col>
        </v-tab-item>
      </v-tabs-items>

      <!-- RELEASE 1
      <v-card>
        <v-card-title class="subtitle-1 text-uppercase font-weight-medium">
          {{ $t('user_management.user_page.worker_licence') }}
        </v-card-title>
        <v-card-text>
          <v-text-field
            :disabled="saving"
            outlined
            dense
            hide-details
            class="mb-5"
            :label="$t('user_management.user_page.address_1')"
            light
            item-value=""
            autocomplete="off"
            v-model="worker.fiAddress.address"
            clearable>
            <template v-slot:prepend>
              <v-icon>mdi mdi-card-account-details-outline</v-icon>
            </template>
          </v-text-field>

        </v-card-text>

      </v-card>-->

      <v-btn fab fixed left bottom @click="cancel" v-if="$vuetify.breakpoint.xsOnly" color="default">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-btn fab fixed right bottom @click="saveUser" v-if="$vuetify.breakpoint.xsOnly && id" color="primary">
        <v-icon>mdi mdi-content-save</v-icon>
      </v-btn>
      <v-btn
        fab
        fixed
        right
        bottom
        @click="worker.userId ? saveUser() : createUser()"
        v-if="$vuetify.breakpoint.xsOnly && !id"
        color="primary"
      >
        <v-icon>mdi mdi-plus</v-icon>
      </v-btn>
    </v-col>
    <add-person-dialog ref="AddPersonDialog" :manager="true" @person-created="foremanCreated" />
    <worker-license-dialog ref="workerLicenseDialog" @saved="reloadWorkerLicenses" :worker="worker" />
  </hb-basic-page>
</template>

<script>
import workerLicenceApi from '@/api/workerLicence';
import AddPersonDialog from '@/components/AddPersonDialog';
import personApi from '@/api/person';
import workerApi from '@/api/worker';
import dayjs from 'dayjs';
import tenantApi from '@/api/tenant';
import userApi from '@/api/user';
import PersonCombobox from '@/components/PersonCombobox';
import AddressCard from '@/components/AddressCard';
import Immutable from 'immutable';
import itemsApi from '@/api/item';
import workersApi from '@/api/worker';
import workerLicensesApi from '@/api/workerLicence';
import WorkerLicenseDialog from '@/components/WorkerLicenseDialog';
import NoContentView from '@/components/NoContentView';
import adminApi from '@/api/admin';
import DatePicker from '@/components/DatePicker';
import isEqual from 'lodash/isEqual';
const FOR_NOW_VALUE = 999999999;
import cloneDeep from 'lodash/cloneDeep';
import partyApi from "@/api/party";
import PartyCombobox from "@/components/PartyCombobox";

export default {
  name: 'WorkerView',
  components: {PartyCombobox, DatePicker, NoContentView, WorkerLicenseDialog, AddressCard, PersonCombobox, AddPersonDialog },
  props: {
    id: {
      validator: function (v) {
        parseInt(v);
        return true;
      },
    },
  },
  data() {
    return {
      loadingParties: false,
      workerWhenMounted: {},
      FOR_NOW_VALUE,
      sortBy: 'entryStartTS',
      descending: true,
      loading: true,
      subtleLoading: false,
      searchPhrase: '',
      allEntries: [],
      items: [],
      filterWorker: [],
      filterType: [],
      filterCard: [],
      roles: [],
      workers: [],
      parties: [],
      workerLicenses: [],
      dateRangePicker: false,
      user: null,
      headers: [
        {
          text: this.$t('worker_licenses.card'),
          value: 'card.name',
          sortable: true,
        },
        {
          text: this.$t('worker_licenses.card_number'),
          value: 'cardNumber',
          divider: true,
          sortable: false,
        },

        {
          text: this.$t('worker_licenses.date_from'),
          value: 'dateFrom',
          sortable: true,
        },
        {
          text: this.$t('worker_licenses.date_to'),
          value: '_dateTo',
          sortable: true,
        },
      ],

      tab: 0,
      showAlert: false,
      backButton: {
        show: true,
        fallback: 'tenant_workers',
      },
      saving: false,
      managers: [],
      userList: [],

      relations: [
        { value: 'work', label: this.$t('user_management.user_page.relations.work') },
        { value: 'rent', label: this.$t('user_management.user_page.relations.rent') },
        { value: 'entrepreneur', label: this.$t('user_management.user_page.relations.entrepreneur') },
        { value: 'trainee', label: this.$t('user_management.user_page.relations.trainee') },
        { value: 'voluntary', label: this.$t('user_management.user_page.relations.voluntary') },
      ],
      worker: {
        socialSecurityCountry: '',
        relation: null,
        fiAddress: {
          address: '',
          address2: '',
          address3: '',
          postcode: '',
          city: '',
          country: '',
          dateFrom: dayjs().startOf('year'),
          dateTo: null,
          type: 'VISITING',
          hidden: false,
          procountorCode: '',
        },
        workHours: null,
        person: {
          firstname: '',
          lastname: '',
          address: {
            address: '',
            address2: '',
            address3: '',
            postcode: '',
            city: '',
            country: '',
            dateFrom: dayjs().startOf('year'),
            dateTo: null,
            type: 'VISITING',
            hidden: false,
          },
          taxNumber: '',
          ssn: '',
          dob: null,
          manager: false,
          hidden: false,
          phone: '',
          email: '',
        },
        foreman: null,
        dateFrom: dayjs().startOf('year'),
        dateTo: null,
        hidden: false,
        userId: null,
      },
    };
  },
  computed: {
    dataIsNotChanged() {
      return isEqual(this.workerWhenMounted, this.worker);
    },
    emailExists() {
      return this.userList.filter(u => u.email === this.worker.person.email && u.id !== parseInt(this.id)).length > 0;
    },
    emailIsValidated() {
      return this.$rules.email(this.worker.person.email) === true;
    },
    cards() {
      return this.items.filter(item => item.type === 'lisenssi');
    },
    filteredWorkerLicenses() {
      let wls = Immutable.fromJS(this.workerLicenses).toJS();
      const warningDate = dayjs().add(3, 'month').startOf('day');
      const errorDate = dayjs().add(1, 'month').startOf('day');
      wls.forEach(w => {
        w._dateTo = w.dateTo;
        if (w.dateTo) {
          w._dateError = dayjs(w.dateTo).isBefore(errorDate) ? 'error' : dayjs(w.dateTo).isBefore(warningDate) ? 'warning' : void 0;
        }
        if (!w.dateTo) {
          w._dateTo = FOR_NOW_VALUE;
        }
      });
      if (this.filterCard.length > 0) {
        wls = wls.filter(l => this.filterCard.findIndex(c => c === l.card?.id) > -1);
      }
      if (this.filterWorker.length > 0) {
        wls = wls.filter(l => this.filterWorker.findIndex(w => w === l.worker?.id) > -1);
      }
      const ownLicence = wls.filter(card => card.worker.id === this.worker.id);
      return ownLicence;
    },
    richerWorkers() {
      let filteredUsers = this.workers;
      if (this.$userInfo.email && !this.$userInfo.email.includes('@hubble.fi')) {
        filteredUsers = filteredUsers.filter(user => user.email && !user.email.includes('@hubble.fi'));
      }
      return filteredUsers.map(user => {
        const userCopy = Object.assign({}, user);
        userCopy.combinedInfo = `${userCopy.lastname} ${userCopy.firstname}`;
        return userCopy;
      });
    },
  },
  methods: {
    async getParties() {
      this.loadingParties = true;
      const parties = await partyApi.getParties();
      this.parties = parties.filter(party => party.type === 'contractor' || party.type === 'employer');
      this.loadingParties = false;
    },
    handlePersonEmployerChange(party) {
      this.worker.person.employer = party;
    },
    async createNewWorkerLicense(workerLicence) {
      await this.$refs.workerLicenseDialog.open(workerLicence);
    },
    openWorkerLicenseInDialog(workerLicense) {
      this.$refs.workerLicenseDialog.open(workerLicense);
    },
    userRoles(user) {
      return this.roles.filter(role => user.roles.includes(role));
    },
    availableRolesForUser(user) {
      const availableRoles = this.roles.filter(role => role.startsWith('tenant_') && !user.roles.includes(role));
      return availableRoles;
    },
    handleDobChange(date) {
      this.worker.person.dob = date;
    },
    async addRoleToUser(user, role) {
      try {
        const response = await tenantApi.addRole(user.subject, role);
        user.roles = response.roles || user.roles;
        this.$showSuccessNotification(this.$t('user_management.user_roles_updated'));
      } catch (err) {
        this.$handleApiError(this.$t('user_management.user_roles_update_failed'));
      }
    },
    async removeRoleFromUser(user, role) {
      try {
        const response = await tenantApi.deleteRole(user.subject, role);
        user.roles = response.roles || user.roles;
        this.$showSuccessNotification(this.$t('user_management.user_roles_updated'));
      } catch (err) {
        this.$handleApiError(this.$t('user_management.user_roles_update_failed'));
      }
    },
    async getRoles() {
      try {
        this.roles = await tenantApi.getRoles();
      } catch (error) {
        this.$handleApiError(error);
      }
    },
    async reloadCloudUsers() {
      try {
        const users = await this.$api.getCloudUsers();
        this.users = users;
      } catch (error) {
        this.$store.dispatch('showNotification', {
          color: 'error',
          message: this.$t('errors.error_loading_users'),
          timeOut: 10000,
          showButton: true,
          buttonText: this.$t('try_again'),
          callback: () => {
            this.reloadCloudUsers();
          },
        });
        console.log(error);
      }
    },
    async loadImages(entryId) {
      try {
        const fileEntities = await this.$api.getEntryImages(entryId);
        return fileEntities;
      } catch (error) {
        console.log(error);
      }
      return [];
    },
    async reloadAll() {
      if (this.workerLicenses.length === 0) {
        this.loading = true;
        this.subtleLoading = false;
      } else {
        this.loading = false;
        this.subtleLoading = true;
      }

      await Promise.all([
        (async () => {
          await this.reloadWorkers();
        })(),
        (async () => {
          this.items = await itemsApi.getItemsByTypes(['lisenssi']);
        })(),
        (async () => {
          await this.reloadWorkerLicenses();
        })(),
      ]);

      this.loading = false;
      this.subtleLoading = false;
    },
    async reloadWorkers() {
      this.workers = await workersApi.getWorkers();
    },
    async reloadWorkerLicenses() {
      this.workerLicenses = await workerLicensesApi.getWorkerLicences();
    },
    async getAllUsers() {
      this.userList = await userApi.getAllUsers();
    },
    async getWorker() {
      this.loading = true;
      try {
        this.worker = await workerApi.getWorker(this.id);
        this.worker.userId = this.id;
      } catch (e) {
        console.error(e);
      }
      try {
        const user = await userApi.getUser(this.id);
        this.user = user;
        user.email?.length !== null ? (this.worker.person.email = user.email) : (this.worker.person.email = '');
        user.firstName?.length !== null ? (this.worker.person.firstname = user.firstName) : (this.worker.person.firstname = '');
        user.lastName?.length !== null ? (this.worker.person.lastname = user.lastName) : (this.worker.person.lastname = '');
        this.worker.userId = this.id;
      } catch (error) {
        console.log(error);
        //this.$showErrorNotification(this.$t('user_management.errors.cannot_get_worker_or_user'), false);
      }
      this.workerWhenMounted = cloneDeep(this.worker);
      this.loading = false;
    },
    addManager() {
      this.$refs.AddPersonDialog.open(null, 'foreman');
    },
    async createUser() {
      if (this.worker.workHours === null || this.worker?.workHours?.length === 0) {
        this.$showErrorNotification(this.$t('worker_view.work_hours_required'));
      } else {
        if (this.worker?.id > 0) return;
        if (!this.emailExists && this.emailIsValidated === true) {
          this.loading = true;

          const user = await tenantApi.addNewUserToTenant({
            email: this.worker.person.email,
            firstName: this.worker.person.firstname,
            lastName: this.worker.person.lastname,
          });

          this.worker.userId = user.id;
          await workerApi.createWorker(this.worker);
          this.$showSuccessNotification(this.$t('tenants.user_add_success'));

          this.loading = false;
          await this.$router.push({ name: 'tenant_workers' });
        } else {
          if (this.emailExists) {
            this.showAlert = true;
            this.$showErrorNotification(this.$t('user_management.errors.email_exists'));
          }
          if (!this.emailIsValidated) {
            this.showAlert = true;
            this.$showErrorNotification(this.$t('user_management.errors.email_is_not_validated'));
          }
        }
      }
    },
    async createUserForLicenseCreating() {
      if (this.worker?.id > 0) return;
      if (!this.emailExists && this.emailIsValidated === true) {
        const user = await tenantApi.addNewUserToTenant({
          email: this.worker.person.email,
          firstName: this.worker.person.firstname,
          lastName: this.worker.person.lastname,
        });
        this.worker.userId = user.id;
        const createdWorker = await workerApi.createWorker(this.worker);
        this.worker = createdWorker;
      } else {
        if (this.emailExists) {
          this.showAlert = true;
          this.$showErrorNotification(this.$t('user_management.errors.email_exists'));
        }
        if (!this.emailIsValidated) {
          this.showAlert = true;
          this.$showErrorNotification(this.$t('user_management.errors.email_is_not_validated'));
        }
      }
    },
    async saveUser() {
      if (this.worker.workHours === null || this.worker.workHours.length === 0) {
        this.$showErrorNotification(this.$t('worker_view.work_hours_required'));
      } else {
        this.loading = true;

        if (this.worker.id > 0) {
          await workerApi.updateWorker(this.worker.id, this.worker);
        } else {
          await workerApi.createWorker(this.worker);
        }
        await adminApi.updateUser(this.user.subject, {
          firstName: this.worker.person.firstname,
          lastName: this.worker.person.lastname,
          phone: this.worker.person.phone,
        });

        this.$showSuccessNotification(this.$t('user_management.success_messages.user_updated'));
        this.loading = false;
        await this.$router.push({ name: 'tenant_workers' });
      }
    },
    cancel() {
      this.$router.go(-1);
    },
    async getWorkerLicences() {
      try {
        this.workerLicenses = await workerLicenceApi.getItems();
      } catch (error) {
        console.log(error);
        this.$showErrorNotification(this.$t('errors.error_loading_worker_licences'), false);
      }
    },
    async getManagers() {
      this.managers = await personApi.getManagers();
    },
    async foremanCreated(person) {
      await this.updateManagerList();
      this.worker.foreman = person;
    },
    async updateManagerList() {
      this.managers = await personApi.getManagers();
      this.$showSuccessNotification(this.$t('user_management.user_page.new_foreman_dialog.foreman_created'), false);
    },
    handleFiAddressChange({ name, value }) {
      this.worker.fiAddress[name] = value;
    },
    handlePersonAddressChange({ name, value }) {
      if (!this.worker.person.address) this.worker.person = { ...this.person, address: {} };
      this.worker.person.address[name] = value;
    },
  },
  mounted() {
    this.loading = true;
    if (this.id) {
      this.getWorker();
    }
    this.getRoles();
    this.getParties();
    this.getManagers();
    this.getAllUsers();
    this.reloadAll();
    // RELEASE 1 this.getWorkerLicences();
    this.loading = false;
  },
};
</script>

<style scoped>
.v-tabs-items {
  background-color: transparent !important;
}

.toggle_group_buttons {
  height: 48px !important;
  width: 50% !important;
}
</style>
