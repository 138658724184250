<template>
  <v-autocomplete
    @change="v => this.$emit('change', v)"
    outlined
    clearable
    dense
    return-object
    validate-on-blur
    :value="value"
    item-value="id"
    :item-text="
      item =>
        [item.person ? item.person.firstname : item.firstname, item.person ? item.person.lastname : item.lastname].filter(Boolean).join(' ')
    "
    :label="label"
    :items="workers"
    :disabled="saving || disabled"
    :loading="loading"
    :rules="required && [$rules.required]"
  >
    <template v-slot:selection="{ item }">
      {{ item.person ? item.person.firstname : item.firstname }} {{ item.person ? item.person.lastname : item.lastname }}
    </template>
    <template v-slot:item="data">
      <v-list-item v-bind="data.attrs" v-on="data.on">
        <v-list-item-content>
          <v-list-item-title>
            {{ data.item.person ? data.item.person.firstname : data.item.firstname }}
            {{ data.item.person ? data.item.person.lastname : data.item.lastname }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template v-slot:prepend>
      <v-icon>{{ icon }}</v-icon>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: 'WorkerCombobox',
  props: {
    label: {
      required: true,
      type: String,
    },
    saving: Boolean,
    workers: Array,
    value: Object,
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: Boolean,
    icon: {
      type: String,
      default: 'mdi mdi-account',
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  date() {
    return {};
  },
  methods: {
    addWorker() {
      this.$emit('add-worker');
    },
  },
};
</script>

<style scoped></style>
