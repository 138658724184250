import axios from 'axios';

/*
 * Used for system data calls (only logged in user required, data that's not private but commonly used in the system
 * for example roles or some parameters
 */

const systemApi = {
  async getRoles() {
    const response = await axios.get(`/api/system/roles`);
    return response.data;
  },
};

export default systemApi;
