<template>
  <div>
    <v-card class="mb-3" v-if="combinedSalaryDataForUser">
      <v-card-title class="subtitle-1 text-uppercase font-weight-medium v-card-title">
        {{ $t('reports.pay_period_combined') }}:
        {{ combinedSalaryDataForUser.user.lastName }}
        {{ combinedSalaryDataForUser.user.firstName }}
      </v-card-title>
      <v-card-text>
        <div class="mb-2">
          <span class="mr-3">
            {{ $t('reports.in_total') }}: <strong>{{ inTotal }} h</strong>
          </span>
          <span class="mr-3">
            {{ $t('reports.hourly_pay') }}:
            <strong>{{ $formatNumber(combinedSalaryDataForUser.timeEntryMinutes / 60, '0.0') }} h</strong>
          </span>
          <span class="mr-3" v-if="combinedSalaryDataForUser.education">
            {{ $t('timer.education') }}:
            <strong>{{ $formatNumber(combinedSalaryDataForUser.education / 60, '0.0') }} h</strong>
          </span>
          <span class="mr-3">
            {{ $t('reports.travel_time') }}:
            <strong>{{ $formatNumber(combinedSalaryDataForUser.m1Minutes / 60, '0.0') }} h</strong>
          </span>
          <span class="mr-3">
            {{ $t('reports.travel_time_outside_work_hours') }}:
            <strong>{{ $formatNumber(combinedSalaryDataForUser.m2Minutes / 60, '0.0') }} h</strong>
          </span>
          <span class="mr-3">
            {{ $t('reports.overtime_element') }}:
            <strong>{{ $formatNumber(combinedSalaryDataForUser.overTimeElement / 60, '0.0') }} h</strong>
          </span>
          <span class="mr-3">
            {{ $t('reports.overtimes') }} 50%:
            <strong>{{ $formatNumber(combinedSalaryDataForUser.overTime50 / 60, '0.0') }} h</strong>
          </span>
          <span class="mr-3">
            {{ $t('reports.overtimes') }} 100%:
            <strong>{{ $formatNumber(combinedSalaryDataForUser.overTime100 / 60, '0.0') }} h</strong>
          </span>
        </div>
        <v-divider></v-divider>
        <div class="mt-2 mb-2">
          <span class="mr-3">
            {{ $t('timer.overnight_stay_in_car') }}:
            <strong>{{ combinedSalaryDataForUser.overnightStayInCarPay }} {{ $t('reports.pcs') }}</strong>
          </span>
          <span class="mr-3">
            {{ $t('timer.lunch_pay') }}: <strong>{{ combinedSalaryDataForUser.lunchPay }} {{ $t('reports.pcs') }}</strong>
          </span>
          <span class="mr-3">
            {{ $t('timer.part_day_pay') }}: <strong>{{ combinedSalaryDataForUser.partDayPay }} {{ $t('reports.pcs') }}</strong>
          </span>
          <span class="mr-3">
            {{ $t('timer.home_country_day_pay') }}: <strong>{{ combinedSalaryDataForUser.wholeDayPay }} {{ $t('reports.pcs') }}</strong>
          </span>
          <span class="mr-3"
            >{{ $t('timer.foreign_country_day_pay') }}:
            <strong>{{ combinedSalaryDataForUser.foreignCountryDayPay }} {{ $t('reports.pcs') }}</strong>
          </span>
        </div>
        <template v-if="combinedSalaryDataForUser.diameters && combinedSalaryDataForUser.diameters.length > 0">
          <v-divider></v-divider>
          <div class="mt-2" :class="combinedSalaryDataForUser.away && combinedSalaryDataForUser.away.length > 0 ? 'mb-2' : ''">
            <div v-for="(diameter, index) in combinedSalaryDataForUser.diameters" :key="`diameter_${index}`">
              {{ diameter.workType ? diameter.workType.name : '- ' }}, {{ diameter.diameter ? diameter.diameter.name : ' -' }}:
              <strong>{{ diameter.length }} {{ $t('reports.meters') }}</strong>
            </div>
          </div>
        </template>
        <template v-if="combinedSalaryDataForUser.away && combinedSalaryDataForUser.away.length > 0">
          <v-divider></v-divider>
          <div class="mt-2">
            <span class="mr-3" v-for="(away, index) in combinedSalaryDataForUser.away" :key="`away_${index}`">
              {{ away.awayType ? away.awayType.name : '-' }}:
              <strong>{{ away.amount }} {{ $t('reports.pcs') }}</strong>f
            </span>
          </div>
        </template>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  name: 'CombinedSalaryDataForUser',
  props: ['userId', 'salaryData'],
  computed: {
    combinedSalaryDataForUser() {
      const cDataIndex = this.salaryData.combinedData.findIndex(d => d.user.id === this.userId);
      if (cDataIndex > -1) {
        return this.salaryData.combinedData[cDataIndex];
      }
      return null;
    },
    inTotal() {
      return this.$formatNumber(
        (this.combinedSalaryDataForUser.timeEntryMinutes +
          this.combinedSalaryDataForUser.education +
          this.combinedSalaryDataForUser.m1Minutes +
          this.combinedSalaryDataForUser.m2Minutes +
          this.combinedSalaryDataForUser.overTimeElement) /
          60,
        '0.0'
      );
    },
  },
};
</script>

<style scoped></style>
