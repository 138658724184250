<template>
  <hb-basic-page
    :title="$t('items.title')"
    fluid
    :loading="loading"
    :extension-height="60"
    class="sites-view"
    :pa-0="$vuetify.breakpoint.xsOnly"
    v-if="$isTenantAdmin"
  >
    <template v-if="$vuetify.breakpoint.smAndUp" v-slot:app-bar-extension>
      <v-btn @click="addItem" color="primary" class="mb-3 ml-n1">
        <v-icon class="no-gradient" left>mdi-plus</v-icon>
        <span>{{ $t('items.create_new_item') }}</span>
      </v-btn>
    </template>

    <v-btn v-if="$vuetify.breakpoint.xsOnly" @click="addItem" color="primary" fab fixed bottom right>
      <v-icon class="no-gradient">mdi-plus</v-icon>
    </v-btn>

    <v-col cols="12" class="gradient-icons mb-12">
      <v-fade-transition>
        <v-row no-gutters>
          <v-col style="position: fixed; z-index: 99; width: calc(100vw - 24px)" cols="12" sm="4" lg="3" xl="2">
            <v-select
              v-model="filter"
              :items="filterOptions"
              :placeholder="`${$t('user_management.show_all')}`"
              item-text="desc"
              item-value="value"
              multiple
              hide-details
              single-line
              solo
              prepend-inner-icon="filter_list"
            >
              <template v-slot:selection="{ item, index }">
                <template v-if="filter.length !== filterOptions.length && index === 0 && filter.length > 0">
                  <span class="mr-1">{{ $t('user_management.filtered') }}</span>
                  <span class="caption">({{ filter.length }} {{ $t('user_management.selections') }})</span>
                </template>
                <template v-else>
                  <span v-if="index === 0">{{ $t('user_management.show_all') }}</span>
                </template>
              </template>
            </v-select>
          </v-col>
          <template>
            <v-col cols="12" class="mt-15" v-for="type in filteredItemTypes" :key="type.id">
              <v-row>
                <items-view-item-type
                  :no-content="$t('items.no_' + type)"
                  :items="items.filter(item => item.type === type)"
                  :item-type="type"
                  @open-item-remove-dialog="openItemRemoveDialog"
                  @open-item="openItem"
                  @open-new="addItem"
                />
              </v-row>
            </v-col>
          </template>
          <confirm-dialog ref="openItemRemoveDialog" />
          <add-or-edit-item-dialog
            ref="addOrEditItemDialog"
            @updated="itemUpdatedSnackbar = true && reloadItems()"
            @created="itemCreatedSnackbar = true && reloadItems()"
          />

          <v-snackbar v-model="itemDeletedSnackbar" :timeout="timeOut" top elevation="3" color="error">{{
            $t('items.item_deleted')
          }}</v-snackbar>
          <v-snackbar v-model="itemCreatedSnackbar" :timeout="timeOut" top elevation="3" color="success">{{
            $t('items.item_created')
          }}</v-snackbar>
          <v-snackbar v-model="itemUpdatedSnackbar" :timeout="timeOut" top elevation="3" color="success">{{
            $t('items.item_updated')
          }}</v-snackbar>
        </v-row>
      </v-fade-transition>
    </v-col>
  </hb-basic-page>
</template>

<script>
import itemApi from '@/api/item';
import ItemsViewItemType from '@/components/Items/ItemsViewItemType';
import ConfirmDialog from '@/components/ConfirmDialog';
import AddOrEditItemDialog from '@/components/Items/AddOrEditItemDialog';

export const types = [
  'putki',
  'keskitysrengas',
  'menetelmä',
  'kalustotyyppi',
  'lisenssi',
  'työtehtävä',
  'poissaolo',
  'ylivuotokaivo',
  'päätytulppa',
];

export default {
  name: 'ItemsView',
  components: { AddOrEditItemDialog, ConfirmDialog, ItemsViewItemType },
  data() {
    return {
      loading: true,
      subtleLoading: false,
      offsetTop: 0,
      items: [],
      timeOut: 2000,
      itemDeletedSnackbar: false,
      itemCreatedSnackbar: false,
      itemUpdatedSnackbar: false,
      itemTypes: types,
      filter: [] /*types.map((t, i) => i),*/,
      filterOptions: types.map((t, i) => {
        return { desc: this.$tc('items.' + t, 2), value: i };
      }),
    };
  },
  methods: {
    async saveItem() {
      this.loading = true;
      await itemApi.updateItem(this.item.id, this.item);
      this.loading = false;
    },
    openItem(itemId) {
      const newItem = false;
      this.$refs.addOrEditItemDialog.open(itemId, newItem);
    },
    openItemRemoveDialog(item) {
      this.$refs.openItemRemoveDialog
        .open(this.$t('items.delete_item_dialog.title'), this.$t('items.delete_item_dialog.description'), { color: 'error' })
        .then(confirm => {
          this.removeItem(confirm, item);
        });
    },
    async removeItem(confirm, item) {
      if (confirm === true) {
        const itemModified = {
          id: item.id,
          name: item.name,
          type: item.type,
          active: false,
        };
        await itemApi.removeItem(item.id, itemModified);
        this.itemDeletedSnackbar = true;
        await this.reloadItems();
      } else {
        this.itemDeletedSnackbar = false;
      }
    },
    addItem(type = null) {
      const site = null;
      const newItem = true;
      this.$refs.addOrEditItemDialog.open(site, newItem, type);
    },
    async reloadItems() {
      try {
        this.items = await itemApi.getItems();
      } catch (error) {
        console.log(error);
        this.$showErrorNotification(this.$tc('errors.no_items', 2), false);
      }
    },
  },
  computed: {
    filteredItemTypes() {
      return this.filter.length === 0 ? this.itemTypes : this.itemTypes.filter((f, index) => this.filter.includes(index));
    },
  },
  async mounted() {
    this.loading = true;
    await this.$vuetify.goTo(0, {
      duration: 0,
      offset: 0,
    });
    await this.reloadItems();
    this.loading = false;
  },
};
</script>
