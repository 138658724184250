<template>
  <TimerView :id="id" :admin-mode="true" key="timeradmin" />
</template>

<script>
import TimerView from '@/views/tenant/TimerView';
export default {
  name: 'TimerAdminView',
  components: { TimerView },
  props: ['id'],
};
</script>

<style scoped></style>
