<template xmlns:v-slot='http://www.w3.org/1999/XSL/Transform'>
  <v-dialog
    v-model='dialog'
    :fullscreen='$vuetify.breakpoint.xsOnly'
    :hide-overlay='$vuetify.breakpoint.xsOnly'
    :max-width="$vuetify.breakpoint.xsOnly ? '' : '650'"
    :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : ''"
    content-class='entry-dialog'
    no-click-animation
    persistent
  >
    <v-card :style="$vuetify.breakpoint.xsOnly ? { borderRadius: '0' } : ''" class='gradient-icons'>
      <v-toolbar color='primary' dark>
        <v-toolbar-title>
          <span v-if='TIMEENTRY'>{{ newEntryMode ? $t('timer.create_new_entry') : $t('timer.entry_info') }}</span>
          <span
            v-if='TRAVELINVOICE'>{{ newEntryMode ? $t('timer.create_new_travel_invoice') : $t('timer.travel_invoice_info')
            }}</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn dark icon @click='dialog = false'>
          <v-icon class='no-gradient'>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form ref='form'>
        <div
          v-if='dialog'
          :style="{ maxHeight: $vuetify.breakpoint.xsOnly ? 'calc(100vh - 57px)' : 'calc(100vh - 270px)' }"
          style='overflow-y: auto; overflow-x: hidden'
        >
          <v-row v-if='currentEntry.approved || currentEntry.checked'>
            <v-col>
              <v-alert border='top' class='mt-5 ma-5' color='primary' colored-border elevation='2' type='success'>
                <span v-for='(text, i) in approvedCheckedText' :key='i' class='caption'>{{ text }} <br /></span>
              </v-alert>
            </v-col>
          </v-row>

          <v-card-text v-if='TRAVELINVOICE'>
            <v-btn-toggle v-model='currentEntry.timeEntryType' class='mb-5' color='primary' dense mandatory>
              <v-btn dense outlined style='min-width: 70px' value='M1'> M1</v-btn>
              <v-btn dense outlined style='min-width: 70px' value='M2'> M2</v-btn>
            </v-btn-toggle>
            <v-divider />
          </v-card-text>
          <v-card-text class='pt-0 pb-0'>
            <v-row>
              <v-col class='mt-5' cols='12'>
                <v-combobox
                  v-if='adminMode && users && users.length > 0'
                  id='worker-select'
                  v-model='selectedUser'
                  :disabled='currentEntry.disabled || !adminMode || !$isTenantAdmin || !!currentEntry.id'
                  :items='richerUsers'
                  :label="$t('timer.user')"
                  :menu-props='{ closeOnClick: true, closeOnContentClick: true }'
                  :placeholder="$t('timer.choose_user')"
                  :rules='[$rules.required]'
                  class='title'
                  clearable
                  dense
                  item-text='combinedInfo'
                  item-value='id'
                  outlined
                  style='width: 100%'
                >
                  <template slot='prepend'>
                    <div style='width: 25px; text-align: center'>
                      <v-icon color='grey'>worker</v-icon>
                    </div>
                  </template>
                  <template v-slot:selection='data'>
                    <div v-if='data.item.firstName'>{{ data.item.firstName }} {{ data.item.lastName }}</div>
                    <div v-else>{{ data.item.email }}</div>
                  </template>
                  <template v-slot:item='data'>
                    <v-list-item-title>
                      <template v-if='data.item.firstName'>{{ data.item.firstName }} {{ data.item.lastName }}</template>
                      <template v-else>{{ data.item.email }}</template>
                    </v-list-item-title>
                  </template>
                </v-combobox>

                <site-combobox
                  :label="$t('timer.site')"
                  :multiple='false'
                  :required='false'
                  :saving='saving'
                  :scheduledSitesOnly='true'
                  :sites='sites'
                  :value='currentEntry.site'
                  @change='handleSiteChange'
                />
                <equipment-combobox
                  v-if='TRAVELINVOICE'
                  :equipments='equipments'
                  :hide-details='true'
                  :label="$t('timer.equipment')"
                  :multiple='true'
                  :saving='saving'
                  :value='currentEntry.equipments'
                  @change='handleEquipmentsChange'
                />

                <item-combobox
                  :items='workTypes'
                  :label="$t('timer.work_type')"
                  :multiple='false'
                  :required='true'
                  :saving='saving'
                  :value='currentEntry.workType'
                  @change='handleWorkTypeChange'
                >
                  <template v-slot:prepend>
                    <v-icon>mdi mdi-shovel</v-icon>
                  </template>
                </item-combobox>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-text v-if='TIMEENTRY' class='pb-0'>
            <item-combobox
              v-if="currentEntry.workType && currentEntry.workType.name && currentEntry.workType.name.indexOf('poraus') > -1"
              :disabled='currentEntry.disabled'
              :items='diameters'
              :label="$t('timer.diameter')"
              :multiple='false'
              :required='true'
              :saving='saving'
              :value='currentEntry.diameter'
              class='mt-2'
              @change='handleDiameterChange'
            >
              <template v-slot:prepend>
                <v-icon>mdi mdi-diameter-outline</v-icon>
              </template>
            </item-combobox>
            <numeric-input
              v-if="currentEntry.workType && currentEntry.workType.name && currentEntry.workType.name.indexOf('poraus') > -1"
              v-model='currentEntry.length'
              :disabled='currentEntry.disabled'
              :label="$t('timer.length')"
              dense
              outlined
            >
              <template slot='prepend'>
                <v-icon>mdi-arrow-expand-horizontal</v-icon>
              </template>
              <template slot='append'> m</template>
            </numeric-input>
          </v-card-text>
          <v-card-text>
            <entry-times
              ref='entryTimes'
              :contains-lunch='TRAVELINVOICE ? false : currentEntry.containsLunch'
              :current-entry='currentEntry'
              :default-work-hours='currentEntry.worker && currentEntry.worker.workHours ? currentEntry.worker.workHours : 0'
              :dialog='dialog'
              :existing-entry-ranges='existingEntryRanges'
              :handle-duration-dialog-save='handleDurationDialogSave'
              :travel-invoice='TRAVELINVOICE'
              class='mt-3'
              @duration='getEntryDuration'
            />
          </v-card-text>
          <v-card-text class='pt-0 mb-2'>
            <v-row v-if='TIMEENTRY' class='mt-0' no-gutters>
              <v-col cols='12'>
                <v-checkbox
                  v-model='currentEntry.containsLunch'
                  :disabled='currentEntry.disabled'
                  :label="$t('timer.contains_lunch')"
                  class='my-0 py-0'
                  color='primary'
                  hide-details
                  style='margin-left: 31px'
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text :style="{ paddingBottom: $vuetify.breakpoint.xsOnly && newEntryMode ? '20px' : '' }">
            <v-row>
              <v-col class='pb-0' cols='12'>
                <v-textarea
                  v-model='currentEntry.info'
                  :disabled='currentEntry.disabled'
                  :label="$t('timer.info')"
                  :placeholder="TIMEENTRY ? $t('timer.info_placeholder') : TRAVELINVOICE ? $t('timer.travel_invoice_info_placeholder') : ''"
                  auto-grow
                  dense
                  outlined
                  rows='2'
                >
                  <template v-slot:prepend>
                    <v-icon>info</v-icon>
                  </template>
                </v-textarea>
                <!--<v-text-field
                  v-if="TRAVELINVOICE"
                  :disabled="currentEntry.disabled"
                  class=""
                  v-model="currentEntry.distance"
                  :label="$t('timer.distance')"
                  :placeholder="$t('timer.distance_placeholder')"
                  suffix="km"
                  dense
                  outlined
                >
                  <template slot="prepend">
                    <div style="width: 25px; padding-top: 4px; text-align: center"><v-icon color="grey" size="20">fas fa-car</v-icon></div>
                  </template>
                </v-text-field>-->

                <file-upload
                  v-if='TIMEENTRY'
                  ref='file'
                  :disabled='!!currentEntry.approved || saving'
                  :object-id='currentEntry.id'
                  object='entry'
                ></file-upload>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>
          <v-card-text>
            <v-row no-gutters>
              <v-col cols='12'>
                <v-timeline dense small>
                  <v-timeline-item color='primary'>
                    <template v-slot:icon>
                      <v-icon class='no-gradient' color='white' small>mdi mdi-comment-plus-outline</v-icon>
                    </template>
                    <v-row no-gutters>
                      <v-col class='pb-0' cols='12'>
                        <v-row>
                          <v-col cols='9' sm='10'>
                            <v-textarea v-model='comment' :placeholder="$t('timer.new_comment')" auto-grow dense
                                        outlined
                                        rows='1'>
                            </v-textarea>
                          </v-col>
                          <v-col cols='2'>
                            <v-btn :loading='savingComment' color='white' elevation='2' fab small @click='addComment'
                            >
                              <v-icon class='ml-1'>mdi-send</v-icon>
                            </v-btn
                            >
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col class='pt-0 mt-0' cols='12'>
                        <v-row>
                          <v-col cols='3'>
                            <v-checkbox
                              v-model='notify'
                              :label="notify ? $t('timer.notify') : $t('timer.dont_notify')"
                              dense
                              off-icon='mdi mdi-bell-plus-outline'
                              on-icon='mdi mdi-bell-plus'
                            ></v-checkbox>
                          </v-col>
                        </v-row>
                        <v-divider></v-divider>
                      </v-col>
                    </v-row>
                  </v-timeline-item>

                  <v-timeline-item v-for='(comment, commentIndex) in currentEntry.comments' :key='commentIndex'>
                    <template v-slot:icon>
                      <v-icon v-if='comment.notify && !isCommentWorkTaskDone(comment)' class='no-gradient notify-bell'
                              color='warning'
                      >mdi mdi-bell-alert
                      </v-icon
                      >
                      <v-icon v-if='isCommentWorkTaskDone(comment)' class='no-gradient notify-bell' color='warning'
                      >mdi mdi-bell-check
                      </v-icon
                      >
                      <span class='white--text'>
                        {{ getInitials(comment) }}
                      </span>
                    </template>
                    <v-row>
                      <v-list-item style='width: 100%'>
                        <v-list-item-content v-if='comment.text'>
                          <v-list-item-title class='text-wrap'>
                            <span v-for="line in comment.text.split('\n')" :key='line'>{{ line }}<br /></span>
                          </v-list-item-title>
                          <v-list-item-subtitle v-if='comment.user' class='text-wrap'>
                            <span>{{ comment.user.firstName }} {{ comment.user.lastName
                              }} {{ $formatDateNoTime(comment.datetime) }}</span>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action v-if='$vuetify.breakpoint.smAndUp' class='flex-row align-center'>
                          <v-btn
                            v-if='
                              !isCommentWorkTaskDone(comment) &&
                              comment.workTask &&
                              comment.workTask[0] &&
                              comment.workTask[0].assignedUserId === $userInfo.id
                            '
                            :title="$t('work_tasks.messages.mark_done')"
                            dense
                            icon
                            small
                            @click='workTaskDone(comment.workTask[0])'
                          >
                            <v-icon class='no-gradient' color='success'>mdi mdi-check</v-icon>
                          </v-btn>

                          <v-btn v-if='comment.user && comment.user.id === $userInfo.id' dense icon small
                                 @click='deleteComment(comment)'>
                            <v-icon class='no-gradient' color='error'>mdi mdi-delete</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </v-row>
                  </v-timeline-item>
                </v-timeline>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text v-if='adminMode && $isTenantAdmin && currentEntry.checked && !currentEntry.approved'>
            <v-btn :block='true' :disabled='loadingRemoveCheck' color='error' text @click='removeCheck'>
              <span>{{ $t('timer.remove_checked') }}</span>
            </v-btn>
          </v-card-text>

          <!--<v-card-text>
            <v-row no-gutters>
              <v-col cols="12">
                <v-checkbox class="mt-1" color="primary" v-model="currentEntry.invoiced" :label="$t('reports.invoiced')">
                  <template slot="prepend">
                    <div style="width: 25px; padding-top: 2px; text-align: center">
                      <v-icon color="grey" size="20">fas fa-euro-sign</v-icon>
                    </div>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>
          </v-card-text>-->

          <template v-if='(!adminMode || $isTenantAdmin) && $vuetify.breakpoint.xsOnly && !newEntryMode'>
            <v-divider></v-divider>
            <v-card-text :style="{ paddingBottom: $vuetify.breakpoint.xsOnly ? '120px' : '' }">
              <v-row>
                <v-col class='pb-0' cols='12'>
                  <v-btn
                    v-if='!currentEntry.approved && !newEntryMode && (!adminMode || $isTenantAdmin)'
                    :block='$vuetify.breakpoint.xsOnly'
                    :disabled='saving || currentEntry.disabled'
                    :loading='deleting'
                    color='error'
                    text
                    @click='deleteEntry'
                  >
                    <span>{{ dialogType === 'travelinvoice' ? $t('timer.delete_travel_invoice') : $t('timer.delete_time_entry')
                      }}</span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </template>
          <v-card-text v-if='currentEntry.id'>
            <time-entry-logs :time-entry='currentEntry'></time-entry-logs>
          </v-card-text>
        </div>
      </v-form>

      <v-divider v-if='$vuetify.breakpoint.smAndUp'></v-divider>
      <v-card-actions :class="$vuetify.breakpoint.xsOnly ? 'pa-0' : ''">
        <v-btn
          v-if='!currentEntry.approved && !newEntryMode && (!adminMode || $isTenantAdmin) && $vuetify.breakpoint.smAndUp'
          :disabled='saving || currentEntry.disabled'
          :loading='deleting'
          color='error'
          text
          @click='deleteEntry'
        >
          <span>{{ dialogType === 'travelinvoice' ? $t('timer.delete_travel_invoice') : $t('timer.delete_time_entry')
            }}</span>
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn
          :bottom='$vuetify.breakpoint.xsOnly'
          :disabled='saving || deleting'
          :fab='$vuetify.breakpoint.xsOnly'
          :fixed='$vuetify.breakpoint.xsOnly'
          :left='$vuetify.breakpoint.xsOnly'
          color='default'
          @click='dialog = false'
        >
          <v-icon v-if='$vuetify.breakpoint.xsOnly' class='no-gradient'>mdi-close</v-icon>
          <span v-if='$vuetify.breakpoint.smAndUp'>{{ cancelButtonText ? cancelButtonText : this.$t('cancel') }}</span>
        </v-btn>

        <v-btn
          v-if='!currentEntry.approved && (!adminMode || $isTenantAdmin)'
          :bottom='$vuetify.breakpoint.xsOnly'
          :disabled='currentEntry.disabled || deleting'
          :fab='$vuetify.breakpoint.xsOnly'
          :fixed='$vuetify.breakpoint.xsOnly'
          :loading='saving'
          :right='$vuetify.breakpoint.xsOnly'
          color='primary'
          @click='saveEntry'
        >
          <template v-if='newEntryMode && (!currentEntry.entryEndTime || !currentEntry.entryEndDate)'>
            <v-icon v-if='$vuetify.breakpoint.xsOnly' class='no-gradient'>mdi-plus</v-icon>
            <span v-if='$vuetify.breakpoint.smAndUp'>{{ $t('save') }}</span>
          </template>
          <template v-else>
            <v-icon v-if='$vuetify.breakpoint.xsOnly' class='no-gradient'>mdi mdi-check</v-icon>
            <span v-if='$vuetify.breakpoint.smAndUp'>{{ $t('save') }}</span>
          </template>
        </v-btn>
      </v-card-actions>
    </v-card>
    <confirm-dialog ref='commentHandlingDialog' />
    <confirm-dialog ref='softDeleteEntry' />
  </v-dialog>
</template>

<script>
import dayjs from 'dayjs';
import EntryService from '../../service/entry.service';
import '../../../node_modules/viewerjs/dist/viewer.css';
import EquipmentCombobox from '@/components/EquipmentCombobox';
import timeentriesApi from '@/api/timeentries';
import workerApi from '@/api/worker';
import commentApi from '@/api/comment';

import SiteCombobox from '@/components/SiteCombobox';
import ItemCombobox from '@/components/ItemCombobox';
import EntryTimes from '@/components/Timer/EntryTimes';
import FileUpload from '@/components/FileUpload';
import Immutable from 'immutable';
import ConfirmDialog from '@/components/ConfirmDialog';
import NumericInput from '@/components/NumericInput';
import orderBy from 'lodash/orderBy';
import TimeEntryLogs from '@/components/Timer/TimeEntryLogs';

const dateFormat = 'YYYY-MM-DD';

const minMax = require('dayjs/plugin/minMax');
dayjs.extend(minMax);

export default {
  name: 'EntryDialog',
  components: {
    TimeEntryLogs,
    ConfirmDialog,
    NumericInput,
    FileUpload,
    EntryTimes,
    ItemCombobox,
    SiteCombobox,
    EquipmentCombobox,
  },
  props: {
    adminMode: {
      type: Boolean,
      default: false,
    },
    users: {
      type: [Boolean, Array],
      default: false,
    },
    freeTimes: {
      type: Array,
    },
    propsUser: {
      type: Object,
      default: null,
    },
    travelInvoiceCalculation: String,
    sitesArray: Array,
    equipmentsArray: Array,
    itemsArray: Array,
    cancelButtonText: {
      type: String,
    },
  },
  data() {
    return {
      date: null,
      dialogType: 'timeentry',
      durationInHours: null,
      dialog: false,
      file: [],
      currentEntry: {},
      selectedUser: null,
      savingComment: false,
      loadingRemoveCheck: false,
      entryTemplate: {
        id: null,
        info: '',
        entryStart: null,
        entryStartDate: null,
        entryStartTime: null,
        entryEnd: null,
        entryEndDate: null,
        entryEndTime: null,
        siteId: null,
        equipmentIds: [],
        workType: null,
        containsLunch: true,
        timeEntryType: 'TIMEENTRY',
        homeCountryDayPay: false,
        lunchPay: false,
        partDayPay: false,
        foreignCountryDayPay: false,
        overnightStayInCar: false,
        comments: '',
        approved: null,
      },
      entriesByDate: null,
      newEntryMode: false,
      saving: false,
      deleting: false,
      addedImages: [],
      previewImages: [],
      comment: '',
      notify: false,
      previewIndex: 0,
      viewerOptions: {
        inline: false,
        button: false,
        navbar: false,
        title: false,
        toolbar: true,
        tooltip: true,
        movable: true,
        zoomable: true,
        rotatable: true,
        scalable: true,
        transition: false,
        fullscreen: false,
        keyboard: true,
        url: 'data-source',
      },
    };
  },
  methods: {
    async getWorker() {
      try {
        this.currentEntry.worker = await workerApi.getWorker(this.currentEntry.userId);
      } catch (e) {
        console.error(e);
      }
    },
    getEntryDuration(value) {
      this.durationInHours = value.diff / 3600000; // value is milliseconds
    },
    async workTaskDone(workTask) {
      try {
        const w = await this.$store.dispatch('setWorkTaskStatus', { workTask, status: 'DONE' });
        if (w.status === 'DONE') {
          this.$showSuccessNotification(this.$t('work_tasks.messages.work_task_done'));
        }
      } catch (e) {
        console.error(e);
        this.$showErrorNotification(this.$t('generic_error'));
      }
      this.getComments();
    },
    isCommentWorkTaskDone(comment) {
      return comment.notify && comment.workTask?.[0]?.status === 'DONE';
    },
    log(a) {
      console.log(a);
    },
    loadedImages(file) {
      console.log(file);
      //URL.revokeObjectURL(file.thumbnail)
      //URL.revokeObjectURL(file.source)
    },
    updateSiteForEntry(site) {
      if (site.id) {
        this.$set(this.currentEntry, 'site', site);
        this.$set(this.currentEntry, 'siteId', site.id);
      }
    },
    inited(viewer) {
      this.$viewer = viewer;
    },
    openFullImage(index) {
      this.$viewer.view(index);
    },
    isEntryValid() {
      return EntryService.isEntryValid(this.currentEntry, this.$userInfo);
    },
    getInitials(comment) {
      return [comment.user.firstName.substr(0, 1).toUpperCase(), comment.user.lastName.substr(0, 1).toUpperCase()].join('');
    },
    async removeCheck() {
      this.loadingRemoveCheck = true;
      await timeentriesApi.removeCheck(this.currentEntry.id);
      this.loadingRemoveCheck = false;
      this.dialog = false;
    },
    async deleteComment(comment) {
      const confirm = await this.$confirmDelete(this.$t('comments.confirm_delete'));
      if (confirm && comment) {
        await commentApi.deleteComment(comment.id);
        this.currentEntry.comments = commentApi
          .listComments('timeEntry', this.currentEntry.id)
          .then(comments => (this.currentEntry.comments = comments));
      }
      //this.currentEntry.comments.splice(commentIndex, 1);
    },
    async getComments() {
      this.currentEntry.comments = commentApi
        .listComments('timeEntry', this.currentEntry.id)
        .then(comments => (this.currentEntry.comments = comments));
      return this.currentEntry.comments;
    },
    async addComment() {
      if (!this.currentEntry.comments) {
        this.currentEntry.comments = [];
      }
      if (this.comment.length > 0) {
        const comment = Immutable.fromJS({
          text: this.comment,
          notify: this.notify,
          assignToUser: this.currentEntry?.user?.id,
        }).toJS();

        if (this.currentEntry.id > 0) {
          this.savingComment = true;
          try {
            await commentApi.addComment('timeEntry', this.currentEntry.id, comment);
            this.currentEntry.comments.push(comment);
            this.comment = '';
          } catch (e) {
            console.error(e);
            this.$showErrorNotification(this.$t('timer.comment_could_not_be_saved'));
          }

          this.savingComment = false;
        } else {
          this.currentEntry.comments.push(comment);
          this.comment = '';
        }
      }
      this.$emit('comment-created');
      this.currentEntry.comments = commentApi
        .listComments('timeEntry', this.currentEntry.id)
        .then(comments => (this.currentEntry.comments = comments));
    },

    openNew(date, type, userId = null, entriesByDate = null) {
      this.newEntryMode = true;
      this.dialogType = type;
      this.currentEntry = Object.assign({}, this.entryTemplate);
      this.entriesByDate = entriesByDate;

      if (date) {
        this.date = dayjs(date);
      } else {
        this.date = dayjs();
      }

      this.syncEntryTimesAndDates();
      if (type === 'travelinvoice') {
        this.currentEntry.timeEntryType = 'M1';
        this.currentEntry.containsLunch = false;
      }
      let savedEquipments = localStorage.getItem('EntryDialog.vue-equipments');
      if (savedEquipments && type === 'travelinvoice') {
        this.currentEntry.equipments = JSON.parse(savedEquipments);
      }
      if (this.adminMode) {
        if (userId) {
          this.selectedUser = this.users.find(u => u.id === userId);
        } else {
          this.selectedUser = this.propsUser;
        }
      }

      this.dialog = true;
    },
    async openEntry(entry, stopTimer = false, copy = false, entriesByDate = null, date = null) {
      if (entry.timeEntryType === 'TIMEENTRY') {
        this.dialogType = 'timeentry';
      } else {
        this.dialogType = 'travelinvoice';
      }
      this.newEntryMode = copy;
      let entryCopy = Object.assign({}, entry);
      entryCopy.entryEndTime = null;
      entryCopy = await timeentriesApi.getTimeEntry(entryCopy.id);
      this.currentEntry = entryCopy;
      if (date) {
        this.date = date;
      }
      this.entriesByDate = entriesByDate;

      if (this.adminMode && this.users.length > 0) {
        const userIndex = this.users.findIndex(user => user.id === this.currentEntry.userId);
        if (userIndex > -1) {
          this.selectedUser = this.users[userIndex];
        }
      }

      if (this.$userInfo.id !== this.currentEntry.userId && !this.$isTenantAdmin) {
        this.currentEntry.disabled = true;
      }
      if (this.currentEntry.checked && !this.$isTenantAdmin) {
        this.currentEntry.disabled = true;
      }
      if (this.currentEntry.approved) {
        this.currentEntry.disabled = true;
      }

      if (stopTimer) {
        this.currentEntry.entryEnd = dayjs(dayjs().format(dateFormat) + ' ' + this.getClosestHalfHourInterval());
        //this.checkIfEntryShouldContainLunch();
      }
      this.syncEntryTimesAndDates();
      if (!copy) {
        this.$refs.file?.loadImages();
      }
      /*if (this.currentEntry.id > 0) {
        this.currentEntry.comments = commentApi
          .listComments('timeEntry', this.currentEntry.id)
          .then(comments => (this.currentEntry.comments = comments));
      }*/
      await this.getWorker();
      this.dialog = true;
    },
    syncEntryTimesAndDates(appendix = '') {
      if (!this.currentEntry[`entryStart${appendix}`]) {
        if (!this.hasExistingEntries) {
          this.currentEntry[`entryStartDate${appendix}`] = this.date.format(dateFormat);
          const startTime = dayjs().set('hour', 7).set('minute', 0).set('second', 0).set('millisecond', 0);
          this.currentEntry[`entryStartTime${appendix}`] = startTime.format('HH:mm');
          //this.getClosestHalfHourInterval();
        } else {
          this.currentEntry[`entryStartDate${appendix}`] = this.latestEndTime.format(dateFormat);
          this.currentEntry[`entryStartTime${appendix}`] = this.latestEndTime.format('HH:mm');
        }
      } else {
        this.currentEntry[`entryStartDate${appendix}`] = this.currentEntry[`entryStart${appendix}`].format(dateFormat);
        this.currentEntry[`entryStartTime${appendix}`] = this.currentEntry[`entryStart${appendix}`].format('HH:mm');
      }
      if (!this.currentEntry[`entryEnd${appendix}`]) {
        this.currentEntry[`entryEndDate${appendix}`] = dayjs(this.currentEntry[`entryStartDate${appendix}`]).format(dateFormat);
      } else {
        this.currentEntry[`entryEndDate${appendix}`] = this.currentEntry[`entryEnd${appendix}`].format(dateFormat);
        this.currentEntry[`entryEndTime${appendix}`] = this.currentEntry[`entryEnd${appendix}`].format('HH:mm');
      }
    },
    handleDurationDialogSave(end, appendix) {
      this.currentEntry[`entryEnd${appendix}`] = dayjs(end);
      this.currentEntry[`entryEndDate${appendix}`] = dayjs(end).format(dateFormat);
      this.currentEntry[`entryEndTime${appendix}`] = dayjs(end).format('HH:mm');
    },
    handleTimeChangeInDurationDialog(appendix = '') {
      this.$nextTick(() => {
        this.$refs[`entryTimes${appendix}`]?.$refs?.durationDialog?.updateTimes();
      });
    },
    handleSiteChange(value) {
      this.currentEntry.site = value;
    },
    handleEquipmentsChange(value) {
      localStorage.setItem('EntryDialog.vue-equipments', JSON.stringify(value));
      this.currentEntry.equipments = value;
    },
    handleWorkTypeChange(value) {
      this.currentEntry.workType = value;
    },
    handleDiameterChange(value) {
      this.currentEntry.diameter = value;
    },

    async undoDelete(copy) {
      copy.deleted = false;
      await timeentriesApi.createTimeEntry(copy);
      this.$emit('dismiss');
    },
    async deleteEntry() {
      const confirm = await this.$confirmDelete(
        this.dialogType === 'timeentry'
          ? this.$t('timer.delete_dialog_time_entry.description')
          : this.$t('timer.delete_dialog_travel_invoice.description'),
        this.dialogType === 'timeentry'
          ? this.$t('timer.delete_dialog_time_entry.title')
          : this.$t('timer.delete_dialog_travel_invoice.title'),
        {
          color: 'error',
        },
      );
      if (!confirm) return null;

      this.deleting = true;

      try {
        const copy = Object.assign({}, this.currentEntry);
        if (this.adminMode) {
          copy.userSubject = this.selectedUser.subject;
        } else {
          if (!copy.userSubject) copy.userSubject = this.$userInfo.subject;
        }

        await timeentriesApi.deleteTimeEntry(this.currentEntry.id);

        this.$store.dispatch('showNotification', {
          color: 'orange',
          timeOut: 10000,
          message: this.$t('timer.entry_deleted_succesfully'),
          showButton: true,
          buttonText: this.$t('undo'),
          callback: () => this.undoDelete(copy),
        });

        await this.reloadTimeEntries();

        this.deleting = false;
        this.dialog = false;
        this.$showSuccessNotification(this.$t('timer.entry_deleted'));
      } catch (error) {
        console.log(error);
        this.deleting = false;
        this.$store.dispatch('showNotification', {
          color: 'error',
          message: this.$t('errors.error_deleting_entry'),
          showButton: false,
        });
      }
    },
    async saveEntry() {
      if (
        this.currentEntry.entryEndTime === null ||
        this.currentEntry.entryStartTime === null ||
        this.currentEntry.entryStartDate === null ||
        this.currentEntry.entryEndDate === null
      ) {
        this.$showErrorNotification(this.$t('timer.check_entry_times'));
        return;
      }
      if (this.TIMEENTRY && this.durationInHours > 24) {
        this.$showErrorNotification(this.$t('timer.cannot_exceed_24_hours'));
        return;
      }
      this.saving = true;

      if (!this.$refs.form.validate()) {
        this.saving = false;
        return;
      }
      const currentEntry = Immutable.fromJS(this.currentEntry).toJS();

      if (this.adminMode) {
        currentEntry.user = this.selectedUser;
        currentEntry.userId = this.selectedUser.id;
      } else {
        if (!currentEntry.userId) currentEntry.userId = this.$userInfo.id;
      }
      if (!currentEntry.siteId && currentEntry.site) currentEntry.siteId = currentEntry.site.id;

      currentEntry.entryStart = dayjs(`${currentEntry.entryStartDate} ${currentEntry.entryStartTime}`);

      if (currentEntry.entryEndDate && currentEntry.entryEndTime) {
        currentEntry.entryEnd = dayjs(`${currentEntry.entryEndDate} ${currentEntry.entryEndTime}`);
      }

      let upsertedEntry = null;
      try {
        if (currentEntry.id && currentEntry.id > 0) {
          currentEntry.comments = void 0;
          upsertedEntry = await timeentriesApi.updateTimeEntry(currentEntry.id, currentEntry);
        } else {
          currentEntry.comments = void 0;
          upsertedEntry = await timeentriesApi.createTimeEntry(currentEntry);
          if (this.currentEntry.comments && Array.isArray(this.currentEntry.comments)) {
            for (let comment of this.currentEntry.comments) {
              await comment.addComment('TimeEntry', upsertedEntry.id, comment);
            }
          }
        }

        if (upsertedEntry != null && this.newEntryMode) {
          await this.$refs.file?.uploadFiles(upsertedEntry.id);
        }
      } catch (e) {
        this.$showErrorNotification(`${this.$t('generic_error')} - ${e}`);
        console.error(e);
        this.saving = false;
        return;
      }
      this.saving = false;
      if (upsertedEntry != null) {
        this.dialog = false;
      }
      this.$emit('entry-saved', upsertedEntry);
    },
    openCreateSiteDialog() {
      this.$refs.siteDialog.openNew();
    },
    addImage() {
      this.$refs.imageFileInput.click();
    },
    getClosestHalfHourInterval() {
      const nowMinutes = dayjs().minute();
      if (nowMinutes < 45) {
        return dayjs().format('HH:') + (nowMinutes > 15 ? '30' : '00');
      } else {
        return dayjs().add(1, 'hours').format('HH:') + '00';
      }
    },
    createChangedEntryDate(dateType = 'start', appendix = '') {
      const key = `entry${dateType.capitalize()}${appendix}`;
      const d = dayjs(
        `${this.currentEntry[`entry${dateType.capitalize()}Date${appendix}`]} ${
          this.currentEntry[`entry${dateType.capitalize()}Time${appendix}`]
        }`,
      );
      if (d.isValid()) this.currentEntry[key] = d;
    },
    checkIfEntryShouldContainLunch() {
      const lunchStart = dayjs('11:00', 'HH:mm');
      const lunchEnd = dayjs('11:30', 'HH:mm');
      const entryStart = this.currentEntry.entryStart.clone();
      const entryEnd = this.currentEntry.entryEnd.clone();
      const entryStartedBeforeLunchStart = entryStart.isBefore(lunchStart);
      const entryEndAfterLunchEnd = entryEnd.isAfter(lunchEnd);
      if (entryStartedBeforeLunchStart && entryEndAfterLunchEnd) {
        this.currentEntry.containsLunch = true;
      }
    },
    reloadTimeEntries() {
      //this.$emit('reload-time-entries');
    },
  },
  computed: {
    latestEndTime() {
      if (this.existingEntries && this.existingEntries.length) {
        let latest;
        this.existingEntries.forEach(e => {
          if (!latest || e.entryEnd.isAfter(latest)) {
            latest = e.entryEnd;
          }
        });
        return latest;
      }
      return false;
    },
    existingEntries() {
      if (this.entriesByDate && this.entriesByDate.length && this.entriesByDate.some(dayObj => dayjs(dayObj.date).format('YYYY-MM-DD') === dayjs(this.date).format('YYYY-MM-DD'))) {
        return this.entriesByDate.find(dayObject => {
          return dayjs(dayObject.date).format('YYYY-MM-DD') === dayjs(this.date).format('YYYY-MM-DD');
        }).entries.filter(e => e.timeEntryType !== 'M0');
      } else {
        return false;
      }
    },
    hasExistingEntries() {
      return this.existingEntries && this.existingEntries.length;
    },
    existingEntryRanges() {
      if (!this.hasExistingEntries) return false;
      return this.existingEntries.map((e) => ({
        end: e.entryEnd,
        id: e.id,
        start: e.entryStart,
      }));
    },
    sites() {
      return [...this.sitesArray]
        .map(site => {
          site.combinedInfo = `${site.name} ${site.address}`;
          return site;
        })
        .filter(site => (site.status !== 'BILLED' && site.status !== 'DELETED') || this.currentEntry.site?.id === site.id);
    },
    equipments() {
      let equipments = [...this.equipmentsArray];
      equipments = equipments.filter(e => e.status === 'ACTIVE');
      return equipments;
    },
    richerUsers() {
      const users = this.users;
      let filteredUsers = users;
      if (this.$userInfo.email && !this.$userInfo.email.includes('@hubble.fi')) {
        filteredUsers = filteredUsers.filter(user => user.email && !user.email.includes('@hubble.fi'));
      }
      return orderBy(
        filteredUsers.map(user => {
          const userCopy = Object.assign({}, user);
          userCopy.combinedInfo = `${userCopy.firstName} ${userCopy.lastName} ${userCopy.email}`;
          return userCopy;
        }),
        ['combinedInfo'],
        ['asc'],
      );
    },
    approvedCheckedText() {
      const currentEntry = this.currentEntry;
      return [
        currentEntry.approved &&
        [
          this.$t('timer.this_entry_has_been_approved'),
          currentEntry.approvedUser && [currentEntry.approvedUser.lastName, currentEntry.approvedUser.firstName].join(' '),
          this.$formatDateTime(currentEntry.approved),
        ]
          .filter(Boolean)
          .join(' '),

        currentEntry.checked &&
        [
          this.$t('timer.this_entry_has_been_checked'),
          currentEntry.checkedUser && [currentEntry.checkedUser.lastName, currentEntry.checkedUser.firstName].join(' '),
          this.$formatDateTime(currentEntry.checked),
        ]
          .filter(Boolean)
          .join(' '),
      ].filter(Boolean);
    },
    durationInMinutes() {
      if (!this.currentEntry.entryStart) return dayjs.duration(0);

      let diff;
      let start = dayjs(this.currentEntry.entryStart);
      let end = dayjs(this.currentEntry.entryEnd);

      if (end.isBefore(start)) {
        diff = start.diff(end);
      } else {
        diff = end.diff(start);
      }
      return diff / 1000 / 60; //dayjs.duration(diff).asMinutes;
    },
    workTypes() {
      return this.itemsArray.filter(item => item.type === 'työtehtävä' && (!this.freeTimes || this.freeTimes?.indexOf?.(item.name) === -1));
    },
    diameters() {
      return this.itemsArray.filter(item => item.type === 'putki');
    },
    TIMEENTRY() {
      return this.dialogType === 'timeentry';
    },
    TRAVELINVOICE() {
      return this.dialogType === 'travelinvoice';
    },
    approved() {
      return !!this.currentEntry.approved;
    },
  },
  watch: {
    dialog(value) {
      if (!value) {
        this.file = [];
        this.previewImages = [];
        this.selectedUser = null;
        this.entriesByDate = [];
        this.$emit('dismiss');
      }
    },
    durationInMinutes(value) {
      this.currentEntry._partDayPay = false;
      this.currentEntry._homeCountryDayPay = false;
      this.currentEntry._foreignCountryDayPay = false;
      if (value >= 10 * 60) {
        // 10 tuntia
        this.currentEntry._homeCountryDayPay = true;
      } else if (value >= 6 * 60) {
        // yli 6 tuntia
        this.currentEntry._partDayPay = true;
      }
    },
    'currentEntry.partDayPay'(value) {
      if (value) {
        this.currentEntry.homeCountryDayPay = false;
        this.currentEntry.foreignCountryDayPay = false;
      }
    },
    'currentEntry.foreignCountryDayPay'(value) {
      if (value) {
        this.currentEntry.homeCountryDayPay = false;
        this.currentEntry.partDayPay = false;
      }
    },
    'currentEntry.homeCountryDayPay'(value) {
      if (value) {
        this.currentEntry.foreignCountryDayPay = false;
        this.currentEntry.partDayPay = false;
      }
    },
    'currentEntry.entryStartDate'() {
      this.createChangedEntryDate('start');
    },
    'currentEntry.entryStartTime'() {
      this.createChangedEntryDate('start');
    },
    'currentEntry.entryEndDate'() {
      this.createChangedEntryDate('end');
    },
    'currentEntry.entryEndTime'() {
      this.createChangedEntryDate('end');
    },
  },
};
</script>

<style scoped>
.notify-bell {
  position: absolute;
  top: 10px;
  left: 0px;
}
</style>
<style lang='scss'>
.entry-dialog {
  overflow: hidden;
}
</style>
