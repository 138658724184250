<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-dialog
    v-model="dialog"
    persistent
    :fullscreen="$vuetify.breakpoint.xsOnly"
    :max-width="$vuetify.breakpoint.xsOnly ? '' : '600'"
    :hide-overlay="$vuetify.breakpoint.xsOnly"
    :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : ''"
  >
    <v-form style="height: 100%" ref="form">
      <v-card style="height: 100%">
        <v-toolbar dark color="primary">
          <v-toolbar-title>{{
            equipment && equipment.id ? $t('equipments.edit_equipment') : $t('equipments.create_new_equipment')
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="gradient-icons">
          <!--<v-text-field
            :disabled="saving"
            outlined
            dense
            hide-details
            :rules="[$rules.required]"
            validate-on-blur
            type="text"
            class="mb-5 mt-4"
            :label="$t('equipments.name')"
            light
            item-value=""
            autocomplete="off"
            clearable
            v-model="equipment.name"
          >
            <template v-slot:prepend>
              <v-icon>mdi mdi-car-pickup</v-icon>
            </template>
          </v-text-field>-->

          <v-text-field
            tabindex="1"
            :disabled="saving"
            outlined
            dense
            hide-details
            :rules="[$rules.required]"
            validate-on-blur
            type="text"
            class="mb-5 mt-4"
            :label="$t('equipments.make')"
            light
            item-value=""
            autocomplete="off"
            clearable
            v-model="equipment.make"
          >
            <template v-slot:prepend>
              <v-icon>mdi mdi-star-circle-outline</v-icon>
            </template>
          </v-text-field>

          <v-text-field
            tabindex="2"
            :disabled="saving"
            outlined
            dense
            hide-details
            type="text"
            class="mb-5 mt-4"
            :label="$t('equipments.license_number')"
            light
            item-value=""
            autocomplete="off"
            clearable
            v-model="equipment.licenseNumber"
          >
            <template v-slot:prepend>
              <v-icon>mdi mdi-numeric</v-icon>
            </template>
          </v-text-field>
          <!--<v-text-field
            :disabled="saving"
            outlined
            dense
            hide-details
            type="text"
            class="mb-5 mt-4"
            :label="$t('equipments.code')"
            light
            item-value=""
            autocomplete="off"
            clearable
            v-model="equipment.code"
          >
            <template v-slot:prepend>
              <v-icon>mdi mdi-code-string</v-icon>
            </template>
          </v-text-field>-->

          <v-text-field
            tabindex="1"
            :disabled="saving"
            outlined
            dense
            hide-details
            :rules="[$rules.required]"
            validate-on-blur
            type="text"
            class="mb-5 mt-4"
            :label="$t('equipments.serialNumber')"
            light
            item-value=""
            autocomplete="off"
            clearable
            v-model="equipment.serialNumber"
          >
            <template v-slot:prepend>
              <v-icon>mdi mdi-barcode</v-icon>
            </template>
          </v-text-field>

          <v-text-field
            tabindex="1"
            :disabled="saving"
            outlined
            dense
            hide-details
            :rules="[$rules.required]"
            validate-on-blur
            class="mb-5 mt-4"
            :label="$t('equipments.year')"
            light
            item-value=""
            autocomplete="off"
            clearable
            type="number"
            v-model="equipment.year"
          >
            <template v-slot:prepend>
              <v-icon>mdi mdi-calendar-month</v-icon>
            </template>
          </v-text-field>

          <v-autocomplete
            tabindex="3"
            :disabled="saving"
            v-model="equipment.type"
            :rules="[$rules.required]"
            validate-on-blur
            :items="types"
            item-text="name"
            class="mb-5 mt-4"
            :label="$t('equipments.type')"
            outlined
            clearable
            dense
            return-object
          >
            <template v-slot:prepend>
              <v-icon>mdi mdi-truck</v-icon>
            </template>
          </v-autocomplete>
          <v-textarea
            tabindex="4"
            :disabled="saving"
            outlined
            dense
            no-resize
            hide-details
            rows="4"
            class="mb-5 mt-4"
            :label="$t('equipments.description')"
            light
            item-value=""
            autocomplete="off"
            clearable
            v-model="equipment.description"
          >
            <template v-slot:prepend>
              <v-icon>mdi mdi-text</v-icon>
            </template>
          </v-textarea>

          <v-alert :value="showAlert" outlined class="mt-4" type="error">{{ $t('validation.check_all_required_fields') }}.</v-alert>
        </v-card-text>

        <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>

        <v-card-actions>
          <div v-if="equipment.id" style="width: 100%" class="px-2">
            <v-btn text :block="$vuetify.breakpoint.xsOnly" color="error" tabindex="5" @click="softDeleteEquipment" :disabled="saving">
              <span>{{ $t('equipments.delete_equipment') }}</span>
            </v-btn>
          </div>
          <v-spacer></v-spacer>
          <v-btn
            tabindex="6"
            @click="dialog = false"
            :disabled="saving"
            :fab="$vuetify.breakpoint.xsOnly"
            :fixed="$vuetify.breakpoint.xsOnly"
            :bottom="$vuetify.breakpoint.xsOnly"
            :left="$vuetify.breakpoint.xsOnly"
          >
            <v-icon v-if="$vuetify.breakpoint.xsOnly">close</v-icon>
            <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('cancel') }}</span>
          </v-btn>
          <v-btn
            tabindex="7"
            color="primary"
            @click="saveEquipment"
            :loading="saving"
            :fab="$vuetify.breakpoint.xsOnly"
            :fixed="$vuetify.breakpoint.xsOnly"
            :bottom="$vuetify.breakpoint.xsOnly"
            :right="$vuetify.breakpoint.xsOnly"
          >
            <v-icon v-if="$vuetify.breakpoint.xsOnly">check</v-icon>
            <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('save') }}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <confirm-dialog ref="softDeleteEquipment" />
  </v-dialog>
</template>

<script>
import equipmentApi from '@/api/equipment';
import itemsApi from '@/api/item';
import ConfirmDialog from '@/components/ConfirmDialog';
export default {
  name: 'EquipmentDialog',
  components: { ConfirmDialog },
  data() {
    return {
      dialog: false,
      saving: false,
      showAlert: false,
      currentEquipment: {},
      equipment: {
        name: '',
        make: '',
        licenseNumber: '',
        code: '',
        description: '',
        type: '',
      },
      equipmentTemplate: {
        name: '',
        make: '',
        licenseNumber: '',
        code: '',
        description: '',
        type: '',
        status: 'ACTIVE',
      },
      types: [],
    };
  },
  mounted() {
    this.getEquipmentTypes();
  },
  methods: {
    softDeleteEquipment() {
      this.$refs.softDeleteEquipment
        .open(this.$t('equipments.delete_dialog.title'), this.$t('equipments.delete_dialog.description'), {
          color: 'error',
        })
        .then(confirm => {
          if (confirm === true) {
            this.loading = true;
            this.equipment.status = 'DISABLED';
            this.saveEquipment();
            this.loading = false;
            this.dialog = false;
            this.$showSuccessNotification(this.$t('equipments.equipment_deleted'));
          }
        });
    },

    async saveEquipment() {
      if (!this.$refs.form.validate()) {
        this.showAlert = true;
        return;
      }
      if (this.equipment.licenseNumber.length > 0) {
        this.equipment.licenseNumber = this.equipment.licenseNumber.toUpperCase();
      }

      this.saving = true;
      if (this.equipment.id) {
        this.equipment = await equipmentApi.updateEquipment(this.equipment.id, this.equipment);
      } else {
        this.equipment = await equipmentApi.createEquipment(this.equipment);
      }
      this.$emit('save', this.equipment);

      this.saving = false;
      this.dialog = false;
    },
    openEquipment(equipment) {
      this.dialog = true;
      this.equipment = Object.assign({}, equipment);
    },
    openNew() {
      this.dialog = true;
      this.equipment = Object.assign({}, this.equipmentTemplate);
    },
    async getEquipmentTypes() {
      this.types = await itemsApi.getItemsByTypes(['kalustotyyppi']);
    },
  },
  watch: {
    dialog(value) {
      if (!value) {
        this.$refs.form?.reset();
        this.showAlert = false;

        this.$emit('on-dismiss');
      }
    },
  },
};
</script>

<style scoped></style>
