<template>
  <v-app light id="app">
    <confirm-dialog ref="confirm" />
    <template v-if="showApplication">
      <template v-if="loadingOverlay">
        <v-fade-transition>
          <div class="loading-overlay">
            <v-row class="fill-height" justify="center" align="center">
              <v-col cols="4" class="text-center">
                <img style="width: 100px; height: auto; opacity: 0.4" src="/img/hubble-h-logo-pulse.svg" />
              </v-col>
            </v-row>
          </div>
        </v-fade-transition>
      </template>

      <v-snackbar v-model="snackbar" top multi-line :timeout="notification.timeOut" :color="notification.color">
        {{ notification.text }}
        <template v-slot:action>
          <template v-if="notification.showButton">
            <v-btn
              small
              outlined
              text
              @click="
                () => {
                  notification.callback();
                  snackbar = false;
                }
              "
            >
              {{ notification.buttonText }}
            </v-btn>
          </template>
          <template v-else>
            <v-btn icon @click="snackbar = false">
              <v-icon>close</v-icon>
            </v-btn>
          </template>
        </template>
      </v-snackbar>

      <v-navigation-drawer
        style="z-index: 100"
        v-if="shouldMenuBeAvailable"
        v-model="menuOpenState"
        :mini-variant="miniMenu && $vuetify.breakpoint.lgAndUp"
        app
        fixed
        dark
        width="300"
        class="main-menu gradient-icons"
      >
        <v-list class="pa-0 elevation-4" light style="background: rgb(245, 246, 250); position: fixed; top: 0; z-index: 99999">
          <v-list-item style="padding: 2px 10px">
            <v-list-item-avatar @click.stop="toggleMiniMenu" style="margin-top: 10px; margin-bottom: 10px">
              <!--<img style="height: 100%; width: auto;" src="/img/logo.svg" />-->
              <v-icon>mdi mdi-bulldozer</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ $t('title') }}</v-list-item-title>
              <v-list-item-subtitle class="caption" v-if="$isLoggedIn">{{ $userInfo.email }}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn icon @click.stop="toggleMiniMenu" small>
                <v-icon>mdi mdi-chevron-left</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>

        <v-list :style="{ marginBottom: $vuetify.breakpoint.xsOnly && '110px', marginTop: '64px' }" :dense="$vuetify.breakpoint.mdAndDown">
          <template v-if="$userInfo && $userInfo.tenants.length > 1">
            <TenantSelect :miniMenu="miniMenu" />
          </template>

          <template v-if="filteredPrimaryPages.length > 0">
            <v-list-item
              v-for="(page, index) in filteredPrimaryPages"
              :key="index + 'primary'"
              :to="getPageLink(page)"
              @click="closeMenuIfOnSamePage(page.link)"
              exact
            >
              <v-list-item-action>
                <v-icon>{{ page.icon }}</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>{{ page.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </template>

          <template v-if="filteredSecondaryPages.length > 0">
            <v-divider></v-divider>
            <v-subheader v-if="!miniMenu">{{ $t('menu_subheaders.tenant_pages') }}</v-subheader>
            <v-list-item
              v-for="(page, index) in filteredSecondaryPages"
              :key="index + 'secondary'"
              @click="closeMenuIfOnSamePage(page.link)"
              :to="getPageLink(page)"
              exact
            >
              <v-list-item-action>
                <v-icon>{{ page.icon }}</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>{{ page.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </template>

          <template v-if="filteredTenantPages.length > 0">
            <v-divider></v-divider>
            <v-subheader v-if="!miniMenu">{{ $t('menu_subheaders.organization_admin') }}</v-subheader>
            <v-list-item
              v-for="(page, index) in filteredTenantPages"
              :key="index + 'tadmin'"
              @click="closeMenuIfOnSamePage(page.link)"
              :to="getPageLink(page)"
              exact
            >
              <v-list-item-action>
                <v-icon>{{ page.icon }}</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>{{ page.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </template>

          <template v-if="filteredAdminPages.length > 0">
            <v-subheader v-if="!miniMenu">{{ $t('menu_subheaders.application_admin') }}</v-subheader>
            <v-list-item
              v-for="(page, index) in filteredAdminPages"
              :key="index + 'admin'"
              @click="closeMenuIfOnSamePage(page.link)"
              :to="getPageLink(page)"
              exact
            >
              <v-list-item-action>
                <v-icon>{{ page.icon }}</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>{{ page.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </template>

          <v-list-item :to="{ name: 'userprofile' }" @click="closeMenuIfOnSamePage('userprofile')">
            <v-list-item-action>
              <v-icon>mdi mdi-cog</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ $t('user_profile.title') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="logout">
            <v-list-item-action>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ $t('login.logout') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-main class="main-bg">
        <router-view />
      </v-main>
      <template v-if="!isUserAdmin">
        <v-bottom-navigation
          v-if="$vuetify.breakpoint.mdAndDown && this.$isLoggedIn && user.type !== 'admin'"
          :value="bottomNavSelected"
          app
          grow
          dark
          background-color="grey darken-4"
        >
          <v-btn @click="Sites">
            <span class="text-caption">{{ $t('tenants.menu.sites') }}</span>
            <v-icon class="mb-1">mdi mdi-shovel</v-icon>
          </v-btn>

          <v-btn @click="workTimeCheck">
            <span class="text-caption">{{ $t('tenants.menu.work_times_check') }}</span>
            <v-icon class="mb-1">mdi mdi-calendar-check</v-icon>
          </v-btn>
          <v-btn @click="ownWorkTimes">
            <span class="text-caption">{{ $t('tenants.menu.own_work_times') }}</span>
            <v-icon class="mb-1">mdi mdi-calendar-clock</v-icon>
          </v-btn>
        </v-bottom-navigation>
      </template>
    </template>
    <template v-else>
      <NoTenantsView />
    </template>
  </v-app>
</template>

<script>
import dayjs from 'dayjs';
import 'dayjs/locale/fi';

import * as Sentry from '@sentry/vue';
const advancedFormat = require('dayjs/plugin/advancedFormat');
const isoWeek = require('dayjs/plugin/isoWeek');
const customParseFormat = require('dayjs/plugin/customParseFormat');
const calendar = require('dayjs/plugin/calendar');
const duration = require('dayjs/plugin/duration');
const isBetween = require('dayjs/plugin/isBetween');
const objectSupport = require('dayjs/plugin/objectSupport');
dayjs.extend(customParseFormat);
dayjs.extend(isoWeek);
dayjs.extend(advancedFormat);
dayjs.extend(calendar);
dayjs.extend(duration);
dayjs.extend(isBetween);
dayjs.extend(objectSupport);
dayjs.locale('fi');

import TenantSelect from './components/TenantSelect.vue';
import TenantService from './service/tenant.service';
import NoTenantsView from './views/NoTenantsView.vue';
import ConfirmDialog from '@/components/ConfirmDialog';
import UiEventBus from '@/UiEventBus';

export default {
  components: { TenantSelect, NoTenantsView, ConfirmDialog },
  data() {
    return {
      user: {
        type: 'admin',
      },
      notification: {
        color: '',
        text: '',
        buttonText: '',
        showButton: false,
        timeOut: -1,
        callback: () => {},
      },
      snackbar: false,
      miniMenu: false,
      bottomNavSelected: '',
      showBottomNav: true,
      menuOpenState: false,
      pages: [
        {
          title: this.$t('timer.title'),
          icon: 'mdi mdi-clock-outline',
          link: 'tenant_timer',
          params: () => ({ tenantId: TenantService.tenant }),
          roles: ['tenant_basic', 'tenant_admin', 'tenant_manager'],
          primary: true,
        },
        {
          title: this.$t('tenants.menu.sites'),
          icon: 'mdi mdi-shovel',
          link: 'tenant_worksites',
          params: () => ({ tenantId: TenantService.tenant }),
          roles: ['tenant_admin', 'tenant_basic', 'tenant_manager'],
          primary: true,
        },
        {
          title: this.$t('work_tasks.title'),
          icon: 'mdi mdi-clipboard-outline',
          link: 'tenant_work_tasks_view',
          params: () => ({ tenantId: TenantService.tenant }),
          roles: ['tenant_admin', 'tenant_basic', 'tenant_manager'],
          primary: true,
        },
      ],

      tenantPages: [
        {
          title: this.$t('reports.title'),
          icon: 'mdi mdi-calendar-clock',
          iconSize: '20px',
          link: 'tenant_report',
          params: () => ({ tenantId: TenantService.tenant }),
          roles: ['tenant_admin', 'tenant_manager'],
          primary: true,
        },
        {
          title: this.$t('timer.admin_title'),
          icon: 'mdi mdi-clock-edit-outline',
          link: 'tenant_timer_admin',
          params: () => ({ tenantId: TenantService.tenant }),
          roles: ['tenant_admin'],
          primary: true,
        },
        {
          title: this.$t('worker_licenses.title'),
          icon: 'mdi mdi-credit-card-multiple-outline',
          iconSize: '20px',
          link: 'tenant_worker_licenses',
          params: () => ({ tenantId: TenantService.tenant }),
          roles: ['tenant_admin'],
          primary: true,
        },

        /*{
          title: this.$tc('sites.title', 2),
          icon: 'mdi mdi-map-search-outline',
          iconSize: '20px',
          link: 'tenant_worksites',
          params: () => ({ tenantId: TenantService.tenant }),
          roles: ['tenant_admin'],
          primary: true,
        },*/
        {
          title: this.$tc('projects.title', 2),
          icon: 'mdi mdi-office-building',
          iconSize: '20px',
          link: 'tenant_projects',
          params: () => ({ tenantId: TenantService.tenant }),
          roles: ['tenant_admin'],
          primary: true,
        },
        {
          title: this.$tc('equipments.title', 2),
          icon: 'mdi mdi-truck',
          iconSize: '20px',
          link: 'tenant_equipments',
          params: () => ({ tenantId: TenantService.tenant }),
          roles: ['tenant_admin'],
          primary: true,
        },
        {
          title: this.$tc('items.title', 2),
          icon: 'mdi mdi-clipboard-list-outline',
          iconSize: '20px',
          link: 'tenant_items',
          params: () => ({ tenantId: TenantService.tenant }),
          roles: ['tenant_admin'],
          primary: true,
        },
        {
          title: this.$tc('party.title', 2),
          icon: 'mdi mdi-domain',
          iconSize: '20px',
          link: 'tenant_parties',
          params: () => ({ tenantId: TenantService.tenant }),
          roles: ['tenant_admin'],
          primary: true,
        },
        {
          title: this.$tc('person.title', 2),
          icon: 'mdi mdi-card-account-details-outline',
          iconSize: '20px',
          link: 'tenant_persons',
          params: () => ({ tenantId: TenantService.tenant }),
          roles: ['tenant_admin'],
          primary: true,
        },
        /*{
          title: this.$t('tenants.management_title'),
          icon: 'mdi mdi-cog',
          link: 'tenant_settings',
          params: () => ({ tenantId: TenantService.tenant }),
          roles: ['tenant_admin'],
          primary: false,
        },*/
        {
          title: this.$t('tenants.work_groups'),
          icon: 'mdi mdi-briefcase-account',
          link: 'tenant_workgroups',
          params: () => ({ tenantId: TenantService.tenant }),
          roles: ['tenant_admin'],
          primary: false,
        },
        {
          title: this.$t('tenants.organization_users'),
          icon: 'mdi mdi-badge-account-outline',
          link: 'tenant_workers',
          params: () => ({ tenantId: TenantService.tenant }),
          roles: ['tenant_admin'],
          primary: false,
        },
      ],
      adminPages: [
        {
          title: this.$t('user_management.title'),
          icon: 'mdi mdi-account-multiple',
          link: 'users',
          roles: ['ADMIN'],
          primary: false,
        },
        {
          title: this.$t('tenants.title'),
          icon: 'mdi mdi-briefcase',
          link: 'tenants',
          roles: ['ADMIN'],
          primary: false,
        },
      ],
    };
  },
  methods: {
    Sites() {
      this.$router.push({ name: 'tenant_worksites' });
    },
    workTimeCheck() {
      this.$router.push({ name: 'tenant_worktimecheck' });
    },
    ownWorkTimes() {
      this.$router.push({ name: 'tenant_worktimes' });
    },
    getPageLink(page) {
      const link = {
        name: page.link,
      };

      if ('params' in page) {
        link.params = page.params();
      }
      return link;
    },
    logout() {
      UiEventBus.$emit('userLoggedOut');
    },
    goToLink(name) {
      this.$router.push({ name: name });
    },
    toggleMiniMenu() {
      if (this.$vuetify.breakpoint.lgAndUp) {
        this.miniMenu = !this.miniMenu;
      } else {
        this.$store.commit('toggleMainMenuState');
      }
    },
    closeMenuIfOnSamePage(link) {
      if (this.$vuetify.breakpoint.mdAndDown && this.$route.name === link) {
        this.menuOpenState = false;
      }
    },
    handleSelectedBottomNavItem(name) {
      const isPagePrimary = this.pages.findIndex(p => p.primary && p.link === name) > -1;
      if (isPagePrimary) {
        this.bottomNavSelected = name;
        this.showBottomNav = true;
      } else {
        this.showBottomNav = false;
      }
    },
  },
  watch: {
    $isLoggedIn(newValue, oldValue) {
      console.log('isLoggedInChange', newValue, oldValue);
      if (newValue === true) {
        UiEventBus.$emit('loginSuccess');
      }
    },
    loadingOverlay(to) {
      if (to) {
        setTimeout(() => {
          this.$store.commit('setLoadingOverlay', false);
        }, 2000);
      }
    },
    notificationObject(to) {
      this.notification.color = to.color;
      this.notification.text = to.message;
      this.snackbar = true;
      this.notification.buttonText = to.buttonText;
      this.notification.showButton = to.showButton;
      this.notification.timeOut = to.timeOut;
      this.notification.callback = to.callback;
    },
    menuOpenComputed(to) {
      this.menuOpenState = to;
    },
    menuOpenState(to) {
      if (to !== this.$store.state.mainMenuOpen) {
        this.$store.commit('toggleMainMenuState');
      }
    },
    $route(to, from) {
      if (from && from.name) this.$store.commit('setPreviousRouteName', from.name);
      this.handleSelectedBottomNavItem(to.name);
    },
    currentRouteName(val) {
      if (!this.$isLoggedIn && val !== 'login') {
        this.$router.push({ name: 'login' });
      }
    },
    $isTenantRoute(newValue, oldValue) {
      if (newValue !== oldValue && newValue === true) {
        setTimeout(() => {
          // Joni, tyhmä tapa, mutta tenantin id ei ole vielä axios conffeissa kun tämä suoritetaan. Enkä keksinyt miten pääsisin väliin.
          this.$store.dispatch('reloadWorkTasks');
        }, 1000);
      }
    },
    $tenant(newValue, oldValue) {
      if (newValue !== oldValue && newValue) {
        setTimeout(() => {
          // Joni, tyhmä tapa, mutta tenantin id ei ole vielä axios conffeissa kun tämä suoritetaan. Enkä keksinyt miten pääsisin väliin.
          this.$store.dispatch('reloadWorkTasks');
        }, 1000);
      }
    },
  },
  computed: {
    showApplication() {
      return !this.$isLoggedIn || this.$isAdmin || (this.$userInfo && this.$userInfo.tenants.length > 0);
    },
    loadingOverlay() {
      return this.$store.state.loadingOverlay;
    },
    shouldMenuBeAvailable() {
      return this.$isLoggedIn;
    },
    notificationObject() {
      return this.$store.state.notificationObject;
    },
    menuOpenComputed() {
      let menuState = true;
      if (this.$vuetify.breakpoint.mdAndDown) {
        menuState = this.$store.state.mainMenuOpen;
      }
      return menuState;
    },
    filteredPrimaryPages() {
      if (!this.$tenant) {
        return [];
      }
      let pages = [];
      this.pages.forEach(page => {
        if (page.primary) {
          pages.push(page);
        }
      });
      return pages.filter(page => {
        if (!page.roles || page.roles.length === 0) return true;
        return this.$hasMatchingTenantRoles(page.roles);
      });
    },
    filteredSecondaryPages() {
      if (!this.$tenant) {
        return [];
      }
      let pages = [];
      this.pages.forEach(page => {
        if (!page.primary) {
          pages.push(page);
        }
      });
      return pages.filter(page => {
        if (!page.roles || page.roles.length === 0) return true;
        return this.$hasMatchingTenantRoles(page.roles);
      });
    },
    filteredTenantPages() {
      if (this.$tenant) {
        return this.tenantPages.filter(page => {
          if (!page.roles || page.roles.length === 0) return true;
          return this.$hasMatchingTenantRoles(page.roles);
        });
      }
      return [];
    },
    filteredAdminPages() {
      if (this.$isAdmin) {
        return this.adminPages.filter(page => {
          if (!page.roles || page.roles.length === 0) return true;
          return this.$hasMatchingRoles(page.roles);
        });
      }
      return [];
    },

    currentRouteName() {
      return this.$route.name;
    },
    isUserAdmin() {
      // Katsotaan onko käyttäjä admin ryhmään kuuluva (työnjohto, toimitusjohtaja, konttoriporukka), sen mukaan näytetään menua ja bottom navia
      return false;
    },
  },
  created() {
    // If application is opened from link, and some other tenant id has been saved in tenant service
    // This sets the one from URL
    if ('tenantId' in this.$router.currentRoute.params) {
      TenantService.setTenant(this.$router.currentRoute.params.tenantId);
    }
  },
  async mounted() {
    if (this.$userInfo) {
      if (Sentry) {
        Sentry.setUser({ email: this.$userInfo.email });
      }
    }
    if (this.$vuetify.breakpoint.lgAndUp) this.menuOpenState = true;
    this.handleSelectedBottomNavItem(this.$route.name);
    this.$root.$confirm = this.$refs.confirm.open;
    await this.$store.commit('setUserInfo', this.$userInfo);

    if (this.$isTenantRoute && this.$tenant) await this.$store.dispatch('reloadWorkTasks');
  },
};
</script>

<style lang="scss">
@import './sass/commonstyles.scss';
</style>
