<template>
  <v-dialog v-model="dialog"
            persistent
            :fullscreen="$vuetify.breakpoint.xsOnly"
            :max-width="$vuetify.breakpoint.xsOnly ? '' : '600'"
            :hide-overlay="$vuetify.breakpoint.xsOnly"
            :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : ''">

    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>{{ $t('user_management.edit_user_settings') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>

        <v-form ref="form">
          <v-text-field
            class="mt-5 firstname"
            :label="$t('user_management.first_name')"
            validate-on-blur
            type="text"
            v-model="currentUser.firstName"
          ></v-text-field>

          <v-text-field
            class="mt-5 lastname"
            :label="$t('user_management.last_name')"
            validate-on-blur
            type="text"
            v-model="currentUser.lastName"
          ></v-text-field>

          <v-text-field
            class="mt-5 phone"
            :label="$t('user_management.phone')"
            validate-on-blur
            type="tel"
            v-model="currentUser.phone"
          ></v-text-field>

        </v-form>

        <v-alert :value="showAlert" outlined class="mt-4" type="error">{{ $t('validation.check_all_required_fields') }}.</v-alert>

      </v-card-text>
      <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="default"
               @click="dialog = false"
               :disabled="saving"
               :fab="$vuetify.breakpoint.xsOnly"
               :fixed="$vuetify.breakpoint.xsOnly"
               :bottom="$vuetify.breakpoint.xsOnly"
               :left="$vuetify.breakpoint.xsOnly">
          <v-icon v-if="$vuetify.breakpoint.xsOnly">close</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('cancel') }}</span>
        </v-btn>
        <v-btn color="primary"
               @click="saveUser"
               :loading="saving"
               :fab="$vuetify.breakpoint.xsOnly"
               :fixed="$vuetify.breakpoint.xsOnly"
               :bottom="$vuetify.breakpoint.xsOnly"
               :right="$vuetify.breakpoint.xsOnly">
          <v-icon v-if="$vuetify.breakpoint.xsOnly">check</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('save') }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import userApi from "@/api/user";

  export default {
    name: "EditUserProfileDialog",
    data() {
      return {
        dialog: false,
        saving: false,
        currentUser: {},
        showAlert: false,
      }
    },
    methods: {
      async saveUser() {
        if (!this.$refs.form.validate()) {
          this.showAlert = true;
          return;
        }

        this.saving = true;

        try {

          const user = await userApi.updateUserInfo(this.currentUser.email, this.currentUser.firstName, this.currentUser.lastName, this.currentUser.phone);
          this.dialog = false;
          this.$emit('user-profile-updated', user);
          this.$showSuccessNotification(this.$t('user_management.user_info_updated'));
        } catch (err) {
          this.$handleApiError(this.$t('user_management.user_info_update_failed'));
        }
        this.saving = false;
      },
      openUser(user) {
        this.dialog = true;
        console.log(user);
        this.currentUser = Object.assign({}, user);
      },
    },
    watch: {
      dialog(value) {
        if (!value) {
          this.showAlert = false;
          this.$refs.form.reset();
          this.currentUser = {};
          this.$emit('on-dismiss');
        }
      }
    },
  }
</script>

<style scoped>

</style>
