<template>
  <tr class='grey lighten-4'>
    <td :title='getItemCheckColumnTitle'>
      <v-icon v-if='entry && entry.checked && !entry.approved' class='no-gradient' color='success'>mdi mdi-check
      </v-icon>
      <v-icon v-if='entry && !!entry.approved' class='no-gradient' color='success'>mdi mdi-check-all</v-icon>
      <v-checkbox
        v-if='showSelectionCheckbox'
        v-model='selected'
        class='mt-0 mb-1'
        hide-details
        @click.stop
      >
      </v-checkbox>
      <loading-indicator
        v-if='entry && !(!loadingApprove || (loadingApprove && parentSelectedItems.findIndex(i => i.id === entry.id) === -1))'
        :size='24'
      />
    </td>
    <td :colspan='entry ? 10 : 11'
        :style="{ borderRight: dayIndex % 2 === 0 ? '4px solid #8ddbff' : '4px solid #cbf4ff' }">

      <div style='margin-left: -8px; margin-right: -8px; width: calc(100% + 16px)'>
        <v-card class='my-2' elevation='0' tile>
          <v-card-text class='body-2 py-2'>
            <div>
              <v-row justify='space-between' no-gutters>
                <div class='my-1'>
                                    <span class='mr-3'
                                    >{{ $t('reports.in_total') }}: <strong>{{
                                        inTotal(userSalaryDataForDay)
                                      }} h</strong>
                                    </span>
                  <span class='mr-3'>
                                      {{ $t('reports.overtimes') }} 50%:
                                      <strong>{{ duration(userSalaryDataForDay.overTime50 / 60) }} h</strong>
                                    </span>
                  <span class='mr-6'>
                                      {{ $t('reports.overtimes') }} 100%:
                                      <strong>{{ duration(userSalaryDataForDay.overTime100 / 60) }} h</strong>
                                    </span>
                  <span v-if='userSalaryDataForDay.education > 0' class='mr-6'>
                                      {{ $t('timer.education') }}:
                                      <strong>{{ duration(userSalaryDataForDay.education / 60) }} h</strong>
                                    </span>
                </div>
                <div class='my-1'>
                  <v-row justify='space-between' no-gutters>
                    <template class='pa-0 ma-0'>
                      <v-checkbox v-model='overnightStayInCarPay'
                                  :disabled='!$isTenantAdmin || loading || entry && !!entry.approved'
                                  :label="$t('timer.overnight_stay_in_car')"
                                  class='small-radio ml-4 mr-3 mb-0 pb-0 pt-1 my-n1'
                                  dense
                                  hide-details
                                  @change="confirmEditM0('check')"
                      >
                      </v-checkbox>
                      <v-radio-group v-model='dayPay'
                                     :disabled='!$isTenantAdmin || loading || entry && !!entry.approved'
                                     class='small-radio pt-1 my-n1 pr-n2 mr-n2'
                                     dense
                                     hide-details
                                     row
                                     @change="confirmEditM0('radio')">
                        <v-radio :label="$t('timer.no_travel_pay')" :value='0' dense></v-radio>
                        <v-radio :label="$t('timer.lunch_pay')" :value='1' dense></v-radio>
                        <v-radio :label="$t('timer.part_day_pay')" :value='2' dense></v-radio>
                        <v-radio :label="$t('timer.home_country_day_pay')" :value='3' dense></v-radio>
                        <v-radio :label="$t('timer.foreign_country_day_pay')" :value='4' dense></v-radio>
                      </v-radio-group>
                    </template>
                  </v-row>
                </div>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
        <confirm-dialog ref='confirmEditM0'></confirm-dialog>
      </div>
    </td>
  </tr>
</template>
<script>
import dayjs from 'dayjs';
import numeral from 'numeral';
import timeentriesApi from '@/api/timeentries';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import LoadingIndicator from '@/components/LoadingIndicator';


export default {
  name: 'combined-salary-data-table-row-summary',
  components: { ConfirmDialog, LoadingIndicator },
  props: {
    dayIndex: { type: Number },
    loadingApprove: { type: Boolean },
    parentSelectedItems: { type: Array },
    userSalaryDataForDay: {
      type: Object,
    },
  },
  data() {
    return {
      loading: false,
      unsaved: false,
      overnightStayInCarPay: null,
      dayPay: null,
      selected: false,
    };
  },
  watch: {
    selected(val) {
      this.$emit('handle-selected-change', this.entry, val);
    },
    parentSelectedItems(val) {
      if (!this.entry) return;
      if (val.find((e) => e.id === this.entry.id)) {
        this.selected = true;
      } else {
        this.selected = false;
      }
    },
  },
  computed: {
    showSelectionCheckbox() {
      if (!this.entry) return false;
      const managerSelectable = (!this.entry.checked && this.$isTenantManager && !this.$isTenantAdmin && !this.entry.approved && !this.loadingApprove);
      const adminSelectable = (!this.entry.approved && this.$isTenantAdmin && !this.loadingApprove);
      const notSelectedAsManagerWhileLoading = (this.$isTenantManager && (!this.entry.checked && !this.entry.approved && this.loadingApprove && this.parentSelectedItems.findIndex(i => i.id === this.entry.id) === -1));
      const notSelectedAsAdminWhileLoading = (this.$isTenantAdmin && (!this.entry.approved && this.loadingApprove && this.parentSelectedItems.findIndex(i => i.id === this.entry.id) === -1));
      return (managerSelectable || adminSelectable || notSelectedAsManagerWhileLoading || notSelectedAsAdminWhileLoading);

    },
    entry() {
      return this.userSalaryDataForDay.entries.find(e => e.timeEntryType === 'M0') || null;
    },
    getItemCheckColumnTitle() {
      const item = this.entry || {};

      let title = '';
      if (item.approved) {
        const approvedDateAndUser = [
          item.approvedUser && [item.approvedUser.lastName, item.approvedUser.firstName].join(' '),
          this.$formatDateTime(item.approved),
        ]
          .filter(Boolean)
          .join(', ');
        title = `${this.$t('reports.approved')}: ` + approvedDateAndUser;
      }
      if (item.checked) {
        if (item.approved) title = title + ' - ';
        const checkedDateAndUser = [
          item.checkedUser && [item.checkedUser.lastName, item.checkedUser.firstName].join(' '),
          this.$formatDateTime(item.checked),
        ]
          .filter(Boolean)
          .join(', ');
        title = title + `${this.$t('reports.checked')}: ` + checkedDateAndUser;
      }
      return title;
    },
  },
  methods: {
    confirmEditM0(source) {
      let desc;
      if (source === 'check') {
        desc = this.$tc('reports.change_overnight_stay_pay', this.overnightStayInCarPay ? 2 : 1, { param: `${this.userSalaryDataForDay.user.lastName} ${this.userSalaryDataForDay.user.firstName}` });
      } else {
        let payType;
        switch (this.dayPay) {
          case 0:
            payType = this.$t('timer.no_travel_pay');
            break;
          case 1:
            payType = this.$t('timer.lunch_pay');
            break;
          case 2:
            payType = this.$t('timer.part_day_pay');
            break;
          case 3:
            payType = this.$t('timer.home_country_day_pay');
            break;
          case 4:
            payType = this.$t('timer.foreign_country_day_pay');
            break;
        }
        desc = this.$t('reports.change_day_pays', {
          param: `${this.userSalaryDataForDay.user.lastName} ${this.userSalaryDataForDay.user.firstName}`,
          param2: `${payType}`,
        });
      }
      this.$refs.confirmEditM0
        .open(dayjs(this.userSalaryDataForDay.date).format('ddd DD.MM.YYYY').capitalize(), desc, {
          color: 'primary',
        })
        .then(confirm => {
          if (confirm === true) {
            this.saveDayPayRow();
            this.dialog = false;
          } else {
            this.resetData();
          }
        });
    },
    duration(duration) {
      return duration ? numeral(Number(duration)).format('0.0') : numeral(Number(0)).format('0.0');
    },
    inTotal(userSalaryDataForDay) {
      return this.duration(
        (userSalaryDataForDay.timeEntryMinutes +
          userSalaryDataForDay.m1Minutes +
          userSalaryDataForDay.m2Minutes +
          userSalaryDataForDay.overTimeElement) /
        60,
      );
    },
    async saveDayPayRow() {
      if (!this.$isTenantAdmin) return;
      this.loading = true;
      const payload = this.entry || {
        approved: null,
        comments: '',
        containsLunch: false,
        entryEnd: dayjs(this.userSalaryDataForDay.date).startOf('day').set({ hour: 12, minute: 0, second: 0 }),
        entryStart: dayjs(this.userSalaryDataForDay.date).startOf('day').set({ hour: 12, minute: 0, second: 0 }),
        equipmentIds: [],
        foreignCountryDayPay: false,
        homeCountryDayPay: false,
        id: null,
        info: '',
        lunchPay: false,
        overnightStayInCar: false,
        partDayPay: false,
        siteId: null,
        timeEntryType: 'M0',
        userId: this.userSalaryDataForDay.user.id,
        workType: null,
      };
      const data = {
        ...payload,
        overnightStayInCar: this.overnightStayInCarPay,
        lunchPay: false,
        partDayPay: false,
        homeCountryDayPay: false,
        foreignCountryDayPay: false,
      };
      switch (this.dayPay) {
        case 1:
          data.lunchPay = true;
          break;
        case 2:
          data.partDayPay = true;
          break;
        case 3:
          data.homeCountryDayPay = true;
          break;
        case 4:
          data.foreignCountryDayPay = true;
          break;
        default:
          break;
      }
      let response;
      try {
        if (data.id && data.id > 0) {
          data.comments = void 0;
          response = await timeentriesApi.updateTimeEntry(data.id, data);
        } else {
          data.comments = void 0;
          response = await timeentriesApi.createTimeEntry(data);
        }
        this.$showSuccessNotification(this.$t('reports.entry_saved'));
        response.entryEnd = dayjs(response.entryEnd);
        response.entryStart = dayjs(response.entryStart);
        this.$emit('entry-updated', response);
      } catch (e) {
        this.resetData();
        this.$showErrorNotification(`${this.$t('generic_error')} - ${e}`);
        console.error(e);
      }
      this.loading = false;
    },
    resetData() {
      this.loading = true;
      if (this.entry) {
        this.overnightStayInCarPay = this.entry.overnightStayInCar;
        if (this.entry.lunchPay) {
          this.dayPay = 1;
        } else if (this.entry.partDayPay) {
          this.dayPay = 2;
        } else if (this.entry.homeCountryDayPay) {
          this.dayPay = 3;
        } else if (this.entry.foreignCountryDayPay) {
          this.dayPay = 4;
        } else {
          this.dayPay = 0;
        }
      } else {
        this.overnightStayInCarPay = false;
        this.dayPay = 0;
      }
      this.loading = false;
    },
  },
  mounted() {
    this.resetData();
  },
};
</script>

<style scoped>
::v-deep .small-radio i {
  font-size: 14px !important;
}

::v-deep .small-radio label {
  font-size: 14px !important;
  padding-left: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

::v-deep .small-radio [class*="__ripple"] {
  left: -9px !important;
}
</style>
