<template>
  <v-col cols="12">
    <v-alert
      class="text-left"
      :class="uiComponentProperties.rounded ? 'rounded-b-pill rounded-t-pill' : ''"
      :text="uiComponentProperties.text"
      :value="true"
      v-if="error"
      type="error"
    >
      {{ error }}
    </v-alert>

    <v-alert
      class="text-left"
      :class="uiComponentProperties.rounded ? 'rounded-b-pill rounded-t-pill' : ''"
      :text="uiComponentProperties.text"
      :value="true"
      v-if="isConfirmSignup && !confirmationSent"
      type="error"
    >
      <div>{{ $t('login.confirm_email') }}</div>
      <v-btn :loading="sendingConfirmation" outline d color="error" small class="mt-2" @click="sendConfirmation">{{
        $t('login.resend_confirmation')
      }}</v-btn>
    </v-alert>

    <v-alert
      class="text-left"
      :class="uiComponentProperties.rounded ? 'rounded-b-pill rounded-t-pill' : ''"
      :text="uiComponentProperties.text"
      :value="true"
      v-if="confirmationSent"
      type="info"
    >
      <div>{{ $t('login.confirm_email_sent') }}</div>
    </v-alert>

    <v-form>
      <v-text-field
        v-model="options.username"
        :label="$t('login.username')"
        color="white"
        :rounded="uiComponentProperties.rounded && uiComponentProperties.outlined"
        :outlined="uiComponentProperties.outlined"
        autocomplete="username"
        autofocus
        hide-details
        class="mb-8"
        v-on:keyup.enter="signIn"
      />

      <v-text-field
        v-model="password"
        type="password"
        :label="$t('login.password')"
        color="white"
        :rounded="uiComponentProperties.rounded && uiComponentProperties.outlined"
        :outlined="uiComponentProperties.outlined"
        autocomplete="current-password"
        hide-details
        class="mb-8"
        v-on:keyup.enter="signIn"
      />

      <div class="text-center">
        <v-btn
          large
          :rounded="uiComponentProperties.rounded"
          class="primary--text font-weight-bold"
          block
          color="primary lighten-5"
          :loading="signingIn"
          @click="signIn"
        >
          <v-icon left>mdi mdi-login</v-icon>
          {{ $t('login.sign_in') }}</v-btn
        >

        <div class="text-center font-weight-light mt-4">
          <a v-bind:class="amplifyUI.a" v-on:click="forgot" class="primary--text text--lighten-5">{{ $t('login.forgot_password') }}</a>
        </div>
      </div>
    </v-form>
    <!-- TUSKIN TARVII KUN HENKILÖKUNTA KÄYTTÄÄ TAI SOPIMUSKUMPPANIT
    <div class="login-guide white--text text-center caption mt-5">
      <p>{{ $t('login.by_clicking_you_agree') }} <a href="https://www.hubble.fi" class="primary--text text--lighten-5" target="_blank">{{ $t('login.terms_of_service') }}</a>.</p>
      <p>{{ $t('login.to_learn_more_about_privacy') }} <a href="https://www.hubble.fi" class="primary--text text--lighten-5" target="_blank">{{ $t('login.privacy_policy') }}</a>.</p>
    </div>-->
  </v-col>
</template>

<script>
// import Auth from '@aws-amplify/auth';
import { AmplifyEventBus } from 'aws-amplify-vue';
import * as AmplifyUI from '@aws-amplify/ui';

export default {
  name: 'SignIn',
  props: ['signInConfig', 'isConfirmSignup', 'uiComponentProperties'],
  data() {
    return {
      sendingConfirmation: false,
      confirmationSent: false,
      password: '',
      error: '',
      amplifyUI: AmplifyUI,
      logger: {},
      signingIn: false,
    };
  },
  computed: {
    options() {
      const defaults = {
        header: this.$Amplify.I18n.get('Sign In Account'),
        username: '',
        isSignUpDisplayed: true,
      };
      return Object.assign(defaults, this.signInConfig || {});
    },
  },
  mounted() {
    this.logger = new this.$Amplify.Logger(this.$options.name);
  },
  methods: {
    async sendConfirmation() {
      this.sendingConfirmation = true;
      try {
        await this.$Amplify.Auth.resendSignUp(this.options.username);
        this.confirmationSent = true;
        this.setError(false);
      } catch (e) {
        this.setError(e);
      }
      this.sendingConfirmation = false;
    },
    async signIn(/*event*/) {
      this.options.username = this.options.username.toLowerCase().trim();
      this.signingIn = true;
      try {
        const data = await this.$Amplify.Auth.signIn(this.options.username, this.password);

        this.logger.info('sign in success');
        this.setError(false);
        if (data.challengeName === 'SMS_MFA' || data.challengeName === 'SOFTWARE_TOKEN_MFA') {
          AmplifyEventBus.$emit('localUser', data);
          return AmplifyEventBus.$emit('authState', 'confirmSignIn');
        } else if (data.challengeName === 'NEW_PASSWORD_REQUIRED') {
          AmplifyEventBus.$emit('localUser', data);
          return AmplifyEventBus.$emit('authState', 'requireNewPassword');
        } else if (data.challengeName === 'MFA_SETUP') {
          AmplifyEventBus.$emit('localUser', data);
          return AmplifyEventBus.$emit('authState', 'setMfa');
        } else {
          return AmplifyEventBus.$emit('authState', 'signedIn');
        }
      } catch (e) {
        if (e.code && e.code === 'UserNotConfirmedException') {
          AmplifyEventBus.$emit('localUser', { username: this.options.username });
          AmplifyEventBus.$emit('authState', 'confirmSignUp');
        } else {
          this.setError(e);
        }
      }
      this.signingIn = false;
    },
    forgot: function () {
      AmplifyEventBus.$emit('authState', 'forgotPassword');
    },
    signUp: function () {
      AmplifyEventBus.$emit('authState', 'signUp');
    },
    setError: function (e) {
      if (!e) {
        this.error = '';
        return;
      }
      this.error = this.$Amplify.I18n.get(e.message || e);
      this.logger.error(this.error);
    },
  },
};
</script>
