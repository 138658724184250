<template>
  <v-row class="mb-0">
    <v-col>
      <viewer :options="viewerOptions" @inited="inited" ref="viewer" class="viewer" :images="filteredImages" rebuild :key="executionKey">
        <template slot-scope="scope">
          <v-row no-gutters align="stretch" justify="start">
            <template v-if="scope.images.length > 0">
              <v-col v-for="(fileEntity, index) in scope.images" :key="fileEntity.source" class="pt-2 pr-2">
                <v-card flat tile style="position: relative">
                  <v-img
                    :lazy-src="fileEntity.thumbnail"
                    :src="fileEntity.source"
                    @click="openFullImage(index)"
                    aspect-ratio="1"
                    class="primary"
                    max-width="250"
                    max-height="125"
                    min-width="125"
                  >
                    <template v-slot:placeholder>
                      <v-row align="center" justify="center" class="ma-0 fill-height">
                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <img class="image" :src="fileEntity.thumbnail" style="display: none" :data-source="fileEntity.source" />
                </v-card>
              </v-col>
            </template>
            <!-- FILES -->
            <template v-if="filteredFiles.length > 0">
              <v-col
                v-for="file in filteredFiles"
                :key="file.key"
                cols="6"
                class="pt-2 pr-2"
                max-width="250"
                max-height="250"
                min-width="250"
              >
                <v-card flat tile class="d-flex" style="position: relative">
                  <v-img @click="openFile(file)" aspect-ratio="1" style="cursor: pointer">
                    <template v-slot:placeholder>
                      <v-row align="center" justify="center" class="ma-0 fill-height" style="border: 1px solid #f1f1f1">
                        <v-col cols="12" class="d-flex align-center justify-center">
                          <v-icon style="font-size: 2em">mdi mdi-{{ getContentTypeIcon(file.contentType) }}</v-icon>
                        </v-col>
                        <v-col cols="12" class="d-flex align-center justify-center" style="word-break: break-word; font-size: 0.8em">
                          {{ file.filename }}
                        </v-col>
                      </v-row>
                    </template>
                  </v-img>
                </v-card>
              </v-col>
            </template>
          </v-row>
        </template>
      </viewer>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios';
import '../../node_modules/viewerjs/dist/viewer.css';
import Viewer from 'v-viewer/src/component.vue';
import downloadjs from 'downloadjs';
export default {
  name: 'FileViewer',
  components: { Viewer: Viewer },
  data() {
    return {
      files: [],
      loading: false,
      executionKey: (Math.random() + 1).toString(36).substring(7),
      viewerOptions: {
        inline: false,
        button: false,
        navbar: false,
        title: false,
        toolbar: true,
        tooltip: true,
        movable: true,
        zoomable: true,
        rotatable: true,
        scalable: true,
        transition: false,
        fullscreen: false,
        keyboard: true,
        url: 'data-source',
      },
    };
  },
  props: {
    fileList: Array,
    disabled: Boolean,
  },
  async mounted() {},
  computed: {
    filteredFiles() {
      return this.fileList?.filter?.(file => !file.contentType?.startsWith('image/')) || [];
    },
    filteredImages() {
      return this.fileList?.filter?.(file => file.contentType?.startsWith('image/')) || [];
    },
  },
  methods: {
    getContentTypeIcon(mimeType) {
      const mappings = {
        image: 'file-image',
        audio: 'file-audio',
        video: 'file-video',
        // Documents
        'application/pdf': 'file-pdf',
        'application/msword': 'file-word',
        'application/vnd.ms-word': 'file-word',
        'application/vnd.oasis.opendocument.text': 'file-word',
        'application/vnd.openxmlformats-officedocument.wordprocessingml': 'file-word',
        'application/vnd.ms-excel': 'file-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml': 'file-excel',
        'application/vnd.oasis.opendocument.spreadsheet': 'file-excel',
        'application/vnd.ms-powerpoint': 'file-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml': 'file-powerpoint',
        'application/vnd.oasis.opendocument.presentation': 'file-powerpoint',
        'text/plain': 'file-document',
        'text/html': 'file-code',
        'application/json': 'file-code',
        // Archives
        'application/gzip': 'zip-box',
        'application/zip': 'zip-box',
      };
      for (let key in mappings) {
        // eslint-disable-next-line
        if (mappings.hasOwnProperty(key)) {
          if (mimeType.search(key) === 0) {
            // Found it
            return mappings[key];
          }
        } else {
          return 'file';
        }
      }
    },
    inited(viewer) {
      this.$viewer = viewer;
    },
    openFullImage(index) {
      this.$viewer.view(index);
    },
    async openFile(file) {
      downloadjs(await this.getFile(file), file.filename, file.contentType);
    },
    getBase64(file) {
      return new Promise(resolve => {
        const reader = new FileReader();

        reader.addEventListener('load', () => resolve(reader.result));
        reader.readAsDataURL(file);
      });
    },
    generateNewKey() {
      this.executionKey = (Math.random() + 1).toString(36).substring(7);
    },
    async getFileUrl(file) {
      const url = `${this.$baseUrl}/api/tenant/file/${file.key}`;
      const img = await axios.get(url, { responseType: 'blob' });
      return URL.createObjectURL(new Blob([img.data], { type: file.contentType }));
    },
    async getFileUrlLazy(file) {
      const url = `${this.$baseUrl}/api/tenant/file/${file.key}?thumbnail=true`;
      const img = await axios.get(url, { responseType: 'blob' });
      return URL.createObjectURL(new Blob([img.data], { type: file.contentType }));
    },
    async getFile(file) {
      const url = `${this.$baseUrl}/api/tenant/file/${file.key}`;
      return await axios.get(url, { responseType: 'blob' }).then(r => r.data);
    },
  },
  watch: {
    imageList() {
      this.executionKey = this.generateNewKey();
    },
  },
};
</script>

<style scoped></style>
