<template>
  <v-row class="mb-0">
    <v-col>
      <!--<v-row v-if="fileEntities.length === 0 && $isOnlyTenantBasic">
        <no-content-view
          :description="$t('sites.no_attachments_description_no_rights')"
          title-icon="mdi-file"
          :title="$t('sites.no_attachments_title')"
        />
      </v-row>-->
      <viewer :options="viewerOptions" @inited="inited" ref="viewer" class="viewer" :images="filteredImages" rebuild>
        <template slot-scope="scope">
          <v-row no-gutters align="stretch" justify="start">
            <template v-if="scope.images.length > 0 || previewFiles.length > 0">
              <v-col v-for="(file, index) in scope.images" :key="file.source" cols="6" md="3" class="pt-2 pr-2">
                <v-card flat tile class="d-flex" style="position: relative; border-radius: 5px">
                  <v-img
                    style="border-radius: 5px"
                    :lazy-src="file.thumbnail"
                    :src="file.source"
                    @click="openFullFile(index)"
                    aspect-ratio="1"
                    class="primary"
                  >
                    <template v-slot:placeholder>
                      <v-row align="center" justify="center" class="ma-0 fill-height">
                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>

                  <img class="file" :src="file.thumbnail" style="display: none" :data-source="file.source" />

                  <v-btn
                    v-if="!disabled && !$isOnlyTenantBasic"
                    :disabled="disabled || loading"
                    icon
                    style="position: absolute; top: -2px; right: -2px"
                    ><v-icon size="30" color="error" @click="deleteFile(file)">delete</v-icon></v-btn
                  >
                </v-card>
                <v-col v-if="file.description" cols="12" class="mt-1 pa-0 d-flex" style="word-break: break-word; font-size: 0.8em">
                  {{ file.description }}
                </v-col>
              </v-col>
              <v-col v-for="file in previewFiles" :key="file.index" cols="6" md="3" class="pt-2 pr-2">
                <v-card flat tile class="d-flex" style="position: relative">
                  <v-img style="border-radius: 5px" :src="file.data" aspect-ratio="1" class="primary lighten-2">
                    <template v-slot:placeholder>
                      <v-row align="center" justify="center" class="ma-0 fill-height">
                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <v-progress-circular v-if="file.loading" indeterminate color="grey lighten-5"></v-progress-circular>

                  <v-btn
                    v-if="!disabled && !$isOnlyTenantBasic"
                    :disabled="disabled || loading"
                    icon
                    style="position: absolute; top: -2px; right: -2px"
                    ><v-icon size="30" color="error" @click="deletePreviewFile(file)">delete</v-icon></v-btn
                  >
                </v-card>
              </v-col>
            </template>
            <!-- FILES -->
            <template v-if="filteredFiles.length > 0 || previewFiles.length > 0">
              <v-col v-for="file in filteredFiles" :key="file.key" cols="6" md="3" class="pt-2 pr-2">
                <v-card flat tile class="d-flex" style="position: relative; border-radius: 5px">
                  <v-img @click="openFile(file)" aspect-ratio="1" style="cursor: pointer; border-radius: 5px">
                    <template v-slot:placeholder>
                      <v-row align="center" justify="center" class="ma-0 fill-height" style="border: 1px solid #f1f1f1">
                        <v-col cols="12" class="d-flex align-center justify-center">
                          <v-icon v-if="!downloadingPDF" style="font-size: 2em">mdi mdi-{{ getContentTypeIcon(file.contentType) }}</v-icon>
                        </v-col>
                      </v-row>
                    </template>
                  </v-img>
                  <v-progress-circular
                    v-if="downloadingPDF"
                    indeterminate
                    color="grey lighten-1"
                    style="position: absolute; top: calc(50% - 32px / 2); left: calc(50% - 32px / 2)"
                  ></v-progress-circular>
                  <v-btn
                    v-if="!disabled && !$isOnlyTenantBasic"
                    :disabled="disabled"
                    icon
                    style="position: absolute; top: -2px; right: -2px"
                    ><v-icon size="30" color="error" @click="deleteFile(file)">delete</v-icon></v-btn
                  >
                </v-card>
                <v-col
                  v-if="file.description"
                  cols="12"
                  class="mt-1 pa-0 d-flex align-center justify-center"
                  style="word-break: break-word; font-size: 0.8em"
                >
                  {{ file.description }}
                </v-col>
              </v-col>
            </template>

            <v-col v-if="!$isOnlyTenantBasic" cols="6" md="3" class="pt-2 pr-2">
              <v-card
                :disabled="disabled || loading"
                class="text-xs-center fill-height"
                elevation="2"
                style="cursor: pointer; height: auto; border-radius: 5px"
                @click="addFile"
              >
                <v-img aspect-ratio="1">
                  <template v-slot:placeholder>
                    <v-row class="fill-height" align="center" justify="center">
                      <v-icon v-if="!loading" class="mt-4" size="28">mdi mdi-file-plus</v-icon>
                      <v-progress-circular
                        v-if="loading"
                        indeterminate
                        color="grey"
                        style="position: absolute; top: calc(50% - 32px / 2); left: calc(50% - 32px / 2)"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-card>
            </v-col>
          </v-row>
        </template>
      </viewer>

      <input type="file" style="display: none" :multiple="false" ref="fileInput" @change="fileSelected" :key="executionKey" />
      <add-file-dialog v-if="useDescription" @description-given="onFileFileChanged" ref="addFileDialog" />
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios';
import fileApi from '@/api/file';
import '../../node_modules/viewerjs/dist/viewer.css';
import Viewer from 'v-viewer/src/component.vue';
import downloadjs from 'downloadjs';
import AddFileDialog from '@/components/Sites/AddFileDialog';
export default {
  name: 'FileUpload',
  components: { AddFileDialog, Viewer: Viewer },
  data() {
    return {
      downloadingPDF: false,
      previewFiles: [],
      addedFiles: [],
      fileEntities: [],
      loading: false,
      executionKey: (Math.random() + 1).toString(36).substring(7),

      previewIndex: 0,
      viewerOptions: {
        inline: false,
        button: false,
        navbar: false,
        title: false,
        toolbar: {
          play: false,
          flipHorizontal: false,
          flipVertical: false,
          reset: false,
          zoomIn: true,
          zoomOut: true,
          rotateLeft: true,
          rotateRight: true,
        },
        tooltip: true,
        movable: true,
        zoomable: true,
        rotatable: true,
        scalable: true,
        transition: false,
        fullscreen: false,
        keyboard: true,
        url: 'data-source',
      },
    };
  },
  props: {
    useDescription: {
      type: Boolean,
      default: false,
    },
    fileList: Array,
    object: String,
    objectId: Number,
    field: {
      type: String,
      default: null,
    },
    compress: {
      type: Boolean,
      default: true,
    },
    disabled: Boolean,
    item: Object,
  },
  async mounted() {
    if (this.object && this.objectId) {
      await this.loadFiles();
    }
  },
  computed: {
    filteredImages() {
      return (this.fileList || this.fileEntities)?.filter?.(file => file.contentType?.startsWith('image/'));
    },
    filteredFiles() {
      return (this.fileList || this.fileEntities)?.filter?.(file => !file.contentType?.startsWith('image/'));
    },
  },
  methods: {
    getContentTypeIcon(mimeType) {
      const mappings = {
        image: 'file-image',
        audio: 'file-audio',
        video: 'file-video',
        // Documents
        'application/pdf': 'file-pdf',
        'application/msword': 'file-word',
        'application/vnd.ms-word': 'file-word',
        'application/vnd.oasis.opendocument.text': 'file-word',
        'application/vnd.openxmlformats-officedocument.wordprocessingml': 'file-word',
        'application/vnd.ms-excel': 'file-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml': 'file-excel',
        'application/vnd.oasis.opendocument.spreadsheet': 'file-excel',
        'application/vnd.ms-powerpoint': 'file-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml': 'file-powerpoint',
        'application/vnd.oasis.opendocument.presentation': 'file-powerpoint',
        'text/plain': 'file-document',
        'text/html': 'file-code',
        'application/json': 'file-code',
        // Archives
        'application/gzip': 'zip-box',
        'application/zip': 'zip-box',
      };
      for (let key in mappings) {
        // eslint-disable-next-line
        if (mappings.hasOwnProperty(key)) {
          if (mimeType.search(key) === 0) {
            // Found it
            return mappings[key];
          }
        } else {
          return 'file';
        }
      }
    },
    addFile() {
      this.$refs.fileInput.click();
    },
    inited(viewer) {
      this.$viewer = viewer;
    },
    openFullFile(index) {
      this.$viewer.view(index);
    },
    async openFile(file) {
      this.downloadingPDF = true;
      downloadjs(await this.getFile(file), file.filename, file.contentType);
      this.downloadingPDF = false;
    },
    deletePreviewFile(file) {
      this.previewFiles = this.previewFiles.filter(ig => ig.index !== file.index);
      this.addedFiles = this.addedFiles.filter(ig => ig.previewIndex !== file.index);
    },
    async loadFiles() {
      this.loading = true;
      try {
        if (!this.objectId) {
          this.loading = false;
          return;
        }
        const fileEntities = await fileApi.getFiles(this.objectId, this.object, this.field);
        for (let i = 0; i < fileEntities.length; i++) {
          const file = fileEntities[i];
          file.thumbnail = await this.getFileUrlLazy(file);
          file.source = await this.getFileUrl(file);
          fileEntities[i] = file;
        }
        this.fileEntities = fileEntities;
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },

    async uploadFiles(objectId = this.objectId) {
      for (let i = 0; i < this.addedFiles.length; i++) {
        const file = this.addedFiles[i];
        try {
          await fileApi.uploadFile(objectId, this.object, file.file, file.filename, { field: this.field, compress: this.compress });
        } catch (e) {
          this.$showErrorNotification(this.$t('error_uploading_file'));
          console.error(e);
        }
      }
      this.loadFiles();
    },
    async deleteFile(file) {
      this.loading = true;
      try {
        await fileApi.deleteFile(file.key);
        this.fileEntities = this.fileEntities.filter(img => img.key !== file.key);
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },

    getBase64(file) {
      return new Promise(resolve => {
        const reader = new FileReader();

        reader.addEventListener('load', () => resolve(reader.result));
        reader.readAsDataURL(file);
      });
    },
    fileSelected(fileAddedEvent) {
      if (this.useDescription) {
        this.$refs.addFileDialog.open(fileAddedEvent);
      } else {
        this.onFileFileChanged(fileAddedEvent);
      }
    },
    async onFileFileChanged(fileAddedEvent) {
      const file = {
        file: fileAddedEvent.target.files[0],
        filename: fileAddedEvent.target.value.split('\\').pop(),
      };
      const reader = new FileReader();
      const ind = (this.previewIndex += 1);
      reader.onload = e => {
        const ig = {
          data: e.target.result,
          index: ind,
          loading: false,
        };
        this.previewIndex += 1;
        this.previewFiles.push(ig);
      };
      reader.readAsDataURL(file.file);
      file.previewIndex = ind;
      if (this.objectId) {
        this.loading = true;
        let previewFileIndex = this.previewFiles.findIndex(ig => ig.index !== file.index);
        if (previewFileIndex > -1) {
          this.previewFiles[previewFileIndex].loading = true;
        }
        try {
          const fileResponse = await fileApi.uploadFile(this.objectId, this.object, file.file, file.filename, {
            field: this.field,
            compress: this.compress,
            description: fileAddedEvent.description,
          });
          fileResponse.thumbnail = await this.getFileUrlLazy(fileResponse);
          fileResponse.source = await this.getFileUrl(fileResponse);
          let previewFileIndex = this.previewFiles.findIndex(ig => ig.index !== file.index);
          if (previewFileIndex > -1) {
            this.previewFiles[previewFileIndex].loading = false;
            this.previewFiles.splice(previewFileIndex, 1);
          }
          this.generateNewKey();

          this.fileEntities.push(fileResponse);
        } catch (error) {
          console.log(error);
        }

        this.loading = false;
      } else {
        this.addedFiles.push(file);
      }
    },
    generateNewKey() {
      this.executionKey = (Math.random() + 1).toString(36).substring(7);
    },
    async getFileUrl(file) {
      if (!file.contentType?.startsWith('image/')) return null;
      const data = await this.getFile(file);
      return URL.createObjectURL(new Blob([data], { type: file.contentType }));
    },
    async getFile(file) {
      const url = `${this.$baseUrl}/api/tenant/file/${file.key}`;
      return await axios.get(url, { responseType: 'blob' }).then(r => r.data);
    },
    async getFileUrlLazy(file) {
      if (!file.contentType?.startsWith('image/')) return null;
      const url = `${this.$baseUrl}/api/tenant/file/${file.key}?thumbnail=true`;
      const img = await axios.get(url, { responseType: 'blob' });
      return URL.createObjectURL(new Blob([img.data], { type: file.contentType }));
    },
  },
};
</script>

<style scoped></style>
