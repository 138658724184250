<template>
  <v-autocomplete
    @change="v => this.$emit('change', v)"
    outlined
    clearable
    :hide-details="hideDetails"
    dense
    return-object
    validate-on-blur
    :value="value"
    item-value="id"
    item-text="companyName"
    :label="label"
    :items="parties"
    :disabled="saving"
    :loading="loading"
  >
    <template v-slot:selection="{ item }">
      {{ item.companyName }}
      <span v-if="item.phone" class="ml-2 grey--text"
        ><small>{{ item.phone }}</small></span
      >
    </template>
    <template v-slot:item="data">
      <v-list-item v-bind="data.attrs" v-on="data.on">
        <v-list-item-content>
          <v-list-item-title> {{ data.item.companyName }} </v-list-item-title>
          <v-list-item-subtitle>
            <span v-if="data.item.phone"
              ><small>{{ data.item.phone }}</small></span
            >
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template v-slot:prepend>
      <v-icon>{{ icon }}</v-icon>
    </template>
    <template v-slot:append-outer v-if="value && value.phone">
      <a style="text-decoration: none" :href="`tel:${value.phone}`" @click.stop>
        <v-icon>mdi mdi-phone</v-icon>
      </a>
    </template>
    <template v-slot:append-item v-if="addNewText">
      <v-divider></v-divider>
      <v-list-item @click="addParty">
        <v-list-item-title class="accent--text"> {{ addNewText }} </v-list-item-title>
        <v-list-item-action>
          <v-icon color="accent">add</v-icon>
        </v-list-item-action>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: 'PartyCombobox',
  props: {
    label: {
      required: true,
      type: String,
    },
    loading: Boolean,
    saving: Boolean,
    parties: Array,
    addNewText: {
      type: String,
    },
    value: Object,
    icon: String,
    hideDetails: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    addParty() {
      this.$emit('add-party');
    },
  },
};
</script>

<style scoped></style>
