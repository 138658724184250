<template>
  <v-divider :key="entry.id + '_divider'"
             :class="[entryTimeOverlap(entry.id) && ((!dayEntries[index-1] || (dayEntries[index-1] && entryTimeOverlap(dayEntries[index-1].id))) && (dayEntries[index+1] && entryTimeOverlap(dayEntries[index+1].id))) && 'border-bottom-red']"/>
</template>
<script>

export default {
  name: 'timer-entry-divider',
  props: {
    dayEntries: {},
    entry: {},
    entryTimeOverlap: {},
    index: {}
  }
}
</script>
<style langs='scss' scoped>
.dayRanges-scrollable-content {
  max-height: 100px;
  overflow: scroll;
}

.border-bottom-red {
  border-bottom: 1px solid red;
}
</style>
