import axios from 'axios';
import Helper from '@/helper';
import i18n from '../i18n';

export default {
  async getParties() {
    return await axios({
      url: '/api/tenant/party',
    }).then(r => r.data);
  },
  async getPartiesByType(type) {
    return await axios({
      url: '/api/tenant/party/' + encodeURIComponent(type),
    }).then(r => r.data);
  },
  async getPartiesPaged(/*filter*/ filter = {}, /* v-data-table.options */ options = {}) {
    return await axios({
      url: `/api/tenant/party/paged`,
      params: { ...Helper.tableOptionsToRestPageParams(options), types: filter.types.join(','), search: filter.search },
    }).then(r => r.data);
  },
  async getParty(id) {
    return await axios({
      url: '/api/tenant/party/' + id,
    })
      .then(r => r.data)
      .then(r => {
        if (!r.visitingAddress) r.visitingAddress = {};
        if (!r.billingAddress) r.billingAddress = {};
        return r;
      });
  },
  async updateParty(id, /* Party */ data) {
    return await axios({
      url: '/api/tenant/party/' + id,
      method: 'put',
      data,
    }).then(r => r.data);
  },
  async createParty(data) {
    return await axios({
      url: '/api/tenant/party/',
      method: 'post',
      data,
    }).then(r => r.data);
  },
  getPartyTypes() {
    return [
      { label: i18n.t('party.types.customer'), value: 'customer' },
      { label: i18n.t('party.types.supplier'), value: 'supplier' },
      { label: i18n.t('party.types.employer'), value: 'employer' },
      { label: i18n.t('party.types.contractor'), value: 'contractor' },
    ];
  },
};
