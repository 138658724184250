import axios from 'axios';

export default {
  async getMVR(id) {
    return await axios({
      url: '/api/tenant/mvr/' + id,
    }).then(r => r.data);
  },
  async getMVRsBySite(siteId) {
    return await axios({
      method: 'post',
      url: '/api/tenant/mvr/' + siteId,
    }).then(r => r.data);
  },
  async createMVR(data) {
    return await axios({
      url: '/api/tenant/mvr',
      method: 'post',
      data,
    }).then(r => r.data);
  },
  async updateMVR(id, /* MVR */ data) {
    return await axios({
      url: '/api/tenant/mvr/' + id,
      method: 'put',
      data,
    }).then(r => r.data);
  },
  async markMVRChecked(id ) {
    return await axios({
      url: '/api/tenant/mvr/' + id + '/check',
      method: 'post',
    }).then(r => r.data);
  },
  async markMVRInspected(id ) {
    return await axios({
      url: '/api/tenant/mvr/' + id + '/inspected',
      method: 'post',
    }).then(r => r.data);
  },
  async addNotice(mvrid, notice) {
    return await axios({
      url: '/api/tenant/mvr/' + mvrid + '/notice',
      method: 'post',
      data: notice,
    }).then(r => r.data);
  },
  async updateNotice(mvrid, notice) {
    return await axios({
      url: '/api/tenant/mvr/' + mvrid + '/notice/' + notice.id,
      method: 'put',
      data: notice,
    }).then(r => r.data);
  },
  async markNoticeFixed(mvrid, noticeid) {
    return await axios({
      url: '/api/tenant/mvr/' + mvrid + '/notice/' + noticeid + '/fixed',
      method: 'put',
    }).then(r => r.data);
  },
};
