import axios from 'axios';

export default {
  async getSiteWorkerListReport(siteId) {
    return await axios({
      url: '/api/tenant/report/site-worker-list/' + siteId,
      responseType: 'blob',
    }).then(r => r.data);
  },
  async getSiteDaybookReport(siteId) {
    return await axios({
      url: '/api/tenant/report/site-daybooks/' + siteId,
      responseType: 'blob',
    }).then(r => r.data);
  },
  async getSiteWorkerIntroReport(siteId) {
    return await axios({
      url: 'api/tenant/report/introduction/' + siteId,
      method: 'GET',
      responseType: 'blob',
    }).then(r => r.data);
  },
};
