import axios from 'axios';
import Helper from '@/helper';

export default {
  async getWorkTasksPaged(/*filter*/ filter = {}, /* v-data-table.options */ options = {}) {
    return await axios({
      url: `/api/tenant/worktask/paged`,
      method: 'post',
      params: { ...Helper.tableOptionsToRestPageParams(options) },
      data: filter,
    }).then(r => r.data);
  },
  async getWorkTasks() {
    return await axios({
      url: '/api/tenant/worktask',
    }).then(r => r.data);
  },
  async updateWorkTaskStatus(id, data) {
    return await axios({
      url: `/api/tenant/worktask/${id}/status`,
      method: 'put',
      data,
    }).then(r => r.data);
  },
  async createWorkTask(/*WorkTask*/ workTask) {
    return axios({
      method: 'post',
      url: '/api/tenant/worktask',
      data: workTask,
    }).then(r => r.data);
  },
  async updateWorkTask(id, /*WorkTask*/ workTask) {
    return axios({
      method: 'put',
      url: '/api/tenant/worktask/' + id,
      data: workTask,
    }).then(r => r.data);
  },
  async deleteWorkTask(id) {
    return axios({
      method: 'delete',
      url: '/api/tenant/worktask/' + id,
    });
  },
};
