var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-autocomplete',_vm._b({attrs:{"clearable":!_vm.$attrs.readonly,"outlined":"","dense":"","return-object":"","light":"","hide-details":_vm.hideDetails,"autocomplete":"off","item-value":"id","item-text":function (item) { return [item.name].filter(Boolean).join(' '); },"items":_vm.items,"label":_vm.label,"disabled":_vm.saving || _vm.disabled,"value":_vm.value,"multiple":_vm.multiple,"rules":[_vm.required && _vm.$rules.required].filter(Boolean)},on:{"change":function (value) { return this$1.$emit('change', value); }},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var parent = ref.parent;
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
var disabled = ref.disabled;
var attrs = ref.attrs;
var on = ref.on;
return [_c('span',_vm._g(_vm._b({key:item.id},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item",fn:function(data){return [_c('v-list-item',_vm._g(_vm._b({key:data.item.id},'v-list-item',data.attrs,false),data.on),[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(data.item.name)+" ")])],1)],1)]}},{key:"prepend",fn:function(){return [_vm._t("prepend")]},proxy:true}],null,true)},'v-autocomplete',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }