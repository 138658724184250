<template>
  <v-card :elevation="$vuetify.breakpoint.xsOnly ? 0 : ''" class="site-equipments mb-5">
    <v-card-title>
      {{ $t('sites.equipment_checks.site_equipment') }}
    </v-card-title>
    <v-list v-if="equipments.length > 0">
      <template v-for="equipment in equipments">
        <v-list-item :key="equipment.id">
          <v-list-item-content>
            <v-list-item-title>
              <span>{{ equipment.make }} - {{ equipment.licenseNumber }}</span>
              <v-chip v-if="isEquipmentCheckWaitingForInspector(equipmentChecks[equipment.equipmentCheckIndex])" x-small color="info" class="ml-2 mt-n1">
                {{ $t('sites.equipment_checks.waiting_for_inspection') }}
              </v-chip>
              <v-chip v-if="isEquipmentCheckOk(equipmentChecks[equipment.equipmentCheckIndex])" x-small color="success" class="ml-2 mt-n1">
                {{ $t('sites.equipment_checks.ok') }}
              </v-chip>
              <v-chip v-if="equipmentCheckHasUncheckedFaults(equipmentChecks[equipment.equipmentCheckIndex])" x-small color="error" class="ml-2 mt-n1">
                {{ $t('sites.equipment_checks.fault') }}
              </v-chip>
            </v-list-item-title>
            <template v-if="equipmentChecks[equipment.equipmentCheckIndex]">
              <v-list-item-subtitle>
                {{ $t('sites.equipment_checks.checked_at') }}: {{ $formatDateNoTime(equipmentChecks[equipment.equipmentCheckIndex].createdAt) }},
                {{ equipmentChecks[equipment.equipmentCheckIndex].workerUser.firstName }} {{ equipmentChecks[equipment.equipmentCheckIndex].workerUser.lastName }}
              </v-list-item-subtitle>
              <v-list-item-subtitle v-if="equipmentChecks[equipment.equipmentCheckIndex].inspectorUser">
                {{ $t('sites.equipment_checks.approved_by') }}:
                {{ equipmentChecks[equipment.equipmentCheckIndex].inspectorUser.firstName }} {{ equipmentChecks[equipment.equipmentCheckIndex].inspectorUser.lastName }}
              </v-list-item-subtitle>
            </template>

            <v-list-item-subtitle v-else>
              {{ $t('sites.equipment_checks.not_checked') }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn small outlined @click="selectEquipmentForCheck(equipment)">
              {{ equipment.equipmentCheckIndex > -1 ? $t('sites.equipment_checks.open') : $t('sites.equipment_checks.create') }}
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </template>
    </v-list>
    <v-card-text v-else>
      {{ $t('sites.equipment_checks.no_equipment_assigned_to_the_site') }}
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "SiteEquipmentChecksEquipmentList",
  props: ['site', 'equipmentChecks', 'equipmentCheckSteps'],
  methods: {
    selectEquipmentForCheck(equipment) {
      this.$emit('select-equipment', equipment);
    },
    isEquipmentCheckWaitingForInspector(equipmentCheck) {
      if (equipmentCheck) {
        return !equipmentCheck.inspectorUserId;
      }
      return false;
    },
    isEquipmentCheckOk(equipmentCheck) {
      if (equipmentCheck) {
        if (!this.equipmentCheckHasUncheckedFaults(equipmentCheck)) {
          return !!equipmentCheck.inspectorUserId;
        }
      }
      return false;
    },
    equipmentCheckHasUncheckedFaults(equipmentCheck) {
      if (equipmentCheck) {
        const uncheckedFaults = [];
        this.equipmentCheckSteps.forEach(step => {
          uncheckedFaults.push(equipmentCheck[step] === 'false' && equipmentCheck[step + 'Fixed'] === null);
        });
        return uncheckedFaults.includes(true);
      }
      return false;
    },
  },
  computed: {
    equipments() {
      const equipments = [...this.site.equipments];
      equipments.forEach(equipment => {
        equipment.equipmentCheckIndex = this.equipmentChecks?.findIndex(ec => ec.equipment?.id === equipment.id);
      })
      return equipments;
    }
  }
}
</script>

<style scoped>

</style>
