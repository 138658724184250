import axios from 'axios';

export default {
  async getByAddress(address) {
    return await axios({
      url: '/api/tenant/geocoding/by-address',
      params: {
        address: address,
      },
    }).then(r => r.data);
  },
  async getByLatLng(lat, lng) {
    return await axios({
      url: '/api/tenant/geocoding/by-latlng',
      params: {
        lat,
        lng,
      },
    }).then(r => r.data);
  },
};
