<template>
  <v-autocomplete
    outlined
    dense
    light
    clearable
    :hide-details="hideDetails"
    two-line
    :readonly="readonly"
    return-object
    item-value="id"
    autocomplete="off"
    ref="select"
    :label="label"
    :item-text="
      item =>
        [
          item.name,
          item.address,
          item.project && item.project.name,
          item.project && item.project.orderer && item.project.orderer.companyName,
        ]
          .filter(Boolean)
          .join(' ')
    "
    :value="value"
    :items="filteredSites"
    :disabled="saving"
    :multiple="multiple"
    :rules="[required && $rules.required].filter(Boolean)"
    @change="
      v => {
        this.$refs.select.blur();
        this.$emit('change', v);
      }
    "
  >
    <template v-if="icon" v-slot:prepend>
      <v-icon>mdi mdi-map-legend</v-icon>
    </template>
    <template v-slot:selection="data">
      <v-list-item style="max-width: 100%" two-line class="ml-n4">
        <v-list-item-content>
          <v-list-item-title>{{
            [data.item.project && data.item.project.orderer && data.item.project.orderer.companyName, data.item.name]
              .filter(Boolean)
              .join(', ')
          }}</v-list-item-title>
          <v-list-item-subtitle
            >{{ data.item.address }} <span v-if="data.item.project && data.item.project.name">|</span>
            {{ data.item.project && data.item.project.name }}</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
    </template>
    <template v-slot:item="data">
      <v-list-item two-line v-bind="data.attrs" v-on="data.on">
        <v-list-item-content>
          <v-list-item-title class="text-wrap">{{
            [data.item.project && data.item.project.orderer && data.item.project.orderer.companyName, data.item.name]
              .filter(Boolean)
              .join(', ')
          }}</v-list-item-title>
          <v-list-item-subtitle class="text-wrap"
            >{{ data.item.address }} <span v-if="data.item.project && data.item.project.name">|</span>
            {{ data.item.project && data.item.project.name }}</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: 'SiteCombobox',
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    value: [Object, Array],
    sites: {
      type: Array,
    },
    label: {
      type: String,
      default: '',
    },
    readonly: Boolean,
    saving: Boolean,
    required: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Boolean,
      default: true,
    },
    scheduledSitesOnly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    filteredSites() {
      let sites;
      if (this.scheduledSitesOnly) {
        sites = this.sites.filter(s => s.plannedStartDate !== null);
      } else {
        sites = this.sites;
      }
      return sites;
    },
  },
};
</script>

<style scoped></style>
