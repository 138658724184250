<template>
  <div>
    <div>
      <v-data-table
        id='entries-table'
        :headers='headers'
        :items.sync='combinedSalaryDataAndEntries.salaryData'
        :loading='loading'
        :mobile-breakpoint='0'
        :no-data-text="$t('reports.no_entries')"
        class='gradient-info__table'
        disable-pagination
        hide-default-footer
        item-key='id'
        locale='fi-FI'

      >
        <template v-slot:header.select>
          <v-checkbox
            :indeterminate='showSelectAllAsIndeterminate'
            :value='selectedItems && selectedItems.length === entriesThatCanBeSelected.length'
            @click='toggleAllEntriesSelect'
          ></v-checkbox>
        </template>
        <template v-slot:body='{ items }'>
          <template v-if='items.length > 0 && entries.length > 0'>
            <tbody>
            <template v-for='(day, dayIndex) in items'>
              <tr :key='`${day.date}_divider`'>
                <td
                  v-if='dayIndex > 0'
                  :class="dayIndex % 2 === 0 ? 'accent lighten-4' : 'primary lighten-4'"
                  colspan='12'
                  style='height: 4px; border: 0'
                ></td>
              </tr>
              <tr :key='`${day.date}_divider2`'>
                <td
                  :class="dayIndex % 2 === 0 ? 'primary lighten-4' : 'accent lighten-4'"
                  colspan='12'
                  style='height: 4px; border: 0'
                ></td>
              </tr>

              <template v-for='(userSalaryDataForDay, index) in day.salaryData'>
                <tr v-if='index > 0'
                    :key='`${userSalaryDataForDay.date}_${userSalaryDataForDay.worker.id}_usersalary_divider`'>
                  <td :class="dayIndex % 2 === 0 ? 'primary lighten-4' : 'accent lighten-4'"
                      colspan='11'
                      style='height: 6px; border: 0'
                  >
                  </td>
                </tr>

                <template
                  v-for="(entry, entryIndex) in userSalaryDataForDay.entries.filter(e => e.timeEntryType !== 'M0')"
                >
                  <combined-salary-data-table-row v-if='userSalaryDataForDay.entries.length > 0'
                                                  :key='`${userSalaryDataForDay.date}_${userSalaryDataForDay.user.id}_${entry.id}`'
                                                  :date-cell-row-span='dateCellRowSpan(day)'
                                                  :day='day'
                                                  :day-index='dayIndex'
                                                  :entry='entry'
                                                  :entry-index='entryIndex'
                                                  :index='index'
                                                  :loading-approve='loadingApprove'
                                                  :parent-selected-items='selectedItems'
                                                  :show-add-entry-button='showAddEntryButton'
                                                  :user-salary-data-for-day='userSalaryDataForDay'
                                                  @new-entry-for-user-and-day='newEntryForUserAndDay'
                                                  @open-entry='openEntryInDialog'
                                                  @handle-selected-items='handleSelectedItemChange'
                  />
                </template>
                <combined-salary-data-table-row-summary
                  v-if="userSalaryDataForDay.entries.filter(e => e.timeEntryType !== 'M0').length > 0"
                  :key='`${userSalaryDataForDay.date}_${userSalaryDataForDay.user.id}_${index}`'
                  :day-index='dayIndex'
                  :loading-approve='loadingApprove'
                  :parent-selected-items='selectedItems'
                  :user-salary-data-for-day='userSalaryDataForDay'
                  @entry-updated='bubbleEntryUpdate'
                  @handle-selected-change='handleSelectedItemChange'
                />
              </template>
              <tr v-if='dayIndex === items.length - 1' :key='`${day.date}_divider3`'>
                <td
                  :class="dayIndex % 2 === 0 ? 'primary lighten-4' : 'accent lighten-4'"
                  colspan='12'
                  style='height: 4px; border: 0'
                ></td>
              </tr>
            </template>
            </tbody>
          </template>
          <tbody v-else>
          <tr v-if='!loading'>
            <td class='text-center grey--text' colspan='12'>
              <span>{{ $t('reports.no_entries') }}</span>
            </td>
          </tr>
          <tr v-if='loading'>
            <td class='text-center grey--text' colspan='12'>
              <hb-loading-indicator class='ma-0 pa-0' style='height: 100%' />
            </td>
          </tr>
          </tbody>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import _ from 'lodash';
import CombinedSalaryDataTableRow from '@/components/Report/CombinedSalaryDataTableRow.vue';
import CombinedSalaryDataTableRowSummary from '@/components/Report/CombinedSalaryDataTableRowSummary.vue';
import Immutable from 'immutable';
import moment from 'moment';

export default {
  name: 'CombinedSalaryDataTable',
  props: ['salaryData', 'entries', 'filters', 'loadingApprove', 'parentsSelectedItems', 'loading'],
  components: {
    CombinedSalaryDataTableRowSummary,
    CombinedSalaryDataTableRow,

  },
  data() {
    return {
      selectedItems: [],
      headers: [
        {
          text: this.$t('reports.date'),
          sortable: false,
          align: 'center',
          width: 50,
        },
        {
          value: 'select',
          sortable: false,
          align: 'center',
          width: 50,
        },
        {
          text: this.$t('reports.employee'),
          sortable: false,
          width: 150,
        },
        {
          text: this.$t('reports.start_end'),
          sortable: false,
          width: 100,
        },
        {
          text: this.$t('reports.duration'),
          sortable: false,
          width: 100,
          align: 'right',
        },
        {
          text: this.$t('reports.site'),
          sortable: false,
          width: 250,
        },
        {
          text: this.$t('reports.travel_type'),
          sortable: false,
          width: 20,
        },
        {
          text: this.$t('reports.equipment'),
          sortable: false,
        },
        {
          text: this.$t('timer.work_type', 1),
          sortable: false,
        },
        {
          text: this.$t('reports.diameter'),
          sortable: false,
        },
        {
          text: this.$t('reports.length'),
          sortable: false,
          align: 'right',
        },
        {
          text: '',
          sortable: false,
          width: 25,
        },
      ],
    };
  },
  methods: {
    newEntryForUserAndDay(userId, date) {
      const salaryDayData = this.combinedSalaryDataAndEntries.salaryData.find(salaryDay => salaryDay.date === date.format('YYYY-MM-DD')).salaryData;
      let entriesByDate = [];
      for (const userData in salaryDayData) {
        if (salaryDayData[userData].user.id === parseInt(userId)) {
          entriesByDate.push({
            date: date.format('YYYY-MM-DD'),
            entries: salaryDayData[userData].entries,
          });
        }
      }
      this.$emit('new-entry-for-user-and-day', userId, date, entriesByDate);
    },
    openEntryInDialog(arg) {
      this.$emit('open-entry', arg);
    },
    bubbleEntryUpdate(arg) {
      this.$emit('entry-updated', arg);
    },
    dateCellRowSpan(item) {
      let rowSpan = 0;
      item.salaryData.forEach((sd, index) => {
        if (index > 0) rowSpan = rowSpan + 1;
        rowSpan = rowSpan + sd.entries.filter(e => e.timeEntryType !== 'M0').length + 1;
      });
      return rowSpan;
    },
    handleSelectedItemChange(entry, val) {
      if (val) {
        this.selectedItems.push(entry);
      } else {
        this.selectedItems = this.selectedItems.filter((e) => e.id !== entry.id);
      }
      this.$emit('handle-selected-items', this.selectedItems);
    },
    resetSelectedItems() {
      this.selectedItems = [];
    },
    toggleAllEntriesSelect() {
      if (this.selectedItems?.length < this.entriesThatCanBeSelected.length) {
        this.selectedItems = Immutable.fromJS(this.entriesThatCanBeSelected).toJS();
      } else {
        this.selectedItems = [];
      }
    },
  },
  computed: {
    showAddEntryButton() {
      return (this.filters.filterUser && this.filters.filterUser.length === 1);
    },
    showSelectAllAsIndeterminate() {
      return this.selectedItems?.length > 0 && this.selectedItems?.length < this.entriesThatCanBeSelected.length;
    },
    entriesThatCanBeSelected() {
      return this.entries.filter(entry => {
        return (
          (!entry.checked && this.$isTenantManager && !this.$isTenantAdmin && !entry.approved) ||
          (!entry.approved && this.$isTenantAdmin && !this.loadingApprove) ||
          (this.loadingApprove && this.selectedItems.findIndex(i => i.id === entry.id) === -1)
        );
      });
    },
    combinedSalaryDataAndEntries() {
      const entries = this.entries.filter((entry) => {

        if (this.filters.filterSite.length > 0) {
          return this.filters.filterSite.findIndex((site) => parseInt(entry?.site?.id, 10) === parseInt(site, 10)) > -1;
        }
        return true;
      });

      let salaryData = this.salaryData.salaryData.map((sd) => ({
        ...sd,
        entries: entries.filter(
          (entry) => entry.entryStart.startOf('day').isSame(dayjs(sd.date).startOf('day')) && entry.userId === sd.user.id,
        ),
        ranges: [],
        clashes: {},
      }));
      salaryData = salaryData.filter((sd) => {
        if (sd.entries.length === 0) return false;
        if (this.filters.filterUser.length > 0) {
          return this.filters.filterUser.findIndex((user) => sd.user.id === user) > -1;
        }
        return true;
      });
      salaryData = salaryData.map((sd) => ({
        ...sd,
        ranges: sd.entries?.filter((e) => e.timeEntryType !== 'M0').map((e) => (
          {
            id: e?.id,
            end: e?.entryEnd?.format(),
            start: e?.entryStart?.format(),
          }
        )),
      }));
      salaryData.forEach(sd => {

        if (sd.ranges && sd.ranges.length > 1) {
          sd.ranges.forEach((r) => {
            sd.ranges.filter(r2 => r2.id !== r.id).forEach(r2 => {
              const obj = sd.clashes[r.id] || {};
              if (!sd.clashes[r.id] || (sd.clashes[r.id].start === false)) {
                obj.start = moment(r.start).isBetween(r2.start, r2.end);
              }
              if (!sd.clashes[r.id] || (sd.clashes[r.id].end === false)) {
                obj.end = moment(r.end).isBetween(r2.start, r2.end);
              }
              sd.clashes[r.id] = obj;
            });
          });
        }
      });

      salaryData = _.sortBy(salaryData, ['date', 'user.lastName', 'user.firstName']);

      salaryData = _.groupBy(salaryData, 'date');
      const salaryDataArray = [];
      Object.keys(salaryData).forEach(key => {

        salaryDataArray.push({ date: key, salaryData: salaryData[key] });
      });
      let dates = {};

      salaryDataArray.forEach(sd => {

        if (sd?.salaryData) {
          sd.salaryData.forEach((sd2, sdindex) => {
            let index = sd2.entries.findIndex(e => e.timeEntryType !== 'M0');
            if (index > -1 && !dates[sd.date] && sd2.entries.length > 0) {
              dates[sd.date] = true;
              sd.salaryData[sdindex].entries[index]._firstEntry = true;
            }
          });

        }
      });

      return { combinedData: this.salaryData.combinedData, salaryData: salaryDataArray };
    },
  },
};
</script>

