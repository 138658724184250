<template>
  <hb-basic-page
    :title="$t('worker_licenses.title')"
    fluid
    :loading="loading"
    :fill-height="loading || workerLicenses.length === 0"
    :extension-height="60"
    class="worker-licenses-view"
    :pa-0="$vuetify.breakpoint.xsOnly"
    v-if="$isTenantAdmin"
  >
    <template v-if="$vuetify.breakpoint.smAndUp" v-slot:app-bar-extension>
      <v-btn class="mb-3 ml-n1" @click="createNewWorkerLicense" color="primary">
        <v-icon class="no-gradient" left>mdi-plus</v-icon>
        <span>{{ $t('worker_licenses.create_new') }}</span>
      </v-btn>
    </template>
    <v-col cols="12" class="gradient-icons">
      <v-fade-transition>
        <v-row v-if="!loading" no-gutters>
          <v-btn v-if="$vuetify.breakpoint.xsOnly" @click="createNewWorkerLicense" color="primary" fixed fab bottom right>
            <v-icon class="no-gradient">mdi-plus</v-icon>
          </v-btn>
          <v-col style="position: fixed; z-index: 99; width: calc(100vw - 24px)" cols="12">
            <v-row>
              <v-col cols="12" sm="6" md="4" lg="3" xl="2" :class="$vuetify.breakpoint.xsOnly ? 'pb-0' : ''">
                <v-select
                  v-model="filterCard"
                  :items="cards"
                  :placeholder="$t('worker_licenses.title')"
                  item-text="name"
                  item-value="id"
                  hide-details
                  single-line
                  multiple
                  light
                  solo
                  clearable
                  background-color=""
                  append-icon="filter_list"
                >
                  <template slot="prepend-inner">
                    <v-icon class="mr-2" size="17">mdi mdi-alpha-t</v-icon>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                <v-select
                  v-model="filterWorker"
                  :items="richerWorkers"
                  :placeholder="`${$t('reports.employee')}`"
                  item-text="combinedInfo"
                  item-value="id"
                  multiple
                  hide-details
                  single-line
                  solo
                  clearable
                  background-color=""
                  append-icon="filter_list"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" :style="{ marginTop: $vuetify.breakpoint.xsOnly ? '120px' : '60px' }">
            <template v-if="!loading">
              <v-data-table
                id="worker-licenses-table"
                :items="filteredWorkerLicenses"
                :headers="headers"
                disable-pagination
                hide-default-footer
                locale="fi-FI"
                :no-data-text="$t('worker_licenses.no_licenses')"
                :mobile-breakpoint="0"
                :sort-by.sync="sortBy"
                :sort-desc.sync="descending"
                class="gradient-info__table"
              >
                <template v-slot:body="{ items }">
                  <tbody v-for="item in items" :key="item.id">
                    <tr
                      v-if="items.length > 0"
                      :class="[item._dateError ? `date-${item._dateError}` : ''].filter(Boolean)"
                      @click="openWorkerLicenseInDialog(item)"
                      class="bottom-border"
                      style="cursor: pointer"
                    >
                      <td style="white-space: nowrap">
                        <span v-if="item.worker">{{ item.worker.lastname }} {{ item.worker.firstname }}</span>
                      </td>
                      <td>
                        <span v-if="item.worker">{{ item.card.name }}</span>
                      </td>
                      <td>{{ item.cardNumber }}</td>
                      <td>{{ $formatDateNoTime(item.dateFrom) }}</td>
                      <td :class="[item._dateError ? `date-${item._dateError}` : ''].filter(Boolean)">
                        <span>{{ item._dateTo !== FOR_NOW_VALUE ? $formatDateNoTime(item.dateTo) : $t('worker_licenses.for_now') }}</span>
                        <v-icon small v-if="item._dateError === 'warning'" color="warning" class="no-gradient ml-4"
                          >mdi mdi-exclamation</v-icon
                        >
                        <v-icon small v-if="item._dateError === 'error'" color="error" class="no-gradient ml-4">mdi mdi-alert</v-icon>
                      </td>
                    </tr>
                    <tr v-else>
                      <td colspan="6" class="text-center grey--text">
                        <span>{{ $t('worker_licenses.no_licenses') }}</span>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </template>
          </v-col>
        </v-row>
      </v-fade-transition>
      <worker-license-dialog ref="workerLicenseDialog" @saved="reloadWorkerLicenses" />
    </v-col>
  </hb-basic-page>
</template>

<script>
import dayjs from 'dayjs';
import Immutable from 'immutable';
import itemsApi from '@/api/item';
import workersApi from '@/api/worker';
import workerLicensesApi from '@/api/workerLicence';
import WorkerLicenseDialog from '@/components/WorkerLicenseDialog';
import orderBy from 'lodash.orderby';
const FOR_NOW_VALUE = 999999999;
export default {
  name: 'WorkerLicensesView',
  components: { WorkerLicenseDialog },
  data() {
    return {
      FOR_NOW_VALUE,
      sortBy: 'entryStartTS',
      descending: true,
      loading: true,
      subtleLoading: false,
      searchPhrase: '',
      allEntries: [],
      items: [],
      filterWorker: [],
      filterType: [],
      filterCard: [],
      workers: [],
      workerLicenses: [],
      dateRangePicker: false,

      headers: [
        {
          text: this.$t('worker_licenses.worker'),
          value: 'worker.lastname',
          sortable: true,
        },
        {
          text: this.$t('worker_licenses.card'),
          value: 'card.name',
          sortable: true,
        },
        {
          text: this.$t('worker_licenses.card_number'),
          value: 'cardNumber',
          divider: true,
          sortable: false,
        },

        {
          text: this.$t('worker_licenses.date_from'),
          value: 'dateFrom',
          sortable: true,
        },
        {
          text: this.$t('worker_licenses.date_to'),
          value: '_dateTo',
          sortable: true,
        },
      ],
    };
  },
  methods: {
    createNewWorkerLicense() {
      this.$refs.workerLicenseDialog.open();
    },
    openWorkerLicenseInDialog(workerLicense) {
      this.$refs.workerLicenseDialog.open(workerLicense);
    },

    async reloadCloudUsers() {
      try {
        const users = await this.$api.getCloudUsers();
        this.users = users;
      } catch (error) {
        this.$store.dispatch('showNotification', {
          color: 'error',
          message: this.$t('errors.error_loading_users'),
          timeOut: 10000,
          showButton: true,
          buttonText: this.$t('try_again'),
          callback: () => {
            this.reloadCloudUsers();
          },
        });
        console.log(error);
      }
    },
    async loadImages(entryId) {
      try {
        const fileEntities = await this.$api.getEntryImages(entryId);
        return fileEntities;
      } catch (error) {
        console.log(error);
      }
      return [];
    },
    async reloadAll() {
      if (this.workerLicenses.length === 0) {
        this.loading = true;
        this.subtleLoading = false;
      } else {
        this.loading = false;
        this.subtleLoading = true;
      }

      await Promise.all([
        (async () => {
          await this.reloadWorkers();
        })(),
        (async () => {
          this.items = await itemsApi.getItemsByTypes(['lisenssi']);
        })(),
        (async () => {
          await this.reloadWorkerLicenses();
        })(),
      ]);

      this.loading = false;
      this.subtleLoading = false;
    },
    async reloadWorkers() {
      this.workers = await workersApi.getWorkers();
    },
    async reloadWorkerLicenses() {
      this.workerLicenses = await workerLicensesApi.getWorkerLicences();
    },
  },
  computed: {
    cards() {
      return this.items.filter(item => item.type === 'lisenssi');
    },
    filteredWorkerLicenses() {
      let wls = Immutable.fromJS(this.workerLicenses).toJS();
      const warningDate = dayjs().add(3, 'month').startOf('day');
      const errorDate = dayjs().add(1, 'month').startOf('day');
      wls.forEach(w => {
        w._dateTo = w.dateTo;
        if (w.dateTo) {
          w._dateError = dayjs(w.dateTo).isBefore(errorDate) ? 'error' : dayjs(w.dateTo).isBefore(warningDate) ? 'warning' : void 0;
        }
        if (!w.dateTo) {
          w._dateTo = FOR_NOW_VALUE;
        }
      });
      if (this.filterCard.length > 0) {
        wls = wls.filter(l => this.filterCard.findIndex(c => c === l.card?.id) > -1);
      }
      if (this.filterWorker.length > 0) {
        wls = wls.filter(l => this.filterWorker.findIndex(w => w === l.worker?.id) > -1);
      }

      wls = orderBy(wls, ['worker.lastname', 'worker.firstname', 'dateTo'], ['asc', 'asc', 'asc']);
      return wls;
    },
    richerWorkers() {
      let filteredUsers = this.workers;
      if (this.$userInfo.email && !this.$userInfo.email.includes('@hubble.fi')) {
        filteredUsers = filteredUsers.filter(user => user.email && !user.email.includes('@hubble.fi'));
      }
      return filteredUsers.map(user => {
        const userCopy = Object.assign({}, user);
        userCopy.combinedInfo = `${userCopy.person.lastname} ${userCopy.person.firstname}`;
        return userCopy;
      });
    },
  },
  watch: {
    async filterType() {
      await this.reloadEntries();
    },
    async filterDates() {
      await this.reloadEntries();
    },
  },
  mounted() {
    this.reloadAll();
  },
};
</script>
<style lang="scss">
#entries-table {
  tr.bottom-border td {
    border-bottom: 1px solid var(--v-default-base);
  }
  .hidden-xlsx-only {
    display: none;
  }
}
</style>

<style scoped lang="scss">
#worker-licenses-table {
  tr.date-error {
    td {
      //background-color: var(--v-error-lighten3);
      //color: #fff;
    }
  }
  tr.date-warning {
    td {
      // color: #fff;
      //background-color: var(--v-warning-lighten3);
    }
  }
  td.date-error {
    color: var(--v-error-base);
  }
  td.date-warning {
    color: var(--v-warning-base);
  }
}
</style>
