<template>
  <v-text-field
    :clearable="!$attrs.readonly"
    v-bind="{ ...$attrs }"
    :value="model.value"
    @input="onInput"
    inputmode="numeric"
    ref="input"
    @autoNumeric:formatted="
      () => {
        this.changedByInput = false; // Remove the flag when autonumeric finish formatting
      }
    "
  >
    <template v-for="(index, name) in $slots" v-slot:[name]>
      <slot :name="name" />
    </template>
  </v-text-field>
</template>

<script>
import AutoNumeric from 'autonumeric';
export default {
  name: 'NumericInput',
  components: {},
  data() {
    return {
      model: { value: this.value || null },
      an: null,
      changedByInput: false,
    };
  },
  props: {
    value: {
      default: null,
      validator: function validator(value) {
        return value === null || typeof value === 'string' || value instanceof String || typeof value === 'number';
      },
    },
    options: {
      type: Object,
      default() {
        return {
          decimalCharacter: ',',
          digitGroupSeparator: ' ',
          decimalPlaces: 2,
          allowDecimalPadding: 'floats',
          emptyInputBehavior: 'null',
        };
      },
    },
    raw: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    log(k) {
      console.log(k);
    },
    onInput() {
      this.changedByInput = true;
      let value = parseFloat(this.an.getNumber());
      if (isNaN(value)) value = null;
      this.$emit('input', value);
    },
  },
  mounted() {
    this.$input = this.$refs.input?.$refs.input;
    this.an = new AutoNumeric(this.$input, this.options);
    if (this.an) {
      this.an.set(this.value);
    }
  },
  watch: {
    /*'model.value'(currentValue) {
      this.changedByInput = true;
      let value = parseFloat(this.an.getNumber());
      this.$emit('input', value);
    },*/

    value(newValue) {
      // Pitäisi ajaa tämä, jotta modelin value päivittyisi ulkopuolisilla muutoksilla, mutta sitten hajoaa AutoNumeric kentässä. Testaa vaikka Työmaapäiväkirjalla muuttamalla ko.päivänä-kenttää.
      // this.model.value = newValue;

      if (!this.an || !this.changedByInput) return;
      this.an.set(newValue);
    },
  },
};
</script>

<style scoped></style>
