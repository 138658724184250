var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-autocomplete',{ref:"select",attrs:{"outlined":"","dense":"","light":"","clearable":"","hide-details":_vm.hideDetails,"two-line":"","readonly":_vm.readonly,"return-object":"","item-value":"id","autocomplete":"off","label":_vm.label,"item-text":function (item) { return [
        item.name,
        item.address,
        item.project && item.project.name,
        item.project && item.project.orderer && item.project.orderer.companyName ]
        .filter(Boolean)
        .join(' '); },"value":_vm.value,"items":_vm.filteredSites,"disabled":_vm.saving,"multiple":_vm.multiple,"rules":[_vm.required && _vm.$rules.required].filter(Boolean)},on:{"change":function (v) {
      this$1.$refs.select.blur();
      this$1.$emit('change', v);
    }},scopedSlots:_vm._u([(_vm.icon)?{key:"prepend",fn:function(){return [_c('v-icon',[_vm._v("mdi mdi-map-legend")])]},proxy:true}:null,{key:"selection",fn:function(data){return [_c('v-list-item',{staticClass:"ml-n4",staticStyle:{"max-width":"100%"},attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s([data.item.project && data.item.project.orderer && data.item.project.orderer.companyName, data.item.name] .filter(Boolean) .join(', ')))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(data.item.address)+" "),(data.item.project && data.item.project.name)?_c('span',[_vm._v("|")]):_vm._e(),_vm._v(" "+_vm._s(data.item.project && data.item.project.name))])],1)],1)]}},{key:"item",fn:function(data){return [_c('v-list-item',_vm._g(_vm._b({attrs:{"two-line":""}},'v-list-item',data.attrs,false),data.on),[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-wrap"},[_vm._v(_vm._s([data.item.project && data.item.project.orderer && data.item.project.orderer.companyName, data.item.name] .filter(Boolean) .join(', ')))]),_c('v-list-item-subtitle',{staticClass:"text-wrap"},[_vm._v(_vm._s(data.item.address)+" "),(data.item.project && data.item.project.name)?_c('span',[_vm._v("|")]):_vm._e(),_vm._v(" "+_vm._s(data.item.project && data.item.project.name))])],1)],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }