/* eslint-disable no-param-reassign,no-unused-vars */
import Vue from 'vue';
import moment from 'moment/moment';
import dayjs from 'dayjs';
import md5 from 'md5';

import numeral from 'numeral';
import 'numeral/locales/fi';

numeral.locale('fi');

import adminApi from '../api/admin';
import userApi from '../api/user';
import systemApi from '../api/system';
import tenantApi from '../api/tenant';
import tenantService from '../service/tenant.service';
import store from '../store';
import i18n from '../i18n';
import api from '@/api/api';

import sanitizeFilename from 'sanitize-filename';
import parseFilepath from 'parse-filepath';
import workTaskApi from '@/api/workTask';

moment.locale('fi');

const HelperPlugin = {
  install(/* vue */) {
    Vue.mixin({
      computed: {
        $tenant() {
          const savedTenant = tenantService.tenant;
          if (savedTenant != null) {
            return savedTenant;
          }

          if (savedTenant == null && this.$userInfo && this.$userInfo.tenants.length > 0) {
            return this.$userInfo.tenants[0];
          }

          return null;
        },
        $isTenantRoute() {
          if ('tenantId' in this.$route.params) {
            return true;
          }
          return false;
        },
        $userApi() {
          return userApi;
        },
        $api() {
          return api;
        },
        $adminApi() {
          return adminApi;
        },
        $systemApi() {
          return systemApi;
        },
        $tenantApi() {
          return tenantApi;
        },
        $baseUrl() {
          const hasCustomBaseUrl = process.env.VUE_APP_API_BASEURL.indexOf('undefined') === -1;
          if (hasCustomBaseUrl) {
            return process.env.VUE_APP_API_BASEURL;
          }
          return '';
        },
        $bottomNavigationStyle() {
          if (this.$vuetify.breakpoint.xsOnly) {
            return 'z-index: 5; bottom: 72px';
          }
          return '';
        },
      },
      methods: {
        $isValidEmail(mail) {
          if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
            return true;
          }
          return false;
        },
        $showSuccessNotification(message) {
          store.dispatch('showNotification', {
            color: 'success',
            message,
            showButton: false,
          });
        },
        $showWarningNotification(message) {
          store.dispatch('showNotification', {
            color: 'warning',
            message,
            showButton: false,
          });
        },
        $showErrorNotification(message) {
          const msg = message != null ? message : i18n.t('generic_error');
          store.dispatch('showNotification', {
            color: 'error',
            message: msg,
            showButton: false,
          });
        },
        $numeral: numeral,

        $formatNumber1Dec(num) {
          return this.$numeral(parseFloat(num)).format('0.0');
        },

        $formatNumberInt(num) {
          return this.$numeral(parseFloat(num)).format('0,0');
        },
        $formatNumber(num, format = '0,0.0') {
          return this.$numeral(parseFloat(num)).format(format);
        },
        $formatNumberIntAppendix(num, appendix) {
          return this.$numeral(parseFloat(num)).format('0,0') + ' ' + appendix;
        },

        $formatNumber2DecAppendix(num, appendix) {
          return this.$numeral(parseFloat(num)).format('0,0.[00]') + ' ' + appendix;
        },
        /**
         *
         * @param {!error} err
         * @param {msg} [msg] message to show
         */
        $handleApiError(err, msg) {
          console.log(err);
          if (err.response != null && err.response.data && err.response.data.error_key != null) {
            this.$showErrorNotification(i18n.t(err.response.data.error_key));
          } else {
            this.$showErrorNotification(msg);
          }
        },
        $reloadApp() {
          location.reload();
        },
        $formatTimeStamp(date) {
          return dayjs(date).format('LLLL');
        },
        $formatUnix(date) {
          return dayjs(date).unix();
        },
        $formatDateTime(date) {
          return date ? dayjs(date).format('DD.MM.YYYY HH:mm:ss') : '';
        },
        $formatDateNoTime(date) {
          return date ? dayjs(date).format('DD.MM.YYYY') : '';
        },
        $formatDateNoTimeNoYear(date) {
          return date ? dayjs(date).format('DD.MM') : '';
        },
        $formatYear(date) {
          return date ? dayjs(date).format('YYYY') : '';
        },
        $formatWeekDay(date) {
          return date ? dayjs(date).format('dd').capitalize() : '';
        },
        $formatTimeNoDate(date) {
          if (date) {
            return dayjs(date).format('HH:mm');
          }
        },
        $formatStartEndDate(startDate, endDate) {
          if (!this.$isDateSet(startDate) && !this.$isDateSet(endDate)) return i18n.t('date_not_defined');
          moment.locale(this.$i18n.locale);
          const firstDay = moment(startDate).format('D.M');
          const lastDay = moment(endDate).format('D.M.YYYY');
          return firstDay + ' - ' + lastDay;
        },
        $humanizeDuration(duration, showSeconds = false) {
          if (duration.asHours() > 23) {
            return Math.floor(duration.asHours()) + 'h ' + duration.minutes() + 'min';
          }
          return duration.hours() + 'h ' + duration.minutes() + 'min ' + (showSeconds ? duration.seconds() + 's' : '');
        },
        $humanizeDurationWithoutNegative(duration, showSeconds = false) {
          if (duration.asHours() > 23) {
            return Math.floor(duration.asHours()) + 'h ' + duration.minutes() + 'min';
          }
          return (
            Math.abs(duration.hours()) +
            'h ' +
            Math.abs(duration.minutes()) +
            'min ' +
            (showSeconds ? Math.abs(duration.seconds()) + 's' : '')
          );
        },
        $randomizeColorFromString(string, saturation = 75, lightness = 60) {
          let hash = 0;
          if (string.length > 0) {
            for (var i = 0; i < string.length; i++) {
              hash = string.charCodeAt(i) + ((hash << 5) - hash);
              hash = hash & hash; // Convert to 32bit integer
            }
          }

          if (hash < 0) hash = hash * -1;

          let shortened = hash % 200;
          return `hsl(${shortened}, ${saturation}%, ${lightness}%)`;
        },
        $getImageUrl(imageKey) {
          return `${this.$baseUrl}/api/public/image/${imageKey}`;
        },
        $getGravatarUrl(email, size) {
          const picSize = size ? size : '68';
          const hash = md5(email.toLowerCase().trim());
          return `https://www.gravatar.com/avatar/${hash}?s=${picSize}&d=mm`; // eslint-disable-line no-undef
        },
        $fileNameSanitizer(filename) {
          return FileNameSanitizer(filename);
        },
        $confirm(type, title, message) {
          return this.$root.$confirm(title, message, { color: type });
        },
        $confirmDelete(message, title) {
          return this.$root.$confirm(title ? title : this.$t('delete'), message, { color: 'error' });
        },
        $randomString() {
          return (Math.random() + 1).toString(36).substring(2);
        },
        $snakeCase(str) {
          return str?.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
        },
        $workTaskStatusIcon(status) {
          switch (status) {
            case 'RECEIVED':
              return 'mdi-archive-outline';
            case 'WAITING':
              return 'mdi-timer-sand';
            case 'INPROGRESS':
              return 'mdi-progress-wrench';
            case 'DONE':
              return 'mdi-check';
            default:
              return '';
          }
        },
        $richerUsers(users = []) {
          if (!Array.isArray(users)) return [];
          let filteredUsers = [...users];
          if (this.$userInfo.email && !this.$userInfo.email.includes('@hubble.fi')) {
            filteredUsers = filteredUsers.filter(user => user.email && !user.email.includes('@hubble.fi'));
          }
          return filteredUsers.map(user => {
            const userCopy = Object.assign({}, user);
            userCopy.combinedInfo = `${userCopy.lastName} ${userCopy.firstName} ${userCopy.email}`;
            return userCopy;
          });
        },
        async $loadUsers() {
          try {
            return await this.$api.getCloudUsers();
          } catch (error) {
            this.$store.dispatch('showNotification', {
              color: 'error',
              message: this.$t('errors.error_loading_users'),
              timeOut: 10000,
            });
            console.log(error);
          }
          return null;
        },
        $createPayPeriods() {
          const now = dayjs();
          const payPeriodStart = dayjs('2019-12-30');

          const weekDiff = Math.floor(now.diff(payPeriodStart, 'week') / 2);
          const currentPeriodStart = payPeriodStart
            .clone()
            .add(weekDiff * 2, 'week')
            .startOf('week');

          const currentPeriodEnd = currentPeriodStart.clone().add(2, 'week').add(-1, 'day').endOf('week');

          const dayRanges = [];

          for (let i = 0; i <= 5; i++) {
            let start = currentPeriodStart
              .clone()
              .add(-i * 2, 'week')
              .startOf('week');
            let end = start.clone().add(2, 'week').add(-1, 'day').endOf('week');

            dayRanges.push({
              label: [this.$formatDateNoTime(start), this.$formatDateNoTime(end)].join(' - '),
              dates: [start.toISOString(), end.toISOString()],
            });
          }
          return { filterDates: [currentPeriodStart.toISOString(), currentPeriodEnd.toISOString()], dayRanges };
        },
      },
    });
  },
};
String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};
export default HelperPlugin;

let isIE = false;
if (typeof window !== 'undefined') {
  let ua = window.navigator.userAgent;
  let old_ie = ua.indexOf('MSIE ');
  let new_ie = ua.indexOf('Trident/');

  if (old_ie > -1 || new_ie > -1) {
    isIE = true;
  }
}

function FileNameSanitizer(filename) {
  const dirSeparator = '-----';
  const dRegEx = new RegExp(dirSeparator, 'gi');
  const parsedPath = parseFilepath(filename);

  let name = parsedPath['name'];
  if (!isIE) name = name.normalize();
  const ext = parsedPath['ext'];
  const dir = parsedPath['dirname']; //.replace(/\//gi, dirSeparator)
  name = sanitizeFilename(name, { replacement: '_' }).replace(/[^a-zA-ZäöÄÖåÅ0-9_\-()]/gi, '_');

  // DO not use sanitize on dir
  //dir = sanitize(dir, {replacement: '_'}).replace(dRegEx, '/')

  if (dir.length > 0) return dir + '/' + name + ext;
  else return name + ext;
}
