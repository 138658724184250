<template>
  <div>
    <v-card-subtitle v-if="subtitle" class="pt-2 subtitle-1 text-uppercase font-weight-medium">
      {{ subtitle }}
    </v-card-subtitle>
    <v-card-text :class="[!subtitle && 'mt-5'].filter(Boolean)">
      <v-text-field
        :disabled="saving"
        outlined
        dense
        hide-details
        class="mb-5"
        :label="$t('address.address_1')"
        light
        :value="address.address1"
        autocomplete="off"
        @input="value => this.$emit('change', { name: 'address1', value: value })"
        clearable
      >
        <template v-slot:prepend>
          <v-icon>mdi mdi-home</v-icon>
        </template>
      </v-text-field>

      <v-text-field
        :disabled="saving"
        outlined
        dense
        hide-details
        class="mb-5"
        :label="$t('address.address_2')"
        light
        :value="address.address2"
        autocomplete="off"
        @input="value => this.$emit('change', { name: 'address2', value: value })"
        clearable
      >
        <template v-slot:prepend>
          <v-icon>m</v-icon>
        </template>
      </v-text-field>

      <v-text-field
        :disabled="saving"
        outlined
        dense
        hide-details
        class="mb-5"
        :label="$t('address.address_3')"
        light
        :value="address.address3"
        autocomplete="off"
        @input="value => this.$emit('change', { name: 'address3', value: value })"
        clearable
      >
        <template v-slot:prepend>
          <v-icon>m</v-icon>
        </template>
      </v-text-field>

      <v-text-field
        :disabled="saving"
        outlined
        dense
        type="number"
        hide-details
        class="mb-5"
        :label="$t('address.post_code')"
        light
        :value="address.postcode"
        autocomplete="off"
        @input="value => this.$emit('change', { name: 'postcode', value: value })"
        clearable
      >
        <template v-slot:prepend>
          <v-icon>mdi mdi-numeric</v-icon>
        </template>
      </v-text-field>

      <v-text-field
        :disabled="saving"
        outlined
        dense
        hide-details
        class="mb-5"
        :label="$t('address.city')"
        light
        :value="address.city"
        autocomplete="off"
        @input="value => this.$emit('change', { name: 'city', value: value })"
        clearable
      >
        <template v-slot:prepend>
          <v-icon>mdi mdi-city</v-icon>
        </template>
      </v-text-field>

      <v-text-field
        :disabled="saving"
        outlined
        dense
        hide-details
        :label="$t('address.country')"
        light
        :value="address.country"
        autocomplete="off"
        @input="value => this.$emit('change', { name: 'country', value: value })"
        clearable
      >
        <template v-slot:prepend>
          <v-icon>mdi mdi-earth</v-icon>
        </template>
      </v-text-field>
    </v-card-text>
  </div>
</template>

<script>
export default {
  name: 'AddressCard',
  props: {
    title: String,
    subtitle: String,
    address: Object,
    saving: Boolean,
  },
  data() {
    return {};
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
@import '../sass/variables.scss';
.v-card__title {
  border-top-left-radius: $border-radius-root;
  border-top-right-radius: $border-radius-root;
}
</style>
