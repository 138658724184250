<template>
  <div v-if="dayHasPaidEntries" class="px-3">
    <v-row no-gutters>
      <v-col cols="12">
        <v-checkbox
          v-model="overnightStayInCar"
          :disabled="travelInvoiceOptionsEntry.approved && !!travelInvoiceOptionsEntry.approved || travelInvoiceOptionsEntry.checked && !!travelInvoiceOptionsEntry.checked"
          :label="$t('timer.overnight_stay_in_car')"
          hide-details
          @change="upsertEntry"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-radio-group
          v-model="dayPayOption"
          :disabled="!!travelInvoiceOptionsEntry.approved || !!travelInvoiceOptionsEntry.checked"
          @change="upsertEntry"
        >
          <v-radio :label="$t('timer.no_travel_pay')" :value="0"></v-radio>
          <v-radio :label="$t('timer.lunch_pay')" :value="1"></v-radio>
          <v-radio :label="$t('timer.part_day_pay')" :value="2"></v-radio>
          <v-radio :label="$t('timer.home_country_day_pay')" :value="3"></v-radio>
          <v-radio :label="$t('timer.foreign_country_day_pay')" :value="4"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import timeentriesApi from '@/api/timeentries';
import Immutable from 'immutable';
import dayjs from 'dayjs';

export default {
  name: 'TravelInvoiceOptions',
  props: ['day', 'freeTimes', 'propsUser', 'adminMode'],
  data() {
    return {
      overnightStayInCar: false,
      dayPayOption: 0,
      travelInvoiceOptionsEntry: {},
    };
  },
  methods: {
    async upsertEntry() {
      const currentEntry = Immutable.fromJS(this.travelInvoiceOptionsEntry).toJS();

      currentEntry.lunchPay = false;
      currentEntry.partDayPay = false;
      currentEntry.homeCountryDayPay = false;
      currentEntry.foreignCountryDayPay = false;

      switch (this.dayPayOption) {
        case 1:
          currentEntry.lunchPay = true;
          break;
        case 2:
          currentEntry.partDayPay = true;
          break;
        case 3:
          currentEntry.homeCountryDayPay = true;
          break;
        case 4:
          currentEntry.foreignCountryDayPay = true;
          break;
      }

      currentEntry.overnightStayInCar = this.overnightStayInCar;
      if (!currentEntry.userId) {
        if (this.propsUser && this.adminMode) {
          currentEntry.userId = this.propsUser.id;
        } else {
          currentEntry.userId = this.$userInfo.id;
        }
      }

      try {
        if (currentEntry.id && currentEntry.id > 0) {
          currentEntry.comments = void 0;
          await timeentriesApi.updateTimeEntry(currentEntry.id, currentEntry);
        } else {
          currentEntry.comments = void 0;
          this.travelInvoiceOptionsEntry = await timeentriesApi.createTimeEntry(currentEntry);
        }
      } catch (e) {
        this.resetData();
        this.$showErrorNotification(`${this.$t('generic_error')} - ${e}`);
        console.error(e);
      }
      this.$emit('entry-updated');
    },
    resetData() {
      const entryIndex = this.day.entries.findIndex(entry => entry.timeEntryType === 'M0');
      if (entryIndex > -1) {
        this.travelInvoiceOptionsEntry = this.day.entries[entryIndex];
        this.overnightStayInCar = this.day.entries[entryIndex].overnightStayInCar;
        if (this.day.entries[entryIndex].lunchPay) this.dayPayOption = 1;
        if (this.day.entries[entryIndex].partDayPay) this.dayPayOption = 2;
        if (this.day.entries[entryIndex].homeCountryDayPay) this.dayPayOption = 3;
        if (this.day.entries[entryIndex].foreignCountryDayPay) this.dayPayOption = 4;
      } else {
        this.travelInvoiceOptionsEntry = {
          id: null,
          info: '',
          entryStart: dayjs(this.day.date).startOf('day').set({hour: 12, minute: 0, second: 0}),
          entryEnd: dayjs(this.day.date).startOf('day').set({hour: 12, minute: 0, second: 0}),
          siteId: null,
          equipmentIds: [],
          workType: null,
          containsLunch: false,
          timeEntryType: 'M0',
          homeCountryDayPay: false,
          lunchPay: false,
          partDayPay: false,
          foreignCountryDayPay: false,
          overnightStayInCar: false,
          comments: '',
          approved: null,
        };
      }
    },
  },
  computed: {
    dayHasPaidEntries() {
      return this.day.entries.findIndex(entry => this.freeTimes.indexOf(entry.workType?.name) === -1) > -1;
    },
  },
  mounted() {
    this.resetData();
  },
};
</script>

<style scoped></style>
