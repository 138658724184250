import Vue from 'vue';
import Vuetify from 'vuetify';
import fi from 'vuetify/lib/locale/fi';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  lang: {
    locales: { fi },
    current: 'fi',
  },
  theme: {
    options: {
      customProperties: true,
    },
    dark: false,
    themes: {
      light: {
        primary: '#007090',
        secondary: '#3968a8',
        accent: '#4E87EF',
        error: '#F4511E',
        warning: '#ff9800',
        info: '#40c4ff',
        success: '#66bb6a',
        default: '#dbdbdb',
      },
      dark: {
        primary: '#007090',
        secondary: '#3968a8',
        accent: '#4E87EF',
        error: '#F4511E',
        warning: '#ff9800',
        info: '#40c4ff',
        success: '#66bb6a',
        default: '#dbdbdb',
      },
    },
  },
});
