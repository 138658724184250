<template>
  <hb-basic-page
    :title="$tc('person.title', 2)"
    fluid
    :loading="false"
    :fill-height="true"
    :extension-height="60"
    :search-title="`${$t('person.search')}...`"
    @handle-search="debouncedSearch"
    class="parties-view"
    :no-gutters="true"
    v-if="$isTenantAdmin"
  >
    <template v-if="$vuetify.breakpoint.smAndUp" v-slot:app-bar-extension>
      <v-btn class="mb-3 ml-n1" @click="createNewParty" color="primary">
        <v-icon class="no-gradient" left>mdi-plus</v-icon>
        <span>{{ $t('person.buttons.new_person') }}</span>
      </v-btn>
    </template>

    <v-col cols="12" class="gradient-icons">
      <v-fade-transition>
        <v-row no-gutters>
          <v-btn v-if="$vuetify.breakpoint.xsOnly" @click="createNewParty" color="primary" fab fixed bottom right>
            <v-icon class="no-gradient">mdi-plus</v-icon>
          </v-btn>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="pagedResult.content"
              class="h-100 gradient-info__table"
              :options.sync="options"
              :server-items-length="pagedResult.totalElements"
              :loading="loading"
              :footer-props="{ itemsPerPageOptions: [50, 100, 500, -1] }"
              :items-per-page="50"
              @click:row="editPerson"
              style="cursor: pointer"
            >
            </v-data-table>
          </v-col>
        </v-row>
      </v-fade-transition>
    </v-col>
  </hb-basic-page>
</template>

<script>
import personApi from '@/api/person';
import debounce from 'lodash.debounce';

export default {
  name: 'PersonsView',
  components: {},
  data() {
    return {
      loading: false,
      options: {},
      pagedResult: {},
      searchString: '',
      headers: [
        {
          text: this.$t('person.lastname'),
          value: 'lastname',
        },
        {
          text: this.$t('person.firstname'),
          value: 'firstname',
        },
        {
          text: this.$t('person.email'),
          value: 'email',
        },
        {
          text: this.$t('person.phone'),
          value: 'phone',
        },
        {
          text: this.$t('person.employer'),
          value: 'employer.companyName',
        },
        {
          text: '',
          value: 'actions',
          align: 'end',
          sortable: false,
        },
      ],
      /*filter: ['customer', 'supplier', 'employer', 'contractor'],
      filterOptions: [
        { desc: this.$t('person.types.customer'), value: 'customer' },
        { desc: this.$t('person.types.supplier'), value: 'supplier' },
        { desc: this.$t('person.types.employer'), value: 'employer' },
        { desc: this.$t('person.types.contractor'), value: 'contactor' },
      ],*/
      parties: [],
    };
  },
  created() {
    this.debouncedSearch = debounce(this.handleSearch, 450, { maxWait: 5000 });
  },
  mounted() {
    //this.reloadPersons();
  },
  methods: {
    async reloadPersons() {
      this.loading = true;
      this.pagedResult = await personApi.getPersonsPaged({ searchString: this.searchString || void 0 }, this.options);
      this.loading = false;
    },
    createNewParty() {
      this.$router.push({ name: 'tenant_new_person' });
    },
    handleSearch(e) {
      this.searchString = e;
    },
    handleFilterChange(filters) {
      this.filter = filters;
    },
    editPerson(person) {
      this.$router.push({ name: 'tenant_person', params: { id: person.id, tenantId: this.$tenant } });
    },
  },
  watch: {
    options: {
      handler() {
        this.reloadPersons();
      },
      deep: true,
    },
    searchString() {
      this.reloadPersons();
    },
  },
};
</script>

<style scoped lang="scss"></style>

<style lang="scss"></style>
