import Vue from 'vue';
import Vuex from 'vuex';
import i18n from './i18n.js';
import worktaskApi from '@/api/workTask';
import UserService from '@/service/user.service';
import UiEventBus from '@/UiEventBus';
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLoggedIn: false,
    mainMenuOpen: false,
    notificationObject: {
      color: 'alert',
      message: '',
      timeOut: 4000,
      showButton: false,
      buttonText: '',
      callback: () => {},
    },
    loadingOverlay: false,
    bottomNavigation: false,
    workTasks: [],
    userInfo: {},
    reportViewFilters: {
      filterSite: [],
      filterEquipment: [],
      filterUser: [],
      filterType: undefined,
      filterDates: [],
      filterInvoiced: undefined,
    },
  },
  mutations: {
    setReportViewFilters(state, payload) {
      for (const key in payload) {
        state.reportViewFilters[key] = payload[key];
      }
    },
    setLoggedIn(state, payload) {
      state.isLoggedIn = payload;
    },
    toggleMainMenuState(state) {
      state.mainMenuOpen = !state.mainMenuOpen;
    },
    setPreviousRouteName(state, val) {
      state.previousRouteName = val;
    },
    setLoadingOverlay(state, payload) {
      state.loadingOverlay = payload;
    },
    setWorkTasks(state, { workTasks }) {
      state.workTasks = workTasks;
    },
    setUserInfo(state, payload) {
      state.userInfo = payload;
    },
  },
  actions: {
    setStoreFilters({ commit }, filters) {
      commit('setReportViewFilters', filters);
    },
    showNotification(context, payload) {
      context.state.notificationObject = {
        color: payload.color,
        message: payload.message,
        timeOut: payload.timeOut !== undefined ? payload.timeOut : 4000,
        showButton: payload.showButton !== undefined ? payload.showButton : false,
        buttonText: payload.buttonText !== undefined ? payload.buttonText : '',
        callback: payload.showButton && payload.callback ? payload.callback : () => {},
      };
    },
    async reloadWorkTasks(context) {
      try {
        const userInfo = UserService.getUserInfo();
        if (!userInfo || !userInfo?.id) return;
        const workTasks = (
          await worktaskApi.getWorkTasksPaged({ assignedUserIds: [userInfo.id], statuses: ['RECEIVED', 'WAITING', 'INPROGRESS'] }, {})
        ).content;

        context.commit('setWorkTasks', { workTasks });
      } catch (error) {
        context.dispatch('showNotification', {
          color: 'error',
          message: i18n.t('errors.error_loading_worktasks'),
          timeOut: 10000,
          showButton: true,
          buttonText: i18n.t('try_again'),
          callback: () => {
            context.dispatch('reloadWorkTasks');
          },
        });
        console.log(error);
      }
    },
    async setWorkTaskStatus(context, { workTask, status }) {
      const copyWorkTask = { ...workTask };
      const w = await worktaskApi.updateWorkTaskStatus(workTask.id, { status });
      const windex = context.state.workTasks.findIndex(t => t.id === w.id);
      if (windex > -1) {
        if (status === 'DONE') {
          context.dispatch('showNotification', {
            color: 'success',
            message: i18n.t('work_tasks.messages.marked_done'),
            timeOut: 2000,
            showButton: true,
            buttonText: i18n.t('undo'),
            callback: () => {
              context.dispatch('setWorkTaskStatus', { workTask: copyWorkTask, status: copyWorkTask.status });
            },
          });
        }
        const workTasks = [...context.state.workTasks];
        workTasks[windex] = w;
        context.commit('setWorkTasks', { workTasks: [...workTasks] });
      }
      UiEventBus.$emit('workTasks.changed');
      return w;
    },
  },
  getters: {
    getReportViewFilters(state) {
      return state.reportViewFilters;
    }
  }
});
