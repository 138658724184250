var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.givenEntryClashesWithExisting && _vm.givenEntryClashesWithExisting.length)?_c('v-row',{staticClass:"mb-4 mt-n6",attrs:{"no-gutters":""}},[_c('v-alert',{attrs:{"icon":"mdi-information-outline no-gradient ","outlined":"","type":"warning","width":"100%"}},[_vm._v(" "+_vm._s(_vm.$tc('timer.clash_in_entries', this.givenEntryClashesWithExisting.length === 1 ? 1 : 0) + ': ' + _vm.givenEntryClashesWithExisting.filter(Boolean).join(', '))+" ")])],1):(_vm.givenEntryWillCreateGap)?_c('v-row',{staticClass:"mb-4 mt-n6",attrs:{"no-gutters":""}},[_c('v-alert',{attrs:{"icon":"mdi-information-outline","outlined":"","type":"info","width":"100%"}},[_vm._v(" "+_vm._s(_vm.$t('timer.gap_in_entries') + _vm.otherEntryRangesToString)+" ")])],1):_vm._e(),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":_vm.showDate ? 6 : 12}},[_c('v-dialog',{ref:"startTimeDialog",attrs:{"disabled":_vm.currentEntry.disabled,"persistent":"","width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"title",attrs:{"clearable":_vm.clearable,"disabled":_vm.currentEntry.disabled,"label":_vm.travelInvoice ? _vm.$t('timer.travel_invoice_start_time') : _vm.$t('timer.start_time'),"value":_vm.currentEntry[("entryStartTime" + _vm.appendix)],"dense":"","readonly":""},on:{"change":_vm.entryStartTimeClear}},on),[_c('template',{slot:"prepend"},[_c('div',{staticStyle:{"width":"25px","text-align":"center"}},[_c('v-icon',{attrs:{"color":"grey","size":"18"}},[_vm._v("fas fa-clock")])],1)])],2)]}}]),model:{value:(_vm.startTimeModal),callback:function ($$v) {_vm.startTimeModal=$$v},expression:"startTimeModal"}},[(_vm.startTimeModal)?_c('v-time-picker',{attrs:{"allowed-minutes":[0, 30],"color":"primary","format":"24hr","full-width":"","light":""},model:{value:(_vm.currentEntry[("entryStartTime" + _vm.appendix)]),callback:function ($$v) {_vm.$set(_vm.currentEntry, ("entryStartTime" + _vm.appendix), $$v)},expression:"currentEntry[`entryStartTime${appendix}`]"}},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.startTimeModal = false}}},[_vm._v(_vm._s(_vm.$t('cancel')))]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","elevation":"0"},on:{"click":function($event){_vm.startTimeModal = false}}},[_vm._v(_vm._s(_vm.$t('ok')))])],1):_vm._e()],1)],1),(_vm.showDate)?_c('v-col',{attrs:{"cols":"6"}},[_c('v-dialog',{ref:"startDateDialog",attrs:{"disabled":_vm.currentEntry.disabled,"light":"","persistent":"","width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"ml-2 title",attrs:{"clearable":_vm.clearable,"disabled":_vm.currentEntry.disabled,"value":_vm.$formatDateNoTime(_vm.currentEntry[("entryStartDate" + _vm.appendix)]),"dense":"","readonly":""}},on))]}}],null,false,4220687882),model:{value:(_vm.startDateModal),callback:function ($$v) {_vm.startDateModal=$$v},expression:"startDateModal"}},[_c('v-date-picker',{attrs:{"color":"primary","first-day-of-week":"1","light":"","locale":"fi-fi","scrollable":""},model:{value:(_vm.currentEntry[("entryStartDate" + _vm.appendix)]),callback:function ($$v) {_vm.$set(_vm.currentEntry, ("entryStartDate" + _vm.appendix), $$v)},expression:"currentEntry[`entryStartDate${appendix}`]"}},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.startDateModal = false}}},[_vm._v(_vm._s(_vm.$t('cancel')))]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","elevation":"0"},on:{"click":function($event){_vm.startDateModal = false}}},[_vm._v(_vm._s(_vm.$t('ok')))])],1)],1)],1):_vm._e()],1)],1),_c('v-col',{class:_vm.showDate ? 'pt-0 pb-0' : '',attrs:{"cols":"12"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":_vm.showDate ? 6 : 12}},[_c('v-dialog',{ref:"endTimeDialog",attrs:{"disabled":_vm.currentEntry.disabled,"persistent":"","width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"title",attrs:{"clearable":_vm.clearable,"disabled":_vm.currentEntry.disabled,"label":_vm.travelInvoice ? _vm.$t('timer.travel_invoice_end_time') : _vm.$t('timer.end_time'),"value":_vm.currentEntry[("entryEndTime" + _vm.appendix)],"dense":"","placeholder":"--:--","readonly":""},on:{"change":_vm.entryEndTimeClear}},on),[_c('template',{slot:"prepend"},[_c('div',{staticStyle:{"width":"25px","padding-top":"4px","text-align":"center"}})])],2)]}}]),model:{value:(_vm.endTimeModal),callback:function ($$v) {_vm.endTimeModal=$$v},expression:"endTimeModal"}},[(_vm.endTimeModal)?_c('v-time-picker',{attrs:{"allowed-minutes":[0, 30],"color":"primary","format":"24hr","full-width":"","light":""},model:{value:(_vm.currentEntry[("entryEndTime" + _vm.appendix)]),callback:function ($$v) {_vm.$set(_vm.currentEntry, ("entryEndTime" + _vm.appendix), $$v)},expression:"currentEntry[`entryEndTime${appendix}`]"}},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.endTimeModal = false}}},[_vm._v(_vm._s(_vm.$t('cancel')))]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","elevation":"0"},on:{"click":function($event){_vm.endTimeModal = false}}},[_vm._v(_vm._s(_vm.$t('ok')))])],1):_vm._e()],1)],1),(_vm.showDate)?_c('v-col',{attrs:{"cols":"6"}},[(_vm.showDate)?_c('v-dialog',{ref:"endDateDialog",attrs:{"disabled":_vm.currentEntry.disabled,"persistent":"","width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"ml-2 title",attrs:{"clearable":_vm.clearable,"disabled":_vm.currentEntry.disabled,"value":_vm.$formatDateNoTime(_vm.currentEntry[("entryEndDate" + _vm.appendix)]),"dense":"","placeholder":"--.--.----","readonly":""}},on))]}}],null,false,3678870352),model:{value:(_vm.endDateModal),callback:function ($$v) {_vm.endDateModal=$$v},expression:"endDateModal"}},[_c('v-date-picker',{attrs:{"color":"primary","first-day-of-week":"1","light":"","locale":"fi-fi","scrollable":""},model:{value:(_vm.currentEntry[("entryEndDate" + _vm.appendix)]),callback:function ($$v) {_vm.$set(_vm.currentEntry, ("entryEndDate" + _vm.appendix), $$v)},expression:"currentEntry[`entryEndDate${appendix}`]"}},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.endDateModal = false}}},[_vm._v(_vm._s(_vm.$t('cancel')))]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","elevation":"0"},on:{"click":function($event){_vm.endDateModal = false}}},[_vm._v(_vm._s(_vm.$t('ok')))])],1)],1):_vm._e()],1):_vm._e()],1)],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12"}},[(_vm.dialog)?[_c('duration-dialog',{ref:"durationDialog",attrs:{"containsLunch":_vm.containsLunch,"default-work-hours":_vm.defaultWorkHours,"disabled":_vm.currentEntry.disabled,"end-date":_vm.currentEntry[("entryEndDate" + _vm.appendix)],"end-time":_vm.currentEntry[("entryEndTime" + _vm.appendix)],"start-date":_vm.currentEntry[("entryStartDate" + _vm.appendix)],"start-time":_vm.currentEntry[("entryStartTime" + _vm.appendix)]},on:{"duration":_vm.emitDuration,"on-save":function (value) { return _vm.handleDurationDialogSave(value, _vm.appendix); }}})]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }