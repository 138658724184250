<template>
  <hb-basic-page
    :title="id ? $t('projects.edit_project.title') : $t('projects.new_project.title')"
    :loading="loading"
    :fill-height="loading"
    :back-button="backButton"
    :extension-height="60"
  >
    <template v-if="$vuetify.breakpoint.smAndUp && ($isTenantAdmin || $isTenantManager)" v-slot:app-bar-extension>
      <v-col class="px-0">
        <v-row>
          <div class="ml-2 mb-3">
            <v-btn v-if="id" @click="saveProject" class="primary">
              <v-icon left>mdi mdi-content-save</v-icon>
              <span>{{ $t('save') }}</span>
            </v-btn>
            <v-btn v-if="!id" @click="createProject" class="primary">
              <v-icon left>mdi mdi-content-save</v-icon>
              <span>{{ $t('save') }}</span>
            </v-btn>
          </div>
        </v-row>
      </v-col>
    </template>

    <v-col
      md="8"
      offset-md="2"
      lg="8"
      offset-lg="2"
      class="gradient-icons"
      :class="$vuetify.breakpoint.xsOnly ? 'pa-0 ma-0' : 'mt-5'"
      :style="$vuetify.breakpoint.xsOnly ? 'margin-bottom: 132px' : 'margin-bottom: 65px'"
    >
      <v-card :elevation="$vuetify.breakpoint.xsOnly ? 0 : ''">
        <v-card-text>
          <v-text-field
            :disabled="saving"
            outlined
            dense
            hide-details
            class="mb-5 mt-4"
            :label="$t('projects.name')"
            light
            item-value=""
            autocomplete="off"
            clearable
            v-model="project.name"
          >
            <template v-slot:prepend>
              <v-icon>mdi mdi-office-building</v-icon>
            </template>
          </v-text-field>

          <v-text-field
            v-if="this.project.code !== null"
            disabled
            outlined
            dense
            hide-details
            class="mb-5 mt-4"
            :label="$t('projects.code')"
            light
            item-value=""
            autocomplete="off"
            clearable
            v-model="project.code"
          >
            <template v-slot:prepend>
              <v-icon>mdi mdi-clipboard-account-outline</v-icon>
            </template>
          </v-text-field>

          <v-autocomplete
            outlined
            dense
            hide-details
            class="mb-4 mt-4"
            :label="$t('projects.status')"
            :items="statuses"
            item-text="label"
            light
            autocomplete="off"
            clearable
            v-model="project.status"
          >
            <template v-slot:prepend>
              <v-icon>mdi mdi-help-circle-outline</v-icon>
            </template>
          </v-autocomplete>
          <v-divider></v-divider>
          <party-combobox
            :saving="saving"
            class="mb-5 mt-5"
            :hide-details="true"
            :value="project.orderer"
            :parties="orderers"
            :add-new-text="$t('projects.new_customer')"
            :label="$t('projects.orderer')"
            icon="mdi-office-building"
            @add-party="addNewOrderer"
            @change="handleOrdererChange"
          />
          <person-combobox
            :hide-details="true"
            class="mb-5"
            :saving="saving"
            :value="project.ordererPerson"
            :add-new-text="$t('person.new_person')"
            :label="$t('projects.orderer_contact')"
            :persons="ordererPersons"
            :loading="ordererPersonsLoading"
            @add-person="addNewOrdererPerson"
            @change="handleOrdererPersonChange"
            icon="mdi-account"
            :disabled="!project.orderer"
          />
          <v-divider></v-divider>
          <party-combobox
            :saving="saving"
            class="mb-5 mt-5"
            :hide-details="true"
            :value="project.primeContractor"
            :parties="contractors"
            :add-new-text="$t('projects.add_new_prime_contractor')"
            :label="$t('projects.prime_contractor')"
            icon="mdi-truck"
            @add-party="addNewContractor"
            @change="handlePrimeContractorChange"
          />

          <person-combobox
            class="mb-5"
            :saving="saving"
            :hide-details="true"
            :value="project.primeContractorPerson"
            :add-new-text="$t('person.new_person')"
            :label="$t('projects.prime_contractor_contact')"
            :persons="contractorPersons"
            :loading="contractorPersonsLoading"
            :disabled="!project.primeContractor"
            @add-person="addNewPrimeContractorPerson"
            @change="handlePrimeContractorPersonChange"
            icon="mdi-account"
          />
        </v-card-text>
      </v-card>

      <v-card
        :elevation="$vuetify.breakpoint.xsOnly ? 0 : ''"
        :class="$vuetify.breakpoint.smAndUp ? 'mt-5' : 'mb-16'"
        v-if="project.name.length > 0"
      >
        <v-card-title class="subtitle-1 text-uppercase font-weight-medium v-card-title">
          {{ $t('projects.sites') }}
          <v-spacer></v-spacer>
          <v-btn
            :style="{ height: $vuetify.breakpoint.xsOnly && '48px' }"
            :block="$vuetify.breakpoint.xsOnly"
            :class="$vuetify.breakpoint.xsOnly && 'mt-3'"
            @click="addNewSiteToProject"
            small
            color="primary"
            class="add_site_button"
            ><v-icon left class="no-gradient">mdi mdi-plus</v-icon>{{ $t('projects.add_new_site_to_project') }}</v-btn
          >
        </v-card-title>
        <v-card-text>
          <template v-if="sites.length > 0">
            <template v-for="site in sites">
              <v-list-item style="height: 50px" :key="site.id + '_listitem'" @click="openSite(site)">
                <v-list-item-content class="mr-3" style="flex: initial">
                  <v-chip :color="statusColor(site.status).color" :text-color="statusColor(site.status).textColor">
                    {{ site.status === null ? $t(`sites.statuses.no_status`) : $t(`sites.statuses.${site.status}`) }}
                  </v-chip>
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-title :class="site.status === 0 ? 'grey--text' : ''">{{ site.name }}</v-list-item-title>
                  <v-list-item-subtitle :class="site.status === 0 ? 'grey--text text--darken-1' : ''">{{
                    site.address
                  }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn small @click.stop="removeSiteFromProjectDialog(site)" text fab>
                    <v-icon class="no-gradient" color="error">mdi mdi-delete</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-divider :key="site.id + '_divider'"></v-divider>
            </template>
          </template>

          <template v-if="sites.length === 0">
            <no-content-view
              class=""
              :reload-button="false"
              :function-button="false"
              :title="$t('projects.no_sites_in_project')"
              :description="$t('projects.no_sites_in_project_help')"
              title-icon="mdi mdi-shovel"
            />
          </template>
        </v-card-text>
      </v-card>
    </v-col>
    <v-btn fab fixed right bottom v-if="id && $vuetify.breakpoint.xsOnly" @click="saveProject" class="primary">
      <v-icon>mdi mdi-content-save</v-icon>
    </v-btn>
    <v-btn fab fixed right bottom v-if="!id && $vuetify.breakpoint.xsOnly" @click="createProject" class="primary">
      <v-icon>mdi mdi-content-save</v-icon>
    </v-btn>

    <confirm-dialog ref="removeSiteFromProject" />
    <add-party-dialog ref="addNewParty" @created="handlePartyCreated" />
    <add-person-dialog ref="addNewPerson" @person-created="handlePersonCreated" />
    <add-site-to-project-dialog ref="addSiteToProject" :projectId="this.project.id" :projectSites="sites" @sites-added="getProjectSites" />
  </hb-basic-page>
</template>

<script>
import projectApi from '@/api/project';
import partyApi from '@/api/party';
import personApi from '@/api/person';
import ConfirmDialog from '@/components/ConfirmDialog';
import PartyCombobox from '@/components/PartyCombobox';
import AddPartyDialog from '@/components/AddPartyDialog';
import NoContentView from '@/components/NoContentView';
import AddSiteToProjectDialog from '@/components/Projects/AddSiteToProjectDialog';
import PersonCombobox from '@/components/PersonCombobox';
import AddPersonDialog from '@/components/AddPersonDialog';
import siteApi from '@/api/site';

export default {
  name: 'ProjectView',
  components: {
    AddPersonDialog,
    PersonCombobox,
    AddSiteToProjectDialog,
    NoContentView,
    AddPartyDialog,
    PartyCombobox,
    ConfirmDialog,
  },
  props: ['id'],
  data() {
    return {
      loading: false,
      sites: [],
      backButton: {
        show: true,
        fallback: 'tenant_projects',
      },
      saving: false,
      project: {
        name: '',
        status: 'CREATED',
        code: null,
        primeContractor: null,
        primeContractorPerson: null,
        orderer: null,
        ordererPerson: null,
      },
      parties: [],
      ordererPersons: [],
      contractorPersons: [],
      contractorPersonsLoading: false,
      ordererPersonsLoading: false,
      statuses: [
        { value: 'CREATED', label: this.$t('projects.statuses.CREATED') },
        { value: 'ONHOLD', label: this.$t('projects.statuses.ONHOLD') },
        { value: 'WORKDONE', label: this.$t('projects.statuses.WORKDONE') },
        { value: 'BILLED', label: this.$t('projects.statuses.BILLED') },
        { value: 'DELETED', label: this.$t('projects.statuses.DELETED') },
        { value: 'DONE', label: this.$t('projects.statuses.DONE') },
      ],
    };
  },
  computed: {
    orderers() {
      return this.parties.filter(party => party.type === 'customer' || party.type === 'contractor');
    },
    contractors() {
      return this.parties.filter(party => party.type === 'customer' || party.type === 'contractor');
    },
  },
  methods: {
    statusColor(status) {
      switch (status) {
        case 'CREATED':
          return { color: 'info darken-1', textColor: 'white' };
        case 'ONHOLD':
          return { color: 'orange', textColor: 'white' };
        case 'WORKDONE':
          return { color: 'info darken-2', textColor: 'white' };
        case 'BILLED':
          return { color: 'green', textColor: 'white' };
        case 'DONE':
          return { color: 'green darken-3', textColor: 'white' };
        case 'DELETED':
          return { color: 'error darken-3', textColor: 'white' };
        default:
          return { color: 'pink', textColor: 'white' };
      }
    },
    openSite(site) {
      this.$router.push({ name: 'tenant_site_edit_site', params: { id: site.id } });
    },
    addNewOrderer() {
      this.$refs.addNewParty.open({ type: 'customer' }, 'orderer');
    },
    addNewContractor() {
      this.$refs.addNewParty.open({ type: 'contractor' }, 'primeContractor');
    },
    async handlePersonCreated(person, personRole) {
      if (personRole === 'primeContractorPerson') {
        if (!this.project.primeContractor) return;
        this.contractorPersons = await this.getPersons(this.project.primeContractor.id);
        this.project.primeContractorPerson = person;
      } else if (personRole === 'ordererPerson') {
        if (!this.project.orderer) return;
        this.ordererPersons = await this.getPersons(this.project.orderer.id);
        this.project.ordererPerson = person;
      }
    },
    async handlePartyCreated(party, type) {
      await this.getParties();
      if (type === 'primeContractor') {
        this.project.primeContractor = party;
      } else if (type === 'orderer') {
        this.project.orderer = party;
      }
    },
    async handlePrimeContractorChange(value) {
      this.project.primeContractor = value;
      this.project.primeContractorPerson = null;
      if (value && value.id) {
        this.getPrimeContractorPersons(value.id);
      } else {
        this.contractorPersons = [];
      }
    },
    async handlePrimeContractorPersonChange(value) {
      this.project.primeContractorPerson = value;
    },

    async handleOrdererChange(value) {
      this.project.orderer = value;
      this.project.ordererPerson = null;
      if (value && value.id) {
        this.getOrdererPersons(value.id);
      } else {
        this.ordererPersons = [];
      }
    },
    async handleOrdererPersonChange(value) {
      this.project.ordererPerson = value;
    },
    async getPrimeContractorPersons() {
      if (!this.project?.primeContractor?.id) return;
      this.contractorPersonsLoading = true;
      this.contractorPersons = await this.getPersons(this.project.primeContractor.id);
      this.contractorPersonsLoading = false;
    },
    async getOrdererPersons() {
      if (!this.project?.orderer?.id) return;
      this.ordererPersonsLoading = true;
      this.ordererPersons = await this.getPersons(this.project.orderer.id);
      this.ordererPersonsLoading = false;
    },

    async addNewPrimeContractorPerson() {
      await this.$refs.addNewPerson.open(this.project.primeContractor, 'primeContractorPerson');
    },
    async addNewOrdererPerson() {
      await this.$refs.addNewPerson.open(this.project.orderer, 'ordererPerson');
    },
    async getParties() {
      this.parties = (await partyApi.getPartiesPaged({ types: ['customer', 'contractor'] })).content;
    },
    async getPersons(employerId) {
      return await personApi.getPersonsByEmployer(employerId);
    },
    async addNewSiteToProject() {
      if (this.project.code) {
        this.$refs.addSiteToProject.open();
      }
      if (!this.project.code) {
        const response = await projectApi.createProject(this.project);
        this.project = response;
        this.$refs.addSiteToProject.open();
      }
    },
    removeSiteFromProjectDialog(site) {
      this.$refs.removeSiteFromProject
        .open(this.$t('projects.delete_site_from_project_dialog.title'), this.$t('projects.delete_site_from_project_dialog.description'), {
          color: 'error',
        })
        .then(async confirm => {
          if (confirm === true) {
            let removingSite = site;
            removingSite.project = null;
            await siteApi.updateSite(site.id, removingSite);
            await this.getProject();
            this.$showSuccessNotification(this.$t('projects.notifications.site_deleted_from_project'));
          } else {
            this.$showErrorNotification(this.$t('projects.notifications.site_cannot_be_deleted_from_project'));
          }
        });
    },
    async createProject() {
      if (this.project.code === null) {
        this.loading = true;
        await projectApi.createProject(this.project);
        this.loading = false;
      }
      this.$showSuccessNotification(this.$t('projects.notifications.project_created'));
      await this.$router.push({ name: 'tenant_projects', params: { siteStatus: 'CREATED' } });
    },
    async saveProject() {
      this.loading = true;
      await projectApi.updateProject(this.id, this.project);
      this.loading = false;
      this.$showSuccessNotification(this.$t('projects.notifications.project_updated'));
      await this.$router.push({ name: 'tenant_projects', params: { siteStatus: 'CREATED' } });
    },
    async getProject() {
      const project = await projectApi.getProject(this.id);
      this.sites = project.sites;
      this.project = project;
    },
    async getProjectSites() {
      const project = await projectApi.getProject(this.project.id);
      this.sites = project.sites;
    },
    cancel() {
      this.$router.go(-1);
    },
  },
  async mounted() {
    this.loading = true;
    if (this.id) {
      await this.getProject();
    }
    this.getParties();
    this.getOrdererPersons();
    this.getPrimeContractorPersons();
    this.loading = false;
  },
};
</script>

<style scoped>
.button_group {
  position: fixed;
  bottom: 16px;
  right: 16px;
}

.combobox_negative_margin {
  margin-top: -6px;
}
</style>
