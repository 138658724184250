<template>
  <hb-basic-page
    :loading="false"
    :title="$tc('projects.title', 2)"
    :fill-height="true"
    :extension-height="60"
    :search-title="`${$t('projects.search')}...`"
    :pa-0="$vuetify.breakpoint.xsOnly"
    @handle-search="debounceSearch"
    fluid
    class="projects-view"
    :no-gutters="true"
  >
    <template v-if="$vuetify.breakpoint.smAndUp" v-slot:app-bar-extension>
      <v-btn class="mb-3 ml-n1" @click="createNewProject" color="primary">
        <v-icon class="no-gradient" left>mdi-plus</v-icon>
        <span>{{ $t('projects.create_new_project') }}</span>
      </v-btn>
    </template>
    <v-col cols="12" class="gradient-icons">
      <v-fade-transition>
        <v-row no-gutters>
          <!-- Mobiilissa fixed fab, desktopilla app bar extensionissa -->
          <v-btn @click="createNewProject" v-if="$vuetify.breakpoint.xsOnly" color="primary" fab bottom right fixed>
            <v-icon class="no-gradient ma-0" left>mdi-plus</v-icon>
          </v-btn>
          <v-col style="position: fixed; z-index: 99; width: calc(100vw - 24px)" cols="12">
            <v-row>
              <v-col cols="12" sm="4" lg="3" xl="2">
                <v-select
                  v-model="filterStatus"
                  :items="filterOptions"
                  :placeholder="`${$t('user_management.filtered')} 0 ${$t('user_management.selections')}`"
                  item-text="desc"
                  item-value="value"
                  multiple
                  hide-details
                  single-line
                  solo
                  prepend-inner-icon="filter_list"
                  :class="$vuetify.breakpoint.xsOnly && 'pa-3 pb-1'"
                  @blur="this.reloadProjects"
                >
                  <template v-slot:selection="{ item, index }">
                    <template v-if="filterStatus.length !== filterOptions.length && index === 0">
                      <span class="mr-1">{{ $t('user_management.filtered') }}</span>
                      <span class="caption">({{ filterStatus.length }} {{ $t('user_management.selections') }})</span>
                    </template>
                    <template v-else>
                      <span v-if="index === 0">{{ $t('user_management.show_all') }}</span>
                    </template>
                  </template>
                </v-select>
              </v-col>

              <v-checkbox
                :class="$vuetify.breakpoint.xsOnly ? 'mt-0 px-5 py-2 d-flex align-center' : 'ma-0 pa-3 d-flex align-center'"
                v-model="projectsAsPrimeContractor"
                :label="$t('projects.lap_as_prime_contractor')"
                hide-details
              ></v-checkbox>
            </v-row>
          </v-col>

          <v-col cols="12" :style="{ marginTop: $vuetify.breakpoint.xsOnly ? '128px' : '60px' }">
            <!--<checkbox-filter :filter="filter" :filter-options="filterOptions" @change="handleFilterChange" @blur="this.reloadProjects" />-->
            <v-data-table
              :headers="headers"
              :items="filteredProjects"
              class="h-100 gradient-info__table"
              :options.sync="options"
              :server-items-length="projects.totalElements"
              :loading="loading"
              :footer-props="{ itemsPerPageOptions: [50, 100, 500, -1] }"
              :items-per-page="50"
              @click:row="openProject"
              style="cursor: pointer"
            >
              <!--<template v-slot:item.type="{ item }"> </template>-->
              <template v-slot:item.status="{ item }">
                <v-chip :color="statusColor(item.status).color" :text-color="statusColor(item.status).textColor">
                  {{ item.status === null ? $t(`projects.statuses.no_status`) : $t(`projects.statuses.${item.status}`) }}
                </v-chip>
              </template>
            </v-data-table>
            <confirm-dialog ref="confirmProjectHide" />
          </v-col>
        </v-row>
      </v-fade-transition>
    </v-col>
  </hb-basic-page>
</template>

<script>
import projectApi from '@/api/project';
import debounce from 'lodash.debounce';
import ConfirmDialog from '@/components/ConfirmDialog';

export default {
  name: 'ProjectsView',
  components: { ConfirmDialog },
  props: ['siteStatus'],
  data() {
    return {
      loading: true,
      projectsAsPrimeContractor: false,
      projects: [],
      subtleLoading: false,
      searchPhrase: '',
      options: {},
      offsetTop: 0,
      filterStatus: ['CREATED', 'ONHOLD', 'WORKDONE', 'BILLED'],
      filterOptions: [
        { desc: this.$t('projects.statuses.CREATED'), value: 'CREATED' },
        { desc: this.$t('projects.statuses.ONHOLD'), value: 'ONHOLD' },
        { desc: this.$t('projects.statuses.WORKDONE'), value: 'WORKDONE' },
        { desc: this.$t('projects.statuses.BILLED'), value: 'BILLED' },
        { desc: this.$t('projects.statuses.DELETED'), value: 'DELETED' },
        { desc: this.$t('projects.statuses.DONE'), value: 'DONE' },
      ],
      headers: [
        {
          text: this.$t('projects.name'),
          value: 'name',
        },
        {
          text: this.$t('projects.code'),
          value: 'code',
        },
        {
          text: this.$t('projects.orderer'),
          value: 'orderer.companyName',
        },
        {
          text: this.$t('projects.prime_contractor'),
          value: 'primeContractor.companyName',
        },
        {
          text: this.$t('projects.status'),
          value: 'status',
        },
        {
          text: '',
          value: 'actions',
          align: 'end',
          sortable: false,
        },
      ],
    };
  },
  created() {
    this.debounceSearch = debounce(this.handleSearch, 400, { maxWait: 1000 });
  },
  mounted() {
    this.$vuetify.goTo(0, {
      duration: 0,
      offset: 0,
    });

    //this.reloadProjects();
    this.loading = false;
  },
  methods: {
    statusColor(status) {
      switch (status) {
        case 'CREATED':
          return { color: 'info darken-1', textColor: 'white' };
        case 'ONHOLD':
          return { color: 'orange', textColor: 'white' };
        case 'WORKDONE':
          return { color: 'info darken-2', textColor: 'white' };
        case 'BILLED':
          return { color: 'green', textColor: 'white' };
        case 'DONE':
          return { color: 'green darken-3', textColor: 'white' };
        default:
          return { color: 'pink', textColor: 'white' };
      }
    },
    openProjectHideDialog() {
      this.$refs.confirmProjectHide
        .open(this.$t('projects.delete_project_dialog.title'), this.$t('projects.delete_project_dialog.description'), { color: 'error' })
        .then(confirm => {
          if (confirm === true) {
            this.loading = true;
            // TODO - PROJEKTIN POISTO / PIILOTUS - JOS PROJEKTILLA EI OLE TYÖMAITA
            this.$showSuccessNotification(this.$t('projects.notifications.project_deleted'));
            this.loading = false;
          } else {
            this.$showErrorNotification(this.$t('projects.notifications.project_cannot_be_deleted'));
          }
        });
    },

    handleSearch(searchPhrase) {
      this.searchPhrase = searchPhrase;
    },
    createNewProject() {
      this.$router.push({ name: 'tenant_project_new_project' });
    },
    openProject(project) {
      this.$router.push({ name: 'tenant_project_edit_project', params: { id: project.id } });
    },
    getStatusColor(status) {
      return projectApi.getStatusColor(status);
    },
    async reloadProjects() {
      this.loading = true;
      try {
        const filter = {};
        if (this.searchPhrase) {
          filter.code = this.searchPhrase;
          filter.name = this.searchPhrase;
          filter.ordererName = this.searchPhrase;
          filter.primeContractor = this.searchPhrase;
        }
        if (this.filterStatus && this.filterStatus.length > 0) {
          filter.status = this.filterStatus;
        }
        this.projects = await projectApi.getProjectsPaged(filter, this.options);
      } catch (error) {
        console.log(error);
        this.$showErrorNotification(this.$t('errors.no_projects_with_filter'), false);
      }
      this.loading = false;
    },
  },
  computed: {
    filteredProjects() {
      const filteredProjects = this.projects?.content || [];

      if (this.projectsAsPrimeContractor) {
        const projectsByContractor = filteredProjects.filter(p => p.primeContractor?.tenantParty);
        return projectsByContractor;
      } else {
        return filteredProjects;
      }

      /*
      if (this.searchPhrase == null || this.searchPhrase.length === 0) {
        return filteredProjects.filter(project => this.filterStatus.includes(project.status));
      }

      const searchPhraseLower = this.searchPhrase.toLowerCase();
      const searchPhraseArray = searchPhraseLower.split(' ');
      return filteredProjects.filter(project => {
        let found = [];
        const lowerSearchedString = [project.name, project.code, project.orderer?.companyName]
          .filter(Boolean)
          .map(p => p.toLowerCase())
          .join(' ');
        searchPhraseArray.forEach(phrase => {
          if (lowerSearchedString.indexOf(phrase) !== -1) {
            found.push(true);
          } else {
            found.push(false);
          }
        });
        if (this.filterStatus.includes(project.status)) found.push(true);
        else found.push(false);
        return found.indexOf(false) === -1;
      });*/
    },
  },
  watch: {
    projectsAsPrimeContractor() {
      this.reloadProjects();
    },
    options: {
      handler() {
        this.reloadProjects();
      },
      deep: true,
    },
    searchPhrase() {
      this.reloadProjects();
    },
  },
};
</script>

<style scoped></style>
