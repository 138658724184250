import axios from 'axios';

export default {
  async getIntroduction(id) {
    return await axios({
      url: '/api/tenant/introduction/' + id,
    }).then(r => r.data);
  },
  async getIntroductionsBySite(siteId) {
    return await axios({
      method: 'post',
      url: '/api/tenant/introduction/' + siteId,
    }).then(r => r.data);
  },
  async getIntroductionByUserAndSite(siteId) {
    return await axios({
      method: 'get',
      url: '/api/tenant/introduction/' + siteId + '/byuser',
    }).then(r => r.data);
  },
  async createIntroduction(data) {
    return await axios({
      url: '/api/tenant/introduction',
      method: 'post',
      data,
    }).then(r => r.data);
  },
  async updateIntroduction(id, /* Introduction */ data) {
    return await axios({
      url: '/api/tenant/introduction/' + id,
      method: 'put',
      data,
    }).then(r => r.data);
  },
  async markIntroductionChecked(id ) {
    return await axios({
      url: '/api/tenant/introduction/' + id + '/check',
      method: 'post',
    }).then(r => r.data);
  },
};
