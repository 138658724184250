<template>
  <v-dialog :fullscreen="$vuetify.breakpoint.xsOnly" v-model="dialog" max-width="600" @keydown.esc="cancel()" persistent>
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title class="white--text">
          {{ currentEntry.id ? $t('work_times.edit_away_entry') : $t('work_times.add_new_away_entry') }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="cancel">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-5 gradient-icons">
        <div v-if="currentEntry.approved">
          <v-alert border="top" colored-border color="primary" type="success" elevation="2">
            {{ $t('timer.this_entry_has_been_approved') }}
            <span class="caption">{{
              currentEntry.approved &&
              [
                currentEntry.approvedUser && [currentEntry.approvedUser.lastName, currentEntry.approvedUser.firstName].join(' '),
                $formatDateTime(currentEntry.approved),
              ]
                .filter(Boolean)
                .join(', ')
            }}</span>
          </v-alert>
        </div>
        <v-form ref="form">
          <v-combobox
            :disabled="currentEntry.disabled || !adminMode || !$isTenantAdmin || !!currentEntry.id"
            v-if="adminMode && users && users.length > 0"
            v-model="selectedUser"
            :items="richerUsers"
            :menu-props="{ closeOnClick: true, closeOnContentClick: true }"
            :label="$t('timer.user')"
            :placeholder="$t('timer.choose_user')"
            item-value="id"
            clearable
            outlined
            dense
            id="worker-select"
            :rules="[$rules.required]"
            item-text="combinedInfo"
            style="width: 100%"
            class="title"
          >
            <template slot="prepend">
              <div style="width: 25px; text-align: center"><v-icon color="grey">worker</v-icon></div>
            </template>
            <template v-slot:selection="data">
              <div v-if="data.item.firstName">{{ data.item.firstName }} {{ data.item.lastName }}</div>
              <div v-else>{{ data.item.email }}</div>
            </template>
            <template v-slot:item="data">
              <v-list-item-title>
                <template v-if="data.item.firstName">{{ data.item.firstName }} {{ data.item.lastName }}</template>
                <template v-else>{{ data.item.email }}</template>
              </v-list-item-title>
            </template>
          </v-combobox>
          <v-date-picker
            v-if="!currentEntry.id"
            :disabled="currentEntry.approved ? true : false"
            :min="dates[0]"
            first-day-of-week="1"
            no-title
            full-width
            v-model="dates"
            range
          />
          <v-btn
            v-if="!currentEntry.id"
            :disabled="currentEntry.approved ? true : false"
            @click="dates = []"
            text
            color="error"
            class="mb-6"
            small
            :block="$vuetify.breakpoint.xsOnly"
            >{{ $t('timer.reset_choosed_days') }}</v-btn
          >
          <item-combobox
            :value="currentEntry.workType"
            :label="$t('work_times.away_type')"
            :saving="saving"
            :disabled="currentEntry.approved ? true : false"
            :items="items"
            :multiple="false"
            @change="handleWorkTypeChange"
            :required="true"
          >
          </item-combobox>
          <v-card-title>{{ $t('timer.away_time') }}</v-card-title>
          <v-card-subtitle v-if="dates.length > 0">{{ $formatDateNoTime(dates[0]) }} - {{ $formatDateNoTime(dates[1]) }}</v-card-subtitle>
          <v-card-subtitle v-else>{{ $t('timer.no_choosed_time') }}</v-card-subtitle>
        </v-form>
      </v-card-text>
      <v-card-text v-if="currentEntry.id">
        <time-entry-logs :time-entry="currentEntry"></time-entry-logs>
      </v-card-text>
      <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>

      <v-card-actions :class="$vuetify.breakpoint.xsOnly ? 'flex-column-reverse' : ''">
        <v-btn
          v-if="currentEntry.id && currentEntry.id > 0"
          color="error"
          text
          :disabled="saving || currentEntry.disabled || !!currentEntry.approved"
          :loading="deleting"
          @click="deleteEntryConfirm"
        >
          <span>{{ $t('timer.delete_away_entry') }}</span>
        </v-btn>

        <v-spacer></v-spacer>
        <v-btn
          :left="$vuetify.breakpoint.xsOnly"
          :fixed="$vuetify.breakpoint.xsOnly"
          :bottom="$vuetify.breakpoint.xsOnly"
          :fab="$vuetify.breakpoint.xsOnly"
          color="default"
          @click.native="cancel()"
          ><v-icon v-if="$vuetify.breakpoint.xsOnly">mdi mdi-close</v-icon
          ><span v-if="$vuetify.breakpoint.smAndUp">{{ $t('cancel') }}</span></v-btn
        >
        <v-btn
          :right="$vuetify.breakpoint.xsOnly"
          :fixed="$vuetify.breakpoint.xsOnly"
          :bottom="$vuetify.breakpoint.xsOnly"
          :disabled="currentEntry.approved ? true : false"
          :fab="$vuetify.breakpoint.xsOnly"
          color="primary"
          @click="saveAwayEntry"
          :loading="saving"
          ><v-icon v-if="$vuetify.breakpoint.xsOnly">mdi mdi-content-save</v-icon
          ><span v-if="$vuetify.breakpoint.smAndUp">{{ $t('save') }}</span></v-btn
        >
      </v-card-actions>
    </v-card>
    <confirm-dialog ref="softDeleteEntry" />
  </v-dialog>
</template>

<script>
import ItemCombobox from '@/components/ItemCombobox';
import itemsApi from '@/api/item';
import Immutable from 'immutable';
import dayjs from 'dayjs';
import timeentriesApi from '@/api/timeentries';
import ConfirmDialog from '@/components/ConfirmDialog';
import orderBy from 'lodash/orderBy';
import TimeEntryLogs from '@/components/Timer/TimeEntryLogs';

export default {
  name: 'AddAwayEntryDialog',
  components: { TimeEntryLogs, ConfirmDialog, ItemCombobox },
  props: {
    adminMode: {
      type: Boolean,
      default: false,
    },
    propsUser: {
      type: Object,
      default: null,
    },
    users: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      saving: false,
      deleting: false,
      dialog: false,
      dates: [],
      items: [],
      selectedUser: null,
      currentEntry: {
        containsLunch: false,
        timeEntryType: 'AWAYENTRY',
        entryStart: null,
        entryEnd: null,
      },
    };
  },
  computed: {
    dateRangeText() {
      return this.dates.join(' ~ ');
    },
    richerUsers() {
      const users = this.users;
      let filteredUsers = users;
      if (this.$userInfo.email && !this.$userInfo.email.includes('@hubble.fi')) {
        filteredUsers = filteredUsers.filter(user => user.email && !user.email.includes('@hubble.fi'));
      }
      return orderBy(
        filteredUsers.map(user => {
          const userCopy = Object.assign({}, user);
          userCopy.combinedInfo = `${userCopy.firstName} ${userCopy.lastName} ${userCopy.email}`;
          return userCopy;
        }),
        ['combinedInfo'],
        ['asc']
      );
    },
  },
  methods: {
    deleteEntryConfirm() {
      this.$refs.softDeleteEntry
        .open(this.$t('timer.away_entry_delete_dialog.title'), this.$t('timer.away_entry_delete_dialog.description'), {
          color: 'error',
        })
        .then(confirm => {
          if (confirm === true) {
            this.deleteEntry();
          }
        });
    },
    async deleteEntry() {
      this.deleting = true;
      try {
        const currentEntry = Immutable.fromJS(this.currentEntry).toJS();
        await timeentriesApi.deleteTimeEntry(currentEntry.id);
        this.deleting = false;
        this.$emit('away-entry-changed', 'deleted');
        this.cancel();
      } catch (error) {
        console.log(error);
        this.deleting = false;
        this.$showErrorNotification(this.$t('timer.delete_error'));
      }
    },
    async saveAwayEntry() {
      this.saving = true;
      try {
        if (this.dates.length < 2) {
          this.$showErrorNotification(this.$t('timer.check_dates'));
          this.saving = false;
          return;
        }

        this.currentEntry.entryStart = dayjs(this.dates[0]).set({ hour: 12, minute: 0, second: 0 });
        this.currentEntry.entryEnd = dayjs(this.dates[1]).set({ hour: 12, minute: 0, second: 0 });

        if (!this.$refs.form.validate()) {
          this.saving = false;
          return;
        }
        const currentEntry = Immutable.fromJS(this.currentEntry).toJS();
        if (this.adminMode) {
          currentEntry.user = this.selectedUser;
          currentEntry.userId = this.selectedUser.id;
        } else {
          if (!currentEntry.userId) currentEntry.userId = this.$userInfo.id;
        }

        if (currentEntry.id && currentEntry.id > 0) {
          currentEntry.comments = void 0;
          await timeentriesApi.updateTimeEntry(currentEntry.id, currentEntry);
          this.$emit('away-entry-changed', 'updated');
        } else {
          currentEntry.comments = void 0;
          await timeentriesApi.createTimeEntry(currentEntry);
          this.$emit('away-entry-changed', 'created');
        }

        this.saving = false;
        this.dates = [];
        this.cancel();
      } catch (error) {
        this.saving = false;
        console.log(error);
      }
    },
    handleWorkTypeChange(value) {
      this.currentEntry.workType = value;
    },
    async getWorkTypes() {
      try {
        this.items = await itemsApi.getItemsByTypes(['poissaolo']);
      } catch {
        this.$showErrorNotification(this.$t('generic_error'));
      }
    },
    open() {
      this.dialog = true;
      this.loading = true;
      this.getWorkTypes();
      this.selectedUser = null;
      if (this.adminMode) {
        this.selectedUser = this.propsUser;
      }

      this.loading = false;
    },
    openEntry(entry) {
      this.dialog = true;
      this.loading = true;
      this.getWorkTypes();

      this.currentEntry = entry;
      this.dates = [entry.entryStart.format('YYYY-MM-DD'), entry.entryEnd.format('YYYY-MM-DD')];
      if (this.adminMode && this.users.length > 0) {
        const userIndex = this.users.findIndex(user => user.id === this.currentEntry.userId);
        if (userIndex > -1) {
          this.selectedUser = this.users[userIndex];
        }
      }
      this.loading = false;
    },
    cancel() {
      (this.currentEntry = {
        containsLunch: false,
        timeEntryType: 'AWAYENTRY',
        entryStart: null,
        entryEnd: null,
      }),
        (this.dates = []);
      this.dialog = false;
    },
  },
};
</script>
