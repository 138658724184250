<template>
  <v-container
    fluid
    :fill-height="fillHeight"
    :class="[name, alignNormal ? 'align-normal' : null].filter(Boolean)"
    :pa-0="noContainerPadding"
  >
    <v-progress-linear
      style="position: fixed; left: 0; right: 0; margin: 0; z-index: 99"
      :style="{ top: $vuetify.breakpoint.smAndDown ? '54px' : '62px' }"
      color="primary lighten-2"
      height="2"
      v-show="subtleLoading"
      :indeterminate="true"
    ></v-progress-linear>

    <v-app-bar :extension-height="extensionHeight" color="grey darken-3" dark fixed app :extended="!!$slots['app-bar-extension']">
      <template v-if="$vuetify.breakpoint.mdAndDown && !showBackButton && !hideMenu">
        <hb-main-menu-button />
      </template>

      <template v-if="showSearch">
        <v-toolbar-title style="margin-left: 0; width: 100%" class="title">
          <v-text-field
            :placeholder="searchTitle"
            v-model="searchPhrase"
            class="topbar-search"
            style="font-size: inherit"
            single-line
            full-width
            hide-details
            color="red lighten-3"
          >
            <v-icon style="cursor: pointer" slot="append">search</v-icon>
          </v-text-field>
        </v-toolbar-title>
      </template>

      <template v-else>
        <hb-back-button v-if="showBackButton" :fallback="this.backButton.fallback" :override="this.backButton.override"></hb-back-button>
        <v-toolbar-title style="margin-left: 0; width: 100%" class="title">
          {{ title }}
        </v-toolbar-title>
      </template>

      <v-spacer></v-spacer>
      <v-badge
        v-if="$isTenantRoute"
        color="error"
        class="mr-1"
        :value="fab ? 0 : undoneWorkTasks.length"
        :content="undoneWorkTasks.length"
        overlap
        :dot="$vuetify.breakpoint.xsOnly"
        offset-x="14"
        offset-y="14"
      >
        <v-btn @click="openNotificationDrawer" class="ml-5" fab text small><v-icon>mdi mdi-clipboard-alert-outline</v-icon></v-btn>
      </v-badge>
      <template v-if="!!$slots['app-bar-extension']" v-slot:extension>
        <slot name="app-bar-extension"></slot>
      </template>
    </v-app-bar>

    <v-navigation-drawer
      style="z-index: 100"
      v-model="showNotificationDrawer"
      right
      app
      dark
      :width="$vuetify.breakpoint.smAndUp ? '400' : '100%'"
    >
      <v-list class="pa-0 elevation-4 gradient-icons" light style="background: rgb(245, 246, 250); height: 64px">
        <v-list-item style="padding: 2px 10px">
          <v-list-item-avatar>
            <v-btn icon @click.stop="showNotificationDrawer = !showNotificationDrawer">
              <v-icon>chevron_right</v-icon>
            </v-btn>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ $t('timer.work_tasks') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list v-if="undoneWorkTasks.length > 0" class="py-0">
        <template v-for="workTask in undoneWorkTasks">
          <v-list-item @click="openWorkTaskTarget(workTask.objectId)" :key="workTask.id">
            <v-list-item-icon>
              <v-icon>{{ $workTaskStatusIcon(workTask.status) }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="pt-0 mt-0">
              <v-list-item-title class="mb-2 mt-3 text-wrap">
                <span>{{ $t(`work_tasks.objects.${$snakeCase(workTask.object)}`) }} {{ workTask.title }}</span>
              </v-list-item-title>
              <v-list-item-subtitle class="text-wrap white--text mb-1">
                {{ workTask.message }}
              </v-list-item-subtitle>
              <v-list-item-subtitle class="font-weight-light text-wrap">
                <span v-if="workTask.assignerUser"
                  >Määrännyt: {{ workTask.assignerUser.lastName }} {{ workTask.assignerUser.firstName }}</span
                >
                {{ $formatDateNoTime(workTask.assignTime) }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-menu transition="slide-y-transition" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon><v-icon>mdi mdi-dots-vertical</v-icon></v-btn>
                </template>

                <v-list dense>
                  <v-subheader>{{ $t('work_tasks.status.subtitle') }}</v-subheader>
                  <v-list-item
                    v-for="(status, i) in workTaskStatuses"
                    :key="`${i}_${status}`"
                    @click.stop="$store.dispatch('setWorkTaskStatus', { workTask, status })"
                    :disabled="status === workTask.status"
                    link
                  >
                    <v-list-item-icon>
                      <v-icon>{{ $workTaskStatusIcon(status) }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ $t(`work_tasks.status.${status}`) }} </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item-action>
          </v-list-item>
          <v-divider :key="`${workTask.id}_divider`"></v-divider>
        </template>
      </v-list>
      <v-list v-if="undoneWorkTasks.length === 0" class="py-0">
        <v-list-item>
          <no-content-view
            functionButtonIconColor="white"
            :reload-button="false"
            title-icon="mdi mdi-clipboard-off-outline"
            :title="$t('work_tasks.no_work_tasks')"
          />
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <hb-loading-indicator v-if="loading" align-middle />

    <v-fade-transition>
      <v-row
        v-if="!loading"
        style="position: relative"
        :no-gutters="noGutters"
        :class="[/*row100 && 'h-100',*/ fillHeight && 'fill-height'].filter(Boolean)"
      >
        <slot></slot>
      </v-row>
    </v-fade-transition>
  </v-container>
</template>

<script>
import NoContentView from '@/components/NoContentView';

export default {
  components: { NoContentView },
  props: {
    name: {
      type: String,
      default: undefined,
    },
    title: {
      type: String,
      default: undefined,
    },
    loading: {
      type: Boolean,
      default: undefined,
    },
    subtleLoading: {
      type: Boolean,
      default: undefined,
    },
    backButton: {
      type: Object,
      default: undefined,
    },
    hideMenu: {
      type: Boolean,
      default: false,
    },
    extensionHeight: {
      type: Number,
      default: null,
    },
    searchTitle: {
      type: String,
      default: undefined,
    },
    noGutters: {
      type: Boolean,
      default: undefined,
    },
    noContainerPadding: {
      type: Boolean,
      default: undefined,
    },
    fillHeight: {
      type: Boolean,
      default: undefined,
    },
    alignNormal: {
      type: Boolean,
      default: undefined,
    },
    row100: {
      type: Boolean,
      default: undefined,
    },
  },
  data() {
    return {
      searchPhrase: '',
      showNotificationDrawer: false,
      fab: false,
      workTasks: [],
      workTaskStatuses: ['RECEIVED', 'WAITING', 'INPROGRESS', 'DONE'],
      users: [],
    };
  },
  computed: {
    undoneWorkTasks() {
      return this.$store.state.workTasks.filter(w => w.status !== 'DONE');
    },
    showBackButton() {
      return this.backButton != null && this.backButton.show;
    },
    showSearch() {
      return this.searchTitle != null;
    },
  },
  methods: {
    openWorkTaskTarget(id) {
      if (this.$route.name !== 'tenant_timer') {
        this.$router.push({ name: 'tenant_timer', params: { id: id } });
      } else if (this.$route.name === 'tenant_timer') {
        this.$router.replace({ name: 'tenant_timer', params: { id: id } });
      }
    },
    openNotificationDrawer() {
      this.showNotificationDrawer = !this.showNotificationDrawer;
      if (this.showNotificationDrawer) this.$store.dispatch('reloadWorkTasks');
    },
  },
  watch: {
    searchPhrase(to) {
      this.$emit('handle-search', to);
    },
  },
};
</script>

<style lang="scss">
.topbar-search {
  .v-input__slot {
    padding: 0 !important;

    &:after {
      display: none;
    }
  }
}
.h-100 {
  height: 100%;
}
.container.fill-height.align-normal {
  align-items: normal;
}
</style>
