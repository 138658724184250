<template>
  <hb-basic-page
    :title="$tc('party.title', 1)"
    :loading="loading"
    :fill-height="loading"
    :back-button="backButton"
    :extension-height="$vuetify.breakpoint.smAndUp ? 107 : 47"
  >
    <template v-slot:app-bar-extension>
      <v-col class="px-0">
        <v-row>
          <div v-if="$vuetify.breakpoint.smAndUp" class="ml-2" :class="$isTenantAdmin || $isTenantManager ? 'mb-3' : ''">
            <v-btn @click="id ? save() : create()" class="primary mr-3">
              <v-icon left class="no-gradient">mdi-content-save</v-icon>
              {{ $t('save') }}
            </v-btn>
          </div>
          <template>
            <v-tabs v-model="tab" background-color="grey darken-3" v-if="tabs && party">
              <v-tab v-for="item in tabs" :key="item.name">
                {{ item.name }}
              </v-tab>
            </v-tabs>
          </template>
        </v-row>
      </v-col>
    </template>

    <v-col cols="12" style="margin-bottom: 72px" class="pa-0">
      <v-tabs-items v-model="selectedTab">
        <!-- BASIC INFO -->
        <v-tab-item>
          <v-col
            cols="12"
            md="8"
            offset-md="2"
            lg="6"
            offset-lg="3"
            :class="$vuetify.breakpoint.xsOnly ? 'gradient-icons pa-0 ma-0' : ' gradient-icons mt-5'"
          >
            <v-card :elevation="$vuetify.breakpoint.xsOnly ? 0 : ''">
              <v-card-text>
                <v-text-field
                  tabindex="1"
                  :disabled="saving"
                  outlined
                  dense
                  hide-details
                  class="mb-5 mt-4"
                  :label="$t('party.company_name_long')"
                  light
                  item-value=""
                  autocomplete="off"
                  clearable
                  v-model="party.companyName"
                >
                  <template v-slot:prepend>
                    <v-icon>mdi mdi-office-building</v-icon>
                  </template>
                </v-text-field>
                <v-text-field
                  tabindex="2"
                  :disabled="saving"
                  outlined
                  dense
                  hide-details
                  class="mb-5 mt-4"
                  :label="$t('party.business_code')"
                  light
                  item-value=""
                  autocomplete="off"
                  clearable
                  v-model="party.businessCode"
                >
                  <template v-slot:prepend>
                    <v-icon>mdi mdi-identifier</v-icon>
                  </template>
                </v-text-field>
                <v-text-field
                  tabindex="3"
                  :disabled="saving"
                  outlined
                  dense
                  hide-details
                  class="mb-5 mt-4"
                  :label="$t('party.contact_firstname')"
                  light
                  item-value=""
                  autocomplete="off"
                  clearable
                  v-model="party.contactFirstname"
                >
                  <template v-slot:prepend>
                    <v-icon>mdi mdi-account</v-icon>
                  </template>
                </v-text-field>
                <v-text-field
                  tabindex="4"
                  :disabled="saving"
                  outlined
                  dense
                  hide-details
                  class="mb-5 mt-4"
                  :label="$t('party.contact_lastname')"
                  light
                  item-value=""
                  autocomplete="off"
                  clearable
                  v-model="party.contactLastname"
                >
                  <template v-slot:prepend>
                    <v-icon>mdi mdi-account</v-icon>
                  </template>
                </v-text-field>
                <v-text-field
                  tabindex="5"
                  :disabled="saving"
                  outlined
                  dense
                  hide-details
                  class="mb-5 mt-4"
                  :label="$t('party.phone')"
                  light
                  item-value=""
                  autocomplete="off"
                  clearable
                  v-model="party.phone"
                >
                  <template v-slot:prepend>
                    <v-icon>mdi mdi-phone</v-icon>
                  </template>
                </v-text-field>
                <v-text-field
                  tabindex="6"
                  :disabled="saving"
                  outlined
                  dense
                  hide-details
                  class="mb-5 mt-4"
                  :label="$t('party.email')"
                  light
                  item-value=""
                  autocomplete="off"
                  clearable
                  v-model="party.email"
                >
                  <template v-slot:prepend>
                    <v-icon>mdi mdi-email</v-icon>
                  </template>
                </v-text-field>
                <v-autocomplete
                  tabindex="7"
                  :disabled="saving"
                  outlined
                  dense
                  hide-details
                  class="mb-5"
                  :label="$t('party.type')"
                  light
                  item-value="value"
                  item-text="label"
                  :items="types"
                  autocomplete="off"
                  v-model="party.type"
                  clearable
                >
                  <template v-slot:prepend>
                    <v-icon>mdi mdi-shape-outline</v-icon>
                  </template>
                </v-autocomplete>
                <v-checkbox v-if="party.tenantParty" v-model="party.tenantParty" :label="$t('party.tenant_party')" readonly disabled>
                </v-checkbox>
              </v-card-text>
            </v-card>
          </v-col>
        </v-tab-item>

        <!-- ADDRESS -->
        <v-tab-item>
          <v-col
            md="8"
            offset-md="2"
            lg="6"
            offset-lg="3"
            :class="$vuetify.breakpoint.xsOnly ? 'gradient-icons pa-0 ma-0' : ' gradient-icons mt-5'"
          >
            <v-card :elevation="$vuetify.breakpoint.xsOnly ? 0 : ''">
              <address-card
                :address="party.visitingAddress"
                :title="$t('party.addresses')"
                :subtitle="$t('party.visiting_address')"
                :saving="saving"
                @change="handleVisitingAddressChange"
              />
              <address-card
                :address="party.billingAddress"
                :subtitle="$t('party.billing_address')"
                :saving="saving"
                @change="handleBillingAddressChange"
              />
            </v-card>
          </v-col>
        </v-tab-item>

        <!-- PERSONS -->
        <v-tab-item>
          <v-col
            cols="12"
            md="8"
            offset-md="2"
            lg="6"
            offset-lg="3"
            :class="$vuetify.breakpoint.xsOnly ? 'gradient-icons ma-0' : ' gradient-icons'"
          >
            <v-btn :block="$vuetify.breakpoint.xsOnly" @click="addNewPerson" color="primary">
              <v-icon class="no-gradient" left>mdi-content-save</v-icon>
              <span>{{ $t('party.buttons.new_person') }}</span>
            </v-btn>
            <v-card :elevation="$vuetify.breakpoint.xsOnly ? 0 : ''">
              <v-data-table
                id="worker-licenses-table"
                :items="partyPersons"
                :headers="headers"
                disable-pagination
                hide-default-footer
                locale="fi-FI"
                :no-data-text="$t('worker_licenses.no_licenses')"
                :mobile-breakpoint="0"
                :sort-by.sync="sortBy"
                :sort-desc.sync="descending"
                class="gradient-info__table mt-4 mb-4"
              >
                <template v-slot:body="{ items }">
                  <tbody v-for="item in items" :key="item.id">
                    <tr v-if="items.length > 0" @click="editPerson(item)" class="bottom-border" style="cursor: pointer">
                      <td style="white-space: nowrap">
                        <span>{{ item.lastname }} {{ item.firstname }}</span>
                      </td>
                      <td>
                        <span>{{ item.phone }}</span>
                      </td>
                      <td>
                        <span>{{ item.email }}</span>
                      </td>
                      <td>
                        <v-btn
                          fab
                          text
                          small
                          @click.stop="
                            $confirmDelete($t('party.delete_person_from_party')).then(confirm => {
                              if (confirm) removePersonFromParty(item);
                            })
                          "
                          ><v-icon class="no-gradient" color="error">mdi mdi-delete</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-tab-item>
      </v-tabs-items>
    </v-col>

    <div v-if="$vuetify.breakpoint.xsOnly">
      <v-btn fab fixed bottom left @click="cancel" color="default"><v-icon>mdi-close</v-icon></v-btn>
      <v-btn v-if="id" @click="save" color="primary" fab fixed bottom right>
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
      <v-btn v-else fab fixed bottom right @click="create" color="primary">
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
    </div>
    <select-person-dialog ref="selectPersonDialog" @created="getPersons" />
  </hb-basic-page>
</template>

<script>
import partyApi from '@/api/party';
import personApi from '@/api/person';
import AddressCard from '@/components/AddressCard';
import SelectPersonDialog from '@/components/Parties/selectPersonDialog';

export default {
  name: 'PartyView',
  components: { SelectPersonDialog, AddressCard },
  props: ['id'],
  data() {
    return {
      tab: 0,
      tabs: [
        {
          name: this.$t('party.basic_info'),
        },
        {
          name: this.$t('party.address_info'),
        },
        {
          name: this.$tc('party.person', 2),
        },
      ],
      sortBy: 'entryStartTS',
      descending: true,
      headers: [
        {
          text: 'Nimi',
        },
        {
          text: 'Puhelinnumero',
        },
        {
          text: 'Sähköposti',
        },
        {
          text: '',
        },
      ],
      partyPersons: [],
      selectedTab: 'basic_info',
      loading: false,
      searchPhrase: '',
      backButton: {
        show: true,
        fallback: 'tenant_parties',
      },
      saving: false,
      types: partyApi.getPartyTypes(),
      party: {
        companyName: '',
        businessCode: '',

        contactFirstname: '',
        contactLastname: '',

        phone: '',
        email: '',
        code: '',
        type: 'customer',
        visitingAddress: {},
        billingAddress: {},
        tenantParty: false,
      },
    };
  },
  mounted() {
    this.loading = true;
    if (this.id) {
      this.getParty();
      this.getPersons();
    }
    this.selectedTab = this.tab;
    this.loading = false;
  },
  watch: {
    /*selectedTab(val) {
      if (val !== this.tab) {
        if (this.id) {
          this.$router.replace({ name: 'tenant_party', params: { id: this.party.id, tab: val } });
        } else {
          this.$router.replace({ name: 'tenant_new_party', tab: val });
        }
      }
    },*/
    tab(val) {
      if (val !== this.selectedTab) {
        this.selectedTab = val;
      }
    },
  },
  methods: {
    addNewPerson() {
      this.$refs.selectPersonDialog.open(this.party);
    },
    async removePersonFromParty(person) {
      let personData = person;
      personData.employer = null;
      await personApi.updatePerson(person.id, personData);
      await this.getPersons();
    },
    editPerson(person) {
      this.$router.push({ name: 'tenant_person', params: { id: person.id, tenantId: this.$tenant } });
    },
    async getPersons() {
      this.partyPersons = await personApi.getPersonsByEmployer(this.id);
    },
    async getParty() {
      this.loading = true;
      this.party = await partyApi.getParty(this.id);
      this.loading = false;
    },
    async save() {
      this.saving = true;
      await partyApi.updateParty(this.id, this.party);
      this.saving = false;
      await this.$router.push({ name: 'tenant_parties' });
    },
    async create() {
      this.saving = true;
      await partyApi.createParty(this.party);
      this.saving = false;
      await this.$router.push({ name: 'tenant_parties' });
    },
    async cancel() {
      await this.$router.push({ name: 'tenant_parties' });
    },
    handleVisitingAddressChange({ name, value }) {
      this.party.visitingAddress[name] = value;
    },
    handleBillingAddressChange({ name, value }) {
      this.party.billingAddress[name] = value;
    },
  },
};
</script>

<style scoped>
.v-tabs-items {
  background-color: transparent !important;
}
</style>
