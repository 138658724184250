<template>
  <v-btn icon @click="goBack">
    <v-icon>arrow_back</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'BackButton',
  props: ['fallback', 'override'],
  data() {
    return {};
  },
  methods: {
    goBack() {
      if (this.override) {
        this.$router.go(-1);
        this.$router.replace(this.override);
      } else if (this.$store.state.previousRouteName) {
        this.$router.go(-1);
      } else {
        this.$router.push({ name: this.fallback });
      }
    },
  },
  mounted() {},
};
</script>

<style scoped></style>
