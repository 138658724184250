<template>
  <div class="mb-3 d-flex black--text">
    <div class="d-flex justify-center align-center mr-5">
      <v-icon>{{ icon }}</v-icon>
    </div>
    <div v-if="!daybookItem">
      <label class="caption grey--text"> {{ label }}</label>
      <div>{{ value }}</div>
      <div>{{ value2 }}</div>
    </div>
    <div v-if="daybookItem">
      <label class="caption grey--text"> {{ label }}</label>
      <div v-for="(item, index) in value" :key="index">
        <v-icon>mdi mdi-circle-small</v-icon><span>{{ item.value }}</span
        ><file-viewer v-if="item.files" :file-list="item.files" class="ml-3"></file-viewer>
      </div>
    </div>
  </div>
</template>

<script>
import FileViewer from '@/components/FileViewer';
export default {
  name: 'DaybookEntry',
  components: { FileViewer },
  props: {
    label: String,
    value: [String, Array],
    value2: [String],
    icon: String,
    daybookItem: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped></style>
