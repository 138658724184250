<template>
  <v-dialog :fullscreen="$vuetify.breakpoint.xsOnly" v-model="dialog" max-width="600" @keydown.esc="cancel()" persistent>
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title class="white--text">{{ $t('projects.add_site_to_project_dialog.title') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="cancel">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <div
        style="overflow-y: auto; overflow-x: hidden"
        :class="$vuetify.breakpoint.xsOnly && 'mb-10'"
        v-if="dialog"
        :style="{ maxHeight: $vuetify.breakpoint.xsOnly ? 'calc(100vh - 150px)' : 'calc(100vh - 250px)' }"
      >
        <v-card-text class="pt-5 gradient-icons">
          <v-card-subtitle class="pa-0 pb-3 font-weight-medium">
            {{ $t('projects.add_site_to_project_dialog.choose_site_or_create_new_site') }}
          </v-card-subtitle>
          <v-combobox
            v-model="selectedSite"
            :items="filteredSites"
            :label="$t('projects.add_site_to_project_dialog.field_label')"
            item-text="name"
            @change="addNewSite"
            return-object
            outlined
            dense
          ></v-combobox>
          <v-card-subtitle v-if="selectedSites.length > 0" class="pa-0 pt-5 font-weight-medium">
            {{ $t('projects.add_site_to_project_dialog.chosen_sites') }}
          </v-card-subtitle>
          <div
            style="overflow-y: auto; overflow-x: hidden"
            :class="$vuetify.breakpoint.xsOnly && 'mb-10'"
            v-if="dialog"
            :style="{ maxHeight: $vuetify.breakpoint.xsOnly ? 'calc(100vh - 280px)' : 'calc(100vh - 600px)' }"
          >
            <v-list v-if="selectedSites.length > 0" rounded class="mt-2 mx-0 px-0">
              <v-list-item v-for="(site, index) in selectedSites" :key="index" class="my-0 py-0">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ site.name }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn small fab text @click="removeSiteFromProject(index)">
                    <v-icon class="no-gradient" color="red">mdi-delete</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </div>
        </v-card-text>
      </div>
      <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :fab="$vuetify.breakpoint.xsOnly"
          :fixed="$vuetify.breakpoint.xsOnly"
          :bottom="$vuetify.breakpoint.xsOnly"
          :left="$vuetify.breakpoint.xsOnly"
          :text="$vuetify.breakpoint.smAndUp"
          color="default"
          @click.native="cancel()"
          ><v-icon v-if="$vuetify.breakpoint.xsOnly">mdi-close</v-icon
          ><span v-if="$vuetify.breakpoint.smAndUp">{{ $t('cancel') }}</span></v-btn
        >
        <v-btn
          :fab="$vuetify.breakpoint.xsOnly"
          :fixed="$vuetify.breakpoint.xsOnly"
          :bottom="$vuetify.breakpoint.xsOnly"
          :right="$vuetify.breakpoint.xsOnly"
          color="primary"
          @click="addSitesToProject"
          :loading="saving"
          ><v-icon v-if="$vuetify.breakpoint.xsOnly">mdi-plus</v-icon
          ><span v-if="$vuetify.breakpoint.smAndUp">{{ $t('save') }}</span></v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import siteApi from '@/api/site';
import projectApi from '@/api/project';
export default {
  name: 'AddSiteToProjectDialog',
  props: {
    manager: {
      type: Boolean,
      default: false,
    },
    projectId: {
      type: Number,
      default: null,
    },
    projectSites: {
      type: Array,
    },
  },
  data() {
    return {
      dialog: false,
      saving: false,
      selectedSites: [],
      selectedSite: null,
      sites: [],
    };
  },
  computed: {
    filteredSites() {
      const selectedSiteIds = {};
      this.selectedSites.forEach(s => (selectedSiteIds[s.id] = true));
      this.projectSites.forEach(s => (selectedSiteIds[s.id] = true));
      this.sites.forEach(s => {
        if (s.project) {
          selectedSiteIds[s.id] = true;
        }
      });
      return this.sites.filter(s => !selectedSiteIds[s.id]);
    },
  },
  methods: {
    open() {
      this.dialog = true;
    },
    async addNewSite(site) {
      this.selectedSites.push(site);
      this.$showSuccessNotification(site.name + this.$t('projects.add_site_to_project_dialog.site_added_to_projects_sitelist'));
      this.$nextTick(() => {
        this.selectedSite = null;
      });
    },
    async addSitesToProject() {
      this.loading = true;

      try {
        for (let site in this.selectedSites) {
          await projectApi.addSiteToProject(this.selectedSites[site].id, this.projectId);
        }
        this.loading = false;
        this.$showSuccessNotification(this.$t('projects.notifications.sites_added_to_project'));
        this.$emit('sites-added');
        this.dialog = false;
      } catch (error) {
        console.log(error);
        this.$showErrorNotification('Työmaa on toiselle projektille kuormitettuna');
      }
    },
    removeSiteFromProject(index) {
      this.selectedSites.splice(index, 1);
      this.$showErrorNotification(this.$t('projects.add_site_to_project_dialog.site_deleted_from_sites_list'));
    },
    async reloadSites() {
      try {
        this.sites = await siteApi.getSites();
      } catch (error) {
        console.log(error);
        this.$showErrorNotification(this.$t('errors.error_loading_sites'), false);
      }
    },
    cancel() {
      this.dialog = false;
    },
  },
  mounted() {
    this.loading = true;
    this.reloadSites();
    this.loading = false;
  },
};
</script>

<style scoped>
.v-list-item__content {
  padding: 0;
}

.v-list-item__action {
  margin-top: 7px;
  margin-bottom: 7px;
}
</style>
