<template>
  <v-autocomplete
    v-bind="$attrs"
    :clearable="!$attrs.readonly"
    :tabindex="tabindex"
    outlined
    dense
    light
    two-line
    :hide-details="hideDetails"
    ref="select"
    return-object
    class="mb-6 gradient-icons"
    item-value="id"
    autocomplete="off"
    :label="label"
    :item-text="item => [item.code, item.make, item.licenseNumber, item.type && item.type.name].filter(Boolean).join(' ')"
    :items="activeEquipments"
    :disabled="saving"
    :multiple="multiple"
    :value="value"
    @change="
      v => {
        this.$refs.select.blur();
        this.$emit('change', v);
      }
    "
  >
    <template v-slot:prepend>
      <v-icon>mdi mdi-truck</v-icon>
    </template>
    <template v-slot:selection="data">
      <v-list-item style="max-width: 100%" two-line class="ml-n4">
        <v-list-item-content>
          <v-list-item-title>{{ data.item.licenseNumber }} {{ data.item.make }} </v-list-item-title>
          <v-list-item-subtitle
            >{{ [data.item.type && data.item.type.name, data.item.description].filter(Boolean).join(' - ') }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template v-slot:item="data">
      <v-list-item two-line v-bind="data.attrs" v-on="data.on">
        <v-list-item-content>
          <v-list-item-title>{{ data.item.licenseNumber }} {{ data.item.make }}</v-list-item-title>
          <v-list-item-subtitle>{{
            [data.item.type && data.item.type.name, data.item.description].filter(Boolean).join(' - ')
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: 'EquipmentCombobox',
  props: {
    tabindex: {
      type: Number,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    equipments: {
      type: Array,
    },
    value: [Object, Array],
    label: {
      type: String,
      default: '',
    },
    saving: Boolean,
    hideDetails: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    activeEquipments() {
      return this.equipments.filter(e => e.status === 'ACTIVE');
    },
  },
};
</script>

<style scoped></style>
