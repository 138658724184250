var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('hb-basic-page',{staticClass:"worktask-view",attrs:{"title":_vm.$tc('work_tasks.title', 2),"fluid":"","loading":false,"fill-height":true,"extension-height":60,"search-title":((_vm.$t('work_tasks.search')) + "..."),"no-gutters":true},on:{"handle-search":_vm.debouncedSearch},scopedSlots:_vm._u([(!_vm.$isOnlyTenantBasic && _vm.$vuetify.breakpoint.smAndUp)?{key:"app-bar-extension",fn:function(){return [(!_vm.$isOnlyTenantBasic)?_c('v-btn',{staticClass:"mb-3 ml-n1",attrs:{"color":"primary"},on:{"click":_vm.openNewInDialog}},[_c('v-icon',{staticClass:"no-gradient",attrs:{"left":""}},[_vm._v("mdi-plus")]),_c('span',[_vm._v(_vm._s(_vm.$t('work_tasks.new')))])],1):_vm._e()]},proxy:true}:null],null,true)},[(!_vm.$isOnlyTenantBasic && _vm.$vuetify.breakpoint.xsOnly)?_c('v-btn',{attrs:{"color":"primary","fab":"","fixed":"","bottom":"","right":""},on:{"click":_vm.openNewInDialog}},[_c('v-icon',{staticClass:"no-gradient"},[_vm._v("mdi-plus")])],1):_vm._e(),_c('v-col',{staticClass:"gradient-icons",attrs:{"cols":"12"}},[_c('v-fade-transition',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{class:_vm.$vuetify.breakpoint.smAndDown ? 'pb-0' : '',attrs:{"cols":"12","sm":"4","lg":"3","xl":"2"}},[_c('checkbox-filter',{attrs:{"filter":_vm.filter,"filter-options":_vm.filterOptions},on:{"change":_vm.handleFilterChange,"blur":this.reload}})],1),_c('v-col',{attrs:{"xs":"12","md":"6","lg":"3"}},[(!_vm.$isOnlyTenantBasic)?_c('v-select',{attrs:{"items":_vm.richerUsers,"placeholder":("" + (_vm.$t('reports.employee'))),"item-text":"combinedInfo","item-value":"id","multiple":"","hide-details":"","single-line":"","solo":"","clearable":"","background-color":"","append-icon":"filter_list"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0 && _vm.filterUser.length === 1)?[(item.lastName)?_c('span',[_vm._v(_vm._s(item.lastName)+" "+_vm._s(item.firstName))]):_c('span',[_vm._v(_vm._s(item.email))])]:_vm._e(),(_vm.filterUser.length > 1 && index === 0)?[_c('span',[_vm._v(_vm._s(_vm.filterUser.length)+" "+_vm._s(_vm.$t('reports.selections')))])]:_vm._e()]}},{key:"item",fn:function(data){return [_c('v-list-item-title',[(data.item.lastName)?[_vm._v(_vm._s(data.item.lastName)+" "+_vm._s(data.item.firstName))]:[_vm._v(_vm._s(data.item.email))]],2)]}}],null,false,268380038),model:{value:(_vm.filterUser),callback:function ($$v) {_vm.filterUser=$$v},expression:"filterUser"}},[_c('template',{slot:"prepend-inner"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("people")])],1)],2):_vm._e()],1)],1)],1),_c('v-col',{staticClass:"mt-5",attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"h-100 gradient-info__table",staticStyle:{"cursor":"pointer"},attrs:{"headers":_vm.headers,"items":_vm.pagedResult.content,"options":_vm.options,"server-items-length":_vm.pagedResult.totalElements,"loading":_vm.loading,"footer-props":{ itemsPerPageOptions: [50, 100, 500, -1] },"items-per-page":50},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.openInDialog},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [(item.object)?_c('span',[_vm._v(_vm._s(_vm.$t(("work_tasks.objects." + (_vm.$snakeCase(item.object))))))]):_vm._e(),_vm._v(" "),_c('span',[_vm._v(_vm._s(item.title))])]}},{key:"item.assignedUser.lastName",fn:function(ref){
var item = ref.item;
return [(item.assignedUser)?_c('span',[_vm._v(_vm._s(item.assignedUser.lastName)+" "+_vm._s(item.assignedUser.firstName))]):_vm._e()]}},{key:"item.assignerUser.lastName",fn:function(ref){
var item = ref.item;
return [(item.assignerUser)?_c('span',[_vm._v(_vm._s(item.assignerUser.lastName)+" "+_vm._s(item.assignerUser.firstName))]):_vm._e()]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [(item.createdAt)?_c('span',[_vm._v(_vm._s(_vm.$formatDateTime(item.createdAt))+" ")]):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status)?_c('span',[_c('v-icon',[_vm._v(_vm._s(_vm.$workTaskStatusIcon(item.status)))]),_vm._v(" "+_vm._s(_vm.$t(("work_tasks.status." + (item.status))))+" ")],1):_vm._e()]}}])})],1)],1)],1)],1),_c('work-task-dialog',{ref:"dialog",on:{"save":_vm.reload}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }