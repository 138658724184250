<template>
  <v-dialog :fullscreen="$vuetify.breakpoint.smAndDown" v-model="dialog" :width="'calc(100vw - 30%)'" @keydown.esc="cancel()">
    <v-card v-if="dialog" :class="$vuetify.breakpoint.smAndDown && 'rounded-0'">
      <div :class="$vuetify.breakpoint.smAndDown && 'mobile-toolbar'">
        <v-toolbar dark color="primary">
          <v-toolbar-title :style="{ position: 'fixed' }" class="white--text">{{ $t('sites.map_dialog.title') }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="cancel">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <div v-if="!$isOnlyTenantBasic" class="search-box mt-3" :class="$vuetify.breakpoint.smAndDown ? 'px-4' : 'pl-4'">
          <v-text-field
            tabindex="1"
            outlined
            :disabled="loading"
            dense
            hide-details
            :style="{ width: $vuetify.breakpoint.smAndDown ? '100%' : '40%' }"
            class="mb-5"
            :placeholder="$t('sites.map_dialog.search_placeholder')"
            background-color="white"
            v-on:keyup.enter="findAddress"
            item-value=""
            autocomplete="off"
            clearable
            v-model="searchPhrase"
          >
            <template v-slot:append>
              <v-icon @click="findAddress" color="grey">mdi mdi-magnify</v-icon>
            </template>
          </v-text-field>
        </div>
      </div>
      <div
        v-if="loading"
        class="ma-0 pa-0"
        id="loader"
        :style="{ height: $vuetify.breakpoint.smAndDown ? '100vh' : 'calc(100vh - 200px)' }"
      >
        <loading-indicator class="ma-0 pa-0" style="height: 100%" />
      </div>
      <div v-if="!loading" id="map-container">
        <div
          style="width: 100%"
          :style="{
            height: $vuetify.breakpoint.smAndDown ? 'auto' : 'calc(100vh - 200px)',
            paddingTop: $vuetify.breakpoint.smAndDown ? '56px' : '',
          }"
        >
          <l-map
            ref="map"
            :zoom="zoom"
            :center="center"
            :options="mapOptions"
            :style="{
              height: $vuetify.breakpoint.smAndDown ? 'calc(100vh - 56px)' : '100%',
            }"
            @update:center="centerUpdate"
            @update:zoom="zoomUpdate"
            @drag="changeMarkerToCenter"
            @zoom="changeMarkerToCenter"
            :key="executionKey"
          >
            <l-tile-layer :url="url" :attribution="attribution" />
            <l-marker @update:lat-lng="updateMarkerLatLng" :draggable="false" :lat-lng="center"> </l-marker>
          </l-map>
        </div>

        <div
          :class="$vuetify.breakpoint.smAndUp ? 'd-flex justify-end map-dialog-save-location-button' : 'map-dialog-save-location-button'"
        >
          <v-btn
            v-if="!$isOnlyTenantBasic && !settingLocation"
            :fab="$vuetify.breakpoint.xsOnly"
            :fixed="$vuetify.breakpoint.xsOnly"
            :left="$vuetify.breakpoint.xsOnly"
            :bottom="$vuetify.breakpoint.xsOnly"
            tabindex="2"
            :class="$vuetify.breakpoint.xsOnly ? '' : 'mr-3'"
            color="primary"
            :loading="loading"
            @click="setLocation"
            ><v-icon :left="$vuetify.breakpoint.smAndUp" class="no-gradient">mdi mdi-map-marker</v-icon
            ><span v-if="$vuetify.breakpoint.smAndUp">{{ $t('sites.map_dialog.edit_location') }}</span></v-btn
          >
          <v-btn
            v-if="!$isOnlyTenantBasic && settingLocation"
            tabindex="2"
            :fab="$vuetify.breakpoint.xsOnly"
            :fixed="$vuetify.breakpoint.xsOnly"
            :left="$vuetify.breakpoint.xsOnly"
            :bottom="$vuetify.breakpoint.xsOnly"
            :class="$vuetify.breakpoint.xsOnly ? '' : 'mr-3'"
            color="default"
            :loading="loading"
            @click="cancelNewLocation"
            ><v-icon :left="$vuetify.breakpoint.xsOnly" class="no-gradient">mdi mdi-close</v-icon
            ><span v-if="$vuetify.breakpoint.smAndUp">{{ $t('cancel') }}</span></v-btn
          >
          <v-btn
            v-if="!$isOnlyTenantBasic && settingLocation"
            tabindex="2"
            :fab="$vuetify.breakpoint.xsOnly"
            :fixed="$vuetify.breakpoint.xsOnly"
            :right="$vuetify.breakpoint.xsOnly"
            :bottom="$vuetify.breakpoint.xsOnly"
            :class="$vuetify.breakpoint.xsOnly ? '' : 'mr-3'"
            color="primary"
            :loading="loading"
            @click="saveNewLocation"
            ><v-icon :left="$vuetify.breakpoint.smAndUp" class="no-gradient">mdi mdi-map-marker</v-icon
            ><span v-if="$vuetify.breakpoint.smAndUp">{{ $t('sites.map_dialog.save_location') }}</span></v-btn
          >

          <v-btn
            v-if="(!this.settingLocation && this.site.address) || (!this.settingLocation && this.geocoding.address)"
            :fab="$vuetify.breakpoint.xsOnly"
            :fixed="$vuetify.breakpoint.xsOnly"
            :right="$vuetify.breakpoint.xsOnly"
            :bottom="$vuetify.breakpoint.xsOnly"
            tabindex="2"
            :class="$vuetify.breakpoint.xsOnly ? '' : 'mr-3'"
            color="primary"
            :loading="loading"
            @click="goToTheLocation"
            ><v-icon :left="$vuetify.breakpoint.smAndUp" class="no-gradient">mdi mdi-navigation</v-icon
            ><span v-if="$vuetify.breakpoint.smAndUp">{{ $t('sites.go_to_the_location') }}</span></v-btn
          >
        </div>
      </div>
    </v-card>

    <v-snackbar centered v-model="snackbar" multi-line timeout="3000">
      {{ $t('sites.address_changed') }}

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false"> {{ $t('close') }} </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<script>
import { latLng } from 'leaflet';
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import geocodingApi from '@/api/geocoding';
import Immutable from 'immutable';
import LoadingIndicator from '@/components/LoadingIndicator';

let L; // kartta, tulee l-mapista ja on määriteltävä, mutuen eslint virhe

export default {
  name: 'MapDialog',
  components: {
    LoadingIndicator,
    LMap,
    LTileLayer,
    LMarker,
  },
  data() {
    return {
      centerMounted: null,
      settingLocation: false,
      multiLine: true,
      snackbar: false,
      searchPhrase: '',
      loading: false,
      executionKey: (Math.random() + 1).toString(36),
      dialog: false,
      saving: false,
      zoom: 16,
      center: latLng(61.44004, 21.843224),
      //url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      currentZoom: 11.5,
      showParagraph: false,
      mapOptions: {
        dragging: false,
        zoomSnap: 0.5,
        zoomControl: false,
      },
      gettingLocation: false,
      marker: null,
      geocoding: {
        lat: null,
        lng: null,
      },
      site: null,
    };
  },
  methods: {
    setLocation() {
      this.settingLocation = true;
    },
    async goToTheLocation() {
      if (!this.geocoding.address && this.site.address.length > 0) {
        window.open('https://www.google.com/maps/search/?api=1&query=' + this.site.address);
      }
      if (this.site.geocoding.address) {
        if (this.geocoding.lat === this.site.geocoding.lat && this.geocoding.lng === this.site.geocoding.lng) {
          window.open('https://www.google.com/maps/search/?api=1&query=' + this.site.geocoding.lat + '%2C' + this.site.geocoding.lng);
        } else {
          this.snackbar = true;
        }
      }
    },
    changeMarkerToCenter() {
      if (!this.$isOnlyTenantBasic && this.settingLocation) {
        const center = this.$refs.map.mapObject.getCenter();
        this.center = latLng(center.lat, center.lng);
      }
    },

    async findAddress() {
      this.loading = true;
      try {
        const geocoding = (await geocodingApi.getByAddress(this.searchPhrase))?.[0];
        this.center = latLng(geocoding.lat, geocoding.lng);
      } catch {
        this.$showErrorNotification(this.$t('sites.map_dialog.check_search_phrase'));
      }
      this.loading = false;
    },
    async getLocationOfGivenAddress(site) {
      let geocoding;

      if (!this.geocoding.address && site.address.length > 0) {
        geocoding = (await geocodingApi.getByAddress(site.address))?.[0];
      }
      if (this.geocoding.address.length > 0 && site.address.length === 0) {
        geocoding = (await geocodingApi.getByAddress(site.address))?.[0];
      }

      if (!geocoding) return;
      this.center = latLng(geocoding.lat, geocoding.lng);
      this.centerMounted = latLng(geocoding.lat, geocoding.lng);
      this.geocoding.lat = geocoding.lat;
      this.geocoding.lng = geocoding.lng;
    },
    saveNewLocation() {
      this.settingLocation = false;
      this.$emit('change', this.geocoding);
      this.$showSuccessNotification(this.$t('sites.map_dialog.location_saved'));
      //this.dialog = false;
    },
    cancelNewLocation() {
      if (this.geocoding.lat && this.site.geocoding.lng) {
        this.center = latLng(this.site.geocoding.lat, this.site.geocoding.lng);
      } else {
        this.center = this.centerMounted;
      }
      this.settingLocation = false;
    },
    updateMarkerLatLng(marker) {
      this.geocoding.lat = marker.lat;
      this.geocoding.lng = marker.lng;
    },
    async open(site = null) {
      this.searchPhrase = '';
      this.site = site;
      this.dialog = true;
      this.loading = true;
      if (site) this.geocoding = Immutable.fromJS(site.geocoding).toJS();

      if (this.geocoding && !this.geocoding.lat && this.geocoding.address.length === 0 && site.address.length === 0) {
        await this.currentPosition();
      } else if (this.geocoding && !this.geocoding.lat && (this.geocoding.address.length > 0 || site.address.length > 0)) {
        await this.getLocationOfGivenAddress(site);
      } else {
        this.sitePosition();
      }
      this.loading = false;
      this.mapOptions.dragging = true;
      this.draggable = true;
      setTimeout(() => {
        this.generateExecutionKey();
      }, 350);
    },
    cancel() {
      this.loading = false;
      this.settingLocation = false;
      this.searchPhrase = '';
      this.dialog = false;
    },
    generateExecutionKey() {
      this.executionKey = (Math.random() + 1).toString(36);
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    sitePosition() {
      this.center = latLng(this.geocoding.lat, this.geocoding.lng);
    },
    currentPosition() {
      // get position
      return new Promise((resolve, reject) =>
        navigator.geolocation.getCurrentPosition(
          pos => {
            this.center = L.latLng(pos.coords.latitude, pos.coords.longitude);
            this.gettingLocation = true;
            resolve();
          },
          err => {
            this.gettingLocation = false;
            this.errorStr = err.message;
            reject(err.message);
          }
        )
      );
    },
  },
  mounted() {
    setTimeout(function () {
      window.dispatchEvent(new Event('resize'));
    }, 250);
    if (!('geolocation' in navigator)) {
      this.errorStr = 'Geolocation is not available.';
      return;
    }
  },
};
</script>

<style lang="scss">
.map-dialog-save-location-button {
  position: absolute;
  height: 50px;
  width: 100%;
  z-index: 10000 !important;
  margin-top: -62px;
}
.search-box {
  position: absolute;
  height: 50px;
  width: 100%;
  z-index: 10000 !important;
}
.leaflet-right {
  display: none !important;
}
#loader {
  background-image: url('../assets/map_overlay_image.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.mobile-toolbar {
  height: auto;
  position: fixed;
  top: 0;
  z-index: 9999;
  width: 100%;
}
</style>
