<template>
  <v-row>
    <v-col cols="12" md="7">
      <item-combobox
        :readonly="$isOnlyTenantBasic"
        :label="label"
        :saving="saving"
        :value="itemsite"
        :items="items"
        class="mb-0"
        hide-details
        @change="changeItem"
      >
        <template v-slot:prepend>
          <v-icon>{{ icon }}</v-icon>
        </template>
      </item-combobox>
    </v-col>
    <v-col cols="10" md="4">
      <v-text-field
        :readonly="$isOnlyTenantBasic"
        :disabled="saving"
        outlined
        dense
        class="mb-0"
        hide-details
        :label="$t('sites.new_site.amount')"
        light
        item-value=""
        autocomplete="off"
        suffix="kpl"
        type="number"
        :value="amount"
        :clearable="!$isOnlyTenantBasic"
        @change="changeItem"
      >
        <template slot="prepend">
          <div style="width: 25px; text-align: center">
            <v-icon color="grey" size="18"></v-icon>
          </div>
        </template>
      </v-text-field>
    </v-col>
    <v-col :class="$vuetify.breakpoint.xsOnly ? 'pl-4' : 'ml-n1'" class="ma-0 pa-0 d-flex justify-center align-center" cols="1">
      <v-btn v-if="!$isOnlyTenantBasic" icon @click="deleteItem"> <v-icon class="no-gradient" color="error">delete</v-icon></v-btn>
    </v-col>
  </v-row>
</template>

<script>
import ItemCombobox from '@/components/ItemCombobox';
export default {
  name: 'SiteDrillingMultipleItemSelect',
  components: { ItemCombobox },
  props: ['label', 'saving', 'itemsite', 'amount', 'items', 'icon', 'type'],
  methods: {
    changeItem(value) {
      this.$emit('change', value);
    },
    deleteItem() {
      this.$emit('delete');
    },
  },
};
</script>

<style scoped></style>
