import axios from 'axios';

export default {
  async getAllEquipments() {
    return axios({
      method: 'get',
      url: '/api/tenant/equipment',
    }).then(r => r.data);
  },
  async getEquipment(id) {
    return axios({
      method: 'get',
      url: '/api/tenant/equipment/' + id,
    }).then(r => r.data);
  },
  async createEquipment(/*Equipment*/ equipment) {
    return axios({
      method: 'post',
      url: '/api/tenant/equipment',
      data: equipment,
    }).then(r => r.data);
  },
  async updateEquipment(id, /*Equipment*/ equipment) {
    return axios({
      method: 'put',
      url: '/api/tenant/equipment/' + id,
      data: equipment,
    }).then(r => r.data);
  },
};
