<template>
  <div>
    <site-mvr-checks-list v-if='!loading && pastChecks && pastChecks.length' :pastChecks='pastChecks' :site='site'
                          :steps='steps'
                          @select-mvr-check='openMVRCheck' />
    <v-divider v-if='$vuetify.breakpoint.xsOnly && mvrCheck'></v-divider>
    <v-row v-if='!mvrCheck' class='mb-2'>
      <v-col class='text-left'>
        <div
          :class="$vuetify.breakpoint.xsOnly ? 'py-2 px-4 pt-5' : 'mt-0'"
          :style="{
                        width: $vuetify.breakpoint.xsOnly ? '100%' : 'auto',
                      }"
        >
          <v-btn
            v-if='!$isOnlyTenantBasic'
            :style="{ width: $vuetify.breakpoint.xsOnly ? '100%' : 'auto' }"
            color='primary'
            @click='openMVRCheck(mvrTemplate, true)'>
            <v-icon class='no-gradient' left>mdi mdi-plus</v-icon>
            {{ $t('sites.mvr.create_new_check_title') }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-card v-if='mvrCheck != null' :elevation="$vuetify.breakpoint.xsOnly ? 0 : ''" class='site-mvr-check-form'>
      <v-card-title class='pb-4 mb-4'>
        {{ mvrCheck.id ? $t('sites.mvr.check_title') : $t('sites.mvr.create_new_check_title') }}:
        <v-chip v-if='hasOpenNotices' class='ml-4' color='warning'>
          {{ $tc('sites.mvr.check_contains_notices', this.hasOpenNotices) }}
        </v-chip>
        <v-spacer></v-spacer>
        <v-btn icon @click='closeMvrCheck'>
          <v-icon>mdi mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-subtitle v-if='mvrCheck.createdAt' class='grey pt-4 lighten-4'>
        <v-simple-table class='transparent'>
          <tr>
            <th class='text-left'>{{ $t('sites.mvr.checked_at') }}:</th>
            <td class='text-right'>{{ $formatDateNoTime(mvrCheck.createdAt) }}</td>
          </tr>
          <tr>
            <th class='text-left'>{{ $t('sites.mvr.mvr_score') }}:</th>
            <td class='text-right'>{{ getMvrScore }}</td>
          </tr>
          <tr v-if='mvrCheck.inspectorUserId'>
            <th class='text-left'>{{ $t('sites.mvr.inspector_user') }}:</th>
            <td class='text-right'>{{ mvrCheck.inspectorUser.firstName }} {{ mvrCheck.inspectorUser.lastName }}</td>
          </tr>
        </v-simple-table>
      </v-card-subtitle>
      <v-divider v-if='mvrCheck.createdAt'></v-divider>
      <v-card-text class='pt-4'>
        <v-text-field
          v-model='mvrCheck.mittauksensuorittaja'
          :disabled='disableInputs'
          :hint="$t('sites.mvr.performed_by_hint')"
          :label="$t('sites.mvr.performed_by')"
          :placeholder="$userInfo.firstName + ' ' + $userInfo.lastName"
          class='mt-2'
          clearable
          outlined
        />
      </v-card-text>
      <div v-for='step in steps' :key='step'>
        <v-card-subtitle>{{ $t(`sites.mvr.steps.${step}`) }}</v-card-subtitle>
        <v-card-text>
          <v-row>
            <v-col class='d-flex' cols='6' xs='12'>
              <v-text-field v-model='mvrCheck[step].correctItems' :disabled='disableInputs'
                            :label="$t('sites.mvr.correct')"
                            dense
                            hide-details
                            outlined>
                <template v-if='!mvrCheck.id && !mvrCheck.createdUserId' v-slot:prepend-inner>
                  <v-btn color='primary' icon
                         style='margin-left: -10px; margin-top: -6px;'
                         @click.stop='incrementStepCorrectTally(step, true)'>
                    <v-icon>mdi mdi-minus-circle</v-icon>
                  </v-btn>
                </template>
                <template v-if='!mvrCheck.id && !mvrCheck.createdUserId' v-slot:append>
                  <v-btn color='success' icon style='margin-right: -10px; margin-top: -6px;'
                         @click.stop='incrementStepCorrectTally(step)'>
                    <v-icon>mdi mdi-plus-circle</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
            <v-col class='d-flex' cols='6' xs='12'>
              <v-text-field
                v-model='mvrCheck[step].incorrectItems'
                :disabled='disableInputs'
                :label="$t('sites.mvr.incorrect')"
                dense
                hide-details outlined
              >
                <template v-if='!mvrCheck.id && !mvrCheck.createdUserId' v-slot:prepend-inner>
                  <v-btn color='primary' icon
                         style='margin-left: -10px; margin-top: -6px;'
                         @click.stop='incrementStepIncorrectTally(step, true)'>
                    <v-icon>mdi mdi-minus-circle</v-icon>
                  </v-btn>
                </template>
                <template v-if='!mvrCheck.id && !mvrCheck.createdUserId' v-slot:append>
                  <v-btn color='success' icon style='margin-right: -10px; margin-top: -6px;'
                         @click.stop='incrementStepIncorrectTally(step)'>
                    <v-icon>mdi mdi-plus-circle</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </div>
      <v-divider />
      <!-- huomautukset tai korjattavat -->
      <v-card-title>
        {{ (mvrCheck.notices && mvrCheck.notices.length) || !mvrCheck.id ? $t('sites.mvr.notices') : $t('sites.mvr.no_notices')
        }}
      </v-card-title>
      <v-card-text>
        <v-data-table
          v-if='mvrCheck.notices && mvrCheck.notices.length'
          :headers='computedHeaders'
          :items='mvrCheck.notices'
          class='transparent'
          hide-default-footer
        >
          <template v-slot:item.notice='{ item }'>
            <td>{{ item.notice }}</td>
          </template>
          <template v-slot:item.user='{ item }'>
            <td>{{ item.user.firstName }} {{ item.user.lastName }}</td>
          </template>
          <template v-slot:item.fixedUser='{ item }'>
            <td>
              {{
                item.fixedUser
                  ? item.fixedUser.firstName + ' ' + item.fixedUser.lastName
                  : $t('sites.mvr.not_fixed')
              }}
            </td>
          </template>
          <template v-slot:item.fixedDate='{ item }'>
            <td>
              {{
                item.fixedDate
                  ? $formatDateNoTime(item.fixedDate)
                  : $t('sites.mvr.not_fixed')
              }}
            </td>
          </template>
          <template v-if='!newCheck && hasOpenNotices' v-slot:item.mark_fixed='{ item }'>
            <td>
              <v-btn
                v-if='!item.fixedUser'
                class='success'
                dark
                icon
                outlined
                small
                @click.stop='markNoticeFixed(item.id)'
              >
                <v-icon>mdi mdi-check</v-icon>
              </v-btn>
            </td>
          </template>
        </v-data-table>

        <template v-if='!mvrCheck.id'>
          <v-form ref='newNotice'>
            <v-autocomplete
              v-if='!disableInputs'
              v-model='newNotice.userId'
              :hint="$t('sites.mvr.user_hint')"
              :item-text='(item) => item.firstname + " " + item.lastname'
              :item-value='(item) => item.userId'
              :items='siteUsers'
              :label="$t('sites.mvr.responsible_user')"
              :rules='[rules.required]'
              class='mt-4'
              outlined
              validate-on-blur
            />
            <v-textarea
              v-if='!mvrCheck.id && !mvrCheck.createdUserId'
              v-model='newNotice.notice'
              :hint="$t('sites.mvr.notices_hint')"
              :label="$t('sites.mvr.new_notice')"
              :rules='[rules.required]'
              clearable
              outlined
              persistent-hint
              validate-on-blur
            />
            <v-alert v-if='noticeInvalid' text type='error'>
              {{ $t('validation.check_all_required_fields') }}
            </v-alert>
          </v-form>
          <v-btn
            v-if='!mvrCheck.id && !mvrCheck.createdUserId'
            :outlined='$vuetify.breakpoint.xsOnly'
            block
            class='mt-2'
            color='primary'
            dark
            primary
            small
            @click='addNotice'
          >{{ $t('sites.mvr.add_notice') }}
          </v-btn>
        </template>
      </v-card-text>
      <v-spacer></v-spacer>
      <v-divider></v-divider>
      <v-card-text>
        <v-alert v-if='validationErrors.length > 0' text type='info'>
          {{ $t('sites.mvr.validation_error_message') }}
        </v-alert>
        <v-btn
          v-if='!disableInputs'
          :disabled='submitting'
          :loading='submitting'
          :small='$vuetify.breakpoint.xsOnly'
          block
          color='primary'
          @click='saveMvrCheck'
        >
          {{ $t('save') }}
        </v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import mvrCheckApi from '@/api/mvr';
import SiteMvrChecksList from '@/components/Sites/SiteMvrChecksList';

export default {
  name: 'SiteMvrChecks',
  components: { SiteMvrChecksList },
  props: {
    site: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      submitting: false,
      newCheck: false,
      mvrCheck: null,
      steps: [
        'tyoskentelyjakoneenkaytto',
        'kalustosahkotjavalaistus',
        'suojauksetjavaroalueet',
        'ajojakulkuvaylat',
        'jarjestysjavarastointi',
      ],
      mvrTemplate: {
        id: null,
        workerUserId: null,
        inspectorUserId: null,
        site: this.site,
        mittauksensuorittaja: null,
        tyoskentelyjakoneenkaytto: { correctItems: 0, incorrectItems: 0 },
        kalustosahkotjavalaistus: { correctItems: 0, incorrectItems: 0 },
        suojauksetjavaroalueet: { correctItems: 0, incorrectItems: 0 },
        ajojakulkuvaylat: { correctItems: 0, incorrectItems: 0 },
        jarjestysjavarastointi: { correctItems: 0, incorrectItems: 0 },
        notices: [],
      },
      newNotice: null,
      noticeTemplate: {
        id: null,
        notice: null,
        userId: null,
        fixedUser: null,
      },
      noticeInvalid: false,
      pastChecks: [],
      headersTemplate: [
        { text: this.$t('sites.mvr.notice'), value: 'notice' },
        { text: this.$t('sites.mvr.responsible_user'), value: 'user' },
        { text: this.$t('sites.mvr.fixed_user'), value: 'fixedUser' },
        { text: this.$t('sites.mvr.fixed_date'), value: 'fixedDate' },
      ],
      rules: {
        required: value => !!value || this.$t('validation.field_is_required'),
      },
    };
  },
  computed: {
    siteUsers() {
      return this.site.workers;
    },
    validationErrors() {
      return false;
    },
    disableInputs() {
      return !!this.mvrCheck.id;
    },
    getMvrScore() {
      let score = 0;
      let totalItems = 0;
      this.steps.forEach(step => {
        const tally = this.mvrCheck[step];
        if (tally) {
          totalItems += tally.correctItems + tally.incorrectItems;
          score += tally.correctItems;
        }
      });
      if (totalItems === 0) {
        return 0;
      }
      return Math.round((score / totalItems) * 100);
    },
    computedHeaders() {
      const headers = [...this.headersTemplate];
      if (this.hasOpenNotices && !this.newCheck) {
        return [...headers,
          { text: this.$t('sites.mvr.mark_fixed'), value: 'mark_fixed', sortable: false },
        ];
      } else {
        return headers;
      }
    },
    hasOpenNotices() {
      if (!this.mvrCheck) {
        return false;
      }
      return this.mvrCheck.notices.filter(notice => !notice.fixedDate).length;
    },
  },
  methods: {
    closeMvrCheck() {
      this.mvrCheck = null;
    },
    async getSiteMvrChecks() {
      const checks = await mvrCheckApi.getMVRsBySite(this.site.id);
      if (checks && checks.length) {
        this.pastChecks.splice(0, this.pastChecks.length, ...checks);
      }
    },
    openMVRCheck(mvrCheck, newCheck = false) {
      this.newCheck = newCheck;
      const mvrCheckCopy = JSON.parse(JSON.stringify(mvrCheck));
      this.steps.forEach(step => {
        if (typeof mvrCheckCopy[step] === 'string') {
          mvrCheckCopy[step] = this.convertTallyToFrontendFormat(mvrCheckCopy[step]);
        }
      });
      this.mvrCheck = mvrCheckCopy;
      if (newCheck) {
        this.setSelfAsPerformer();
      }
      if (!this.mvrCheck.id) this.newNotice = { ...this.noticeTemplate };
    },
    convertTallyToFrontendFormat(tally) {
      if (!tally) return { correctItems: 0, incorrectItems: 0 };
      const tallyArray = tally.split(';');
      return {
        correctItems: parseInt(tallyArray[0], 10),
        incorrectItems: parseInt(tallyArray[1], 10),
      };
    },
    incrementStepCorrectTally(stepKey, decrement = false) {
      const step = this.mvrCheck[stepKey];
      if (decrement) {
        if (step.correctItems > 0) step.correctItems -= 1;
        return;
      }
      step.correctItems += 1;
    },
    incrementStepIncorrectTally(stepKey, decrement = false) {
      const step = this.mvrCheck[stepKey];
      if (decrement) {
        if (step.incorrectItems > 0) step.incorrectItems -= 1;
        return;
      }
      step.incorrectItems += 1;
    },
    async saveMvrCheck() {
      this.submitting = true;

      if (this.newNotice && this.newNotice.notice) {
        await this.addNotice();
      }

      const mvrCheck = { ...this.mvrCheck };
      if (!mvrCheck.site) mvrCheck.site = this.site;

      this.steps.forEach(step => {
        mvrCheck[step] = `${mvrCheck[step].correctItems};${mvrCheck[step].incorrectItems}`;
      });

      console.log(JSON.stringify(mvrCheck, null, 2));

      try {
        const response = await mvrCheckApi.createMVR(mvrCheck);
        if (response && !!response.id) {
          this.newCheck = false;
          await this.markInspected(response.id);
        }
      } catch (e) {
        this.$handleApiError(e);
      }
      await this.getSiteMvrChecks();
      this.submitting = false;
    },
    setSelfAsPerformer() {
      if (!this.mvrCheck) return;
      this.mvrCheck.mittauksensuorittaja = this.$userInfo.firstName + ' ' + this.$userInfo.lastName;
    },
    addNotice() {
      if (!this.$refs.newNotice.validate()) {
        this.noticeInvalid = true;
        return;
      }
      const newNotice = { ...this.newNotice };
      const user = this.siteUsers.find(u => u.userId === newNotice.userId);
      if (user) {
        newNotice.user = {
          userId: user.userId,
          firstName: user.firstname,
          lastName: user.lastname,
        };
      }
      this.mvrCheck.notices.push(newNotice);
      this.newNotice = { ...this.noticeTemplate };
    },
    async markNoticeFixed(id) {
      const currentId = this.mvrCheck.id;
      const notice = this.mvrCheck.notices.find(n => n.id === id);
      if (!notice) return;
      try {
        await mvrCheckApi.markNoticeFixed(this.mvrCheck.id, notice.id);
      } catch (e) {
        this.$handleApiError(e);
      }
      await this.getSiteMvrChecks();
      this.openMVRCheck(this.pastChecks.find(c => c.id === currentId));
    },
    async markInspected(id = null) {
      if (!id) return;
      this.submitting = true;
      try {
        await mvrCheckApi.markMVRInspected(id);
        this.closeMvrCheck();
      } catch (e) {
        console.error(e);
      }
      this.submitting = false;
    },
  },
  async mounted() {
    await this.getSiteMvrChecks();
    this.loading = false;
  },
};
</script>

<style scoped>
</style>
