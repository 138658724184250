<template>
  <v-dialog v-model="dialog" max-width="600" @keydown.esc="cancel()" persistent :fullscreen="$vuetify.breakpoint.xsOnly">
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title class="white--text">{{ newItem ? 'Uusi nimike' : 'Muokkaa nimikettä' }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="cancel">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-5 gradient-icons">
        <v-form ref="form">
          <v-text-field
            :disabled="saving || !isEditable"
            outlined
            required
            dense
            :label="$t('items.item_name')"
            :rules="[$rules.required]"
            v-model="item.name"
            validate-on-blur
            v-on:keydown.enter.prevent="newItem ? createItem : saveItem"
            clearable

          >
            <template v-slot:prepend>
              <v-icon>mdi-text</v-icon>
            </template>
          </v-text-field>

          <v-autocomplete
            v-if="newItem"
            :disabled="saving || !isEditable"
            v-model="item.type"
            :rules="[$rules.required]"
            :items="itemTypes"
            item-text="name"
            item-value="value"
            :label="$t('items.item_type')"
            outlined
            dense
            validate-on-blur
            v-on:keydown.enter.prevent="newItem ? createItem : saveItem"
          >
            <template v-slot:prepend>
              <v-icon>mdi-cards-variant</v-icon>
            </template>
          </v-autocomplete>
        </v-form>
        <v-alert :value="showAlert" outlined type="error">{{ $t('validation.check_all_required_fields') }}</v-alert>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions :class="$vuetify.breakpoint.xsOnly ? 'flex-column-reverse' : ''">
        <v-spacer></v-spacer>
        <v-btn
          :fab="$vuetify.breakpoint.xsOnly"
          :bottom="$vuetify.breakpoint.xsOnly"
          :left="$vuetify.breakpoint.xsOnly"
          :fixed="$vuetify.breakpoint.xsOnly"
          :class="$vuetify.breakpoint.xsOnly ? 'ma-0 pa-0' : ''"
          color="default"
          @click.native="cancel()"
          ><v-icon v-if="$vuetify.breakpoint.xsOnly">mdi-close</v-icon
          ><span v-if="$vuetify.breakpoint.smAndUp">{{ $t('cancel') }}</span></v-btn
        >
        <v-btn
          :fab="$vuetify.breakpoint.xsOnly"
          :bottom="$vuetify.breakpoint.xsOnly"
          :right="$vuetify.breakpoint.xsOnly"
          :fixed="$vuetify.breakpoint.xsOnly"
          :class="$vuetify.breakpoint.xsOnly ? 'ma-0 pa-0' : ''"
          v-if="!newItem && isEditable"
          color="primary"
          @click.native="saveItem()"
          ><v-icon v-if="$vuetify.breakpoint.xsOnly">mdi-content-save</v-icon
          ><span v-if="$vuetify.breakpoint.smAndUp">{{ $t('save') }}</span></v-btn
        >
        <v-btn
          :fab="$vuetify.breakpoint.xsOnly"
          :bottom="$vuetify.breakpoint.xsOnly"
          :right="$vuetify.breakpoint.xsOnly"
          :fixed="$vuetify.breakpoint.xsOnly"
          :class="$vuetify.breakpoint.xsOnly ? 'ma-0 pa-0' : ''"
          v-if="newItem"
          color="primary"
          @click.native="createItem()"
          ><v-icon v-if="$vuetify.breakpoint.xsOnly">mdi-plus</v-icon
          ><span v-if="$vuetify.breakpoint.smAndUp">{{ $t('save') }}</span></v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import itemApi from '@/api/item';
import { types } from '@/views/tenant/ItemsView';
const itemTemplate = {
  id: null,
  name: '',
  type: '',
};
export default {
  name: 'AddOrEditItemDialog',
  data() {
    return {
      itemTypes: types.map(type => {
        return {
          name: this.$tc('items.' + type, 0),
          value: type,
        };
      }),
      showAlert: false,
      title: '',
      newItem: null,
      saving: false,
      dialog: false,
      item: { ...itemTemplate },

    };
  },
  computed: {
    isEditable(){
      const uneditableTypes = ['työtehtävä','poissaolo']
      return this.newItem || !uneditableTypes.includes(this.item.type)
    }
  },
  methods: {
    open(itemId, newItem, type) {
      this.dialog = true;
      this.newItem = newItem;
      this.$refs.form?.reset();
      this.item = { ...itemTemplate };
      if (!this.newItem) {
        this.item.id = itemId;
        this.getItem();
      } else {
        this.item.type = type;
      }
    },
    cancel() {
      this.item.id = '';
      this.item.name = '';
      this.item.type = '';
      this.newItem = null;
      this.showAlert = false;
      this.dialog = false;
    },
    async saveItem() {
      if (!this.item.name) {
        this.showAlert = true;
        return;
      }

      try {
        this.saving = true;
        await itemApi.updateItem(this.item.id, this.item);
        this.$emit('updated');
        this.cancel();
      } catch (err) {
        this.$handleApiError(err);
      }
      this.saving = false;
    },
    async createItem() {
      if (!this.item.name || !this.item.type) {
        this.showAlert = true;
        return;
      }

      try {
        this.saving = true;
        await itemApi.createItem(this.item);
        this.$emit('created');
        this.cancel();
      } catch (err) {
        this.$handleApiError(err);
      }
      this.saving = false;
    },
    async getItem() {
      this.saving = true;

      this.item = await itemApi.getItem(this.item.id);
      this.saving = false;
    },
  },
};
</script>

<style>
.bottom_fixed {
  position: fixed;
  bottom: 0;
}
</style>
