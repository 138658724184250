<template>
  <hb-basic-page
    :title="this.$route.params.id ? $t('daybook.edit_daybook') : $t('daybook.new_daybook')"
    :loading="loading"
    :fill-height="loading"
    :back-button="backButton"
    :extension-height="47"
  >
    <v-col md="8" offset-md="2" lg="8" offset-lg="2" class="gradient-icons" :style="$vuetify.breakpoint.xsOnly && 'margin-bottom: 55px;'">
      <v-card class="mb-5">
        <v-card-title class="subtitle-1 text-uppercase font-weight-medium v-card-title-gradient"
          >{{ $tc('daybook.title', 0) }}
        </v-card-title>
        <v-card-text>
          <site-combobox
            :saving="saving || true"
            :multiple="false"
            :label="$t('daybook.site')"
            :sites="sites"
            :value="daybook.site"
            :readonly="true"
            class="mt-5"
            @change="handleSiteChange"
          />
          <date-picker
            :input-styles="{ outlined: true, class: 'mb-5' }"
            :date="daybook.daybookDate"
            :label="$t('daybook.daybook_date')"
            @change="
              value => {
                handleDaybookDateChange(value, '');
                handleDaybookDateChange(value, 'Wait');
                handleDaybookDateChange(value, 'Wait2');
                handleDaybookDateChange(value, 'Wait3');
              }
            "
          ></date-picker>

          <!--<item-combobox
            :label="$t('daybook.method')"
            :saving="saving"
            :value="daybook.method"
            :items="methods"
            :multiple="false"
            @change="v => (daybook.method = v)"
          >
            <template v-slot:prepend>
              <v-icon>mdi mdi-shovel</v-icon>
            </template>
          </item-combobox>-->
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text>
          <label class="v-label">{{ $t('daybook.entry_times') }}</label>
          <v-row no-gutters class="">
            <entry-times
              ref="entryTimes"
              :current-entry="daybook"
              :dialog="true"
              :show-date="false"
              :contains-lunch="daybook.containsLunch"
              :handle-duration-dialog-save="handleDurationDialogSave"
              class="mt-3 mb-2"
            ></entry-times>
            <v-col cols="12" :class="$vuetify.breakpoint.mdAndUp ? 'pl-5' : ''">
              <v-checkbox
                :class="$vuetify.breakpoint.smAndDown ? 'ml-8' : ''"
                dense
                v-model="daybook.containsLunch"
                :label="$t('daybook.contains_lunch')"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text>
          <label class="v-label">{{ $t('daybook.entry_wait_times') }}</label>
          <br />
          <br />
          <br />
          <v-row>
            <v-col>
              <v-btn v-if="waitTimes < 3" @click="waitTimes++" class="ml-2">
                <v-icon>mdi mdi-plus</v-icon> {{ $t('add') }} {{ $t('daybook.wait_time') }}
              </v-btn>
            </v-col>
          </v-row>

          <!-- ODOTUSAIKA 1 -->
          <v-col>
            <v-row>
              <entry-times
                ref="entryTimesWait"
                :current-entry="daybook"
                :clearable="true"
                :contains-lunch="false"
                :dialog="true"
                :show-date="false"
                appendix="Wait"
                :handle-duration-dialog-save="handleDurationDialogSave"
                class="mt-3 mb-2"
              />
            </v-row>
            <v-row>
              <v-textarea
                :disabled="saving"
                outlined
                dense
                rows="2"
                hide-details
                class="mb-5"
                :label="$t('daybook.wait_time_description_1') + 1 + $t('daybook.wait_time_description_2')"
                light
                item-value=""
                autocomplete="off"
                clearable
                auto-grow
                v-model="daybook.entryWaitDescription"
              >
                <template v-slot:prepend>
                  <v-icon>mdi mdi-progress-question</v-icon>
                </template>
              </v-textarea>
            </v-row>
          </v-col>

          <!-- ODOTUSAIKA 2 -->
          <v-col no-gutters class="" v-if="waitTimes > 1">
            <v-row cols="12">
              <label>{{ $t('daybook.wait_time') }} 2</label>
            </v-row>
            <v-row>
              <entry-times
                ref="entryTimesWait2"
                :current-entry="daybook"
                :clearable="true"
                :contains-lunch="false"
                :dialog="true"
                :show-date="false"
                appendix="Wait2"
                :handle-duration-dialog-save="handleDurationDialogSave"
                class="mt-3 mb-2"
              />
            </v-row>
            <v-row>
              <v-textarea
                :disabled="saving"
                outlined
                dense
                rows="2"
                hide-details
                class="mb-5"
                :label="$t('daybook.wait_time_description_1') + 2 + $t('daybook.wait_time_description_2')"
                light
                item-value=""
                autocomplete="off"
                clearable
                auto-grow
                v-model="daybook.entryWait2Description"
              >
                <template v-slot:prepend>
                  <v-icon>mdi mdi-progress-question</v-icon>
                </template>
              </v-textarea>
            </v-row>
          </v-col>

          <!-- ODOTUSAIKA 3 -->
          <v-col no-gutters class="" v-if="waitTimes > 2">
            <v-row cols="12">
              <label>{{ $t('daybook.wait_time') }} 3</label>
            </v-row>
            <v-row>
              <entry-times
                ref="entryTimesWait3"
                :current-entry="daybook"
                :clearable="true"
                :contains-lunch="false"
                :dialog="true"
                :show-date="false"
                appendix="Wait3"
                :handle-duration-dialog-save="handleDurationDialogSave"
                class="mt-3 mb-2"
              />
            </v-row>
            <v-row>
              <v-textarea
                :disabled="saving"
                outlined
                dense
                rows="2"
                hide-details
                class="mb-5"
                :label="$t('daybook.wait_time_description_1') + 3 + $t('daybook.wait_time_description_2')"
                light
                item-value=""
                autocomplete="off"
                clearable
                auto-grow
                v-model="daybook.entryWait3Description"
              >
                <template v-slot:prepend>
                  <v-icon>mdi mdi-progress-question</v-icon>
                </template>
              </v-textarea>
            </v-row>
          </v-col>
        </v-card-text>

        <v-divider></v-divider>

        <daybook-item
          type="additionalWork"
          icon="mdi mdi-page-next"
          :daybook="daybook"
          :daybook-item-template="daybookItemTemplate"
          :delete-message="$t('daybook.additional_work_confirm_delete')"
          :label="$tc('daybook.additional_work', 0)"
          :saving="saving"
        />

        <v-divider></v-divider>

        <v-card-text>
          <v-textarea
            :disabled="saving"
            outlined
            dense
            rows="2"
            hide-details
            class="mb-5 mt-4"
            :label="$t('daybook.site_situation')"
            light
            item-value=""
            autocomplete="off"
            clearable
            auto-grow
            v-model="daybook.siteSituation"
          >
            <template v-slot:prepend>
              <v-icon>mdi mdi-progress-question</v-icon>
            </template>
          </v-textarea>
        </v-card-text>
        <v-divider></v-divider>
        <!--<v-card-text>
          <v-textarea
            :disabled="saving"
            outlined
            dense
            rows="2"
            hide-details
            class="mb-5 mt-4"
            :label="$tc('daybook.deviation', 2)"
            light
            item-value=""
            autocomplete="off"
            clearable
            auto-grow
            v-model="daybook.deviation"
          >
            <template v-slot:prepend>
              <v-icon>mdi mdi-image-broken-variant</v-icon>
            </template>
          </v-textarea>
          <v-checkbox class="ml-7" :label="$t('daybook.deviation_report_made')" v-model="daybook.deviationReportMade"></v-checkbox>
        </v-card-text>-->
        <daybook-item
          type="deviation"
          icon="mdi mdi-image-broken-variant"
          :daybook="daybook"
          :daybook-item-template="daybookItemTemplate"
          :delete-message="$t('daybook.deviation_confirm_delete')"
          :label="$tc('daybook.deviation', 0)"
          :saving="saving"
          :enable-files="true"
          ref="deviationDaybookItems"
        />
        <v-divider></v-divider>
        <v-card-text>
          <v-textarea
            :disabled="saving"
            outlined
            dense
            rows="2"
            hide-details
            class="mb-5 mt-4"
            :label="$t('daybook.orderer_responsibility_items')"
            light
            item-value=""
            autocomplete="off"
            clearable
            auto-grow
            v-model="daybook.ordererResponsibilityItems"
          >
            <template v-slot:prepend>
              <v-icon>mdi mdi-folder-star-multiple-outline</v-icon>
            </template>
          </v-textarea>
          <v-textarea
            :disabled="saving"
            outlined
            dense
            rows="2"
            hide-details
            class="mb-5 mt-4"
            :label="$t('daybook.reviews_held')"
            light
            item-value=""
            autocomplete="off"
            clearable
            auto-grow
            v-model="daybook.reviewsHeld"
          >
            <template v-slot:prepend>
              <v-icon>mdi mdi-message-draw</v-icon>
            </template>
          </v-textarea>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text>
          <!--<item-combobox
            :label="$t('daybook.diameter')"
            :saving="saving"
            :value="daybook.diameter"
            :items="diameters"
            @change="v => (daybook.diameter = v)"
            class="mt-4"
          >
            <template v-slot:prepend>
              <v-icon>mdi mdi-diameter-outline</v-icon>
            </template>
          </item-combobox>-->

          <template v-if="daybook.site">
            <div v-for="(drilling, key) in this.daybook.daybookDrillings" :key="key">
              <v-row>
                <v-col>
                  <v-card-title class="gradient-info">
                    {{
                      ['Poraus ' + (key + 1), drilling.diameter && drilling.diameter.name, drilling.method && drilling.method.name]
                        .filter(Boolean)
                        .join(' - ')
                    }}</v-card-title
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field
                    :disabled="true"
                    outlined
                    dense
                    hide-details
                    class=""
                    :label="$t('daybook.drilling_length')"
                    light
                    autocomplete="off"
                    clearable
                    readonly
                    suffix="m"
                    v-model.number="drilling.drillingLength"
                  >
                    <template v-slot:prepend>
                      <v-icon>mdi mdi-arrow-expand-horizontal</v-icon>
                    </template>
                  </v-text-field>
                </v-col>

                <v-col v-if="daybook.site" cols="12" md="4">
                  <numeric-input
                    :disabled="saving"
                    outlined
                    dense
                    class=""
                    :label="$t('daybook.drilling_length_at_day')"
                    hide-details
                    light
                    autocomplete="off"
                    clearable
                    suffix="m"
                    raw
                    v-model.number="drilling.drillingLengthAtDay"
                    @input="calculateTotalLengths"
                  >
                    <template v-slot:prepend v-if="$vuetify.breakpoint.smAndDown">
                      <v-icon>mdi mdi-arrow-expand-horizontal</v-icon>
                    </template>
                  </numeric-input>
                </v-col>

                <v-col cols="12" md="4">
                  <numeric-input
                    :disabled="saving"
                    outlined
                    dense
                    hide-detailsordered
                    class=""
                    :label="$t('daybook.of_which_rock')"
                    light
                    item-value=""
                    autocomplete="off"
                    clearable
                    suffix="m"
                    v-model.number="drilling.ofWhichRock"
                  >
                    <template v-slot:prepend v-if="$vuetify.breakpoint.smAndDown">
                      <v-icon>mdi mdi-image-filter-hdr</v-icon>
                    </template>
                  </numeric-input>
                </v-col>
              </v-row>
              <v-row class="mb-5">
                <v-col cols="12" md="6">
                  <numeric-input
                    :disabled="saving"
                    outlined
                    dense
                    hide-details
                    class=""
                    :label="$t('daybook.elevation_top_end')"
                    light
                    item-value=""
                    autocomplete="off"
                    clearable
                    suffix="m"
                    v-model.number="drilling.elevationTopEnd"
                  >
                    <template v-slot:prepend>
                      <v-icon>mdi mdi-elevation-rise</v-icon>
                    </template>
                  </numeric-input>
                  <!--<v-text-field
                    :disabled="saving"
                    outlined
                    dense
                    hide-details
                    class=""
                    :label="$t('daybook.elevation_top_end')"
                    light
                    item-value=""
                    autocomplete="off"
                    clearable
                    suffix="m"
                    v-model.number="drilling.elevationTopEnd"
                  >
                    <template v-slot:prepend>
                      <v-icon>mdi mdi-elevation-rise</v-icon>
                    </template>
                  </v-text-field>-->
                </v-col>
                <v-col cols="12" md="6">
                  <numeric-input
                    :disabled="saving"
                    outlined
                    dense
                    hide-details
                    class=""
                    :label="$t('daybook.elevation_lower_end')"
                    light
                    item-value=""
                    autocomplete="off"
                    clearable
                    suffix="m"
                    v-model.number="drilling.elevationLowerEnd"
                  >
                    <template v-slot:prepend>
                      <v-icon>mdi mdi-elevation-decline</v-icon>
                    </template>
                  </numeric-input>
                  <!--<v-text-field
                    :disabled="saving"
                    outlined
                    dense
                    hide-details
                    class=""
                    :label="$t('daybook.elevation_lower_end')"
                    light
                    item-value=""
                    autocomplete="off"
                    clearable
                    suffix="m"
                    v-model.number="drilling.elevationLowerEnd"
                  >
                    <template v-slot:prepend v-if="$vuetify.breakpoint.smAndDown">
                      <v-icon>mdi mdi-elevation-decline</v-icon>
                    </template>
                  </v-text-field>-->
                </v-col>
                <v-divider></v-divider>
              </v-row>
            </div>
          </template>
        </v-card-text>
        <v-divider></v-divider>
        <daybook-item
          type="bought"
          icon="mdi mdi-cart-arrow-down"
          :daybook="daybook"
          :daybook-item-template="daybookItemTemplate"
          :delete-message="$t('daybook.bought_confirm_delete')"
          :label="$tc('daybook.bought', 0)"
          :saving="saving"
          :enable-files="true"
          ref="boughtDaybookItems"
        />
        <v-divider></v-divider>
        <daybook-item
          type="ordered"
          icon="mdi mdi-shopping-outline"
          :daybook="daybook"
          :daybook-item-template="daybookItemTemplate"
          :delete-message="$t('daybook.ordered_confirm_delete')"
          :label="$tc('daybook.ordered', 0)"
          :saving="saving"
          :enable-files="true"
          ref="orderedDaybookItems"
        />
        <v-divider></v-divider>
        <v-card-text>
          <v-textarea
            :disabled="saving"
            outlined
            dense
            rows="2"
            hide-details
            class="mb-5 mt-4"
            :label="$t('daybook.orderer_notices')"
            light
            item-value=""
            autocomplete="off"
            clearable
            auto-grow
            v-model="daybook.ordererNotices"
          >
            <template v-slot:prepend>
              <v-icon>mdi mdi-message-alert</v-icon>
            </template>
          </v-textarea>
          <v-textarea
            :disabled="saving"
            outlined
            dense
            rows="2"
            hide-details
            class="mb-5 mt-4"
            :label="$t('daybook.other')"
            light
            item-value=""
            autocomplete="off"
            clearable
            auto-grow
            v-model="daybook.other"
          >
            <template v-slot:prepend>
              <v-icon>mdi mdi-frequently-asked-questions</v-icon>
            </template>
          </v-textarea>
        </v-card-text>
      </v-card>
    </v-col>
    <div class="button_group">
      <v-btn
        :fab="$vuetify.breakpoint.xsOnly"
        :fixed="$vuetify.breakpoint.xsOnly"
        :bottom="$vuetify.breakpoint.xsOnly"
        :left="$vuetify.breakpoint.xsOnly"
        @click="cancel"
        class="default mr-3"
        ><v-icon v-if="$vuetify.breakpoint.xsOnly">mdi mdi-close</v-icon
        ><span v-if="$vuetify.breakpoint.smAndUp">{{ $t('cancel') }}</span></v-btn
      >
      <v-btn
        :fab="$vuetify.breakpoint.xsOnly"
        :fixed="$vuetify.breakpoint.xsOnly"
        :bottom="$vuetify.breakpoint.xsOnly"
        :right="$vuetify.breakpoint.xsOnly"
        @click="saveDaybook"
        class="primary mr-3"
        :loading="saving"
      >
        <v-icon :left="$vuetify.breakpoint.smAndUp">mdi-content-save</v-icon>
        <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('save') }}</span>
      </v-btn>
    </div>
  </hb-basic-page>
</template>

<script>
import siteApi from '@/api/site';
import itemApi from '@/api/item';
import personApi from '@/api/person';
import workGroupApi from '@/api/workGroup';
import equipmentApi from '@/api/equipment';
import projectApi from '@/api/project';
import workerApi from '@/api/worker';
import daybookApi from '@/api/daybook';
import dayjs from 'dayjs';
import DatePicker from '@/components/DatePicker';
import SiteCombobox from '@/components/SiteCombobox';
import EntryTimes from '@/components/Timer/EntryTimes';
import DaybookItem from '@/components/SiteDaybook/DaybookItem';
import Immutable from 'immutable';
import NumericInput from '@/components/NumericInput';

const daybookItemTemplate = () => {
  return {
    value: '',
    type: '',
    extra: { _internalKey: (Math.random() + 1).toString(36) },
  };
};

const daybookTemplate = {
  site: null,
  daybookDate: dayjs().startOf('day'),
  method: null,

  entryStart: dayjs().hour(7).minute(0).second(0).millisecond(0),
  entryEnd: dayjs().hour(17).minute(30).second(0).millisecond(0),
  entryStartDate: '',
  entryStartTime: '',
  entryEndDate: '',
  entryEndTime: '',

  entryStartWait: null,
  entryEndWait: null,
  entryStartWaitDate: '',
  entryStartWaitTime: '',
  entryEndWaitDate: '',
  entryEndWaitTime: '',
  entryWaitDescription: '',

  entryStartWait2: null,
  entryEndWait2: null,
  entryStartWait2Date: '',
  entryStartWait2Time: '',
  entryEndWait2Date: '',
  entryEndWait2Time: '',
  entryWait2Description: '',

  entryStartWait3: null,
  entryEndWait3: null,
  entryStartWait3Date: '',
  entryStartWait3Time: '',
  entryEndWait3Date: '',
  entryEndWait3Time: '',
  entryWait3Description: '',

  containsLunch: true,
  additionalWork: '',
  siteSituation: '',
  deviation: '',
  deviationReportMade: false,
  ordererResponsibilityItems: '',
  reviewsHeld: '',
  diameter: null,
  drillingLength: null,
  drillingLengthAtDay: null,
  ofWhichRock: null,
  elevationTopEnd: null,
  elevationLowerEnd: null,
  bought: null,
  ordered: null,
  ordererNotices: null,
  other: null,
  userId: null,
  daybookDrillings: [],
  daybookItems: [
    {
      ...daybookItemTemplate(),
      type: 'deviation',
    },
    {
      ...daybookItemTemplate(),
      type: 'bought',
    },
    {
      ...daybookItemTemplate(),
      type: 'ordered',
    },
    {
      ...daybookItemTemplate(),
      type: 'additionalWork',
    },
  ],
};
const dateFormat = 'YYYY-MM-DD';
const daybookDrillingTemplate = {
  extra: null,
  taskOrder: null,
  method: null,
  diameter: null,
  drillingLength: null,
  drillingLengthAtDay: null,
  ofWhichRock: null,
  elevationTopEnd: null,
  elevationLowerEnd: null,
};
export default {
  name: 'SiteDaybookView',
  components: {
    NumericInput,
    DaybookItem,
    EntryTimes,
    SiteCombobox,
    DatePicker,
  },
  data() {
    return {
      loading: false,
      searchPhrase: '',
      loadingSiteWorkerListReport: false,
      backButton: {
        show: true,
      },
      daybookItemTemplate: Immutable.fromJS({ ...daybookItemTemplate() }).toJS(),
      saving: false,
      items: [],
      persons: [],
      workGroups: [],
      equipments: [],
      daybooks: [],
      projects: [],
      workers: [],
      sites: [],
      daybook: Immutable.fromJS({ ...daybookTemplate, userId: this.$userInfo?.id }).toJS(),
      selectedWorker: null,
      dialog: false,
      waitTimes: 1,
    };
  },
  async mounted() {
    this.loading = true;
    this.siteId = this.$route.params.siteId;

    this.id = this.$route.params.id;

    this.daybook = Immutable.fromJS({ ...daybookTemplate, userId: this.$userInfo?.id }).toJS();
    await this.getSites();
    if (this.id) {
      await this.getDaybook();
    } else {
      this.syncEntryTimesAndDates(this.daybook);
      this.syncEntryTimesAndDates(this.daybook, 'Wait');
      this.syncEntryTimesAndDates(this.daybook, 'Wait2');
      this.syncEntryTimesAndDates(this.daybook, 'Wait3');
      this.addDaybookDrillings();
    }

    this.getItems();
    this.getPersons();
    this.getWorkGroups();
    this.getEquipments();
    this.getProjects();
    this.getWorkers();
    this.getDaybooks();

    this.loading = false;
  },
  computed: {
    methods() {
      return this.items.filter(item => item.type === 'menetelmä');
    },
    diameters() {
      return this.items.filter(item => item.type === 'putki');
    },
    centeringRings() {
      return this.items.filter(item => item.type === 'keskitysrengas');
    },
    filteredWorkers() {
      const ids = {};
      this.site.workers?.forEach(w => (ids[w.id] = true));
      return this.workers.filter(w => !ids[w.id]);
    },
    deviations() {
      return (this.daybook.daybookItems || []).filter(item => item.type === 'deviation');
    },
    bought() {
      return (this.daybook.daybookItems || []).filter(item => item.type === 'bought');
    },
    ordered() {
      return (this.daybook.daybookItems || []).filter(item => item.type === 'ordered');
    },
    additionalWork() {
      return (this.daybook.daybookItems || []).filter(item => item.type === 'additionalWork');
    },

    siteDrillings() {
      const d = this.daybook.site?.items?.filter(i => i.task === 'drilling') || [];

      const tasks = Array.from(new Set(d.map(i => i.taskOrder)));

      const res = {};
      tasks.forEach(t => {
        res[t] = d.filter(r => r.taskOrder === t);
      });
      const keys = Object.keys(res);
      keys.sort();
      const res1 = [];
      keys.forEach(k => {
        res1.push({
          pipe: res[k].find(r => r.attribute === 'putki'),
          centeringRings: res[k].filter(r => r.attribute === 'keskitysrengas'),
          method: res[k].find(r => r.attribute === 'menetelmä'),
          flowingWells: res[k].filter(r => r.attribute === 'ylivuotokaivo'),
          endPlugs: res[k].filter(r => r.attribute === 'päätytulppa'),
          task: 'drilling',
          taskOrder: parseInt(k),
        });
      });

      return res1;
    },
  },

  methods: {
    log(...args) {
      console.log(args);
    },
    getClosestHalfHourInterval() {
      const nowMinutes = dayjs().minute();
      if (nowMinutes < 45) {
        return dayjs().format('HH:') + (nowMinutes > 15 ? '30' : '00');
      } else {
        return dayjs().add(1, 'hours').format('HH:') + '00';
      }
    },
    syncEntryTimesAndDates(daybook, appendix = '') {
      if (!daybook[`entryStart${appendix}`]) {
        daybook[`entryStartDate${appendix}`] = dayjs(daybook.daybookDate).format(dateFormat);
        if (appendix === 'Wait' || appendix === 'Wait2' || appendix === 'Wait3') {
          daybook[`entryStartTime${appendix}`] = '';
        } else {
          daybook[`entryStartTime${appendix}`] = this.getClosestHalfHourInterval();
        }
      } else {
        daybook[`entryStartDate${appendix}`] = daybook[`entryStart${appendix}`].format(dateFormat);
        daybook[`entryStartTime${appendix}`] = daybook[`entryStart${appendix}`].format('HH:mm');
      }
      if (!daybook[`entryEnd${appendix}`]) {
        daybook[`entryEndDate${appendix}`] = dayjs(daybook.daybookDate).format(dateFormat);
      } else {
        daybook[`entryEndDate${appendix}`] = daybook[`entryEnd${appendix}`].format(dateFormat);
        daybook[`entryEndTime${appendix}`] = daybook[`entryEnd${appendix}`].format('HH:mm');
      }
      this.handleTimeChangeInDurationDialog(appendix);
    },

    handleTimeChangeInDurationDialog(appendix = '') {
      this.$nextTick(() => {
        this.$refs[`entryTimes${appendix}`]?.$refs?.durationDialog?.updateTimes(appendix);
      });
    },
    handleDurationDialogSave(end, appendix) {
      this.daybook[`entryEnd${appendix}`] = dayjs(end);
      this.daybook[`entryEndDate${appendix}`] = dayjs(end).format(dateFormat);
      this.daybook[`entryEndTime${appendix}`] = dayjs(end).format('HH:mm');
    },

    handleDaybookDateChange(value, appendix = '') {
      this.daybook.daybookDate = dayjs(value).clone().startOf('day');
      if (value) {
        const v = dayjs(value);

        if (!this.daybook[`entryStart${appendix}`]) {
          return;
        }
        const start = dayjs(this.daybook[`entryStart${appendix}`]);
        const end = dayjs(this.daybook[`entryEnd${appendix}`]);

        if (start && start.isValid()) {
          this.daybook[`entryStart${appendix}`] = dayjs(
            `${v.year()}-${v.month() + 1}-${v.date()} ${start.hour()}:${start.minute()}:00`,
            'YYYY-M-D H:m:s'
          );
        }
        if (end && end.isValid()) {
          this.daybook[`entryEnd${appendix}`] = dayjs(
            `${v.year()}-${v.month() + 1}-${v.date()} ${end.hour()}:${end.minute()}:00`,
            'YYYY-M-D H:m:s'
          );
        }
      }
      this.syncEntryTimesAndDates(this.daybook, appendix);
    },
    handleSiteChange(value) {
      this.daybook.site = value;
    },
    checkIfAddedDaybookItemImageWithoutValue(refKey) {
      if (this.$refs[refKey] && this.$refs[refKey].$refs && this.daybook?.id > 0) {
        const refKeys = Object.keys(this.$refs[refKey].$refs);
        for (let i = 0; i < refKeys.length; i++) {
          const r = refKeys[i];
          if (r.indexOf('images_') > -1) {
            const item = this.daybook.daybookItems.find(
              item => item?.extra?._internalKey === this.$refs[refKey].$refs[r]?.[0]?.item?.extra?._internalKey
            );
            if (item && !item.value && this.$refs[refKey].$refs[r]?.[0]?.addedFiles?.length > 0) {
              this.$showWarningNotification(this.$t('daybook.warning_add_value_for_deviation_with_image'));
              return false;
            }
          }
        }
      }
      return true;
    },
    async uploadDaybookItemImages(daybook, refKey) {
      if (this.$refs[refKey] && this.$refs[refKey].$refs && daybook?.id > 0) {
        const refKeys = Object.keys(this.$refs[refKey].$refs);

        for (let i = 0; i < refKeys.length; i++) {
          const r = refKeys[i];
          if (r.indexOf('images_') > -1) {
            const item = daybook.daybookItems.find(
              item => item?.extra?._internalKey === this.$refs[refKey].$refs[r]?.[0]?.item?.extra?._internalKey
            );
            if (item?.id > 0) {
              await this.$refs[refKey].$refs[r]?.[0]?.uploadFiles?.(item.id);
            }
          }
        }
      }
    },
    createChangedEntryDate(dateType = 'start', appendix = '') {
      const key = `entry${dateType.capitalize()}${appendix}`;

      if (this.daybook[`entry${dateType.capitalize()}Time${appendix}`] === null) {
        this.daybook[key] = null;
      } else {
        this.daybook[`entry${dateType.capitalize()}Date${appendix}`] = dayjs(this.daybook.daybookDate).format('YYYY-MM-DD');
        const d = dayjs(
          `${this.daybook[`entry${dateType.capitalize()}Date${appendix}`]} ${this.daybook[`entry${dateType.capitalize()}Time${appendix}`]}`
        );
        if (d.isValid()) this.daybook[key] = d;
      }

      this.handleTimeChangeInDurationDialog(appendix);
    },
    async saveDaybook() {
      /*if (
        !(
          (this.daybook.drillingLengthAtDay === 0 || this.daybook.drillingLengthAtDay === null) ||
          this.daybook.site?.minDrillingLength === null ||
          this.daybook.drillingLengthAtDay >= this.daybook.site?.minDrillingLength
        )
      ) {
        this.$showErrorNotification(this.$t('daybook.drilling_length_validation_error'));
        return;
      }*/

      this.calculateTotalLengths();
      if (!this.checkIfAddedDaybookItemImageWithoutValue('deviationDaybookItems')) return;
      if (!this.checkIfAddedDaybookItemImageWithoutValue('boughtDaybookItems')) return;
      if (!this.checkIfAddedDaybookItemImageWithoutValue('orderedDaybookItems')) return;
      this.saving = true;

      let daybook = Immutable.fromJS(this.daybook).toJS();
      console.log(daybook);
      daybook.daybookItems = daybook.daybookItems?.filter(d => d.id || d.value);
      /*daybook.daybookDrillings = daybook.daybookDrillings?.filter(
        d => d.drillingLengthAtDay !== null || d.ofWhichRock !== null || d.elevationTopEnd !== null || d.elevationLowerEnd !== null
      );*/
      if (
        ((daybook.entryStartWait && dayjs(daybook.entryStartWait).format('HHmm') === '0000') || daybook.entryStartWait2 === null) &&
        !daybook.entryEndWait
      ) {
        daybook.entryStartWait = null;
      }
      if (
        ((daybook.entryStartWait2 && dayjs(daybook.entryStartWait2).format('HHmm') === '0000') || daybook.entryStartWait2 === null) &&
        !daybook.entryEndWait2
      ) {
        daybook.entryStartWait2 = null;
      }
      if (
        ((daybook.entryStartWait3 && dayjs(daybook.entryStartWait3).format('HHmm') === '0000') || daybook.entryStartWait3 === null) &&
        !daybook.entryEndWait3
      ) {
        daybook.entryStartWait3 = null;
      }
      try {
        if (daybook.id > 0) {
          daybook = await daybookApi.updateDaybook(this.$route.params.id, daybook); // CTRL+P näytttää mitä pitää laittaa
          this.$showSuccessNotification(this.$t('daybook.saved'));
        } else {
          await this.getDaybooks();

          const siteDaybookDates = this.daybooks.map(d => d.daybookDate.format('DD.MM.YYYY'));
          if (siteDaybookDates.includes(this.daybook.daybookDate.format('DD.MM.YYYY'))) {
            this.$showErrorNotification(this.$t('daybook.daybook_exists'));
            this.saving = false;
            return;
          }

          daybook = await daybookApi.createDaybook(daybook);
          this.$showSuccessNotification(this.$t('daybook.created'));
          await this.$router.replace({ name: 'tenant_site_daybook', params: { siteId: daybook.site.id, id: daybook.id } });
        }

        await this.uploadDaybookItemImages(daybook, 'deviationDaybookItems');
        await this.uploadDaybookItemImages(daybook, 'boughtDaybookItems');
        await this.uploadDaybookItemImages(daybook, 'orderedDaybookItems');
      } catch (e) {
        console.error(e);
        this.$showErrorNotification(this.$t('generic_error'));
        this.saving = false;
        return;
      }
      //await this.getDaybook();
      this.saving = false;
      //await this.$router.go(-1);
    },
    async cancel() {
      await this.$router.go(-1);
    },
    async getDaybook() {
      this.loading = true;

      const daybook = await daybookApi.getDaybook(this.$route.params.id);
      this.syncEntryTimesAndDates(daybook);
      this.syncEntryTimesAndDates(daybook, 'Wait');
      this.syncEntryTimesAndDates(daybook, 'Wait2');
      this.syncEntryTimesAndDates(daybook, 'Wait3');
      if ((daybook.entryStartWait2 && dayjs(daybook.entryStartWait2).format('HHmm') !== '0000') || daybook.entryEndWait2 !== null) {
        this.waitTimes = 2;
      }
      if ((daybook.entryStartWait3 && dayjs(daybook.entryStartWait3).format('HHmm') !== '0000') || daybook.entryEndWait3 !== null) {
        this.waitTimes = 3;
      }
      this.daybook = daybook;

      this.addDaybookDrillings();

      this.loading = false;
    },
    async addDaybookDrillings() {
      this.siteDrillings.forEach(drilling => {
        const d = this.daybook.daybookDrillings.find(dd => dd.taskOrder === drilling.taskOrder);
        if (!d) {
          if (this.daybook.daybookDrillings === null) {
            this.daybook.daybookDrillings = [];
          }
          this.daybook.daybookDrillings.push({
            ...Immutable.fromJS(daybookDrillingTemplate).toJS(),
            taskOrder: drilling.taskOrder,
            method: drilling.method?.item,
            diameter: drilling.pipe?.item,
          });
        }
      });
    },
    async getItems() {
      const items = await itemApi.getItemsByTypes(['putki', 'menetelmä', 'keskitysrengas']);
      this.items = items;
    },
    async getPersons() {
      this.persons = await personApi.getPersons();
    },
    async getSites() {
      this.sites = [await siteApi.getSite(this.siteId)];
      if (!this.daybook.site) this.daybook.site = this.sites[0];
      /*if (!this.daybook.method && this.daybook.site && this.daybook.site.methods && this.daybook.site.methods.length > 0) {
        this.daybook.method = this.daybook.site.methods[0];
      }
      if (!this.daybook.diameter && this.daybook.site && this.daybook.site.diameters && this.daybook.site.diameters.length > 0) {
        this.daybook.diameter = this.daybook.site.diameters[0];
      }*/
    },

    async getWorkGroups() {
      this.workGroups = await workGroupApi.getWorkGroups();
    },
    async getEquipments() {
      this.equipments = await equipmentApi.getAllEquipments();
    },
    async getProjects() {
      this.projects = await projectApi.getProjects();
    },
    async getDaybooks() {
      this.daybooks = await daybookApi.getDaybooksBySiteId(this.siteId);

      if (this.$route.name === 'tenant_site_new_daybook') {
        const siteDaybookDates = this.daybooks.map(d => d.daybookDate.format('DD.MM.YYYY'));
        if (siteDaybookDates.includes(this.daybook.daybookDate.format('DD.MM.YYYY'))) {
          this.$showWarningNotification(this.$t('daybook.daybook_exists'));
        }
      }
    },
    async getWorkers() {
      this.workers = (await workerApi.getWorkers()).map(worker => {
        worker.workerId = worker.id;
        return worker;
      });
    },
    async onWorkGroupChange(workGroup) {
      if (this.site.workers?.length === 0 && workGroup && workGroup.workers) {
        this.site.workers = [...workGroup.workers];
        this.$showSuccessNotification(this.$t('sites.messages.workers_added'));
      }
    },
    async deleteWorker(worker) {
      const wIndex = this.site.workers.findIndex(w => w.workerId === worker.workerId);
      if (wIndex > -1) this.site.workers.splice(wIndex, 1);
    },
    calculateTotalLengths() {
      this.daybook.daybookDrillings.forEach(dd => {
        dd.drillingLength =
          this.daybooks
            .map(daybook => {
              if (dayjs(daybook.daybookDate).isBefore(this.daybook.daybookDate)) {
                return daybook.daybookDrillings
                  .filter(_dd => _dd.taskOrder === dd.taskOrder && _dd.drillingLengthAtDay > 0)
                  .map(_dd => _dd.drillingLengthAtDay)
                  .filter(Boolean)
                  .reduce((c, p) => parseFloat(p) + c, 0);
              }
              return null;
            })
            .filter(Boolean)
            .reduce((c, p) => parseFloat(p) + c, 0) + parseFloat(dd.drillingLengthAtDay || 0);
      });
    },
  },
  watch: {
    'daybook.entryStartDate'() {
      this.createChangedEntryDate('start');
    },
    'daybook.entryStartTime'() {
      this.createChangedEntryDate('start');
    },
    'daybook.entryEndDate'() {
      this.createChangedEntryDate('end');
    },
    'daybook.entryEndTime'() {
      this.createChangedEntryDate('end');
    },

    'daybook.entryStartDateWait'() {
      this.createChangedEntryDate('start', 'Wait');
    },
    'daybook.entryStartTimeWait'() {
      this.createChangedEntryDate('start', 'Wait');
    },
    'daybook.entryEndDateWait'() {
      this.createChangedEntryDate('end', 'Wait');
    },
    'daybook.entryEndTimeWait'() {
      this.createChangedEntryDate('end', 'Wait');
    },

    'daybook.entryStartDateWait2'() {
      this.createChangedEntryDate('start', 'Wait2');
    },
    'daybook.entryStartTimeWait2'() {
      this.createChangedEntryDate('start', 'Wait2');
    },
    'daybook.entryEndDateWait2'() {
      this.createChangedEntryDate('end', 'Wait2');
    },
    'daybook.entryEndTimeWait2'() {
      this.createChangedEntryDate('end', 'Wait2');
    },

    'daybook.entryStartDateWait3'() {
      this.createChangedEntryDate('start', 'Wait3');
    },
    'daybook.entryStartTimeWait3'() {
      this.createChangedEntryDate('start', 'Wait3');
    },
    'daybook.entryEndDateWait3'() {
      this.createChangedEntryDate('end', 'Wait3');
    },
    'daybook.entryEndTimeWait3'() {
      this.createChangedEntryDate('end', 'Wait3');
    },
  },
};
</script>

<style scoped>
.button_group {
  position: fixed;
  bottom: 16px;
  right: 16px;
}

.grey--bg {
  background-color: var(--v-default-lighten1);
}
</style>
