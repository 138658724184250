<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-dialog
    v-model="dialog"
    :max-width="$vuetify.breakpoint.xsOnly ? '' : '600'"
    :hide-overlay="$vuetify.breakpoint.xsOnly"
    :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : ''"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>{{ $t('daybook.daybook_dialog_in_sites_speed_dial.new_daybook') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="gradient-icons">
        <site-combobox
          v-if="sites"
          :hide-details="false"
          :icon="false"
          class="mt-6"
          :saving="loading"
          :multiple="false"
          :label="$t('timer.site')"
          :sites="sites"
          :value="selectedSite"
          @change="handleSiteChange"
          :required="true"
        />
        <v-alert :value="showAlert" outlined class="ma-0" type="error">{{
          $t('daybook.daybook_dialog_in_sites_speed_dial.site_is_not_selected')
        }}</v-alert>
      </v-card-text>
      <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :disabled="loading" :loading="loading" class="mb-1" color="primary" @click="openDaybook" :block="$vuetify.breakpoint.xsOnly">
          <span>{{ $t('daybook.daybook_dialog_in_sites_speed_dial.new_daybook_button') }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SiteCombobox from '@/components/SiteCombobox';
import siteApi from '@/api/site';
import daybookApi from '@/api/daybook';
import 'dayjs';
import dayjs from 'dayjs';

export default {
  name: 'selectSiteDialog',
  components: { SiteCombobox },
  data() {
    return {
      showAlert: false,
      dialog: false,
      saving: false,
      loading: false,
      selectedSite: null,
      sites: [],
    };
  },
  methods: {
    async siteDaybook() {
      if (this.selectedSite) await this.$router.push({ name: 'tenant_site_new_daybook', params: { siteId: this.selectedSite.id } });
    },
    async goToDaybook(id) {
      await this.$router.push({ name: 'tenant_site_daybook', params: { siteId: this.selectedSite.id, id: id } });
    },
    handleSiteChange(value) {
      this.selectedSite = value;
    },
    async getOwnSites() {
      const sites = await siteApi.getSites();
      const ownSites = sites.filter(s => s.workers?.findIndex(w => w.userId === this.$userInfo.id) > -1);
      this.sites = ownSites.filter(s => s.plannedStartDate);
    },
    async openDaybook() {
      if (this.selectedSite === null) {
        this.showAlert = true;
      } else {
        this.showAlert = false;
        const date = dayjs().startOf('day');
        const daybooks = (await daybookApi.getDaybooksBySiteId(this.selectedSite.id)).filter(
          d => d.daybook !== null && dayjs(d.daybookDate).startOf('day').isSame(date)
        );
        console.log(daybooks);
        if (daybooks.length > 0) {
          await this.goToDaybook(daybooks[0].id);
        } else {
          await this.siteDaybook();
        }
      }
    },
    cancel() {
      this.loading = false;
      this.settingLocation = false;
      this.searchPhrase = '';
      this.dialog = false;
    },
    async open() {
      this.dialog = true;
      this.loading = true;
      this.getOwnSites();
      this.loading = false;
    },
  },
};
</script>

<style lang="scss"></style>
