<template>
  <v-select
    :items="filterOptions"
    :placeholder="`${$t('user_management.filtered')} 0 ${$t('user_management.selections')}`"
    item-text="desc"
    item-value="value"
    multiple
    hide-details
    single-line
    solo
    prepend-inner-icon="filter_list"
    :value="filter"
    @change="value => this.$emit('change', value)"
    @blur="() => this.$emit('blur')"
  >
    <template v-slot:selection="{ item, index }">
      <template v-if="filter.length !== filterOptions.length && index === 0">
        <span class="mr-1">{{ $t('user_management.filtered') }}</span>
        <span class="caption">({{ filter.length }} {{ $t('user_management.selections') }})</span>
      </template>
      <template v-else>
        <span v-if="index === 0">{{ $t('user_management.show_all') }}</span>
      </template>
    </template>
  </v-select>
</template>

<script>
export default {
  name: 'CheckboxFilter',
  props: {
    filterOptions: {
      type: Array,
      required: true,
    },
    filter: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped></style>
