<template>
  <hb-basic-page
    :title="$t('equipments.title')"
    fluid
    :loading="loading"
    :fill-height="loading"
    :extension-height="60"
    :search-title="`${$t('equipments.search')}...`"
    @handle-search="handleSearch"
    class="equipments-view"
    :pa-0="$vuetify.breakpoint.xsOnly"
    v-if="$isTenantAdmin"
  >
    <template v-if="$vuetify.breakpoint.smAndUp" v-slot:app-bar-extension>
      <v-btn @click="openNewEquipmentInDialog" color="primary" class="mb-3 ml-n1">
        <v-icon class="no-gradient" left>mdi-plus</v-icon>
        <span>{{ $t('equipments.create_new_equipment') }}</span>
      </v-btn>
    </template>
    <v-col cols="12" class="gradient-icons">
      <v-fade-transition>
        <v-row no-gutters>
          <v-btn v-if="$vuetify.breakpoint.xsOnly" @click="openNewEquipmentInDialog" color="primary" fab fixed bottom right>
            <v-icon class="no-gradient">mdi-plus</v-icon>
          </v-btn>
          <v-col cols="12">
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="filteredEquipments"
                  class="h-100 gradient-info__table"
                  :loading="loading"
                  :footer-props="{ itemsPerPageOptions: [50, 100, 500, -1] }"
                  :items-per-page="50"
                  @click:row="openEquipmentInDialog"
                  style="cursor: pointer"
                >
                </v-data-table>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-fade-transition>
    </v-col>
    <equipment-dialog ref="equipmentDialog" @save="reloadEquipments" />
  </hb-basic-page>
</template>

<script>
import EquipmentDialog from '../../components/Equipments/EquipmentDialog.vue';
import equipmentApi from '@/api/equipment';
export default {
  name: 'EquipmentsView',
  components: {
    EquipmentDialog: EquipmentDialog,
  },
  data() {
    return {
      loading: true,
      searchPhrase: '',
      offsetTop: 0,
      filter: ['ACTIVE'],
      filterOptions: [
        { desc: 'Aktiiviset', value: 'ACTIVE' },
        { desc: 'Ei aktiiviset', value: 'DISABLED' },
      ],
      headers: [
        {
          text: this.$tc('equipments.license_number', 1),
          value: 'licenseNumber',
        },
        {
          text: this.$tc('equipments.make', 1),
          value: 'make',
        },
        {
          text: this.$t('equipments.year'),
          value: 'year',
        },
        {
          text: this.$tc('equipments.title', 1),
          value: 'type.name',
        },
        {
          text: this.$tc('equipments.description', 1),
          value: 'description',
          sortable: false,
        },
        {
          text: '',
          value: 'actions',
        },
      ],
      equipments: [],
    };
  },
  methods: {
    handleSearch(searchPhrase) {
      this.searchPhrase = searchPhrase;
    },
    openNewEquipmentInDialog() {
      this.$refs.equipmentDialog.openNew();
    },
    openEquipmentInDialog(equipment) {
      this.$refs.equipmentDialog.openEquipment(equipment);
    },
    async reloadEquipments() {
      if (this.equipments.length === 0) {
        this.loading = true;
      }
      this.equipments = await equipmentApi.getAllEquipments();
      this.loading = false;
    },
    getStatusColor(status) {
      switch (status) {
        case 0:
          return 'grey darken-1';
        case 10:
          return 'success';
      }
    },
  },
  computed: {
    filteredEquipments() {
      const filteredEquipments = this.equipments;

      const filterOutActive = this.filter.findIndex(f => f === 'ACTIVE') === -1;
      const filterOutInactive = this.filter.findIndex(f => f === 'DISABLED') === -1;

      if ((this.searchPhrase == null || this.searchPhrase.length === 0) && !filterOutActive && !filterOutInactive) {
        return filteredEquipments;
      }

      const searchPhraseLower = this.searchPhrase.toLowerCase();
      const searchPhraseArray = searchPhraseLower.split(' ');
      return filteredEquipments.filter(equipment => {
        let found = [];
        const itemCompleteSearchPhrase =
          equipment.make + equipment.description + equipment.licenseNumber + equipment.type.name + equipment.description;
        const lowerSearchedString = itemCompleteSearchPhrase.toLowerCase();
        searchPhraseArray.forEach(phrase => {
          if (lowerSearchedString.indexOf(phrase) !== -1) {
            found.push(true);
          } else {
            found.push(false);
          }
        });
        if (filterOutActive && equipment.status === 'ACTIVE') found.push(false);
        if (filterOutInactive && equipment.status === 'DISABLED') found.push(false);
        return found.indexOf(false) === -1;
      });
    },
  },
  mounted() {
    this.$vuetify.goTo(0, {
      duration: 0,
      offset: 0,
    });
    this.reloadEquipments();
  },
};
</script>

<style scoped></style>
