<template>
  <v-autocomplete
    v-bind="$attrs"
    :clearable="!$attrs.readonly"
    outlined
    dense
    return-object
    light
    :hide-details="hideDetails"
    autocomplete="off"
    item-value="id"
    @change="value => this.$emit('change', value)"
    :item-text="item => [item.name].filter(Boolean).join(' ')"
    :items="items"
    :label="label"
    :disabled="saving || disabled"
    :value="value"
    :multiple="multiple"
    :rules="[required && $rules.required].filter(Boolean)"
  >
    <template v-slot:selection="{ parent, item, select, selected, disabled, attrs, on }">
      <span v-on="on" v-bind="attrs" :key="item.id">
        {{ item.name }}
      </span>
    </template>
    <template v-slot:item="data">
      <v-list-item v-on="data.on" v-bind="data.attrs" :key="data.item.id">
        <v-list-item-content>
          <v-list-item-title>
            {{ data.item.name }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template v-slot:prepend>
      <slot name="prepend"></slot>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: 'ItemCombobox',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      required: true,
      type: String,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    saving: Boolean,
    items: Array,
    value: [Object, Array],
    required: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped></style>
